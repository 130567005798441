import { PublicClientApplication, SilentRequest, AuthenticationResult, AccountInfo, InteractionRequiredAuthError, EndSessionRequest, RedirectRequest } from '@azure/msal-browser';
import { MSAL_CONFIG } from './authConfig';
import UserInfo from '../models/UserInfo';
import UsersService from '../services/UsersService';

const myMSALObj = await PublicClientApplication.createPublicClientApplication(MSAL_CONFIG);
const userService: UsersService = new UsersService();
let account: AccountInfo = getAccount();

const loginRedirectRequest: RedirectRequest = {
	scopes: [],
	redirectStartPage: window.location.href
};

const profileRedirectRequest: RedirectRequest = {
	scopes: [],
	redirectStartPage: window.location.href
};

const silentProfileRequest: SilentRequest = {
	scopes: [`${MSAL_CONFIG.auth.clientId}/.default`],
	account: null,
	forceRefresh: false
};

export let authenticatedUser: UserInfo;

export async function loadAuthModule(): Promise<void> {
	const resp: AuthenticationResult = await myMSALObj.handleRedirectPromise();
	handleResponse(resp);

	const info = await userService.getMyInfo();
	authenticatedUser = new UserInfo(info);
}

function handleResponse(response: AuthenticationResult) {
	if (response !== null) {
		account = response.account;
	} else {
		account = getAccount();
	}

	if (!account) {
		login();
	}
}

function getAccount(): AccountInfo {
	const currentAccounts = myMSALObj.getAllAccounts();
	if (currentAccounts.length === 0) {
		return null;       
	}

	if (currentAccounts.length > 1) {
		// Add choose account code here
		console.log('Multiple accounts detected, need to add choose account code.');
		return currentAccounts[0];
	} if (currentAccounts.length === 1) {
		return currentAccounts[0];
	}
}

export function login(): void {
	myMSALObj.loginRedirect(loginRedirectRequest);
}

export function logout(): void {
	const logOutRequest: EndSessionRequest = {
		account
	};

	myMSALObj.logout(logOutRequest);
}

/**
 * Gets a token silently, or falls back to interactive redirect.
 */
export async function getToken(): Promise<string> {
	silentProfileRequest.account = account;

	try {
		const response: AuthenticationResult = await myMSALObj.acquireTokenSilent(silentProfileRequest);
		//Just allowing access to users with Portal_Allowed_Users role
		if (response.account.idTokenClaims.roles.includes("Portal_Allowed_Users")){
			return response.accessToken;
		}
		else
			throw new TypeError("Access Not Allowed");
	} catch (error) {
		console.log('silent token acquisition fails.');
		if (error instanceof InteractionRequiredAuthError) {
			console.log('acquiring token using redirect');
			myMSALObj.acquireTokenRedirect(profileRedirectRequest).catch(console.error);
		} else {
			console.error(error);
		}
	}
}