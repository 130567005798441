import * as React from 'react';
import parse from 'html-react-parser';
import UserLookup from '../../common/form-controls/UserLookup';
import FieldHandler from '../../../models/interfaces/FieldHandler';

interface DelegatedApproverInputProps extends FieldHandler<string> {
	higherApproval: boolean;
	scope?: string;
}

interface DelegatedApproverInputState {}

export class DelegatedApproverInput extends React.Component<DelegatedApproverInputProps, DelegatedApproverInputState> {
	note = 'Use this to specify an alternate DOAG approver when the original DOAG is not available. It is your responsability to have checked the delegated DOAG has been designated in ';
	
	link = 'https://dalsql320.na.xom.com/ReportServer/Pages/ReportViewer.aspx?/GRDB/DOAG';

	render() {
		return (
			<UserLookup
				label="Delegated Approver"
				isEmployee
				note={<span>
					{this.note}
					{<a style={{ color: '#0c69b0', textDecoration: 'underline' }} href={this.link} target="_blank">eDOAG</a>}
				</span>
				}
				{...this.props}
			/>
		);
	}
}