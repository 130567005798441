import React, { useState } from 'react';
import { Select, Table, Toolbar, TextLink, ProgressBar, AlertVariant, Button, PlusFilledIcon, TextInput, Searchbar } from 'react-unity';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import PortOpeningRequest from '../../models/entities/PortOpeningRequest';
import PortOpeningRequestService from '../../services/PortOpeningRequestService';
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import { LinkTreeItem } from '../common/LinkTree';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import AlertModal from '../common/modals/AlertModal';
import Paginator from '../common/tables/Paginator';
import NewConnectedPORInfoModal from './components/NewConnectedPORInfoModal';
import './PortOpeningRequestsHome.css';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import CheckboxFilter, { CheckboxOption } from '../common/tables/CheckboxFilter';
import Filter from '../common/tables/Filter';
import serviceNow from '../../services/serviceNow.config';
import styled from 'styled-components';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { WorkflowState } from '../../models/enums/WorkflowState';
import ProgressBarSmall from './components/ProgressBarSmall';
import moment from 'moment';

export type PORFilters = {
	scope: CheckboxOption[];
	status: CheckboxOption[];
	searchUser: string;
	subscriptionId: number;
	orderBy: string;
};

const MyP = styled.p`
	margin-right: 8px;
	margin-bottom: auto !important;
	margin-top: auto !important;
`;

const StyledItem = styled(Toolbar.Item)`
	width: 50%;
`;

const MyLink = styled.span`
	color: #0c69b0;
	text-decoration: underline;
	cursor: pointer;
`;

interface PortOpeningRequestsHomeProps extends PortOpeningRequestsHomeWithParamsProps {}

interface PortOpeningRequestsHomeWithParamsProps {
	scopes?: PortOpeningRequestsDirection[];
	subtitle?: string;
	navigate?: any;
	params?: Params<string>;
}

interface PortOpeningRequestsHomeWithParamsState {
	loading: boolean;
	subscription?: SubscriptionRequest;
	requests: PortOpeningRequest[];
	filteredRequests: PortOpeningRequest[];
	endModal: AlertBanner;
	newConnectedPORInfoModalVisible: boolean;
	
	visibleRequests: PortOpeningRequest[];
}

interface PORByRequestFilterProps {
	pors: PortOpeningRequest[];
	onFilterChange: any;
	subscriptionId: number;
	subscriptionOwner: string;
}

class PortOpeningRequestsHomeWithParams extends
	React.Component<PortOpeningRequestsHomeWithParamsProps, PortOpeningRequestsHomeWithParamsState> {
	
	portOpeningRequestService: PortOpeningRequestService;

	subscriptionRequestsService: SubscriptionRequestsService;

	filter: Filter<PORFilters>;
	
	constructor(props) {
		super(props);
		this.portOpeningRequestService = new PortOpeningRequestService();
		this.subscriptionRequestsService = new SubscriptionRequestsService();
		this.state = {
			loading: true,
			requests: [],
			filteredRequests: [],
			visibleRequests: [],
			endModal: {
				visible: false,
			},
			newConnectedPORInfoModalVisible: false,
		};

		this.filter = new Filter(this.filterPORs, this.getDefaultFilters());
	}

	async componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		if (this.props.params.id) {
			const id = parseInt(this.props.params.id);
			const subscription = new SubscriptionRequest(await this.subscriptionRequestsService.getById(id));

			this.setState({
				subscription
			});
		} else {
			this.setState({
				subscription: null
			});
		}
		await this.loadRequests();
	};

	getLinkTree = (): LinkTreeItem[] => {
		return (this.state.subscription?.id && [
			{ text: 'Subscriptions', to: '/subscriptions' },
			{ text: this.state.subscription?.createdSubscriptionName, to: `/subscriptions/${this.state.subscription?.id}` },
		]) || [];
	};

	loadRequests = async () => {
		try {
			let requests = [];
			this.setState({ loading: true });
			if (this.state.subscription?.id) {
				requests = await this.portOpeningRequestService.getBySubscriptionId(this.state.subscription?.id);
			} else {
				requests = await this.portOpeningRequestService.getMyRequests();
			}

			requests = requests.map(r => new PortOpeningRequest(r))
				.reverse()
				.filter(request => this.props.scopes.includes(request.direction));
		
			this.setState({
				requests,
				loading: false,
			}, this.filter.filter);

		} catch {
			this.setState({
				loading: false,
			});
			this.handleEndModal('Something went wrong, try again later.', 'error', 5000);
		}
	};
	
	handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		this.setState({
			endModal: {
				visible: true,
				text,
				variant,
			}
		},
		() => {
			setTimeout(() => {
				this.redirectToSubscriptions();
			}, timeout);
		});
	};

	redirectToSubscriptions = () => {
		this.setState({
			endModal: { visible: false }
		},
		() => {
			this.props.navigate('/subscriptions');
		});
	};

	getDefaultFilters = () => {
		const defaultFilters = {
			searchUser: '',
			status: [
				{ label: 'Submitted', value: 'Submitted', checked: true },
				{ label: 'Rejected', value: 'Rejected', checked: true },
				{ label: 'Pending for DOAG Approval', value: 'Pending for DOAG Approval', checked: true },
				{ label: 'Pending for Cloud Network Approval', value: 'Pending for Cloud Network Approval', checked: true },
				{ label: 'Pending for Gateway Policy Team Approval', value: 'Pending for Gateway Policy Team Approval', checked: true },
				{ label: 'Review', value: 'Review', checked: true },
				{ label: 'Awaiting Implementation', value: 'Awaiting Implementation', checked: true },
				{ label: 'Completed', value: 'Completed', checked: true },
				{ label: 'Cancelled', value: 'Cancelled', checked: true },
				{ label: 'Draft', value: 'Draft', checked: true },
			],
			scope:
				[
					{ label: 'Subscription <-> ExxonMobil Network', value: PortOpeningRequestsDirection.SubscriptionToOnPrem.name, checked: true },
					{ label: 'Subscription -> Internet', value: PortOpeningRequestsDirection.SubscriptionToInternet.name, checked: true }
				]
					
		} as PORFilters;

		return defaultFilters;
	};

	filterPORs = () => {
		let filteredRequests = [];

		const filterCallback = (por: PortOpeningRequest) => {
			return this.filter.filterCheckBox('status', por.workflowInstance.currentWorkflowInstanceState.workflowState?.name)
			&& this.filter.filterCheckBox('scope', por.direction.name)
			&& (this.filter.filterSearchText('searchUser', por.workflowInstance.createdBy.displayName) ||
			 this.filter.filterSearchText('searchUser', por.contactUser?.displayName) ||
			 this.filter.filterSearchText('searchUser', por.id.toString()));
		};

		filteredRequests = this.state.requests.filter(filterCallback);

		this.setState({
			filteredRequests
		});
		
	};

	goToNewRequest = () => {
		this.props.navigate(
			'/new'
		);
	};

	goToPORDetails = (request) => {
		this.props.navigate(
			`/subscriptions/${request.subscriptionRequestId}/portOpeningRequests/${request.id}`
		);
	};

	getTicketURL = (ticket: string) => {
		return ticket.toUpperCase().includes('CRQ') ?
			`https://itconsole.na.xom.com/#/${ticket}`
			: `https://${serviceNow.serviceNowEnv}.service-now.com/nav_to.do?uri=change_request.do?sysparm_query=number=${ticket}&sysparm_limit=1`;
	};

	clearSearchbar = (e) => {
		e.preventDefault();
		this.filter.updateFilter('', 'searchUser');
	};

	render() {
		const rows = this.state.visibleRequests?.map(request => (
			<Table.Body.Row key={request.id}>
				<Table.Body.Cell>
					<TextLink onClick={() => this.goToPORDetails(request)} ># {request.id}</TextLink>
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.direction.displayName}
				</Table.Body.Cell>
				<Table.Body.Cell>
					<ProgressBarSmall workflowState={request.workflowInstance.currentWorkflowInstanceState.workflowState} porScope={request.direction.name}/>
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.implementationTicket() !== '' ?
						<TextLink
							external
							href={this.getTicketURL(request.implementationTicket())}
							target="_blank"
						>
							{request.implementationTicket()}&nbsp;
						</TextLink>
						:
						'-'}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.workflowInstance.createdBy.displayName}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.contactUser?.displayName}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.workflowInstance.currentWorkflowInstanceState.workflowState === WorkflowState.PendingSubscriptionOwnerApproval ? this.state.subscription?.owner.displayName : request.approvingUser?.displayName}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{moment(new Date(request.workflowInstance.createdDate)).format('YYYY-MM-DD')}
				</Table.Body.Cell>
			</Table.Body.Row>
		));

		return (
			<>
				<SectionHomeWrapper
					title={`Port Opening Requests${this.props.subtitle ? `: ${this.props.subtitle}`: ''}`}
					linkTree={this.getLinkTree()}
				>
					<p>
						For more information about PORs {' '}
						<TextLink className='em-u-margin-right-none' external target="_blank" href='https://appwiki.xom.cloud/docs/ConnectedAzure/PortOpeningRequests.html'>
							Click here
						</TextLink>
					</p>
					{this.state.loading ?
						<ProgressBar
							label="Loading requests..."
							className="em-u-margin-top-half"
							indeterminate
							hideValueLabel
						/> :
						<>	
							<Table
								header={
									<><Toolbar>
										<StyledItem>
											<Searchbar
												condensed
												buttonProps={{ onClick: this.clearSearchbar }}
												inputProps={{
													value: this.filter.filters.searchUser,
													onChange: (e) => this.filter.handleSearchTextChange(e, 'searchUser'),
													placeholder: 'Search all fields shown below (i.e. specific IP address, Description, etc.)',
												}} />
										</StyledItem>
										<Toolbar.Item right>
												<Button
													variant="primary"
													size="small"
													onClick={() => {
														if ((this.props.params.id &&
															this.state.subscription?.canSubmitPortOpeningRequests()) ||
															!this.props.scopes.includes(PortOpeningRequestsDirection.SubscriptionToInternet) ||
															!this.props.scopes.includes(PortOpeningRequestsDirection.SubscriptionToOnPrem)) {
															this.goToNewRequest();
														} else {
															this.setState({
																newConnectedPORInfoModalVisible: true
															});
														}
													} }
													disabled={this.state.subscription ?
														!this.state.subscription?.canSubmitPortOpeningRequests()
														: false}
												>
													<PlusFilledIcon size="small" />
													<span>New Request</span>
												</Button>
											</Toolbar.Item>
									</Toolbar>
									<Toolbar>
											<Toolbar.Item>
												<CheckboxFilter
													label="Scope"
													buttonProps={{ size: 'small' }}
													onChange={(event) => this.filter.handleCheckboxChange(event, 'scope')}
													options={this.filter.filters.scope} />
											</Toolbar.Item>
											<Toolbar.Item>
												<CheckboxFilter
													label="Status"
													onChange={(event) => this.filter.handleCheckboxChange(event, 'status')}
													options={this.filter.filters.status} />
											</Toolbar.Item>
											<Toolbar.Item>
												<MyLink onClick={() => this.filter.updateFilters(this.getDefaultFilters())}>Clear filters</MyLink>
											</Toolbar.Item>
												<Toolbar.Item right>
													<Button.Group>
														<MyP>Sort by</MyP>
														<Select
															style={{ marginBottom: -12 }}
															value={this.filter.filters.orderBy}
															onChange={(e) => this.filter.handleSearchTextChange(e, 'orderBy')}
														>
															<option className='em-c-field-option' value="newest">Newest</option>
															<option className='em-c-field-option' value="oldest">Oldest</option>
														</Select>
													</Button.Group>
												</Toolbar.Item>
										</Toolbar></>
								}
								footer={
									<Paginator
										data={this.state.filteredRequests}
										onPageChange={(page) => {
											this.setState({
												visibleRequests: page
											});
										}}
									/>
								}
							>
								<Table.Head>
									<Table.Head.Row>
										<Table.Head.Cell> POR # </Table.Head.Cell>
										<Table.Head.Cell> Scope </Table.Head.Cell>
										<Table.Head.Cell> Status </Table.Head.Cell>
										<Table.Head.Cell> CRQ # </Table.Head.Cell>
										<Table.Head.Cell> Requestor </Table.Head.Cell>
										<Table.Head.Cell> Contact </Table.Head.Cell>
										<Table.Head.Cell> Approver </Table.Head.Cell>
										<Table.Head.Cell> Requested Date </Table.Head.Cell>
									</Table.Head.Row>
								</Table.Head>
								<Table.Body>
									{rows}
								</Table.Body>
							</Table>
						</>}
				</SectionHomeWrapper>
				<AlertModal
					{...this.state.endModal}
					willTimeout={false}
					onClose={this.redirectToSubscriptions}
				/>
				<NewConnectedPORInfoModal
					visible={this.state.newConnectedPORInfoModalVisible}
					onClose={() => this.setState({ newConnectedPORInfoModalVisible: false })}
				/>	
			</>
		);
	}
}

const PortOpeningRequestsHome = (props: PortOpeningRequestsHomeProps) => {

    return <PortOpeningRequestsHomeWithParams scopes={props.scopes} subtitle={props.subtitle} params={useParams()} navigate={useNavigate()}/>;
}

export default PortOpeningRequestsHome;