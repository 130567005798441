import DoNotDecryptRule from '../../../models/entities/DoNotDecryptRule';
import { DoNotDecryptRequestsDirection } from '../../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import { RuleOperation } from '../../../models/enums/DNDR/RuleOperation';


export type DNDRColumn = {
	header: string;
	cell: (rule: DoNotDecryptRule) => string;
	limit?: number;
};

export const RuleColumnsLayout = new Map<DoNotDecryptRequestsDirection, DNDRColumn[]>();

RuleColumnsLayout.set(DoNotDecryptRequestsDirection.SubscriptionToInternet, [
	{
		header: 'Operation',
		cell: (rule: DoNotDecryptRule) => rule.operation?.name,
	},
	{
		header: 'Name',
		cell: (rule: DoNotDecryptRule) => rule.azureName ?? rule.name,
		limit: 20,
	},
	{
		header: 'Region',
		cell: (rule: DoNotDecryptRule) => rule.region?.name,
	},
	{
		header: 'Source IPs',
		cell: (rule: DoNotDecryptRule) => rule.sourceIPs,
		limit: 15,
	},
	{
		header: 'Destination URLs',
		cell: (rule: DoNotDecryptRule) => rule.destinationURLs,
		limit: 15,
	},
	// {
	// 	header: 'Destination IPs',
	// 	cell: (rule: DoNotDecryptRule) => rule.destinationIPs,
	// 	limit: 15,
	// },
	// {
	// 	header: 'Destination Ports',
	// 	cell: (rule: DoNotDecryptRule) => rule.destinationPorts,
	// },
	{
		header: 'Description',
		cell: (rule: DoNotDecryptRule) => rule.description,
		limit: 20,
	},
]);