import React from 'react';
import { Table, Field, Radio } from 'react-unity';
import { PolicyAssignmentResponse } from '../../../models/viewModels/PolicyExemptionRequests/PolicyAssignmentResponse';
import Paginator from '../../common/tables/Paginator';
import { PolicyAssignmentColumn, PolicyAssignmentsColumnsLayout } from './PolicyAssignmentsColumnsLayout';
import '../../common/form-controls/Checkbox.css';

interface PolicyAssignmentsTableProps {
	policyAssignments: PolicyAssignmentResponse[];
	value: PolicyAssignmentResponse;
	readonly?: boolean;
	onChange: (policyAssignmentResponse: PolicyAssignmentResponse) => any;
}

interface PolicyAssignmentsTableState { 
	columns: PolicyAssignmentColumn[];
	pageRows: PolicyAssignmentResponse[];
    selectedAssignment: PolicyAssignmentResponse;
}

export class PolicyAssignmentsTable extends React.Component<PolicyAssignmentsTableProps, PolicyAssignmentsTableState> {

	constructor(props) {
		super(props);
		this.state = {
			columns: [],
			pageRows: [],
			selectedAssignment: this.props.value
		};
	}

	componentDidMount() {
		this.setColumns();
	}

	setColumns() {
		this.setState({
			columns: PolicyAssignmentsColumnsLayout
		});
	}

    handlePageChange = (page: PolicyAssignmentResponse[]) => {
    	this.setState({
    		pageRows: page
    	});
    };

    selectPolicyAssignment(policyAssignment: PolicyAssignmentResponse) {
    	this.setState({ selectedAssignment: policyAssignment });
    	this.props.onChange(policyAssignment);
    }

    removePolicyAssignment() {
    	this.setState({ selectedAssignment: null });
    	this.props.onChange(null);
    }

    render() {
    	const tableHeaders = this.state.columns?.map((column) => (
    		<Table.Head.Cell key={column.header}>
    			{column.header}
    		</Table.Head.Cell>
    	));
    	const tableRows = [];
    	const defaultLimit = 50;

    	this.state.pageRows.forEach((policyAssignment, rowIndex) => {
    		tableRows.push(
    			<Table.Body.Row
    				key={rowIndex}
    				onClick={() => this.state.selectedAssignment === policyAssignment ? this.removePolicyAssignment() : this.selectPolicyAssignment(policyAssignment)}
    			>
    				<Table.Body.Cell>
    					{this.props.policyAssignments.indexOf(policyAssignment) + 1}
    				</Table.Body.Cell>
    				{this.state.columns?.map((column, colIndex) => (
    					<Table.Body.Cell
    						key={`${rowIndex}-${colIndex}`}
    						className="em-u-margin-right-auto"
    						title={(column.cell(policyAssignment) || '-')}
    					>
    						{column.cell(policyAssignment)?.length > (column.limit || defaultLimit) ?
    							(`${column.cell(policyAssignment).trim().substring(0, (column.limit || defaultLimit))}...`)
    							:
    							(column.cell(policyAssignment) || '-')}
    					</Table.Body.Cell>
    				))}
    				{!this.props.readonly &&
						<Table.Body.Cell>
							<Radio
								className="chk-no-borders"
								value={rowIndex}
								id={rowIndex.toString()}
								checked={this.state.selectedAssignment === policyAssignment}
								onClick={() => this.state.selectedAssignment === policyAssignment ? this.selectPolicyAssignment(policyAssignment) : this.removePolicyAssignment()}							/>
						</Table.Body.Cell>}
    			</Table.Body.Row>
    		);
    	});

    	return (
    		<>
    			<Field>
    				<Field.Label>
						Policy Assignments
    				</Field.Label>
    				<Field.Body>
    					<Table
    						striped
    						footer={
    							<Paginator
    								data={this.props.policyAssignments}
    								onPageChange={this.handlePageChange}									
    							/>
    						}
    					>
    						<Table.Head>
    							<Table.Head.Row>
    								<Table.Head.Cell />
    								{tableHeaders}
    								{!this.props.readonly &&
                                <Table.Head.Cell>Select</Table.Head.Cell>}
    							</Table.Head.Row>
    						</Table.Head>
    						<Table.Body>
    							{tableRows.length > 0 ?
    								tableRows
    								:
    								<Table.Body.Row>
    									<Table.Body.Cell
    										colSpan={this.state.columns?.length + 2 || 2}
    									>
    										<i>No policy assignments found.</i>
    									</Table.Body.Cell>
    								</Table.Body.Row>}
    						</Table.Body>
    					</Table>
    				</Field.Body>
    			</Field>
    		</>
    	);
    }
}