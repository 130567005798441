import React, { useState } from 'react';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';
import { WorkflowState } from '../../../models/enums/WorkflowState';
import WorkflowStateBadge from '../../common/WorkflowStateBadge';
import './ProgressBarSmall.css';

interface ProgressBarSmallProps {
	workflowState: WorkflowState;
	porScope: string;
}

const ProgressBarSmall = ({ workflowState, porScope }: ProgressBarSmallProps) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const getFirstBulletClass = () => {
		switch (workflowState.value) {
			case WorkflowState.Review.value:
			case WorkflowState.Draft.value:
				return 'circle-yellow';
			case WorkflowState.PendingSubscriptionOwnerApproval.value:
				return '';
			case WorkflowState.Cancelled.value:
			case WorkflowState.Rejected.value:	
				return 'circle-red';
			default:
				return 'back-blue';
		}
	};

	const getLastBulletClass = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
				return 'circle-yellow';
			case WorkflowState.Error.value:
			case WorkflowState.ImplementationFailed.value:
				return 'circle-red';
			case WorkflowState.Completed.value:
				return 'circle-green';
			default:
				return '';
		}
	};

	const getInternetApproverBullet = () => {
		switch (workflowState.value) {
			case WorkflowState.Review.value:
			case WorkflowState.Draft.value:
			case WorkflowState.PendingSubscriptionOwnerApproval.value:
			case WorkflowState.PendingForDOAGApproval.value:
			case WorkflowState.Cancelled.value:
			case WorkflowState.Rejected.value:		
				return '';
			default:
				return 'back-blue';
		}
	};

	const getInternetGatewayBullet = () => {
		switch (workflowState.value) {
			case WorkflowState.Review.value:
			case WorkflowState.Draft.value:
			case WorkflowState.PendingSubscriptionOwnerApproval.value:
			case WorkflowState.PendingForDOAGApproval.value:
			case WorkflowState.PendingForGatewayPolicyTeamApproval.value:
			case WorkflowState.Cancelled.value:
			case WorkflowState.Rejected.value:
				return '';
			default:
				return 'back-blue';
		}
	};

	const getOnPremNetworkClass = () => {
		switch (workflowState.value) {
			case WorkflowState.Review.value:
			case WorkflowState.Draft.value:
			case WorkflowState.PendingSubscriptionOwnerApproval.value:
			case WorkflowState.PendingForCloudNetworkApproval.value:
			case WorkflowState.Cancelled.value:
			case WorkflowState.Rejected.value:
				return '';
			default:
				return 'back-blue';
		}
	};

	return (
		<div
			className="progress"
			onMouseEnter={() => setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}
			title=""
		>
			{showTooltip && (
				<div className="tooltip">
					<WorkflowStateBadge fullWidth workflowState={workflowState} />
				</div>
			)}
			<div className="progress-bar-line" />
			{porScope === PortOpeningRequestsDirection.SubscriptionToInternet.name ? (
				<>
					<div className={`circle ${getFirstBulletClass()} left-0`} />
					<div className={`circle ${getInternetApproverBullet()} left-33`} />
					<div className={`circle ${getInternetGatewayBullet()} left-66`} />
					<div className={`circle ${getLastBulletClass()} left-100`} />
				</>
			) : (
				<>
					<div className={`circle ${getFirstBulletClass()} left-0`} />
					<div className={`circle ${getOnPremNetworkClass()} left-50`} />
					<div className={`circle ${getLastBulletClass()} left-100`} />
				</>
			)}
		</div>
	);
};

export default ProgressBarSmall;
