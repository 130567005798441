import React, { useState } from 'react';
import { Table } from 'react-unity';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import DoNotDecryptRequest from '../../models/entities/DoNotDecryptRequest';				
import Paginator from '../common/tables/Paginator';
import './DoNotDecryptRequestsHome.css';
import { DoNotDecryptRequestsDirection } from '../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import { CheckboxOption } from '../common/tables/CheckboxFilter';
import DoNotDecryptRequestsByDNDRRow from './DoNotDecryptRequestsByDNDRRow';
import DNDRByRequestFilters from './DNDRByRequestFilter';

export type DNDRFilters = {
	scope: CheckboxOption[];
	status: CheckboxOption[];
	searchUser: string;
};

interface DoNotDecryptRequestsbyDNDRProps {
	subscription: SubscriptionRequest;
	dndrs: DoNotDecryptRequest[];
	scopes?: DoNotDecryptRequestsDirection[];
	subtitle?: string;
}

const DoNotDecryptRequestsbyDNDR = ({ subscription, dndrs }: DoNotDecryptRequestsbyDNDRProps) => {
	const [filteredDndrs, setFilteredDndrs] = useState<DoNotDecryptRequest[]>(dndrs);
	const [visibleDndrs, setVisibleDndrs] = useState<DoNotDecryptRequest[]>([]);
 
	return (
		<Table
			header={<DNDRByRequestFilters dndrs={dndrs} onFilterChange={setFilteredDndrs} subscriptionId={subscription.id} subscriptionOwner={subscription.owner.displayName} />}
			footer={<Paginator data={filteredDndrs} onPageChange={(page) => setVisibleDndrs(page)} />}
		>
			<Table.Head>
				<Table.Head.Row>
					<Table.Head.Cell> DNDR # </Table.Head.Cell>		
					<Table.Head.Cell> Status </Table.Head.Cell>
					<Table.Head.Cell> CRQ # </Table.Head.Cell>
					<Table.Head.Cell> Requestor </Table.Head.Cell>
					<Table.Head.Cell> Contact </Table.Head.Cell>
					<Table.Head.Cell> </Table.Head.Cell>
				</Table.Head.Row>
			</Table.Head>
			<Table.Body>
				{visibleDndrs.map((dndr) => (
					<DoNotDecryptRequestsByDNDRRow
						key={dndr.id}
						request={new DoNotDecryptRequest(dndr)}				
						subOwnerDisplayName={subscription.owner.displayName}
					/>
				))}
			</Table.Body>
		</Table>
	);
};

export default DoNotDecryptRequestsbyDNDR;
