import PortOpeningRule from '../../entities/PortOpeningRule';

export default class UpdatePortOpeningRequestRule {

	id!: number;

	action!: string;

	operation!: string;

	description!: string;

	sourceIPs!: string;

	destinationIPs!: string;

	protocol!: string;

	service!: string;

	region!: string;

	destinationPorts!: string;

	trafficDirection!: string;

	ruleName!: string;

	constructor(rule: PortOpeningRule) {
		Object.assign(this, rule);
		this.action = rule.action?.name;
		this.operation = rule.operation?.name;
		this.region = rule.region?.name;
		this.trafficDirection = rule.trafficDirection?.name;
		this.ruleName = rule.name;
	}

}