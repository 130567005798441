import * as React from 'react';
import { useParams } from 'react-router-dom';
import ApplicationsService from '../../../services/ThirdPartyApplicationsService';
import AADAppsDetail from '../AADAppsDetail';

const AdThirdPartyAppsDetail = (props) => {

	const { id } = useParams<{ id: string }>();

	return (
		<AADAppsDetail
			{...props}
			id={`${id}`}
			redirectTo='/ad3rdPartyApps'
			linkTreeText='AD 3rd PartyApplications'
			applicationsService={new ApplicationsService()}
		/>
	);
}

export default AdThirdPartyAppsDetail; 