export default class UpdateFeedback {

	workItemNumber!: number;

	workItemState!: string;

	comments!: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}