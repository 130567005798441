import * as React from 'react';
import { 
	Table
} from 'react-unity';

interface AADAppsImportProps {
	application: any;

}

const AADAppsImportTableRow = ({application} : AADAppsImportProps) => {

    return ( 	
        <Table.Body.Row>
            <Table.Body.Cell>
                <div className='small-margin'>{application.appGuid}</div>
            </Table.Body.Cell>
            <Table.Body.Cell>
                <div className='small-margin'>{application.ownerUPN}</div>
            </Table.Body.Cell>
            <Table.Body.Cell>
                <div className='small-margin'>{application.environment}</div>
            </Table.Body.Cell>
            <Table.Body.Cell>
                <div className='small-margin'>{application.expirationDate}</div>
            </Table.Body.Cell>
            <Table.Body.Cell>
                <div className='small-margin'>{application.cartaIds}</div>
            </Table.Body.Cell>
        </Table.Body.Row>
    );
}

export default AADAppsImportTableRow;