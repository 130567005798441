import * as React from 'react';
import { Button, Toolbar, Table, TextLink, ProgressBar, AlertVariant, PlusFilledIcon } from 'react-unity';
import { Link, Params, useNavigate, useParams} from 'react-router-dom';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import PortOpeningRule from '../../models/entities/PortOpeningRule';
import PortOpeningRequestService from '../../services/PortOpeningRequestService';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import AlertModal from '../common/modals/AlertModal';
import Paginator from '../common/tables/Paginator';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import ToggleField from '../common/form-controls/ToggleField';
import { PORColumn, RuleColumnsLayout } from '../PortOpeningRequests/components/RuleColumnsLayout';
import CSVGeneratorButton from '../common/CSVGeneratorButton';

interface SubscriptionRulesTableWithParamsProps {
	params: Params<string>;
	navigate: any;
}

interface SubscriptionRulesTableWithParamsState {
	columns: PORColumn[];
	loading: boolean;
	subscription?: SubscriptionRequest;
	rules: PortOpeningRule[];
	scopedRules: PortOpeningRule[];
	endModal: AlertBanner;
	selectedScope: string;
	visibleRules: PortOpeningRule[];
}

class SubscriptionRulesTableWithParams extends
	React.Component<SubscriptionRulesTableWithParamsProps, SubscriptionRulesTableWithParamsState> {
	
	portOpeningRequestService: PortOpeningRequestService;
	
	subscriptionRequestsService: SubscriptionRequestsService;
	constructor(props: SubscriptionRulesTableWithParamsProps) {
		super(props);
		this.portOpeningRequestService = new PortOpeningRequestService();
		this.subscriptionRequestsService = new SubscriptionRequestsService();

		this.state = {
			columns: [],
			loading: true,
			rules: [],
			scopedRules: [],
			visibleRules: [],
			endModal: {
				visible: false,
			},
			selectedScope: PortOpeningRequestsDirection.SubscriptionToInternet.name
		};
	}

	async componentDidMount() {
		const id = parseInt(this.props.params.id);
		const subscription = new SubscriptionRequest(await this.subscriptionRequestsService.getById(id));
		this.setState({
			subscription
		});
		await this.loadRules();
	}

	componentDidUpdate(
		prevProps: SubscriptionRulesTableWithParamsProps,
		prevState: SubscriptionRulesTableWithParamsState) {
		if (prevState.selectedScope !== this.state.selectedScope) {
			this.setColumns();
		}
	}

	setColumns() {
		const { selectedScope, rules } = this.state;
		this.setState({
			columns: RuleColumnsLayout.get(new PortOpeningRequestsDirection().fromName(selectedScope)),
			scopedRules: rules.filter(rule => rule.direction.name === selectedScope),
		});
	}

	loadRules = () => {
		this.setState({
			loading: true
		},
		() => {
			this.portOpeningRequestService.getRulesBySubscriptionId(this.state.subscription.id).then(result => {
				const rules = result;
				this.setState({
					rules,
					loading: false,
				});
				this.setColumns();
			}).catch(() => {
				this.setState({
					loading: false,
				}, ()  => this.handleEndModal('Something went wrong.', 'error', 5000));
			});
		});
	};

	handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		this.setState({
			endModal: {
				visible: true,
				text,
				variant,
			}
		},
		() => {
			setTimeout(() => {
				this.redirectToSubscriptions();
			}, timeout);
		});
	};

	handleToggleClick = (selectedScope) => {
		this.setState({ selectedScope });
	};

	redirectToSubscriptions = () => {
		this.setState({
			endModal: { visible: false }
		},
		() => {
			this.props.navigate('/subscriptions');
		});
	};

	render() {
		const tableHeaders = this.state.columns?.map((column) => (
			<Table.Head.Cell key={column.header}>
				{column.header}
			</Table.Head.Cell>
		));

		const defaultLimit = 10;
		
		const rows = this.state.visibleRules?.map((rule, ruleIndex) => (
			<Table.Body.Row key={rule.id}>
				<Table.Body.Cell>
					{this.state.scopedRules.indexOf(rule) + 1}
				</Table.Body.Cell>
				{this.state.columns?.map((column, colIndex) => (
					<Table.Body.Cell
						key={`${ruleIndex}-${colIndex}`}
						className="em-u-margin-right-auto"
						title={(column.cell(rule) || '-')}
					>
						{column.cell(rule)?.length > (column.limit || defaultLimit) ?
							(`${column.cell(rule).trim().substring(0, (column.limit || defaultLimit))}...`)
							:
							(column.cell(rule) || '-')}
					</Table.Body.Cell>
				))}
				<Table.Body.Cell>
					<Link
						to={`subscriptions/${this.state.subscription?.id}/portOpeningRequests/${rule.portOpeningRequestId}`}
					>
							# {rule.portOpeningRequestId}
					</Link>
				</Table.Body.Cell>
			</Table.Body.Row>
		));
		return (
			<>
				<SectionHomeWrapper
					title="Port Opening Rules"
					linkTree={[
						{ text: 'Subscriptions', to: '/subscriptions' },
						{ text: this.state.subscription?.createdSubscriptionName, to: `/subscriptions/${this.state.subscription?.id}` },
					]}
				>
					{this.state.loading ?
						<ProgressBar
							label="Loading requests..."
							className="em-u-margin-top-half"
							indeterminate
							hideValueLabel
						/>
						:
						<Table
							header={
								<Toolbar>
									<Toolbar.Item>
										<ToggleField
											label=''
											className="em-u-margin-none"
											disabled={this.state.loading}
											size="small"
											value={this.state.selectedScope}
											options={[
												{ value: PortOpeningRequestsDirection.SubscriptionToInternet.name,
													text: PortOpeningRequestsDirection.SubscriptionToInternet.displayName },
												{ value: PortOpeningRequestsDirection.SubscriptionToOnPrem.name,
													text: PortOpeningRequestsDirection.SubscriptionToOnPrem.displayName },
											]}
											onChange={(ev) => {
												this.handleToggleClick(ev.target.value);
											}}
										/>
									</Toolbar.Item>
									<Toolbar.Item right>
										<Button.Group>
											<CSVGeneratorButton
												data={this.state.scopedRules}
												columns={this.state.columns}
											/>
											<Button
												variant="primary"
												size="small"
												onClick={() => {
													this.props.navigate(
														`/subscriptions/${this.state.subscription.id}/portOpeningRequests/new`
													);
												}}
												disabled={!this.state.subscription?.canSubmitPortOpeningRequests()}
											>
												<PlusFilledIcon size="small" />
												<span>Request New POR</span>
											</Button>
											<Button
												variant="secondary"
												size="small"
												onClick={() => {
													this.props.navigate(
														`/subscriptions/${this.state.subscription.id}/PortOpeningRequestsHistory`
													);
												}}
											>
												<span>Request History</span>
											</Button>
										</Button.Group>
										
									</Toolbar.Item>
								</Toolbar>
							}
							footer={
								<Paginator
									data={this.state.scopedRules}
									onPageChange={(page) => {
										this.setState({
											visibleRules: page
										});
									}}
								/>
							}
						>
							<Table.Head>
								<Table.Head.Row>
									<Table.Head.Cell />
									{tableHeaders}
									<Table.Head.Cell>
										#POR Id
									</Table.Head.Cell>
								</Table.Head.Row>
							</Table.Head>
							<Table.Body>
								{rows}
							</Table.Body>
						</Table>}
				</SectionHomeWrapper>
				<AlertModal
					{...this.state.endModal}
					willTimeout={false}
					onClose={this.redirectToSubscriptions}
				/>	
			</>
		);
	}
}

const SubscriptionRulesTable = () => {

    return <SubscriptionRulesTableWithParams params={useParams()} navigate={useNavigate()}/>;
}

export default SubscriptionRulesTable;