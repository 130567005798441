import OrganizationsService from '../../services/OrganizationsService';

export async function getOrganizations() {
    
    let orgList = JSON.parse(sessionStorage.getItem('organizations'));
    if (orgList == null) {        
        orgList = await new OrganizationsService().getAll();
        sessionStorage.setItem('organizations', JSON.stringify(orgList));
    }
    return orgList;
}

export async function refreshOrganizations() {
    
    const orgList = await new OrganizationsService().getAll();
    sessionStorage.setItem('organizations', JSON.stringify(orgList));
    return orgList;
}
