import * as React from 'react';
import { TextInput, TextInputProps } from 'react-unity';
import { Field } from './Field';

export default class TextInputField extends Field<TextInputProps> {
	render() {
		return (
			<TextInput
				{...this.props}
				valid={this.isValid()}
				error={!this.isValid()}
				note={this.nonValidMessage() || this.props.note}
			/>
		);
	}
}

