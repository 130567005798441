import * as React from 'react';
import SelectField from '../../common/form-controls/SelectField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import { Environment } from '../../../models/enums/Environment';

interface EnvironmentInputProps extends FieldHandler<number> { }

interface EnvironmentInputState {

}

export class EnvironmentInput extends React.Component<EnvironmentInputProps, EnvironmentInputState> {
	render() {
		return (
			<SelectField
				label="Environment"
				className="full-width"
				options={
					[
						{ text: 'DEVELOPMENT', value: Environment.Development.value },
						{ text: 'PRODUCTION', value: Environment.Production.value }
					]
				}
				note={!this.props.hideNotes && 'DEVELOPMENT: a place to build and test apps. PRODUCTION: A place to host apps that are ready for customers.'}
				{...this.props}
			/>
		);
	}
}