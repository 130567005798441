import * as React from 'react';
import { Modal, Button, XFilledIcon, Grid, Alert } from 'react-unity';
import './TransferAdAppModal.css';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import UserLookup from '../../common/form-controls/UserLookup';
import UserLookupObject from '../../../models/UserLookupObject';

interface TransferOwnershipProps {
    ownerInTransfer: FieldHandler<string>;
	visible: boolean;
	formIsValid: boolean;
	onConfirm: () => any;
	onCancel: () => any;
}

export default class TransferOwnershipModal extends React.Component<TransferOwnershipProps, {}> {

	render() {
		return (
			<Modal show={this.props.visible}>
				<Modal.Window>
					<Modal.Window.Header>
						<Modal.Window.Header.Title>Transfer Ownership</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onCancel}>
							<XFilledIcon />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body id="confirm-modal-body">
						<UserLookup
							label="Transfer Ownership to XOM Employee"
							isAppAdminEmployee
							value={this.props.ownerInTransfer.value}
							validation={this.props.ownerInTransfer.validation}
							onChange={
								(user: UserLookupObject) => {
									this.props.ownerInTransfer.onChange(user);
								}
							}
						/>
					</Modal.Window.Body>
					<Alert
						variant="warning"
						className="no-alert-actions"
					>
							Once the Application is in transfer, you will not be able to edit the Application.
					</Alert>
					<Modal.Window.Footer>
						<Grid variant="2-up">
							<Grid.Item>
								<Button
									variant="secondary"
									onClick={this.props.onCancel}
								>
									Cancel
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Button
									variant="primary"
									onClick={this.props.onConfirm}
									disabled={this.props.ownerInTransfer.value == null}
								>
									Confirm
								</Button>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}
