import ApiClient from './ApiClient';
import UserInfo from '../models/UserInfo';
import UserLookupObject from '../models/UserLookupObject';

export default class UsersService extends ApiClient {
	async getMyInfo() {
		return this.get<UserInfo>('Users/me');
	}

	async getById(id:string) {
		return this.get<UserInfo>(`Users/${id}`);
	}

	async userLookup(name:string) {
		return this.get<UserLookupObject[]>(`Users?nameFilter=${name}&maxRows=10`);
	}

	async employeeUserLookup(name: string) {
		return this.get<UserLookupObject[]>(`Users/employees?nameFilter=${name}&maxRows=10`);
	}

	async AppAdminEmployeeUserLookup(name: string) {
		return this.get<UserLookupObject[]>(`Users/employees?nameFilter=${name}&maxRows=10&IsAppAdminEmployee=true`);
	}

	async subsOwnerUserLookup(name: string) {
		return this.get<UserLookupObject[]>(`Users/subscriptionOwners?nameFilter=${name}&maxRows=10`);
	}
}