import React, { useState, useEffect } from 'react';
import { Button, PlusFilledIcon, RefreshIcon, Searchbar, Toolbar, EditIcon } from 'react-unity';
import { authenticatedUser } from '../../../authentication/authModule';
import CheckboxFilter, { CheckboxOption } from '../../common/tables/CheckboxFilter';
import Filter from '../../common/tables/Filter';
import ResourceTabs from '../../common/ResourceTabs'
import '../AADAppsHome.css';
import EnterpriseApp from '../../../models/entities/EnterpriseApp';
import { UserRole } from '../../../models/enums/UserRole';
import CSVGeneratorButton from '../../common/CSVGeneratorButton';
import AppsExportColumns from '../components/AdAppsHomeExport';

type ApplicationsFilters = {
	searchGeneral: string;
	type: CheckboxOption[];
	state: CheckboxOption[];
	showResources: string;
};

interface ApplicationsHomeFiltersProps {
	setFilteredApplications: any;
	filteredApplications: EnterpriseApp[];
	areMyAppsLoading: boolean;
	areAllAppsLoading: boolean;
	retreiveApps: any;
	allApplications: EnterpriseApp[];
	myApplications: EnterpriseApp[];
	typeOfApps: string;
	setEditMultiple: any;
	editMultiple: boolean;
	setModalVisibleEditMode: any;
	checkBoxState: number;
	setEnterpriseAppModalVisible: any;
}

const ApplicationsHomeFilters = ({
	setFilteredApplications,
	filteredApplications,
	areMyAppsLoading,
	areAllAppsLoading,
	retreiveApps,
	allApplications,
	myApplications,
	setEditMultiple,
	editMultiple,
	setModalVisibleEditMode,
	checkBoxState,
	typeOfApps,
	setEnterpriseAppModalVisible,
}: ApplicationsHomeFiltersProps) => {
	const defaultFilters = {
		searchGeneral: '',
		type: [
			{ label: 'Temporary', value: 'Temporary', checked: true },
			{ label: 'Permanent', value: 'Permanent', checked: true },
		],
		state: [
			{ label: 'Enabled', value: 'Enabled', checked: true },
			{ label: 'Disabled', value: 'Disabled', checked: true }
		],
		showResources: `My ${typeOfApps}`,
		orderBy: 'newest',
	} as ApplicationsFilters;

	const filterApplications = () => {
		let filtered = [];

		const filterCallback = (app: EnterpriseApp) => {
			return (
				filter.filterCheckBox('state', app.workflowInstance.currentWorkflowInstanceState.workflowState.name) &&
				filter.filterCheckBox('type', app.expirationDate != null ? 'Temporary' : 'Permanent') && 
				(
					filter.filterSearchText('searchGeneral', app.displayName) ||
					filter.filterSearchText('searchGeneral', app.applicationId) ||
					filter.filterSearchText('searchGeneral',app.owner.displayName) || 
					(app.cartaIds.length > 0 && filter.filterSearchTextInArray('searchGeneral', app.cartaIds, 'cartaAppId'))
				));
		}; 

		filtered = allApplications.filter(filterCallback);
		if (filter.filters.showResources == `My ${typeOfApps}`) {
			filtered = myApplications.filter((app: EnterpriseApp) => {
				return (filtered.filter((myApp: EnterpriseApp) => app.id == myApp.id).length > 0);
			});
		}
		//Disables Multiple edit functionality when user enable the functionality beeing in My Applications and then switch to All Applications tab
		if (filter.filters.showResources == 'All Applications') {
			setEditMultiple(false);
			setModalVisibleEditMode(false);
		}

		setFilteredApplications(filtered);
	};

	useEffect(() => {
		filter.updateFilterFunction(filterApplications);
	}, [allApplications]);

	useEffect(() => {
		filter.filter();
	}, []);

	const [filter] = useState<Filter<ApplicationsFilters>>(new Filter(filterApplications, defaultFilters));

	const resourceTabsInterface = [
		{
			item1 : `My ${typeOfApps}`,
			item2: null,
		},
		{
			item1: `All ${typeOfApps}`,
			item2: null
		}
	];

	const handleRefresh = () => {
		filter.updateFilter(`My ${typeOfApps}`, 'showResources');
		retreiveApps(true);
	};

	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchGeneral');
	};

	return (
		<>
			{filter && (
				<>
					<ResourceTabs
						allResources={resourceTabsInterface}
						filter={filter}
						disabled={areMyAppsLoading || areAllAppsLoading}
					/>
					<Toolbar id="toggle-toolbar">
						<Toolbar.Item className="searchbar-width">
							<Searchbar
								condensed
								buttonProps={{ onClick: clearSearchbar}}
								inputProps={{
									value: filter.filters.searchGeneral,
									onChange: (e) => filter.handleSearchTextChange(e, 'searchGeneral'),
									placeholder: 'Search (i.e. Application Name, Application Id, Type, etc.)',
								}}
							/>
						</Toolbar.Item>
						<Toolbar.Item right>
							<Button.Group>
								{typeOfApps == 'Applications' && <Button
									variant="primary"
									size="small"
									disabled={!authenticatedUser.isInRole(UserRole.ADAppsUser)}
									onClick={() => setEnterpriseAppModalVisible(true)}
								>
									<PlusFilledIcon size="small" />
									New Application
								</Button>}
								<CSVGeneratorButton data={filteredApplications} columns={AppsExportColumns} fileName='Apps CSV' />
								<Button variant="secondary" size="small" onClick={handleRefresh}>
									<RefreshIcon size="small" />
									<span> Refresh</span>
								</Button>
								<Button
									disabled={!authenticatedUser.isInRole(UserRole.ADAppsUser) || !(filter.filters.showResources == 'My Applications')}
									variant="secondary"
									size="small"
									onClick={() => {
										setEditMultiple(!editMultiple);
									}}
								>
									<EditIcon className="edit-icon" size="small" />
									{!editMultiple ? <span>Edit Multiple</span> : <span>Cancel Edition</span>}
								</Button>
								{editMultiple &&
									<Button
										disabled={checkBoxState == 0}
										variant="secondary"
										size="small"
										onClick={() => {
											setModalVisibleEditMode(true);
										}}
									>
										<EditIcon className="edit-icon" size="small" />
										<span>Edit</span>
									</Button>
								}
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>

					<Toolbar>
						<Toolbar.Item>
							<CheckboxFilter
								label="Type"
								onChange={(event) => filter.handleCheckboxChange(event, 'type')}
								options={filter.filters.type}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<CheckboxFilter
								label="State"
								onChange={(event) => filter.handleCheckboxChange(event, 'state')}
								options={filter.filters.state}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<span
								className="clear-filters"
								onClick={() =>
									filter.updateFilters({ ...defaultFilters, showResources: filter.filters.showResources })
								}
							>
								Clear filters
							</span>
						</Toolbar.Item>
					</Toolbar>
				</>
			)}
		</>
	);
};

export default ApplicationsHomeFilters;
