import * as React from 'react';
import { Grid, ProgressBar } from 'react-unity';
import SelectField from '../../common/form-controls/SelectField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import { AzureResourceModel } from '../../../models/viewModels/Subscriptions';
import SubscriptionRequestsService from '../../../services/SubscriptionRequestsService';

interface AzureResourceInputProps {
	resourceGroup?: FieldHandler<string>;
	resource?: FieldHandler<string>;
	subscriptionId: number;
	disabled?: boolean;
}

interface AzureResourceInputState {
	resourceList: AzureResourceModel[];
	resourceGroupList: AzureResourceModel[];
	loading: boolean;
}

export class AzureResourceInput extends React.Component<AzureResourceInputProps, AzureResourceInputState> {
	subscriptionRequestService: SubscriptionRequestsService;

	constructor(props: AzureResourceInputProps) {
		super(props);
		this.subscriptionRequestService = new SubscriptionRequestsService();

		this.state = {
			resourceList: [],
			resourceGroupList: [],
			loading: true
		};
	}

	async componentDidMount() {

		const resourceGroups = await this.subscriptionRequestService
			.getAllResourceGroupsInSubscription(this.props.subscriptionId);

		if (this.props.resourceGroup?.value && this.props.resource?.value) {
			await this.getResources(this.props.resourceGroup?.value);
		}

		this.setState({
			resourceGroupList: resourceGroups,
			loading: false,
		});
	}

	handleResourceGroupChange = async (event) => {
		this.props.resourceGroup.onChange(event.target.value);
		await this.getResources(event.target.value);
	};

	getResources = async (resourceGroupId) => {
		const resources = await this.subscriptionRequestService
			.getAllResourcesInResourceGroup(this.props.subscriptionId, resourceGroupId);
		this.setState({
			resourceList: resources
		});
	};

	handleResourceChange = async (event) => {
		this.props.resource.onChange(event.target.value);
	};

	resourceGroupOptions = () => {
		const resourceGroupOptions = this.state.resourceGroupList
			.map((resourceGroup) => ({ value: resourceGroup.id, text: resourceGroup.name }));
		resourceGroupOptions.push({ value: null, text: "" });
		return resourceGroupOptions;
	};

	resourceOptions = () => {
		const resourceOptions = this.state.resourceList
			.map((resource) => ({ value: resource.id, text: resource.name }));
		resourceOptions.push({ value: null, text: "" });
		return resourceOptions;
	};

	render() {
		return (
			<>
				{this.state.loading ? (
					<ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label="Loading Resources..." />
				) : (
					<Grid variant="halves">
						<Grid.Item>
							<SelectField
								label="Resource Group"
								options={this.resourceGroupOptions()}
								className="full-width"
								{...this.props.resourceGroup}
								disabled={this.props.disabled || this.state.loading}
								onChange={this.handleResourceGroupChange}
								note="The exemption will impact at subscription level if this field is left blank."						
							/>
						</Grid.Item>
						<Grid.Item>
							<SelectField
								label="Resource"
								className="full-width"
								options={this.resourceOptions()}
								{...this.props.resource}
								disabled={!this.props.resourceGroup.value
									|| this.state.resourceList.length === 0
									|| this.props.disabled}
								onChange={this.handleResourceChange}
								note={!this.props.resourceGroup.value
									|| this.state.resourceList.length === 0
									|| this.props.disabled ? '' : "The exemption will impact at Resource Group level if this field is left blank."}
							/>
						</Grid.Item>
					</Grid>
				)}
			</>
		);
	}
}
