import * as React from 'react';
import { Field, CopyIcon, Button } from 'react-unity';
import CopyToClipboard from 'react-copy-to-clipboard';
import Tooltip from '../Tooltip';

interface ReadonlyFieldProps {
	label: string;
	text: string;
	copy?: boolean;
	note?: string;
}

interface ReadonlyFieldState {
	copied: boolean;
}

export default class ReadonlyField extends React.Component<ReadonlyFieldProps, ReadonlyFieldState> {
	
	constructor(props) {
		super(props);
		this.state = {
			copied: false,
		};
	}

	render() {
		return (
			<Field className="em-c-search-form" disabled>
				<Field.Label>
					{this.props.label}
				</Field.Label>
				<Field.Body>
					<div className="em-c-search__body">
						<input
							className="em-c-input"
							disabled
							value={this.props.text}
						/>
						{this.props.copy &&
							<Tooltip content={
								<CopyToClipboard
									text={this.props.text}
									onCopy={() => { this.setState({ copied: true }); }}
								>
									<Button
										variant="secondary"
										onBlur={() => this.setState({ copied: false })}
									>
										<CopyIcon />
									</Button>
								</CopyToClipboard>
							}
							>
								{this.state.copied ? 'Copied!' : 'Copy to clipboard'}
							</Tooltip>}
					</div>
					
					{ this.props.note &&
						<>
							<div className="em-c-field__note">
								{this.props.note}
							</div>
						</>}
				</Field.Body>
			</Field>
		);
	}
}