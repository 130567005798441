import React, { useState } from 'react';
import { Table } from 'react-unity';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import PortOpeningRequest from '../../models/entities/PortOpeningRequest';
import Paginator from '../common/tables/Paginator';
import './PortOpeningRequestsHome.css';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import { CheckboxOption } from '../common/tables/CheckboxFilter';
import PortOpeningRequestsByPORRow from './PortOpeningRequestsByPORRow';
import PORByRequestFilters from './PORByRequestFilter';

export type PORFilters = {
	scope: CheckboxOption[];
	status: CheckboxOption[];
	searchUser: string;
};

interface PortOpeningRequestsByPORProps {
	subscription: SubscriptionRequest;
	pors: PortOpeningRequest[];
	scopes?: PortOpeningRequestsDirection[];
	subtitle?: string;
}

const PortOpeningRequestsByPOR = ({ subscription, pors }: PortOpeningRequestsByPORProps) => {
	const [filteredPors, setFilteredPors] = useState<PortOpeningRequest[]>(pors);
	const [visiblePors, setVisiblePors] = useState<PortOpeningRequest[]>([]);
 
	return (
		<Table
			header={<PORByRequestFilters pors={pors} onFilterChange={setFilteredPors} subscriptionId={subscription.id} subscriptionOwner={subscription.owner.displayName} />}
			footer={<Paginator data={filteredPors} onPageChange={(page) => setVisiblePors(page)} />}
		>
			<Table.Head>
				<Table.Head.Row>
					<Table.Head.Cell> POR # </Table.Head.Cell>
					<Table.Head.Cell> Scope </Table.Head.Cell>
					<Table.Head.Cell> Status </Table.Head.Cell>
					<Table.Head.Cell> CRQ # </Table.Head.Cell>
					<Table.Head.Cell> For Attestation </Table.Head.Cell>
					<Table.Head.Cell> Requestor </Table.Head.Cell>
					<Table.Head.Cell> Contact </Table.Head.Cell>
					<Table.Head.Cell> Approver </Table.Head.Cell>
					<Table.Head.Cell> </Table.Head.Cell>
				</Table.Head.Row>
			</Table.Head>
			<Table.Body>
				{visiblePors.map((por) => (
					<PortOpeningRequestsByPORRow
						key={por.id}
						request={new PortOpeningRequest(por)}
						subOwnerDisplayName={subscription.owner.displayName}
					/>
				))}
			</Table.Body>
		</Table>
	);
};

export default PortOpeningRequestsByPOR;
