import React, { useState, useEffect } from 'react';
import {
	Grid,
	Button,
	XFilledIcon,
	Modal,
	Alert,
} from 'react-unity';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import ApplicationsService from '../../services/ApplicationsService';
import EnterpriseApp from '../../models/entities/EnterpriseApp';
import { authenticatedUser } from '../../authentication/authModule';
import UserLookup from '../common/form-controls/UserLookup';
import { UpdateEnterpriseAppModel } from '../../models/viewModels/EnterpriseApps/UpdateEnterpriseAppModel';

export interface IApplicationEditMultipleProps {
	visible: boolean;
	onCancel: (e) => any;
	onClose: (e) => any;
	onConfirm: (e) => any;
	myApplications: EnterpriseApp[];
	setCheckBoxState: any;
	setAlert: any;
}

const AADEditModal = ({ visible, onCancel, onClose, onConfirm, myApplications, setCheckBoxState, setAlert }: IApplicationEditMultipleProps) => {
    const [submitting, setSubmitting] = useState(false);
    const [applicationsService] = useState<ApplicationsService>(new ApplicationsService())
    const [loadingAlert, setLoadingAlert] = useState<AlertBanner>({
        visible: false,
        variant: 'error',
        text: ''
    });

    const handleClose = (e: any) => {
        resetStateForm();
        onClose(e);
    };
    const handleCancel = (e: any) => {
        resetStateForm();
        onClose(e);
    };

    const handleInput = async (fieldName: string, value: string) => {
        form[fieldName] = value;
        setForm(form);
    }
    const handleRequest = async (e: any) => {
		window.scroll(0, 0);
		setSubmitting(true);
		setLoadingAlert({
			visible: true,
			text: 'Saving changes... This could take several minutes'
		});
        const appsSelected = myApplications.filter(app => app.selected);
        const appsToEdit = appsSelected.map(app =>({
            appId: app.applicationId,
	        expirationDate: app.expirationDate,
	        environment: app.environment.value,	
	        cartaIds: app.cartaIds,
	        ownerInTransferId: form.owner['id'] || app.owner.id,
        } as UpdateEnterpriseAppModel));
        applicationsService.updateMultiple(appsToEdit).then(() => {
            setAlert({
                visible: true,
                variant: 'success',
                text: 'Applications updated successfully.'
            });
            setSubmitting(false);
            resetStateForm();
            onConfirm(e);
        })
        .catch((err) => {
            if (err.status != 0) {
                setAlert({
                    visible: true,
                    variant: 'error',
                    text: err.response?.data.message || `The application ${err.request?.body?.applicationId} could not be updated. Please, try again later.`
                });
                setTimeout(() => {
                    setAlert({
                        visible: false,
                    });
                }, 5000);
                setSubmitting(false);
                handleClose(e);
            } else {
                setAlert({
                    visible: true,
                    variant: 'success',
                    text: 'Applications updated successfully.'
                });
                resetStateForm();
                onConfirm(e);
            }
        });
    }

    const [form, setForm] = useState({
        owner: {
            value: '',
            onChange: (value) => {
                handleInput('owner', value)
            }
        }
    });

    const resetStateForm = () => {
        setForm({
            ...form,
            owner: { ...form.owner, value: '' },
        });
        myApplications.map((app) => app.selected = false);
        setCheckBoxState(0);
    };

    const stateFormHandler = () => getStateFormHandlers();

    const getStateFormHandlers = () => {
		return {
			owner: {
				value: '',
				onChange: (value) => {
					handleInput('owner', value)
				}
			}
		}
	}

    return (
        <Modal show={visible}
            onHide={() => { }}>
            <Modal.Window className="autoOverflowModal" id="subs-multi-edit-modal">
                <Modal.Window.Header>
                    <Modal.Window.Header.Title>Edit Your Applications Selected</Modal.Window.Header.Title>
                    <Modal.Window.Header.CloseButton onClick={handleClose}>
                        <XFilledIcon size='small' />
                    </Modal.Window.Header.CloseButton>
                </Modal.Window.Header>
                {loadingAlert.visible &&
                    <Alert
                        variant={loadingAlert.variant}
                        onClose={() => {
                            setAlert({
                                visible: false,
                            });
                        }}
                    >
                        {loadingAlert.text}
                    </Alert>}
                <Modal.Window.Body>
                <UserLookup
						id="owner-lookup"
						label="Emit Owner"
                        isAppAdminEmployee
                        {...stateFormHandler().owner}
					/>
                </Modal.Window.Body>
                <Modal.Window.Footer>
                    <Grid variant="2-up">
                        <Grid.Item>
                            <Button
                                variant="secondary"
                                disabled={submitting}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Grid.Item>
                        <Grid.Item>
                            <Button
                                variant="primary"
                                disabled={submitting}
                                onClick={handleRequest}
                            >
                                Save
                            </Button>
                        </Grid.Item>
                    </Grid>
                </Modal.Window.Footer>
            </Modal.Window>
        </Modal>
    );
}
export default AADEditModal;