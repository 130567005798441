type AssertFunction = (value?: any) => boolean;
type MessageFunction = (value: any) => any;

export interface IValidation {
	required?: boolean | AssertFunction;
	rules?: ValidationRule[];
}

export interface ValidationRule {
	assert: AssertFunction;
	message: any | MessageFunction;
}

export class Validation {
	required?: AssertFunction;

	rules?: ValidationRule[];
	
	constructor(obj: IValidation) {
		this.rules = obj.rules;
		if (typeof (obj.required) == 'boolean') {
			this.required = () => Boolean(obj.required);
		} else {
			this.required = obj.required;
		}
	}

	assert(value: any): boolean {
		if (
			(typeof value != 'string' || value.trim() !== '') &&
			value != null &&
			value !== '' &&
			!(Array.isArray(value) && value.length === 0)
		) return !this.rules || this.rules.every((rule) => rule.assert(value));
		return !this.required || !this.required();
	}
}