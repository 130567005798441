export class DomainOperationRequest {
    
	constructor(obj: any) {
		Object.assign(this, obj);
	}
    
    public resourceGroupName: string;

    public virtualMachineName: string;

    public action: string;
}