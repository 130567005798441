import * as React from 'react';
import { Modal, Button, Grid, XFilledIcon } from 'react-unity';
import TextInputField from '../../common/form-controls/TextInputField';
import { AbstractEnterpriseAppForm, IEnterpriseAppStateForm, EnterpriseAppFormProps } from './AbstractEnterpriseAppForm';
import SelectField from '../../common/form-controls/SelectField';
import EnvironmentInput from './EnvironmentInput';
import './EnterpriseAppModal.css';
import CartaInput from '../../common/form-controls/CartaInput';
import { AbstractFormState } from '../../common/AbstractForm';
import ExpirationDateInput from './ExpirationDateInput';
import { ExpirationAction } from '../../../models/enums/AdApps/ExpirationAction';


interface EnterpriseAppModalProps extends EnterpriseAppFormProps{
}
interface EnterpriseAppModalStateForm extends IEnterpriseAppStateForm{
	applicationType: string;
}

interface EnterpriseAppModalState extends AbstractFormState<EnterpriseAppModalStateForm> {
	stateForm: EnterpriseAppModalStateForm
}

export default class EnterpriseAppModal extends AbstractEnterpriseAppForm<
	EnterpriseAppModalProps,
	EnterpriseAppModalState
>{
	constructor(props: EnterpriseAppModalProps) {
		super(props);
		this.state = {
			stateForm: this.getDefaultForm(),
		};

		this.initFormModel();
	}

	componentDidMount(){
		this.setState({
			stateForm: this.getDefaultForm()
		})
	}

	initFormModel() {
		super.initFormModel();

		this.formModel.addField('applicationType', {
			getValue: () => this.state.stateForm.applicationType,
			validation: {
				required: () => this.props.editingApplication == null
			}
		});
	}

	// eslint-disable-next-line class-methods-use-this
	getDefaultFormState() {
		return {};
	}

	render() {
		return (
			<Modal show={this.props.visible}>
				<Modal.Window className="enterpriseAppModal">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>Create a new application</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.handleCancel}>
							<XFilledIcon size="small" />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<TextInputField
							{...this.stateFormHandler().displayName}
							id="nameInput"
							label="Name"
							disabled={this.props.editingApplication != null}
							note="The user-facing display name for this application."
						/>
						<SelectField
							value={this.state.stateForm.applicationType}
							validation={this.formModel.fields.applicationType.validation}
							onChange={(event) => this.handleStateFormChange('applicationType', event.target.value)}
							disabled={this.props.editingApplication != null}
							options={
								[
									{ value: 'general', text: 'General Application (OAuth/OIDC)' },
									{ value: 'saml', text: 'SAML Application' },
								]
							}
							label="Template"
							className="full-width"
						/>
						
						{
							this.props.editingApplication?.greyBookOwner &&
								<TextInputField
									value={this.props.editingApplication.greyBookOwner.displayName}
									label="Current Owner"
									disabled={true}
								/>
						}
						
						<ExpirationDateInput
							expirationDate={this.stateFormHandler().expirationDate}
							applicationLifeTime={this.stateFormHandler().applicationLifeTime}
							disableState={false}
						/>

						<EnvironmentInput
							{...this.stateFormHandler().environment}
							disabled={this.state.stateForm.applicationLifeTime === ExpirationAction.Temporary}
							note="Production Applications must be permanent"
						/>

						<CartaInput
							{...this.stateFormHandler().cartaIds}
						/>

					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Grid variant="2-up">
							<Grid.Item>
								<Button variant="secondary" onClick={this.handleCancel}>
									Cancel
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Button variant="primary" disabled={!this.formModel.isValid()} onClick={this.handleRequest}>
									{this.props.editingApplication ? 'Save' : 'Create'}
								</Button>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}