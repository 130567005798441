import * as React from 'react';
import { Button, Table, BadgeColor, Badge, PencilIcon, VisibilityOnIcon } from 'react-unity';
import moment from 'moment';
import Feedback from '../../models/entities/Feedback';
import './FeedbackHome.css';
import { useState, useEffect } from 'react';

interface FeedbackHomeTableRowProps {
	feedback: Feedback;
	setUpdateFeedbackModalVisible: any;
	setSelectedFeedback: any;
	isSSPDev: boolean;
 }

const FeedbackHomeTableRow = ({feedback, setUpdateFeedbackModalVisible, setSelectedFeedback, isSSPDev} : FeedbackHomeTableRowProps) => {
	const [date, setDate] = useState<String>('');
	const [status, setStatus] = useState<String>(feedback.workItemState?.name);
	const [badgeColor, setBadgeColor] = useState<BadgeColor>('positive');

	const defineBadgecolor = () => {
		if (feedback.workItemState === null) {
			setBadgeColor('caution');
			setStatus('Unknown');
		} else if (status === 'Committed' || status === 'In Progress' || status === 'To Do') {
			setBadgeColor('caution');
		} else if (status === 'Rejected' || status === 'Removed') {
			setBadgeColor('negative');
		} else if (status === 'Approved' || status === 'New') {
			setBadgeColor('main');
		}
	}

	useEffect(() => {
		setDate(moment(feedback.submitDate).format('MM/DD/YYYY'));
		defineBadgecolor();
	}, []);
	
	return (
		<Table.Body.Row key={feedback.id}>
			<Table.Body.Cell><span className="table__cell-xs">{feedback.id}</span></Table.Body.Cell>
			<Table.Body.Cell>
				<Badge className="small-margin em-u-width-100" color={badgeColor}>
					{status}
				</Badge>
			</Table.Body.Cell>
			<Table.Body.Cell><span className="table__cell-l">{feedback.title}</span></Table.Body.Cell>
			<Table.Body.Cell><span className="table__cell-m">{feedback.category.name}</span></Table.Body.Cell>
			<Table.Body.Cell><span className="table__cell-xl">{feedback.description}</span></Table.Body.Cell>
			<Table.Body.Cell><span className="table__cell-m">{feedback.submittedBy.displayName}</span></Table.Body.Cell>
			<Table.Body.Cell><span className="table__cell-m">{date}</span></Table.Body.Cell>
			<Table.Body.Cell>
				<Button
					onClick={() => {
						setUpdateFeedbackModalVisible(true);
						setSelectedFeedback(feedback);
					}}
					className="edit-button-margins table__cell"
					variant="inverted"
					size="small"
				>
					{isSSPDev ? <PencilIcon size="small" /> : <VisibilityOnIcon size="small" />}
				</Button>
			</Table.Body.Cell>
		</Table.Body.Row>
	);
}

export default FeedbackHomeTableRow;