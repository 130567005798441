import { Enumeration } from "./AppEnum";

interface IOption {
	value: string | number;
	text: string;
}

export type TOption = (string | number | IOption | Enumeration<any>);

export class Option {
	value: string | number;
	
	text: string;

	constructor(option: TOption) {
		if (typeof (option) === 'string' || typeof(option) === 'number') {
			this.value = option;
			this.text = option.toString();
			return;
		}
		if (option instanceof Enumeration) {
			this.value = option.value;
			this.text = option.name;
			return;
		}
		Object.assign(this, option);
	}
}