import { RuleOperation } from '../../enums/POR/RuleOperation';
import UpdatePortOpeningRequestRule from './UpdatePortOpeningRequestRule';

export default class UpdatePortOpeningRequest {

	portOpeningRules!: UpdatePortOpeningRequestRule[];

	contactId!: string;

	emergency!: boolean;

	incidentNumber!: string;

	businessJustification!: string;

	comments!: string;

	customerCoordination!: boolean;

	approverId!: string;

	delegatedApproverId!: string;

	custodianApproval!: boolean;

	operation?: RuleOperation;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}