import React from 'react';
import { Modal, Button, FileUpload, Alert, Field, XFilledIcon } from 'react-unity';
import DoNotDecryptRule from '../../../../models/entities/DoNotDecryptRule';				
import { DoNotDecryptRequestsDirection } from '../../../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import DoNotDecryptRequestService from '../../../../services/DoNotDecryptRequestService';				
import CSVGeneratorButton from '../../../common/CSVGeneratorButton';
import { RuleColumnsLayout, DNDRColumn } from '../RuleColumnsLayout';

interface BulkUploadModalProps {
	direction: DoNotDecryptRequestsDirection;
	onSuccess: (rules: DoNotDecryptRule[]) => any;
	onCancel: (...args: any[]) => any;
	visible: boolean;
}

interface BulkUploadModalState {
	loading: boolean;
	file: any;
	alert: {
		visible: boolean;
		text?: string;
	},
	templateColumns: DNDRColumn[];
}

export default class BulkUploadModal
	extends React.Component<BulkUploadModalProps, BulkUploadModalState> {
	
	doNotDecryptRequestService: DoNotDecryptRequestService;				

	constructor(props: BulkUploadModalProps) {
		super(props);

		this.doNotDecryptRequestService = new DoNotDecryptRequestService();				

		this.state = {
			loading: false,
			file: null,
			alert: {
				visible: false,
			},
			templateColumns: [],
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.direction?.value !== this.props.direction?.value) {
			this.setColumns();
		}
	}

	handleChange = (file) => {
		this.setState({
			file: file[0],
			alert: {
				visible: false,
			}
		});
	};

	handleUpload = async () => {
		this.setState({
			loading: true
		});
		this.doNotDecryptRequestService.parse(this.props.direction, this.state.file)
			.then((response: DoNotDecryptRule[]) => {
				const rules = response.map(r => new DoNotDecryptRule(r));				
				this.props.onSuccess(rules);
				// TODO: reset input on success or cancellation
			})
			.catch(err => {
				this.setState({
					alert: {
						visible: true,
						text: err.message
					}
				});
			})
			.finally(() => {
				this.setState({
					loading: false,
				});
			});
	};

	setColumns = () => {
		this.setState({
			templateColumns: RuleColumnsLayout.get(this.props.direction)
		});
	};

	render() {
		return (
			<Modal show={this.props.visible}>
				<Modal.Window>
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							Import rules to the request
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onCancel}>
							<XFilledIcon size="small" />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<Field>
							<CSVGeneratorButton
								label="Download Template"
								columns={[
									{ header: "Operation", cell: (DNDRColumn) => DNDRColumn.operation },
									{ header: "Name", cell: (DNDRColumn) => DNDRColumn.operation },
									{ header: "Action", cell: (DNDRColumn) => DNDRColumn.operation },
									{ header: "Region", cell: (DNDRColumn) => DNDRColumn.operation },
									{ header: "Source IPs", cell: (DNDRColumn) => DNDRColumn.operation },
									{ header: "Destination URLs", cell: (DNDRColumn) => DNDRColumn.operation },
									//{ header: "Destination IPs", cell: (DNDRColumn) => DNDRColumn.operation },
									{ header: "Description", cell: (DNDRColumn) => DNDRColumn.operation },
								]}
								fileName={`Template-Rule-${this.props.direction?.name}.csv`}
								data={[]}
							/>
						</Field>
						<FileUpload
							multiple={false}
							onChange={this.handleChange}
						/>
						{this.state.alert.visible &&
							<Alert
								variant="error"
								onClose={() => {
									this.setState({
										alert: {
											visible: false,
										}
									});
								}}
							>
								{this.state.alert.text}
							</Alert>}
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Button
							variant="primary"
							onClick={this.handleUpload}
							loading={this.state.loading}
							disabled={(!this.state.file) || this.state.alert.visible}
						>
							Upload Rules
						</Button>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}