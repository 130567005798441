import { Enumeration } from '../../../utils/AppEnum';

export class TrafficDirection extends Enumeration<TrafficDirection> {

    public static In = new TrafficDirection('In', 0);

    public static Out = new TrafficDirection('Out', 1);

    public static BiDirectional = new TrafficDirection('Bi-Directional', 2);

}
