export default class SubmitFeedback {

	title!: string;


	category!: string;


	description!: string;


	submittedBy!: string;


	submitDate!: Date;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}