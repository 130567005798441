import React from 'react';
import { Modal, XFilledIcon, Button, Grid } from 'react-unity';
import PortOpeningRule from '../../../models/entities/PortOpeningRule';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';
import ImplementedRulesTable from './ImplementedRulesTable';
import './ImplementedRulesModal.css';
import { RuleOperation } from '../../../models/enums/POR/RuleOperation';

interface ImplementedRulesModalProps {
	direction: PortOpeningRequestsDirection;
    rules: PortOpeningRule[];
	onSuccess: (rules: PortOpeningRule[]) => any;
	onCancel: (...args: any[]) => any;
	visible: boolean;
	alreadySelected: string[]
}

interface ImplementedRulesModalState {
    selectedRules: PortOpeningRule[];
}

export default class ImplementedRulesModal
	extends React.Component<ImplementedRulesModalProps, ImplementedRulesModalState> {

	constructor(props) {
		super(props);
		this.setState({
			selectedRules: []
		});
	}

	handleOnChange = (rules: PortOpeningRule[]) => {
		this.setState({ selectedRules: rules });
	};
	
	handleOnSuccess = () => {
		const rules = this.state?.selectedRules;
		rules.forEach(rule => {
			rule.operation = RuleOperation.Modify;
			rule.name = rule.azureName;
		});
		this.setState({ selectedRules: [] });
		this.props.onSuccess(rules);
	};
	
	handleOnCancel = () => {
		this.setState({ selectedRules: [] });
		this.props.onCancel();
	};

	disableAddRulesButton(): boolean {
		return this.state?.selectedRules == null || this.state.selectedRules?.length === 0;
	}
	
	render() {
		return (
			<Modal show={this.props.visible}>
				<Modal.Window className="autoOverflowModal">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							Add rules to the request
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.handleOnCancel}>
							<XFilledIcon size="small" />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<ImplementedRulesTable
							porDirection={this.props.direction}
							rules={this.props.rules}
							selectedRules={this.state?.selectedRules || []}
							onChange={this.handleOnChange}
						/>
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Grid variant="halves">
							<Grid.Item>
								<Button
									variant="secondary"
									onClick={this.handleOnCancel}
								>
									Cancel
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Button
									variant="primary"
									onClick={this.handleOnSuccess}
									disabled={this.disableAddRulesButton()}
								>
									Add Rules
								</Button>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}