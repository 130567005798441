import React from 'react';
import { Modal, Button, FileUpload, Alert, Field, XFilledIcon } from 'react-unity';
import PortOpeningRule from '../../../../models/entities/PortOpeningRule';
import { PortOpeningRequestsDirection } from '../../../../models/enums/POR/PortOpeningRequestsDirection';
import PortOpeningRequestService from '../../../../services/PortOpeningRequestService';
import CSVGeneratorButton from '../../../common/CSVGeneratorButton';
import { RuleColumnsLayout, PORColumn } from '../RuleColumnsLayout';

interface BulkUploadModalProps {
	direction: PortOpeningRequestsDirection;
	subscriptionRequestId: number;
	onSuccess: (rules: PortOpeningRule[]) => any;
	onCancel: (...args: any[]) => any;
	visible: boolean;
}

interface BulkUploadModalState {
	loading: boolean;
	file: any;
	alert: {
		visible: boolean;
		text?: string;
	},
	templateColumns: PORColumn[];
}

export default class BulkUploadModal
	extends React.Component<BulkUploadModalProps, BulkUploadModalState> {
	
	portOpeningRequestService: PortOpeningRequestService;

	constructor(props: BulkUploadModalProps) {
		super(props);
		this.portOpeningRequestService = new PortOpeningRequestService();
		this.state = {
			loading: false,
			file: null,
			alert: {
				visible: false,
			},
			templateColumns: []
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.direction?.value !== this.props.direction?.value) {
			this.setColumns();
		}
	}

	handleChange = (file) => {
		this.setState({
			file: file[0],
			alert: {
				visible: false,
			}
		});
	};

	handleUpload = async () => {
		try{
			this.setState({
				loading: true
			});
			await this.portOpeningRequestService.parse(this.props.direction, this.state.file, Number(this.props.subscriptionRequestId))
			.then((response: PortOpeningRule[]) => {
				const rules = response.map(r => new PortOpeningRule(r));
				this.props.onSuccess(rules);
			})
		}catch(err){
			this.setState({
				alert: {
					visible: true,
					text: err.message
				}
			});
		}finally{
			this.setState({
				loading: false,
				file: null,
				templateColumns: [],					
			});
		}
	};

	handleCancel = () => {
		this.setState({
			loading: false,
			file: null,
			templateColumns: []
		});
		this.props.onCancel();
	}

	setColumns = () => {
		this.setState({
			templateColumns: RuleColumnsLayout.get(this.props.direction)
		});
	};

	render() {
		return (
			<Modal show={this.props.visible}>
				<Modal.Window>
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							Import rules to the request
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.handleCancel}>
							<XFilledIcon size="small" />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<Field>
							<CSVGeneratorButton
								label="Download Template"
								columns={this.state.templateColumns ?? []}
								fileName={`Template-Rule-${this.props.direction?.name}.csv`}
								data={[]}
							/>
						</Field>
						<FileUpload
							multiple={false}
							onChange={this.handleChange}
						/>
						{this.state.alert.visible &&
							<Alert
								variant="error"
								onClose={() => {
									this.setState({
										alert: {
											visible: false,
										}
									});
								}}
							>
								{this.state.alert.text}
							</Alert>}
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Button
							variant="primary"
							onClick={this.handleUpload}
							loading={this.state.loading}
							disabled={(!this.state.file) || this.state.alert.visible}
						>
							Upload Rules
						</Button>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}