import React, { useEffect, useRef, useState } from 'react';
import {
	Table,
	Toolbar,
	Field,
	Button,
} from 'react-unity';
import PortOpeningRule from '../../../models/entities/PortOpeningRule';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';
import CSVGeneratorButton from '../../common/CSVGeneratorButton';
import Paginator from '../../common/tables/Paginator';
import { PORColumn, RuleColumnsLayout } from './RuleColumnsLayout';
import { VirtualNetwork } from '../../../models/entities/AzureResources/VirtualNetwork';
import POROperationBadge from './POROperationBadge';
import PORTypeBadge from './PORTypeBadge';
import { RuleOperation } from '../../../models/enums/POR/RuleOperation';
import './RulesAttestationTable.css';
import { WorkflowState } from '../../../models/enums/WorkflowState';
import { RulesFormProps } from './porRule/AbstractRulesForm';
import RuleModalSubToInternet from './porRule/RuleModalSubToInternet';
import RuleModalSubToOnPrem from './porRule/RuleModalSubToOnPrem';
import ToggleField from '../../common/form-controls/ToggleField';

interface RulesAttestationTableProps {
	subscriptionRequestName?: string;
	subscriptionRequestId?: number;
	rules: PortOpeningRule[];
	validVirtualNetworks?: VirtualNetwork[];
	porDirection: PortOpeningRequestsDirection;
	readonly?: boolean;
	onChange: (rules: PortOpeningRule[]) => any;
	fromDetails?: boolean;
	currentWorkflowState?: number;
}

const RulesAttestationTable = (props: RulesAttestationTableProps) => {
	const [columns, setColumns] = useState<PORColumn[]>([]);
	const [pageRows, setPageRows] = useState<PortOpeningRule[]>([]);
	const [ruleModalVisible, setRuleModalVisible] = useState<boolean>(false);
	const [editingRule, setEditingRule] = useState<PortOpeningRule>();
	const prevProps = useRef(props.porDirection?.value);
	
	useEffect(() => {
		if (prevProps.current)
			setColumns([]);
	}, [props.porDirection?.value]);

	useEffect(() => {
		setColumns(RuleColumnsLayout.get(props.porDirection));
	}, []);

	const handlePageChange = (page: PortOpeningRule[]) => {
		setPageRows(page);
	};

	const handleRuleAttestation = (rule: PortOpeningRule, e: any) => {
		rule.operation = RuleOperation[e.target.value];
		handleRuleChange(rule);
	}

	const handleRuleChange = (rule: PortOpeningRule) => {
		let { rules } = props;
		const index = rules.indexOf(rule);
		rules = [...rules];
		rules[index] = rule;
		props.onChange(rules);
	};

	const getOperationName = (rule: PortOpeningRule) => {
		if (props.fromDetails && props.currentWorkflowState !== WorkflowState.Review.value && props.currentWorkflowState !== WorkflowState.Draft.value){
			return columns.find(column => column.header === 'Operation').cell(rule)
		}
		const operationColumn = columns.find(column => column.header === 'Operation')
		return operationColumn.cell(rule)
	}

	const directionIs = (direction: PortOpeningRequestsDirection) => {
		return props.porDirection === direction;
	}

	const handleOnClose = () => {
		setEditingRule(null);
		setRuleModalVisible(false);
	}

	const getRulesModalProps = (): RulesFormProps => {
		let editingRuleCopy = null;
		if (editingRule != null) {
			editingRuleCopy = {...editingRule};
			editingRuleCopy.name = editingRuleCopy.azureName ?? editingRuleCopy.name;
		}
		return {
			visible: ruleModalVisible,
			onConfirm: handleRuleChange,
			onClose: () => handleOnClose(),
			editingRule: editingRule,
			validVirtualNetworks: props.validVirtualNetworks
		};
	}

	const defaultLimit = 30;

	return (
		<>
			<Field>
				<Field.Label>
					Ruleset
				</Field.Label>
				<Field.Body>
					<Table
						header={
							<Toolbar>
								{props.readonly &&
									<Toolbar.Item right>
										<CSVGeneratorButton
											data={props.rules}
											columns={columns}
										/>
									</Toolbar.Item>
								}
							</Toolbar>
						}
						footer={
							<Paginator
								data={props.rules}
								onPageChange={handlePageChange}
							/>
						}
					>
						<Table.Head>
							<Table.Head.Row>
								<Table.Head.Cell />
								{columns?.filter(column => column.header !== "Protocol" && column.header !== "Description").map((column) => (
									<Table.Head.Cell key={column.header}>
										{column.header}
									</Table.Head.Cell>
								))}
								<Table.Head.Cell className='table__header-cell'>
									{!props.readonly ? 'Actions' : ''}
								</Table.Head.Cell>
							</Table.Head.Row>
						</Table.Head>
						<Table.Body>
							{pageRows.map((rule, rowIndex) =>
								<Table.Body.Row key={rowIndex}>
									<Table.Body.Cell>
										{props.rules.indexOf(rule) + 1}
									</Table.Body.Cell>
									{columns?.filter(column => column.header !== "Protocol" && column.header !== "Description").map((column, colIndex) => (
										<Table.Body.Cell
											key={`${rowIndex}-${colIndex}`}
											className="em-u-margin-right-auto"
											title={(column.cell(rule) || '-')}
										>
											{column.header === 'Operation' ? (<POROperationBadge>{getOperationName(rule)}</POROperationBadge>)
												: (column.header === 'Type' ? (<PORTypeBadge >{column.cell(rule)}</PORTypeBadge>)
													: (column.cell(rule)?.length > (column.limit || defaultLimit) ?
														(`${column.cell(rule).trim().substring(0, (column.limit || defaultLimit))}...`)
														:
														(column.cell(rule) || '-')))}
										</Table.Body.Cell>
									))}
									<Table.Body.Cell>
										{!props.readonly ?
											<ToggleField
												className='em-u-font-size-small-2'
												id="rule-operation"
												value={rule.operation.name.toString()}
												options={[
													{
														value: RuleOperation.Keep.name,
														text: 'Keep'
													},
													{
														value: RuleOperation.Remove.name,
														text: 'Delete'
													},
												]}
												onChange={(e) => handleRuleAttestation(rule, e)}
											/>
											:
											<Button
												style={{ zIndex: 0 }}
												variant="primary"
												size="small"
												onClick={() => {
													setRuleModalVisible(true)
													setEditingRule(rule)
												}}
											>
												See details
											</Button>
										}
									</Table.Body.Cell>
								</Table.Body.Row>
							)}
						</Table.Body>
					</Table>
				</Field.Body>
			</Field>
			{directionIs(PortOpeningRequestsDirection.SubscriptionToInternet) &&
				<RuleModalSubToInternet
					{...getRulesModalProps()}
					subscriptionRequestName={props.subscriptionRequestName}
					subscriptionRequestId={props.subscriptionRequestId}
					isReadOnly={true}
				/>}
			{directionIs(PortOpeningRequestsDirection.SubscriptionToOnPrem) &&
				<RuleModalSubToOnPrem
					{...getRulesModalProps()}
					subscriptionRequestId={props.subscriptionRequestId}
					isReadOnly={true}
				/>}
		</>
	);
}

export default RulesAttestationTable;