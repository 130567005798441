import { Enumeration } from '../../utils/AppEnum';

export class WorkflowState extends Enumeration<WorkflowState> {
	
	public static Submitted = new WorkflowState('Submitted', 0);

	public static Approved = new WorkflowState('Approved', 1);

	public static Rejected = new WorkflowState('Rejected', 2);

	public static Error = new WorkflowState('Error', 3);

	public static PendingDeletion = new WorkflowState('Pending Deletion', 4);

	public static Deleted = new WorkflowState('Deleted', 5);

	public static Disabled = new WorkflowState('Disabled', 6);

	public static PastDue = new WorkflowState('PastDue', 7);

	public static Warned = new WorkflowState('Warned', 8);

	public static Enabled = new WorkflowState('Enabled', 201);

	public static PendingSubscriptionOwnerApproval = new WorkflowState("Pending subscription owner approval", 400);

	public static PendingForDOAGApproval = new WorkflowState('Pending for Approver Review', 401);

	public static PendingForCloudNetworkApproval = new WorkflowState('Pending for Cloud Network Approval', 402);

	public static PendingForGatewayPolicyTeamApproval = new WorkflowState('Pending for Gateway Policy Team Approval', 403);

	public static Review = new WorkflowState('Review', 404);
	
	public static AwaitingImplementation = new WorkflowState('Awaiting Implementation', 405);
	
	public static Completed = new WorkflowState('Completed', 406);
	
	public static Cancelled = new WorkflowState('Cancelled', 408);
	
	public static Draft = new WorkflowState('Draft', 409);
	
	public static ImplementationFailed = new WorkflowState("Implementation Failed", 410);
	
	public static PendingCloudArchitectApproval = new WorkflowState('Pending for Cloud Architect Approval', 411);
	
	public static PendingForCyberApproval = new WorkflowState('Pending for Cyber Team Approval', 412);
	
	public static PendingAttestation = new WorkflowState("Pending Attestation", 413);
}