import React, { useState, useEffect } from 'react';
import {
	TextPassage,
	ProgressBar,
	AlertVariant,
	Alert,
	Modal,
	XFilledIcon,
	Grid,
	Button,
	TextArea,
} from 'react-unity';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import './ApprovalsHome.css';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import AlertModal from '../common/modals/AlertModal';
import ConfirmModal from '../common/modals/ConfirmModal';
import ApprovalsHomeFilters from './ApprovalsHomeFilters';
import ApprovalsAccountsTable from './ApprovalsAccountsTable';
import CloudAccount from '../../models/CloudAccount';
import CloudAccountsService from '../../services/CloudAccountsService';
import moment from 'moment';
import ApprovalsEntraIDAppsTable from './ApprovalsEntraIDAppsTable';
import ApplicationsService from '../../services/ApplicationsService';
import EnterpriseApp from '../../models/entities/EnterpriseApp';
import TAP from '../../models/entities/TAP';
import TemporaryAccessPasswordService from '../../services/TemporaryAccessPasswordService';
import ApprovalsTAPTable from './ApprovalsTAPTable';
import PortOpeningRequest from '../../models/entities/PortOpeningRequest';
import PortOpeningRequestService from '../../services/PortOpeningRequestService';
import ApprovalsPORsTable from './ApprovalsPORsTable';
import DoNotDecryptRequest from '../../models/entities/DoNotDecryptRequest';
import DoNotDecryptRequestService from '../../services/DoNotDecryptRequestService';
import ApprovalsDPIsTable from './ApprovalsDPIsTable';

const memoized = {
	myCloudAccounts: void 0,
	myApps: void 0,
	myPors: void 0,
	myDpis: void 0,
	myTaps: void 0,
}

const ApprovalsHome = () => {

	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const tab = params.get('tab');
	const [areMyApprovalsLoading, setAreMyApprovalsLoading] = useState<boolean>(true);
	const [progressLabel, setProgressLabel] = useState<string>('Loading your approvals...');
	const [activeTab, setActiveTab] = useState<string>(tab);
	const [endModal, setEndModal] = useState<AlertBanner>({ visible: false });
	const [cloudAccounts, setCloudAccounts] = useState<CloudAccount[]>([]);
	const [filteredAccounts, setFilteredAccounts] = useState<CloudAccount[]>([]);
	const [PORs, setPORs] = useState<PortOpeningRequest[]>([]);
	const [filteredPORs, setFilteredPORs] = useState<PortOpeningRequest[]>([]);
	const [DPIs, setDPIs] = useState<DoNotDecryptRequest[]>([]);
	const [filteredDPIs, setFilteredDPIs] = useState<DoNotDecryptRequest[]>([]);
	const [enterpriseApps, setEnterpriseApps] = useState<EnterpriseApp[]>([]);
	const [TAPs, setTAPs] = useState<TAP[]>([]);
	const [filteredTAPs, setFilteredTAPs] = useState<TAP[]>([]);
	const [filteredEnterpriseApps, setFilteredEnterpriseApps] = useState<EnterpriseApp[]>([]);
	const [selectedCloudAccount, setSelectedCloudAccount] = useState<CloudAccount>(null);
	const [approveModalVisible, setApproveModalVisible] = useState<boolean>(false);
	const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
	const [reasonModalVisible, setReasonModalVisible] = useState<boolean>(false);
	const [rejectionReason, setRejectionReason] = useState<string>('');
	const [refresh, setRefresh] = useState<boolean>(false);

	const [alert, setAlertBanner] = useState<AlertBanner>({
		visible: false,
		variant: 'error',
		text: ''
	});
	const cloudAccountsService = new CloudAccountsService();
	const applicationsService = new ApplicationsService();
	const temporaryAccessPasswordService = new TemporaryAccessPasswordService();
	const portOpeningRequestService = new PortOpeningRequestService();
	const doNotDecryptRequestService = new DoNotDecryptRequestService();
	function refreshCache() {
		memoized.myCloudAccounts = void 0;
		memoized.myApps = void 0;
		memoized.myPors = void 0;
		memoized.myDpis = void 0;
		memoized.myTaps = void 0;
	}
	let timeoutId = null;
	const navigate = useNavigate();
	const retrieveApprovals = async (isRefresh: boolean) => {
		setAreMyApprovalsLoading(true);
		setProgressLabel(`${isRefresh ? 'Reloading' : 'Loading'} your approvals...`);

		try {
			if (isRefresh) refreshCache();
			const accountsToBeApproved = await getAccountsForApprove();
			const myAppsApprovals = await getMyAppsForApprove();
			const myPORsToBeApproved = await getMyPORsForApprove();
			const myDPIsToBeApproved = await getMyDPIsForApprove();
			const myTapsApprovals = await getMyTapsForApprove();
			setCloudAccounts(accountsToBeApproved);
			setEnterpriseApps(myAppsApprovals);
			setPORs(myPORsToBeApproved);
			setDPIs(myDPIsToBeApproved);
			setTAPs(myTapsApprovals);
			setAreMyApprovalsLoading(false);
			setAlertBanner({ visible: false })
		} catch (err) {
			handleEndModal('Approvals could not be retrieved.', 'error', 10000);
		}
	};
	const getAccountsForApprove = async () => {
		if (typeof memoized.myCloudAccounts !== 'undefined') return memoized.myCloudAccounts;
		let data = [];
		data = await cloudAccountsService.getAccountsForApprove();
		memoized.myCloudAccounts = data;
		return data;
	};
	const getMyAppsForApprove = async () => {
		if (typeof memoized.myApps !== 'undefined') return memoized.myApps;
		let data = [];
		data = await applicationsService.getMyAppsApprovals();
		memoized.myApps = data;
		return data;
	}
	const getMyPORsForApprove = async () => {
		if (typeof memoized.myPors !== 'undefined') return memoized.myPors;
		let data = [];
		data = await portOpeningRequestService.getMyApprovals();
		memoized.myPors = data;
		return data;
	}
	const getMyDPIsForApprove = async () => {
		if (typeof memoized.myDpis !== 'undefined') return memoized.myDpis;
		let data = [];
		data = await doNotDecryptRequestService.getMyApprovals();
		memoized.myDpis = data;
		return data;
	}
	const getMyTapsForApprove = async () => {
		if (typeof memoized.myTaps !== 'undefined') return memoized.myTaps;
		let data = [];
		data = (await temporaryAccessPasswordService.getAll()).approvals.filter(tap => tap.approvalStatus === 'Requested');
		memoized.myTaps = data;
		return data;
	}
	const handleRequestApproved = async () => {
		setApproveModalVisible(false);
		try {
			const cloudAccount = selectedCloudAccount;

			handleSubmitAlert('Submitting request...');

			const updatedAccount = cloudAccount.approver ? await cloudAccountsService.approveUserCloudAccount(cloudAccount.id) :
				await cloudAccountsService.approveCloudAccount(cloudAccount.id);

			handleAccountChange(cloudAccount, updatedAccount);

			handleSuccessAlert(`The cloud-only account request '${cloudAccount.createdUserPrincipalName}' has been approved.`);

		} catch (error) {
			handleError(error);
		}
	};
	const handleError = async (error, message = 'Your request could not be completed at this time. Please try again later.') => {
		setAlertBanner({ visible: true, variant: 'error', text: error.body?.message ?? message });
		handleSetTimeout();
		throw error;
	};
	const handleSetTimeout = () => {
		if (timeoutId !== undefined) {
			window.clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(handleAlertClose, 10000);
	};
	const handleAlertClose = () => {
		setAlertBanner({ visible: false, variant: 'default', text: '' });
	};
	const handleRequestRejected = async () => {
		setRejectModalVisible(false);

		try {
			const cloudAccount = selectedCloudAccount;

			handleSubmitAlert('Submitting request...');

			const updatedAccount = cloudAccount.approver ? await cloudAccountsService.rejectUserCloudAccount(
				cloudAccount.id, rejectionReason) : await cloudAccountsService.rejectCloudAccount(
					cloudAccount.id, rejectionReason);

			handleAccountChange(cloudAccount, updatedAccount);

			handleSuccessAlert(`The cloud-only account request '${cloudAccount.createdUserPrincipalName}' has been rejected.`);

		} catch (error) {
			handleError(error);
		} finally {
			setRejectionReason('');
		}
	};
	const handleAccountChange = (oldAccount: CloudAccount, newAccount: CloudAccount) => {
		const index = cloudAccounts.indexOf(oldAccount);
		const accounts = cloudAccounts;
		accounts[index] = newAccount;

		// update state and filter again to see changes reflected
		setCloudAccounts(accounts);
	};
	const handleSubmitAlert = (alertText: string) => {
		setAlertBanner({ visible: true, variant: 'default', text: alertText });
	};

	const handleSuccessAlert = (alertText: string) => {
		setAlertBanner({ visible: true, variant: 'success', text: alertText });
		handleSetTimeout();

	};
	const handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		setEndModal({
			visible: true,
			text,
			variant,
		});
		setTimeout(() => endAndRedirect(), timeout);
	};
	const endAndRedirect = () => {
		setEndModal({ visible: false });
		navigate('/');
	};
	useEffect(() => {
		setActiveTab(tab);
	}, []);

	useEffect(() => {
		retrieveApprovals(false);
	}, [refresh]);

	const listsDictionary = {
		accounts: cloudAccounts,
		apps: enterpriseApps,
		pors: PORs,
		dpis: DPIs,
		taps: TAPs,
	};
	const namesDictionary = {
		accounts: 'Cloud Accounts',
		apps: 'Entra Id Apps',
		pors: 'PORs',
		dpis: 'DPIs',
		taps: 'TAPs',
	};

	const getFilterProps = () => {
		return {
			setActiveTab,
			retrieveApprovals,
			setFilteredDPIs,
			setFilteredAccounts,
			setFilteredTAPs,
			setFilteredPORs,
			setFilteredEnterpriseApps,
			listsDictionary,
			namesDictionary,
			activeTab
		};
	};

	return (
		<>
			<SectionHomeWrapper title="Approvals">
				<TextPassage>
					<p><br /></p>
				</TextPassage>
				{alert.visible &&
					<Alert
						variant={alert.variant}
						onClose={() => {
							setAlertBanner({
								visible: false,
							});
						}}
					>
						{alert.text}
					</Alert>
				}
				{areMyApprovalsLoading && (
					<ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label={progressLabel} />
				)}
				{!areMyApprovalsLoading && (
					<>
						<ApprovalsHomeFilters {...getFilterProps()}
						/>
						{{
							accounts: <ApprovalsAccountsTable
								filteredAccounts={filteredAccounts}
								setApproveModalVisible={setApproveModalVisible}
								setReasonModalVisible={setReasonModalVisible}
								setRejectModalVisible={setRejectModalVisible}
								setSelectedCloudAccount={setSelectedCloudAccount}
							/>,
							apps: <ApprovalsEntraIDAppsTable
								setAlert={setAlertBanner}
								filteredEnterpriseApps={filteredEnterpriseApps}
								setRefresh={setRefresh}
								refresh={refresh}
							/>,
							pors: <ApprovalsPORsTable
								filteredPORs={filteredPORs}
							/>,
							dpis: <ApprovalsDPIsTable
								filteredDPIs={filteredDPIs}
							/>,
							taps: <ApprovalsTAPTable
								filteredTAPs={filteredTAPs}
								setEndModal={setEndModal}
								temporaryAccessPasswordService={temporaryAccessPasswordService}
							/>
						}[activeTab]}
					</>
				)}
				<ConfirmModal
					visible={approveModalVisible}
					title={`Approve the cloud-only account request '${selectedCloudAccount?.createdUserPrincipalName}'?`}
					question={`Are you sure you want to approve this request? 
							   A notification will be sent to ${selectedCloudAccount?.workflowInstance.createdBy.displayName} (${selectedCloudAccount?.workflowInstance.createdBy.userPrincipalName.address}) 
							   upon confirmation informing them of this change.`}
					confirmButton={{ label: 'Confirm Approval' }}
					onConfirm={handleRequestApproved}
					onCancel={() => setApproveModalVisible(false)}
				/>
				<ConfirmModal
					visible={rejectModalVisible}
					title={`Reject the cloud-only account request '${selectedCloudAccount?.createdUserPrincipalName}'?`}
					question={`Are you sure you want to reject this request?
							   A notification will be sent to ${selectedCloudAccount?.workflowInstance.createdBy.displayName} (${selectedCloudAccount?.workflowInstance.createdBy.userPrincipalName.address}) 
							   upon confirmation informing them of this change.`}
					confirmButton={{ label: 'Confirm Rejection' }}
					confirmationField={{
						label: 'Please provide a reason for rejecting this request.',
						value: rejectionReason,
						validation: { required: true },
						onChange: (e) => setRejectionReason(e.target.value),
					}}
					onConfirm={handleRequestRejected}
					onCancel={() => {
						setRejectModalVisible(false);
						setRejectionReason('');
					}}
				/>
				<Modal show={reasonModalVisible}>
					<Modal.Window>
						<Modal.Window.Header>
							<Modal.Window.Header.Title>
								{selectedCloudAccount?.createdUserPrincipalName}
							</Modal.Window.Header.Title>
							<Modal.Window.Header.CloseButton
								onClick={() => { setReasonModalVisible(false); }}
							>
								<XFilledIcon />
							</Modal.Window.Header.CloseButton>
						</Modal.Window.Header>
						<Modal.Window.Body id="reason-modal-body">
							{selectedCloudAccount?.workflowInstance.workflowInstanceStates.map((state) => (
								<TextArea
									label={`${state.workflowState.name} on ${moment(state.performedOnDate).format('MM/DD/YYYY')} by ${state.performedByUser.displayName}`}
									disabled
									value={`Reason: ${state.performedReason}`}
								/>))}
						</Modal.Window.Body>
						<Modal.Window.Footer>
							<Grid variant="2-up">
								<Grid.Item>
									<Button
										variant="secondary"
										onClick={() => { setReasonModalVisible(false); }}
									>
										Close
									</Button>
								</Grid.Item>
							</Grid>
						</Modal.Window.Footer>
					</Modal.Window>
				</Modal>
			</SectionHomeWrapper>
			<AlertModal {...endModal} willTimeout={false} onClose={endAndRedirect} />
		</>
	);
};
export default ApprovalsHome;