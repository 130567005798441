import ApiClient from './ApiClient';
import SubmitDoNotDecryptRequest from '../models/viewModels/DoNotDecryptRequests/SubmitDoNotDecryptRequest';
import DoNotDecryptRequest from '../models/entities/DoNotDecryptRequest';
import DoNotDecryptRule from '../models/entities/DoNotDecryptRule';
import UpdateDoNotDecryptRequest from '../models/viewModels/DoNotDecryptRequests/UpdateDoNotDecryptRequest';
import RejectionModel from '../models/interfaces/RejectionModel';
import ReviewReason from '../models/interfaces/ReviewReason';
import CancellationReason from '../models/interfaces/CancellationReason';
import { DoNotDecryptRequestsDirection } from '../models/enums/DNDR/DoNotDecryptRequestsDirection';
import { getToken } from '../authentication/authModule';
import DoNotDecryptRequestApproverVerification from '../models/viewModels/DoNotDecryptRequests/DoNotDecryptRequestApproverVerification';

export default class DoNotDecryptRequestService extends ApiClient {
	async getAll() {
		return this.get<DoNotDecryptRequest[]>('DoNotDecryptRequests');
	}

	async getById(Id: number) {
		return this.get<DoNotDecryptRequest>(`DoNotDecryptRequests/${Id}`);
	}

	async assignedRequests(userPrincipalName: string) {
		return this.get<DoNotDecryptRequest[]>(`DoNotDecryptRequests/${userPrincipalName}/assigned`);
	}

	async getMyRequests() {
		return this.get<DoNotDecryptRequest[]>('DoNotDecryptRequests/me');
	}

	async getMyApprovals() {
		return this.get<DoNotDecryptRequest[]>('DoNotDecryptRequests/myApprovals');
	}

	async getRequestsForAdminReview() {
		return this.get<DoNotDecryptRequest[]>('DoNotDecryptRequests/admin');
	}

	async getBySubscriptionId(id: number) {
		return this.get<DoNotDecryptRequest[]>(`SubscriptionRequests/${id}/doNotDecryptRequests`);
	}

	async approve(doNotDecryptRequestId: number) {
		return this.patch<DoNotDecryptRequest>(`DoNotDecryptRequests/${doNotDecryptRequestId}/approval`, null);
	}

	async reject(doNotDecryptRequestId: number, rejectionModel: RejectionModel) {
		return this.patch<null>(`DoNotDecryptRequests/${doNotDecryptRequestId}/rejection`, rejectionModel);
	}

	async sendToReview(doNotDecryptRequestId: number, review: ReviewReason) {
		return this.patch<null>(`DoNotDecryptRequests/${doNotDecryptRequestId}/review`, review);
	}

	async cancel(doNotDecryptRequestId: number, cancellationModel: CancellationReason) {
		return this.patch<DoNotDecryptRequest>(
			`DoNotDecryptRequests/${doNotDecryptRequestId}/cancellation`,
			cancellationModel
		);
	}

	async create(dndr: SubmitDoNotDecryptRequest) {
		return this.post<DoNotDecryptRequest>('DoNotDecryptRequests', dndr); 
	}

	async saveDraft(dndr: SubmitDoNotDecryptRequest) {
		return this.put<DoNotDecryptRequest>('DoNotDecryptRequests/draft', dndr); 
	}

	async update(doNotDecryptRequestId: number, dndr: UpdateDoNotDecryptRequest) {
		return this.patch<DoNotDecryptRequest>(`DoNotDecryptRequests/${doNotDecryptRequestId}`, dndr);
	}

	async synchronize() {
		return this.put<DoNotDecryptRequest[]>('DoNotDecryptRequests/sync', null);
	}

	async getRulesByDNDRId(id: number, getAsArray: boolean) {
		return this.get<DoNotDecryptRule[]>(`DoNotDecryptRequests/${id}/rules?getAsArray=${getAsArray}`);
	}

	async getRulesBySubscriptionId(id: number) {
		return this.get<DoNotDecryptRule[]>(`SubscriptionRequests/${id}/dndrrules?onlyApproved=true`);
	}

	async verifyApproverLevel(email: string, isEmergency: boolean) {
		return this.get<DoNotDecryptRequestApproverVerification>(`DoNotDecryptRequests/verifyApproverLevel?email=${email}&emergency=${isEmergency}`);
	}

	// eslint-disable-next-line class-methods-use-this
	async parse(scope: DoNotDecryptRequestsDirection, file: File) {
		const formData = new FormData();
		formData.append(file.name, file);
		const token = await getToken();
		// TODO: integrate form-data submission on api client
		return new Promise(async (resolve, reject) => 
			fetch(`/api/v1.0/rules/parse/dndr${scope.name}`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json, */*',
				},
				body: formData,
			})
				.then((response) => {
					if (!response.ok) throw response;
					return response;
				})
				.then(response => resolve(response.json()))
				.catch(response => response.json())
				.then(response => reject(response))
		);
	}
}
