import * as React from 'react';
import FormModel from '../../../utils/FormModel';
import { AbstractFormState, AbstractForm } from '../../common/AbstractForm';
import { DomainOperationRequest } from '../../../models/viewModels/Subscriptions/DomainOperationRequest';

export interface SubDomainOperationFormProps {
	onCreate: (domainOperation: DomainOperationRequest) => void;
	onClose: (event: React.ChangeEvent<any>) => void;
    setStateForm: (any) => void;
 }

export interface ISubDomainOperationStateForm {
	resourceGroupName?: string;
	virtualMachineName?: string;
    action?: string;
}

export class SubDomainOperationForm<
	Props extends SubDomainOperationFormProps,
	State extends AbstractFormState<ISubDomainOperationStateForm>
> extends AbstractForm<Props, State> {

	constructor(props) {
		super(props);
	}
	
	initFormModel(state: AbstractFormState<ISubDomainOperationStateForm>) {
		return (this.formModel = new FormModel({
            resourceGroupName: {
                getValue: () => state?.stateForm?.resourceGroupName,
                validation: {
                    required: () => true
                },
            },
            virtualMachineName: {
                getValue: () => state?.stateForm?.virtualMachineName,
                validation: {
                    required: () => true
                },
            },
            action: {
                getValue: () => state?.stateForm?.action,
                validation: {
                    required: () => true
                },
            },
        }));
	}
	
	getDefaultForm() {
		return {
            stateForm: {
                resourceGroupName: '',
                virtualMachineName: '',
                action: '',
            }
		};
	}

	handleCancel = (e) => {
		this.props.onClose(e);
	};

	isValid = (state: AbstractFormState<ISubDomainOperationStateForm>): boolean => {
		return Object.keys(state?.stateForm || {}).every((key) => {
            const field = state?.stateForm[key];
            return !field ? false : true;
		});
	}
}