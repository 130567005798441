import React from 'react';
import { Grid, Tag } from 'react-unity';
import ReactButton from '../../common/ReactButton';
import moment from 'moment';
import TAP from '../../../models/entities/TAP';

interface TAPRequestsProps {
    tap: TAP;
    handleGetApprovedTap: any;
    loading: boolean;
}

const TAPRequests = ({tap, handleGetApprovedTap, loading}: TAPRequestsProps) => {

    return (
        <Grid style={{ justifyContent: 'space-around' }}>
            {
                {
                    'N/A':
                        <>
                            {moment(moment.utc(tap.startingTime)).local().add(1, 'hours') < moment()
                                ?
                                <Tag
                                    className='small-margin'
                                    color='default'
                                >
                                    Expired
                                </Tag>
                                :
                                <Tag
                                    className='small-margin'
                                    color='positive'
                                >
                                    Active
                                </Tag>
                            }
                        </>,
                    'Requested':
                        <Tag
                            className='small-margin'
                            color='caution'
                        >
                            Waiting for Approval
                        </Tag>,
                    'Approved':
                        <>
                            {tap.startingTime == null
                                ?
                                <>
                                    <ReactButton
                                        loadingVariant='secondary'
                                        handleUpdateSubmit={() => handleGetApprovedTap()}
                                        isLoading={loading}
                                        variant='primary'
                                        disabled={loading}
                                        name="Get"
                                    />
                                </>
                                :
                                <>
                                    {moment(moment.utc(tap.startingTime)).local().add(1, 'hours') > moment()
                                        ?
                                        <Tag
                                            className='small-margin'
                                            color='positive'
                                        >
                                            Active
                                        </Tag>
                                        :
                                        <Tag
                                            className='small-margin'
                                            color='default'
                                        >
                                            Expired
                                        </Tag>
                                    }
                                </>
                            }
                        </>,
                    'Cancelled':
                        <Tag
                            className='small-margin'
                            color='negative'
                        >
                            Cancelled
                        </Tag>,
                }[tap.approvalStatus]
            }
        </Grid>
    );
}


export default TAPRequests;