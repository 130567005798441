import * as React from 'react';
import ThirdPartyApplicationsService from '../../../services/ThirdPartyApplicationsService';
import AADAppsImport from '../AADAppsImport';


const AdThirdPartyAppsImport = () => {
	return (
		<AADAppsImport
			applicationsService={new ThirdPartyApplicationsService()}
			linkTreeText={'AD 3rd Party Applications'}
			linkTreeRedirectTo={'/ad3rdPartyApps'}
			title={'Entra ID 3rd Party Applications: Import 3rd Party Apps data'}
		/>
	);
}

export default AdThirdPartyAppsImport; 