import { Enumeration } from '../../../utils/AppEnum';

export class DoNotDecryptRequestsDirection extends Enumeration<DoNotDecryptRequestsDirection> {

    // eslint-disable-next-line react/static-property-placement
    public displayName: string;

    constructor(name?: string, value?: number, displayName?: string) {
    	super(name, value);
    	this.displayName = displayName;
    }

    public static SubscriptionToInternet = new DoNotDecryptRequestsDirection('SubscriptionToInternet', 0, 'Subscription -> Internet');

    public static SubscriptionToOnPrem = new DoNotDecryptRequestsDirection('SubscriptionToOnPrem', 1, 'Subscription <-> ExxonMobil Network');

    public static OnPremToInternet = new DoNotDecryptRequestsDirection('OnPremToInternet', 2, 'OnPrem -> Internet');

    public static NECTUpdate = new DoNotDecryptRequestsDirection('NECTUpdate', 3, 'NECT Update');

}
