import React, { useEffect, useState } from 'react';
import ResourceTabs from '../common/ResourceTabs';
import Filter from '../common/tables/Filter';
import { Button, RefreshIcon, Searchbar, Toolbar } from 'react-unity';
import moment from 'moment';

interface Dictionary {
	[Key: string]: any;
}

interface ApprovalsHomeFiltersProps {
	setActiveTab: Function;
	activeTab: string;
	retrieveApprovals: any;
	listsDictionary: Dictionary;
	setFilteredAccounts: Function;
	setFilteredEnterpriseApps: Function;
	setFilteredPORs: Function;
	setFilteredDPIs: Function;
	setFilteredTAPs: Function;
	namesDictionary: Dictionary;
}

const ApprovalsHomeFilters = ({ setActiveTab, activeTab, listsDictionary, setFilteredAccounts, setFilteredEnterpriseApps, setFilteredPORs, setFilteredDPIs, setFilteredTAPs, namesDictionary, retrieveApprovals}: ApprovalsHomeFiltersProps) => {
	const resourceTabs = [];
	resourceTabs.push({
		item1: namesDictionary.accounts,
		item2: null,
	});
	resourceTabs.push({
		item1: namesDictionary.apps,
		item2: null,
	});
	resourceTabs.push({
		item1: namesDictionary.pors,
		item2: null,
	});
	resourceTabs.push({
		item1: namesDictionary.dpis,
		item2: null,
	});
	resourceTabs.push({
		item1: namesDictionary.taps,
		item2: null,
	});
	const defaultFilters = {
		searchGeneral: '',
		showResources: namesDictionary[activeTab]
	};
	
	const filterApprovals = () => {
		const filterCallback= (element: any) => {
			return (filter.filterSearchText('searchGeneral', element.createdUserPrincipalName) ||
					filter.filterSearchText('searchGeneral', element.displayName) ||
					filter.filterSearchText('searchGeneral', element.subscriptionRequestName) ||
					filter.filterSearchText('searchGeneral', element.requestor?.displayName) ||
					filter.filterSearchText('searchGeneral', element.owner?.displayName));
		}
		let filtered = [];
		switch (filter.filters.showResources) {
			case 'Cloud Accounts':
				setActiveTab('accounts');
				filtered = listsDictionary.accounts.filter(filterCallback);
				filtered.sort((a, b) => {
						return 	moment(b.workflowInstance.createdDate).valueOf() - 
						moment(a.workflowInstance.createdDate).valueOf()
					});
				setFilteredAccounts(filtered);
				break;
			case 'Entra Id Apps':
				setActiveTab('apps');
				filtered = listsDictionary.apps.filter(filterCallback);
				setFilteredEnterpriseApps(filtered);
				break;
			case 'PORs':
				setActiveTab('pors');
				filtered = listsDictionary.pors.filter(filterCallback);
				setFilteredPORs(filtered);
				break;
			case 'DPIs':
				setActiveTab('dpis');
				filtered = listsDictionary.dpis.filter(filterCallback);
				setFilteredDPIs(filtered);
				break;
			case 'TAPs':
				setActiveTab('taps');
				filtered = listsDictionary.taps.filter(filterCallback);
				setFilteredTAPs(filtered);
				break;
		};
	};
	useEffect(() => {
		filter.filter();
	}, []);
	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchGeneral');
	};

	const handleRefresh = () => {
		filter.updateFilter('My Approvals', 'Show Resources');
		retrieveApprovals(true);
	};

	const [filter, setFilter] = useState<Filter<any>>(new Filter(filterApprovals, defaultFilters));
	return (
		<>
			<ResourceTabs
				allResources={resourceTabs}
				filter={filter}
			/>
			<Toolbar id="toggle-toolbar">
				<Toolbar.Item className="searchbar-width">
					<Searchbar
						condensed
						buttonProps={{ onClick: clearSearchbar }}
						inputProps={{
							value: filter.filters.searchGeneral,
							onChange: (e) => filter.handleSearchTextChange(e, 'searchGeneral'),
							placeholder: 'Search ',
						}}
					/>
				</Toolbar.Item>
				<Button variant="secondary" size="small" onClick={handleRefresh}>
					<RefreshIcon size="small" />
					<span> Refresh</span>
				</Button>
			</Toolbar>
		</>
	);
};

export default ApprovalsHomeFilters;
