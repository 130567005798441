import moment from 'moment';
import _ from 'lodash';
import { CheckboxOption } from './CheckboxFilter';

export default class Filter<T> {
	defaultFilters: T;

	filters: T;

	appliedFiltersFlag: boolean;

	filter: () => void;

	constructor(filter: () => void, defaultFilters: T) {
		this.filter = filter;
		this.defaultFilters = { ...defaultFilters };
		this.filters = { ...defaultFilters };
		this.appliedFiltersFlag = false;
	}

	updateFilterFunction = (newFilterFunction: () => void) => {
		this.filter = newFilterFunction;
	}

	changeDefaultFilters = (defaultFilters: T) => {
		this.defaultFilters = { ...defaultFilters };
		this.setDefaultFilters();
	};

	setDefaultFilters = () => {
		this.filters = _.cloneDeep(this.defaultFilters);
		this.filter();
		this.appliedFiltersFlag = false;
	};

	updateFilter = (value, filter: string,)=> {
		this.filters[filter] = value;
		this.filter();
		this.appliedFiltersFlag = true;
	};

	updateFilters = (newFilters: T) => {
		this.filters = newFilters;
		this.filter();
		this.appliedFiltersFlag = true;
	};

	filterCheckBox = (filter: string, param) => {
		return this.filters[filter].every((option) => { return option.checked; })
			|| this.filters[filter].some((statusOption) =>
				(statusOption.checked && (statusOption.value === param)));
	};

	filterSearchText = (filter: string, param?: string) => {
		const result = param?.toLowerCase().includes(this.filters[filter].toLowerCase().trim())
		return (this.filters[filter] === '' || result);
	};

	filterSearchTextInArray = (filter: string, arr: any[], searchKey: string ) => {
		if(searchKey != ''){
		return (this.filters[filter] === '' ||
			arr?.find(element => element[`${searchKey}`].toLowerCase().includes(this.filters[filter].toLowerCase().trim())));
		}
	};

	handleSearchTextChange = (event, filter: string) => {
		const { value } = event.target;
		this.updateFilter(value, filter);
	};
	
	handleCheckboxChange = (event, filter: string) => {
		const { value } = event.target;
		const isChecked = event.target.checked;
		let checkAll;	

		const option: CheckboxOption[] = this.filters[filter].map((checkBox: CheckboxOption) => {
			const newCheckBox = checkBox;	

			if (checkBox.value === value) {
				newCheckBox.checked = isChecked;
			} else if (value === 'All') {
				if (checkAll === undefined) {
					checkAll = isChecked;
				}
				newCheckBox.checked = checkAll;				
			}	
			return newCheckBox;
		});

		this.updateFilter(option, filter);
	};

	filterDateRange = (filterFrom: string, filterTo: string, subDate: Date) => {
		const start = this.filters[filterFrom];
		const end = this.filters[filterTo];

		return moment(subDate) >= moment(start).startOf('day') && moment(subDate) <= moment(end).endOf('day');
	};

	isInDateRange = (filterFrom: string, filterTo: string, subDate: Date) => {
		const start = this.filters[filterFrom];
		const end = this.filters[filterTo];
		return moment(subDate).isBetween(moment(start),moment(end));
	};
}