import * as React from 'react';
import { TypeaheadProps, Typeahead, TextPassage, TextLink } from 'react-unity';
import { Field, FieldProps } from './Field';
import UsersService from '../../../services/UsersService';
import UserLookupObject from '../../../models/UserLookupObject';
import ReadonlyField from './ReadonlyField';

interface UserLookupProps extends FieldProps {
	label: string;
	placeholder?: string;
	note?: string | JSX.Element;
	noteLink?: string;
	isEmployee?: boolean;
	isAppAdminEmployee?: boolean;
	isSubscriptionOwner?: boolean;

}

interface UserLookupState {
	results: UserLookupObject[];
	inputText: string | null;
	loading: boolean;
}

export default class UserLookup extends Field<TypeaheadProps, UserLookupProps, UserLookupState> {

	usersService: UsersService;

	constructor(props) {
		super(props);
		this.state = {
			results: [],
			inputText: '',
			loading: false
		};
		this.usersService = new UsersService();

	}

	componentDidMount() {
		this.resetInputText(this.props.value);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.resetInputText(this.props.value);
		}
	}

	resetInputText = (newValue) => {
		this.setState({
			inputText: newValue ?? '',
			results: []
		});
	};

	handleTextChange = async (e) => {
		const inputText = e.target.value;
		// TODO: sync results
		this.resetInputText(inputText);
		const searchText = inputText.trim();
		this.props.onChange(null);
		if (searchText?.length > 2) {
			this.setState({ loading: true });
			let response;
			if (this.props.isSubscriptionOwner) {
				response = await this.usersService.subsOwnerUserLookup(searchText);
			} else if (this.props.isEmployee) {
				response = await this.usersService.employeeUserLookup(searchText);
			} else if (this.props.isAppAdminEmployee) {
				response = await this.usersService.AppAdminEmployeeUserLookup(searchText);
			} else {
				response = await this.usersService.userLookup(searchText);
			}
			this.setState({ results: response, loading: false });
		}
	};

	render() {

		const renderedResults =
			(this.state.results.length !== 0 || this.state.inputText?.length < 3 || this.state.loading ?
				this.state.results
					.map(user => (
						<Typeahead.Item value={user.id} key={user.id}>
							<Typeahead.Item.Suggestion>
								<strong>{`${user.userPrincipalName}`}</strong>&nbsp;
								<span>{`(${user.displayName})`}</span>
							</Typeahead.Item.Suggestion>
						</Typeahead.Item>
					)) :
				<Typeahead.Item value={0}>
					<Typeahead.Item.Suggestion aria-disabled>
						<i>No results found.</i>
					</Typeahead.Item.Suggestion>
				</Typeahead.Item>);

		return (
			!this.props.disabled ?
				(
					<>
						<Typeahead
							onChange={(e, value) => {
								const obj = this.state.results.find(o => o.id === value);
								this.setState({ inputText: obj?.userPrincipalName });
								this.props.onChange(obj);
							}}
							fieldProps={{
								label: this.props.label,
								error: !this.isValid(),
								note: this.nonValidMessage() || (!this.props.noteLink? this.props.note: null),
								disabled: this.props.disabled,
							}}
							inputProps={{
								onChange: this.handleTextChange,
								placeholder: this.props.placeholder,
								value: this.state.inputText,
								loading: this.state.loading,
							}}
						>
							{renderedResults}
						</Typeahead>
						{this.props.noteLink? <TextPassage>
							<p className='em-c-field__note' style={!this.isValid()? { color: '#ca6a72' } :null}>
								{this.props.note}
								<TextLink href={this.props.noteLink} external>
									More info...
								</TextLink>
							</p>
						</TextPassage>: null}
					</>
				)
		: 
					
				<ReadonlyField
					label={this.props.label}
					text={this.state.inputText}
				/>
		
		);
		
	}
}


