import { PolicyAssignmentResponse } from '../../../models/viewModels/PolicyExemptionRequests/PolicyAssignmentResponse';

export type PolicyAssignmentColumn = {
	header: string;
	cell: (policyDefinition: PolicyAssignmentResponse) => string;
	limit?: number;
};

export const PolicyAssignmentsColumnsLayout: PolicyAssignmentColumn[] = [
	{
		header: 'Name',
		cell: (policyAssignment) => policyAssignment.properties.displayName,
	},
	{
		header: 'Scope',
		cell: (policyAssignment) => {
			return policyAssignment.properties.scopeDisplayName;
		},
	},
	{
		header: 'Type',
		cell: (policyAssignment) => policyAssignment.properties.policyDefinitionId.includes('policySetDefinitions') ? 'Initiative' : 'Policy',
	}
];