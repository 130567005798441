import * as React from 'react';
import SelectField from '../../common/form-controls/SelectField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import { Environment } from '../../../models/enums/Environment';

interface EnvironmentInputProps extends FieldHandler<Environment> { 
	onChange: (env: Environment) => any;
	note?: string;
}

interface EnvironmentInputState {}

export default class EnvironmentInput extends React.Component<EnvironmentInputProps, EnvironmentInputState> {
	componentDidUpdate(prevProps) {
		if ((prevProps.disabled !== this.props.disabled) && this.props.disabled) {
			this.props.onChange(Environment.Development);
		}
	}

	render() {
		return (
			<SelectField
				label="Environment"
				className="full-width"
				options={
					[
						{ text: 'Production', value: Environment.Production.value },
						{ text: 'Non-production', value: Environment.Development.value }
					]
				}
				{...this.props}
				value={this.props.value?.value}
				onChange={(event) => {
					const env = new Environment().fromValue(Number(event.target.value));
					this.props.onChange(env);
				}}
			/>
		);
	}
}