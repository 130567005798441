import React, { useState, useEffect } from 'react';
import { Table } from 'react-unity';
import { authenticatedUser } from '../../authentication/authModule';
import Paginator from '../common/tables/Paginator';
import { UserRole } from '../../models/enums/UserRole';
import CloudAccount from '../../models/CloudAccount';
import ApprovalsAccountsTableRow from './ApprovalsAccountsTableRow';

interface ApprovalsAccountsTableProps {
    filteredAccounts: CloudAccount[];
    setApproveModalVisible: Function;
    setSelectedCloudAccount: Function;
    setRejectModalVisible: Function;
    setReasonModalVisible: Function;
}

const ApprovalsAccountsTable = ({
    filteredAccounts,
    setApproveModalVisible,
    setSelectedCloudAccount,
    setRejectModalVisible,
    setReasonModalVisible }: ApprovalsAccountsTableProps) => {
    const [accountsOnPage, setAccountsOnPage] = useState<CloudAccount[]>([]);
    return (
        <Table
            footer={
                <Paginator
                    data={filteredAccounts}
                    onPageChange={(accounts: CloudAccount[]) => {
                        setAccountsOnPage(accounts)
                    }}
                />
            }
        >
            <Table.Head>
                <Table.Head.Row>
                    <Table.Head.Cell>Account Name</Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'>Date Requested</Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'>Actions</Table.Head.Cell>
                </Table.Head.Row>
            </Table.Head>
            <Table.Body>
                {accountsOnPage.map((cloudAccount: CloudAccount) => {
                    return <ApprovalsAccountsTableRow
                        account={cloudAccount}
                        setApproveModalVisible={setApproveModalVisible}
                        setReasonModalVisible={setReasonModalVisible}
                        setRejectModalVisible={setRejectModalVisible}
                        setSelectedCloudAccount={setSelectedCloudAccount}
                    />;
                }
                )}
            </Table.Body>
        </Table>
    );
}

export default ApprovalsAccountsTable;