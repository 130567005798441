export default class CartaId {
	id: number;

	workflowInstanceId?: number;

	cartaAppId!: string;

	cartaAppName!: string;

	comment!: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}