import React from 'react';
import { AlertVariant, Field, Grid, ProgressBar, Table, Tag } from 'react-unity';
import { AbstractPolicyExemptionRequestForm, AbstractPolicyExemptionRequestFormState, AbstractPolicyExemptionRequestFormProps } from './AbstractPolicyExemptionRequestForm';
import PolicyExemptionRequest from '../../models/entities/PolicyExemptionRequest';
import AlertModal from '../common/modals/AlertModal';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import Paginator from '../common/tables/Paginator';
import PolicyExemptionDefinition from '../../models/entities/PolicyExemptionDefinition';
import ReadonlyField from '../common/form-controls/ReadonlyField';
import WorkflowDetailWrapper from '../common/wrappers/WorkflowDetailWrapper';
import { AzureResourceInput } from './components';
import SelectField from '../common/form-controls/SelectField';
import { useParams } from 'react-router-dom';

interface PolicyExemptionRequestDetailProps extends AbstractPolicyExemptionRequestFormProps { }


interface PolicyExemptionRequestDetailState extends AbstractPolicyExemptionRequestFormState {
	loading: boolean;
	policyExemptionRequest?: PolicyExemptionRequest;
	embedAlert: AlertBanner;
	modalComment: string;
	readOnly: boolean;
}

class PolicyExemptionRequestsDetailWithParams extends AbstractPolicyExemptionRequestForm
	<PolicyExemptionRequestDetailProps, PolicyExemptionRequestDetailState> {	
	constructor(props) {
		super(props);

		this.state = {
			stateForm: {
				displayName: '',
				businessJustification: '',
				riskAssessmentLink: '',
				durationRequested: '',
				policyDefinitions: [],
				policyAssignment: null,
				resource: null,
				resourceGroup: null
			},
			subscription: null,
			loading: true,
			submissionWarned: false,
			endModal: {
				visible: false,
			},
			embedAlert: {
				visible: false,
			},
			readOnly: true,
			modalComment: '',
		};

		super.initFormModel();
	}

	async componentWillMount() {
		const id = parseInt(this.props.params.id);
		const perid = parseInt(this.props.params.perid);
		await this.loadRequest(perid, id);
	}

	loadRequest = async (perid, id) => {
		this.setState(
			{
				loading: true
			},
			async () => {
				try {

					const subscription = await this.getSubscription(id);

					const policyExemptionRequest = new PolicyExemptionRequest(await this.policyExemptionRequestService.getById(perid));
	
					this.setState({
						subscription,
						policyExemptionRequest,
						loading: false
					}, this.resetStateForm);
					
				}	catch (error) {
					const HTTP_FORBIDDEN_ERROR_CODE = 403;
					if (error.status === HTTP_FORBIDDEN_ERROR_CODE) {
						this.handleEndModal('You are not allowed to see this request.', 'error', 5000, `/subscriptions/${id}/policyExemptionRequests`);
					} else {
						this.handleEndModal('The request could not be retrieved.', 'error', 10000, `/subscriptions/${id}/policyExemptionRequests`);
					}
				}
			}
		);
	};

	resetStateForm = () => {
		this.setState((prevState) => (
			{
				stateForm: this.stateFormFromRequest(prevState.policyExemptionRequest)
			}
		));
	};

	handleEndModal = (text: string, variant: AlertVariant, timeout: number, link: string) => {
		this.setState({
			endModal: {
				visible: true,
				text,
				variant,
				redirectionLink: link
			}
		},
		() => {
			setTimeout(() => {
				this.endAndRedirect();
			}, timeout);
		});
	};

	handleOnPageChange = (definitions: PolicyExemptionDefinition[]) =>{

		const newReq = {
			...this.state.policyExemptionRequest,
			policyExemptionDefinitions: definitions
		};

		this.setState({
			policyExemptionRequest: new PolicyExemptionRequest(newReq)
		});
	};

	render() {

		const rows = this.state.policyExemptionRequest?.policyExemptionDefinitions.map(definition => (
			<Table.Body.Row key={definition.id}>
				<Table.Body.Cell>
					{definition.referenceId ?? '-'}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{definition.displayName}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{definition.riskRating ?? '-'}
				</Table.Body.Cell>
			</Table.Body.Row>
		));

		const exemptions = this.state.policyExemptionRequest?.policyExemptionAssignments.length > 1 ?
			this.state.policyExemptionRequest?.policyExemptionAssignments.map(assignment => (
				<Table.Body.Row key={assignment.id}>
					<Table.Body.Cell>
						{`${this.state.policyExemptionRequest?.azureName}-${assignment.scopeDisplayName}`}
					</Table.Body.Cell>
					<Table.Body.Cell>
						{assignment.scopeDisplayName}
					</Table.Body.Cell>
				</Table.Body.Row>
			))
			: null;


		return (
			<>
				<WorkflowDetailWrapper
					title={`Policy Exception Request #${ this.state.policyExemptionRequest?.id}`}
					linkTree={this.getLinkTree()}
					workflowInstance={this.state.policyExemptionRequest?.workflowInstance}
					loading={this.state.loading}
				>
					{this.state.loading ? (
						<ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label="Loading Policy Exception Request..." />
					) : (
						<>
							<Grid variant="halves">
								<Grid.Item>
									<ReadonlyField
										label="Exception Name"
										text={this.stateFormHandler().displayName.value}
										note={exemptions === null ? 
											`Azure Name: ${  this.state.policyExemptionRequest.azureName}`
											: '(The exception was implemented for multiple scope levels)'}
									/>
								</Grid.Item>
								<Grid.Item>
									{exemptions === null &&
										<>
											<strong>Scope</strong><br />
											<Tag
												className='small-margin'
											>
												{this.state.policyExemptionRequest.policyExemptionAssignments[0].scopeDisplayName}
											</Tag>
										</>}
								</Grid.Item>
							</Grid>
							{exemptions != null &&
								<Field>
									<Field.Label>
										Azure Policy Exceptions
									</Field.Label>
									<Field.Body>
										<Table>
											<Table.Head>
												<Table.Head.Row>
													<Table.Head.Cell> AZURE NAME </Table.Head.Cell>
													<Table.Head.Cell> SCOPE </Table.Head.Cell>
												</Table.Head.Row>
											</Table.Head>
											<Table.Body>
												{exemptions}
											</Table.Body>
										</Table>
									</Field.Body>
								</Field>}
							<Grid variant="halves">
								<Grid.Item>
									<SelectField
										label="Duration Requested"
										value={this.stateFormHandler().durationRequested.value}
										disabled={this.state.readOnly}
										options={this.stateFormHandler().durationRequested.options}
									/>
								</Grid.Item>
								<Grid.Item>
									<ReadonlyField
										label="Approval Type"
										text={this.state.policyExemptionRequest?.approvalType.name}
									/>
								</Grid.Item>
							</Grid>
							
							<AzureResourceInput
								resourceGroup={this.stateFormHandler().resourceGroup}
								resource={this.stateFormHandler().resource}
								subscriptionId={this.state.subscription.id}
								disabled={this.state.readOnly}
							/>
							<ReadonlyField
								label="Risk Assessment Link"
								text={this.stateFormHandler().riskAssessmentLink.value}
							/>
							<ReadonlyField
								label="Business Justification"
								text={this.stateFormHandler().businessJustification.value}
							/>
							<ReadonlyField
								label="Assignment Name"
								text={this.state.policyExemptionRequest.assignmentDisplayName}
							/>
							<Field>
								<Field.Label>
								Policy Definitions
								</Field.Label>
								<Field.Body>
									<Table
										footer={
											<Paginator
												data={this.state.policyExemptionRequest.policyExemptionDefinitions}
												onPageChange={this.handleOnPageChange}
											/>
										}
									>
										<Table.Head>
											<Table.Head.Row>
												<Table.Head.Cell> REFERENCE ID </Table.Head.Cell>
												<Table.Head.Cell> NAME </Table.Head.Cell>
												<Table.Head.Cell> CATEGORY </Table.Head.Cell>
											</Table.Head.Row>
										</Table.Head>
										<Table.Body>
											{rows}
										</Table.Body>
									</Table>
								</Field.Body>
							</Field>
						</>)}
				</WorkflowDetailWrapper>	
				<AlertModal
					{...this.state.endModal}
					willTimeout={false}
					onClose={this.endAndRedirect}
				/>
			</>
		);
	}
}

const PolicyExemptionRequestsDetail = () => {

    return <PolicyExemptionRequestsDetailWithParams params={useParams()}/>;
}

export default PolicyExemptionRequestsDetail;