import { Enumeration } from '../../../utils/AppEnum';

export class WorkItemState extends Enumeration<WorkItemState> {
	public static New = new WorkItemState('New', 0);

	public static Approved = new WorkItemState('Approved', 1);

	public static Committed = new WorkItemState('Committed', 2);

	public static ToDo = new WorkItemState('To Do', 3);

	public static InProgress = new WorkItemState('In Progress', 4);

	public static Done = new WorkItemState('Done', 5);

	public static Removed = new WorkItemState('Removed', 6);

	public static Rejected = new WorkItemState('Rejected', 7);
	
}
