import CartaId from '../../entities/CartaId';
import User from '../../User';
import { UpdateEnterpriseAppModel } from './UpdateEnterpriseAppModel';

export class RequestEnterpriseAppModel extends UpdateEnterpriseAppModel {

	displayName: string;

	applicationType: string;

	constructor(obj: any) {
		super(obj);
		Object.assign(this, obj);
	}
}