import {RequestSubscriptionModel} from './RequestSubscriptionModel';

export class ImportSubscriptionModel extends RequestSubscriptionModel {
	createdSubscriptionId: string;

	tenantId: string;

	constructor(obj: any) {
		super(obj);
		Object.assign(this, obj);
	}
}