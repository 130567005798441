import * as React from 'react';
import { Button, Grid, TextPassage, Fieldset, ProgressBar, Alert, AlertVariant } from 'react-unity';
import FormModel from '../../../utils/FormModel';
import TextInputField from '../../common/form-controls/TextInputField';
import ConfirmModal from '../../common/modals/ConfirmModal';
import SectionHomeWrapper from '../../common/wrappers/SectionHomeWrapper';
import ApplicationsService from '../../../services/ApplicationsService';
import { authenticatedUser } from '../../../authentication/authModule';
import { Params, useParams } from 'react-router-dom';

interface DecideTransferAppOwnershipProps{
	params: Params<string>;
 }

interface DecideTransferAppOwnershipState
{
    applicationId: string;
	applicationName: string;
	currentOwner: string;
	alertVariant: AlertVariant;
	alertText: string;
	approveModalVisible: boolean;
	rejectModalVisible: boolean;
	isLoading: boolean;
	warningAlert: boolean;
}

class DecideTransferAppOwnershipParams extends React.Component<DecideTransferAppOwnershipProps,DecideTransferAppOwnershipState> {
	applicationsService: ApplicationsService;
	form: FormModel;
	constructor(props) {
		super(props);
		this.state = {
			applicationId: '',
			applicationName: '',
			currentOwner: '',
			alertVariant: 'default',
			alertText: '',
			approveModalVisible: false,
			rejectModalVisible: false,
			isLoading: true,
			warningAlert: false,
		};

		this.applicationsService = new ApplicationsService();
		
		this.form = new FormModel({
			applicationName: {
				getValue: () => this.state.applicationName,
			},
			currentOwner: {
				getValue: () => this.state.currentOwner,
			},
		});
	}

	async componentDidMount() {
		await this.getApplicationData();
		this.setState({ isLoading: false });
	}

	closeWarningLabel = (close: boolean) => {
		this.setState({ warningAlert: close });
		this.goToHome();
	};

	goToHome = () => {
		window.location.href = '/adApps';
	};

	getApplicationData = async () => {
		try {
			const entAppId = parseInt(this.props.params.id);
			var app = await this.applicationsService.getEnterpriseAppById(entAppId);
			
			if (app.newOwner?.id == authenticatedUser.userId) {
				var applicationResult = await this.applicationsService.getEnterpriseAppByAppId(app.applicationId);
				this.setState({ applicationId:applicationResult.applicationId, applicationName: applicationResult.displayName, currentOwner: app.owner.displayName });
			} else {
				this.setState({ alertVariant: 'warning', 
				alertText: 'There is no active ownership transfer assigned to you.' });
			}	
		
		} catch (error) {
			this.setState({ alertVariant: 'error', alertText: error.body.message });
		}
	}

	handleReject = async () => {
		try {
			this.setState({ alertText: 'Processing your request...' });
			this.setState({ rejectModalVisible: false });

			await this.applicationsService.rejectOwnershipOfApplication(this.state.applicationId);

			this.setState({ alertVariant: 'warning', alertText: 'You have rejected the ownership proposal for the application.' });
		} catch (error) {
			this.setState({ alertVariant: 'error', alertText: error.body.message });
		}
	} 

	handleAccept = async () => {
		try {
			this.setState({ alertText: 'Processing your request...' });
			this.setState({ approveModalVisible: false });
			
			await this.applicationsService.acceptOwnershipOfApplication(this.state.applicationId);

			this.setState({ alertVariant: 'success', alertText: 'You have successfully took the application ownership.' });
		} catch (error) {
			this.setState({ alertVariant: 'error', alertText: error.body.message });
		}
	}

	render() {
		return (
			<SectionHomeWrapper title="Confirm Transfer App Ownership">
				{this.state.isLoading ? (
					<ProgressBar indeterminate hideValueLabel label="Loading your data..." />					
				) : (this.state.alertText !== '' ? 
					<div>	
						<Alert variant={this.state.alertVariant} onClose={() => this.closeWarningLabel(true)}>{this.state.alertText}</Alert>
						{this.state.alertVariant === 'success' && (
							<p>You may now review your application.</p>
						)}
						<Button disabled={this.state.alertVariant === 'default'} variant="primary" onClick={() => window.location.href = '/adApps'}>Entra ID Applications</Button>
					</div>
					:	
					<div>
						<TextPassage>
							<p>
							You have been chosen as a candidate owner.<br />
							Below you can accept or reject the transfer of ownership of the application.
							</p>
						</TextPassage>
						<Fieldset>
							<Grid variant="2-up">
								<Grid.Item>
									<TextInputField
										label="Application Name"
										disabled
										value={this.state.applicationName}
									/>
								</Grid.Item>
								<Grid.Item />
								<Grid.Item>
									<TextInputField
										label="Current Owner"
										disabled
										value={this.state.currentOwner}
									/>
								</Grid.Item>
							</Grid>
							<Button variant="secondary" style={{ marginRight: '.5rem' }}
								onClick={() => this.setState({
									rejectModalVisible: true
								})}>
									Reject
							</Button>
							<Button variant="primary" 
								onClick={() => this.setState({
									approveModalVisible: true
								})}
								>
									Accept
							</Button>
						</Fieldset>
					</div>
				)}

				<ConfirmModal
					visible={this.state.approveModalVisible}
					title={`You are about to approve ownership for application '${this.state.applicationName}'`}
					question={`Are you sure you want to approve this request? 
							   A notification will be sent to you upon confirmation informing them of this change.`}
					confirmButton={{ label: 'Confirm Acceptance' }}
					onConfirm={this.handleAccept}
					onCancel={() => this.setState({ approveModalVisible: false })}
				/>
				<ConfirmModal
					visible={this.state.rejectModalVisible}
					title={`You are about to reject ownership for application '${this.state.applicationName}'`}
					question={`Are you sure you want to reject this request?`}
					confirmButton={{ label: 'Confirm Rejection' }}
					onConfirm={this.handleReject}
					onCancel={() => this.setState({ rejectModalVisible: false })}
				/>
			</SectionHomeWrapper>
		);
	}
}

const DecideTransferAppOwnership = () => {

    return <DecideTransferAppOwnershipParams params={useParams()}/>;
}

export default DecideTransferAppOwnership;
