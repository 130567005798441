import React, { useState, useEffect } from 'react';
import { Field, Table, Tooltip, Checkbox } from 'react-unity';
import PortOpeningRule from '../../../models/entities/PortOpeningRule';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';
import Paginator from '../../common/tables/Paginator';
import { PORColumn, RuleColumnsLayout } from './RuleColumnsLayout';
import '../../common/form-controls/Checkbox.css';
import ImplementedRulesTableFilters from './ImplementedRulesTableFilters';
import PORTypeBadge from './PORTypeBadge';

interface ImplementedRulesTableProps {
	rules: PortOpeningRule[];
	selectedRules: PortOpeningRule[];
	porDirection: PortOpeningRequestsDirection;
	onChange: (policyDefinitions: PortOpeningRule[]) => any;
}

const ImplementedRulesTable = ({ rules, selectedRules, porDirection, onChange }: ImplementedRulesTableProps) => {
	const [pageRows, setPageRows] = useState<PortOpeningRule[]>([]);
	const [columns, setColumns] = useState<PORColumn[]>([]);
	const [filteredRules, setFilteredRules] = useState<PortOpeningRule[]>(rules);
	const defaultLimit = 10;

	useEffect(() => {
		initColumns();
	}, [porDirection]);

	const initColumns = () => {
		const originalColumns = RuleColumnsLayout.get(porDirection)
			?.filter((c) => c.header !== 'Operation')
			.map((c) => {
				return { ...c };
			});
		const nameColumnHeader = 'Name';
		var nameColumn = originalColumns?.find(
			(c) => c.header.toLocaleLowerCase() === nameColumnHeader.toLocaleLowerCase()
		);
		var nameColumnIndex = originalColumns?.indexOf(nameColumn);
		var newNameColumn = {
			header: nameColumnHeader,
			cell: (rule: PortOpeningRule) => rule.azureName ?? rule.name,
		};
		originalColumns?.splice(nameColumnIndex, 1, newNameColumn);
		setColumns(originalColumns);
	};

	const changeSelectedRulesList = (rules: PortOpeningRule[]) => {
		onChange(rules);
	};

	const addRule = (rule: PortOpeningRule) => {
		changeSelectedRulesList([...selectedRules, rule]);
	};

	const removeRule = (rule: PortOpeningRule) => {
		const rules = selectedRules.filter((porRule) => porRule !== rule);
		changeSelectedRulesList(rules);
	};

	const handleRowClick = (rule) => {
		if (selectedRules.includes(rule)) {
			removeRule(rule);
			return;
		}
		addRule(rule);
	};

	return (
		<>
			<Field>
				<Field.Body>
					<Table
						footer={<Paginator data={filteredRules} onPageChange={(page) => setPageRows(page)} />}
						header={
							<ImplementedRulesTableFilters
								rules={rules}
								onFilterChange={setFilteredRules}
								selectedScope={porDirection.name}
							/>
						}
					>
						<Table.Head>
							<Table.Head.Row>
								<Table.Head.Cell />
								{columns?.map((column) => (
									<Table.Head.Cell key={column.header}>{column.header}</Table.Head.Cell>
								))}
							</Table.Head.Row>
						</Table.Head>
						<Table.Body>
							{pageRows.length > 0 ? (
								pageRows.map((rule, rowIndex) => (
									<Table.Body.Row
										key={rowIndex}
										style={{ cursor: 'pointer' }}
										onClick={() => handleRowClick(rule)}
									>
										<Table.Body.Cell>
											<Checkbox
												className="chk-no-borders"
												value={rowIndex}
												id={rowIndex.toString()}
												checked={selectedRules.includes(rule)}
											/>
										</Table.Body.Cell>
										{columns?.map((column, colIndex) => (
											<Table.Body.Cell key={`${rowIndex}-${colIndex}`}>
												{column.header === 'Type' ? (<PORTypeBadge >{column.cell(rule)}</PORTypeBadge>)
													:
													(column.cell(rule)?.length > (column.limit || defaultLimit) ? (
														<Tooltip className="em-u-margin-none" variant="below" color="light">
															<Tooltip.Passage>
																{`${column
																	.cell(rule)
																	.trim()
																	.substring(0, column.limit || defaultLimit)}...`}
															</Tooltip.Passage>
															<Tooltip.Content>{column.cell(rule)}</Tooltip.Content>
														</Tooltip>
													) : (
														column.cell(rule) || '-'
													))}
											</Table.Body.Cell>
										))}
									</Table.Body.Row>
								))
							) : (
								<Table.Body.Row>
									<Table.Body.Cell colSpan={columns?.length + 2 || 2}>
										<i>No rules found.</i>
									</Table.Body.Cell>
								</Table.Body.Row>
							)}
						</Table.Body>
					</Table>
				</Field.Body>
			</Field>
		</>
	);
};

export default ImplementedRulesTable;