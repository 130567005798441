import React from 'react';
import { WorkflowState } from '../../../models/enums/WorkflowState';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';
import WorkflowStateBadge from '../../common/WorkflowStateBadge';
import './ProgressBarBig.css';

interface ProgressBarBigProps {
	workflowState: WorkflowState;
	porScope: string;
}

const ProgressBarBig = ({ workflowState, porScope }: ProgressBarBigProps) => {
	const hasProgressBar = (): boolean => {
		const noProgressArray = [WorkflowState.Cancelled, WorkflowState.Rejected];
		return !noProgressArray.some((nws) => nws.value === workflowState.value);
	};

	const activeSOApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.PendingForCloudNetworkApproval.value:
			case WorkflowState.PendingForDOAGApproval.value:
			case WorkflowState.PendingForGatewayPolicyTeamApproval.value:
			case WorkflowState.Error.value:
                return "big-back-blue";
            default:
                return "";
		}
	};

    const activeDOAGApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.PendingForCloudNetworkApproval.value:
			case WorkflowState.PendingForGatewayPolicyTeamApproval.value:
			case WorkflowState.Error.value:
                return "big-back-blue";
            default:
                return "";
		}
	};

    const activeTechnicalApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.Error.value:
                return "big-back-blue";
            default:
                return "";
		}
	};

	return (
		<>
			<div className="big-progress-bar__title">
				<span className="em-c-field__label em-c-field__label--margin">Status</span>
				<WorkflowStateBadge workflowState={workflowState} />
			</div>
			{hasProgressBar() && (
				<div className="big-progress-bar">
					<div className="big-progress-bar__line" />
					{porScope === PortOpeningRequestsDirection.SubscriptionToInternet.name ? (
						<>
							<div className="big-progress-bar__circle-with-title big-left-0">
								<div className={`big-progress-bar__circle ${activeSOApproval()}`}>✓</div>
								<div className="big-progress-bar__circle--title">SO Approval</div>
							</div>
							<div className="big-progress-bar__circle-with-title big-left-50">
								<div className={`big-progress-bar__circle ${activeDOAGApproval()}`}>✓</div>
								<div className="big-progress-bar__circle--title">DOAG Approval</div>
							</div>
							<div className="big-progress-bar__circle-with-title big-left-100">
								<div className={`big-progress-bar__circle ${activeTechnicalApproval()}`}>✓</div>
								<div className="big-progress-bar__circle--title">Technical Approval</div>
							</div>
						</>
					) : (
						<>
							<div className="big-progress-bar__circle-with-title big-left-0">
								<div className={`big-progress-bar__circle ${activeSOApproval()}`}>✓</div>
								<div className="big-progress-bar__circle--title">SO Approval</div>
							</div>
							<div className="big-progress-bar__circle-with-title big-left-100">
								<div className={`big-progress-bar__circle ${activeTechnicalApproval()}`}>✓</div>
								<div className="big-progress-bar__circle--title">Technical Approval</div>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default ProgressBarBig;
