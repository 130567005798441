import * as React from 'react';
import { Button, Grid, XFilledIcon, Alert, AlertVariant, TreeNavigation, ButtonBar, SaveDiskIcon, ButtonSize } from 'react-unity';
import { AbstractEnterpriseAppForm, IEnterpriseAppStateForm, EnterpriseAppFormProps } from './components/AbstractEnterpriseAppForm';
import EnvironmentInput from './components/EnvironmentInput';
import CartaInput from '../common/form-controls/CartaInput';
import TransferAdAppModal from './components/TransferAdAppModal';
import ExpirationDateInput from './components/ExpirationDateInput';
import { AbstractFormState } from '../common/AbstractForm';
import ReadonlyField from '../common/form-controls/ReadonlyField';
import EnterpriseApp from '../../models/entities/EnterpriseApp';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import WorkflowDetailWrapper from '../common/wrappers/WorkflowDetailWrapper';
import ConfirmModal from '../common/modals/ConfirmModal';
import { UpdateEnterpriseAppModel } from '../../models/viewModels/EnterpriseApps/UpdateEnterpriseAppModel';
import UserLookup from '../common/form-controls/UserLookup';
import { ExpirationAction } from '../../models/enums/AdApps/ExpirationAction';
import { WorkflowState } from '../../models/enums/WorkflowState';
import { ObjectType } from '../../models/enums/ObjectType';
import ChangeHistoryItem from '../common/ChangeHistoryItem';
import { LinkTreeItem } from '../common/LinkTree';
import GrantCredentialAdministratorModal from './components/GrantCredentialAdministratorModal';
import ApplicationLookupObject from '../../models/ApplicationLookupObject';
import ReactButton from '../common/ReactButton';
import { useNavigate } from 'react-router-dom';
import { RequestEnterpriseAppModel } from '../../models/viewModels/EnterpriseApps/RequestEnterpriseAppModel';

interface EndModal extends AlertBanner {
	redirectionLink?: string;
}

interface ADAppDetailProps extends ADAppDetailWithParamsProps {}

interface ADAppDetailWithParamsProps extends EnterpriseAppFormProps {
	id: string;
	redirectTo: string;
	linkTreeText: string;
	applicationsService: any;
	navigate: any;
}

interface ADAppDetailStateForm extends IEnterpriseAppStateForm{
}

interface ADAppDetailState extends AbstractFormState<ADAppDetailStateForm> {
	stateForm: ADAppDetailStateForm
	enterpriseApp: EnterpriseApp
	loading: boolean;
	editMode: boolean;
	saving: boolean;
	submissionAlert: AlertBanner;
	endModal: EndModal;
	operationInProgress: boolean;
	environmentToggleValue: ExpirationAction;
	decisionModalVisible: {
		deleteApp?: boolean;
		cancelTransfer?: boolean;
		grantCredentialAdministrator?: boolean;
	};
	transferModalVisible: {
		transferApp?: boolean;
	};
	appToGrant: ApplicationLookupObject;
}

class AADAppDetailWithParams extends AbstractEnterpriseAppForm<
	ADAppDetailProps,
	ADAppDetailState
>{
	constructor(props: ADAppDetailProps) {
		super(props);
		this.state = {
			enterpriseApp: null,
			loading: true,
			editMode: false,
			saving: false,
			submissionAlert: {
				visible: false,
			},
			endModal: {
				visible: false,
			},
			transferModalVisible: {},
			operationInProgress: false,
			decisionModalVisible: {},
			environmentToggleValue: null,
			stateForm: this.getDefaultForm(),
			appToGrant: null,
		};
		this.initFormModel();
	}

	appCredentialAdminRoleName = "Application Credential Administrator";

	async componentDidMount() {
		const id = parseInt(this.props.id);
		await this.loadRequest(id);
	}

	loadRequest = async (id) => {
		this.setState(
			{
				loading: true
			},
			async () => {
				try {
					const request = new EnterpriseApp(await this.props.applicationsService.getEnterpriseAppById(id));

					this.setState({
						enterpriseApp: request,
						loading: false
					}, this.resetStateForm);
					
				}	catch (error) {
					const HTTP_FORBIDDEN_ERROR_CODE = 403;
					if (error.status === HTTP_FORBIDDEN_ERROR_CODE) {
						this.handleEndModal('You are not allowed to see this application.', 'error', 5000, `${this.props.redirectTo}`);
					} else {
						this.handleEndModal('The application data could not be retrieved.', 'error', 10000, `${this.props.redirectTo}`);
					}
				}
			}
		);
	};

	resetStateForm = () => {
		this.setState((prevState) => (
			{
				stateForm: this.stateFormFromRequest(prevState.enterpriseApp)
			}
		));
	};

	handleEndModal = (text: string, variant: AlertVariant, timeout: number, link: string) => {
		this.setState({
			endModal: {
				visible: true,
				text,
				variant,
				redirectionLink: link
			}
		},
		() => {
			setTimeout(() => {
				this.endAndRedirect();
			}, timeout);
		});
	};

	endAndRedirect = () => {
		const link = this.state.endModal.redirectionLink;
		this.setState({
			endModal: { visible: false }
		},
		() => {
			this.props.navigate(link);
		});
	};

	actions = () => (
		<TreeNavigation className="em-u-clickable">
			<TreeNavigation.Item
				label="Open in Azure"
				onClick={() => { window.open(`https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/${this.state.enterpriseApp?.applicationId}`, '_blank'); }}
			/>
			{this.state.enterpriseApp?.isEditable() &&
				<TreeNavigation.Item
					label="Edit Application"
					onClick={!this.state.enterpriseApp?.isInTransfer() ? this.enableEdit : null}
					className={this.state.enterpriseApp?.isInTransfer() ? 'em-c-btn--disabled' : ''}
					title={this.state.enterpriseApp?.isInTransfer() ? "You can't edit the app while it's in transfer" : null}
				/>}
			{this.state.enterpriseApp?.isEditable() && 
			!this.state.enterpriseApp?.isInTransfer() &&
			 this.state.enterpriseApp?.isTransferable() &&
			!this.state.enterpriseApp?.isExpired() &&
				<TreeNavigation.Item 
					label="Transfer Ownership"
					onClick={this.openTransferModal}
				/>}
			{this.state.enterpriseApp?.isEditable() &&
			 this.state.enterpriseApp?.isInTransfer() &&
			 this.state.enterpriseApp?.isTransferable() &&
			 <TreeNavigation.Item 
			 label="Cancel Ownership Transfer"
			 onClick={() => { this.openDecisionModal('cancelTransfer'); }}
			 />}
			 {this.state.enterpriseApp?.isEditable() &&
				 <TreeNavigation.Item 
					 label="Delete Application"
					onClick={() => { this.openDecisionModal('deleteApp'); }}
				/>}
			<TreeNavigation.Item
				label="Grant Credential Administrator"
				onClick={() => { this.openDecisionModal('grantCredentialAdministrator'); }}
			/>
			<ChangeHistoryItem
				id={this.state.enterpriseApp?.applicationId}
				type={ObjectType.EnterpriseApp}
				service={this.props.applicationsService}
				handleAlert={this.handleSubmissionAlert}
				handleAlertClose={this.handleSubmissionAlertClose}
			/>
		</TreeNavigation>
	);

	editActions = (size: ButtonSize) => (
		<ButtonBar className='edit-button-bar'>
			{(!this.state.operationInProgress && !this.state.saving) &&
				<>
					<ButtonBar.Item>
						<Button
							size={size}
							variant="secondary"
							onClick={this.cancelEdit}
						>
							<XFilledIcon
								size={size}
							/>
							<span>Cancel</span>
						</Button>
					</ButtonBar.Item>
					<ButtonBar.Item separator />
				</>}
			<ButtonBar.Item>
				<ReactButton
					size={size}
					variant="primary"
					disabled={!this.formModel.isValid() || this.state.operationInProgress || this.state.saving}
					isLoading={this.state.operationInProgress || this.state.saving }
					loadingVariant="secondary"
					handleUpdateSubmit={this.handleSave}
					name="Save"
				>
					<SaveDiskIcon
						className='em-u-margin-right-half'
						size={size}
					/>
				</ReactButton>
			</ButtonBar.Item>
		</ButtonBar>
	);

	
	handleSave = async () => {
		window.scroll(0, 0);
		this.setState({
			saving: true,
			submissionAlert: {
				visible: true,
				text: 'Saving changes...'
			},
			transferModalVisible: {}
		});
		const model = this.formModel.create(UpdateEnterpriseAppModel);
		this.props.applicationsService.updateApplication(this.state.enterpriseApp?.applicationId, model)
			.then(response => {
				const enterpriseApp = new EnterpriseApp(response);
				this.setState({
					enterpriseApp,
					editMode: false,
					submissionAlert: {
						visible: true,
						variant: 'success',
						text: 'Application updated successfully.'
					}
				},
				() => {
					setTimeout(this.handleSubmissionAlertClose, 5000);
				});
			})
			.catch((err) => {
				this.setState({
					submissionAlert: {
						visible: true,
						variant: 'error',
						text: err.response?.data.message || 'The application could not be updated. Please, try again later.'
					}
				});
			})
			.finally(() => {
				this.setState({
					saving: false
				});
			});
	};

	openDecisionModal = (type: 'deleteApp' | 'cancelTransfer' | 'grantCredentialAdministrator') => {
		this.setState({
			decisionModalVisible: {
				[type]: true
			}
		});
	};

	openTransferModal = () => {
		this.setState({
			transferModalVisible: {
				transferApp: true
			}
		});
	};

	handleAppDeletion = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.props.applicationsService.deleteById(this.state.enterpriseApp?.applicationId);
				const updatedAdApp = new EnterpriseApp(this.state.enterpriseApp);
				updatedAdApp.workflowInstance.currentWorkflowInstanceState.workflowState = WorkflowState.Deleted;
				updatedAdApp.newOwner = null;
				this.setState({
					enterpriseApp: updatedAdApp
				});
			},
			inProgressMessage: 'Deleting...',
			successMessage: 'App deleted successfully.',
		});
	};

	handleAppCancelTransfer = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.props.applicationsService.cancelTransferOwnership(this.state.enterpriseApp?.applicationId);
				const updatedAdApp = new EnterpriseApp(this.state.enterpriseApp);
				updatedAdApp.newOwner = null;
				this.stateFormHandler().ownerInTransfer.onChange(null);
				this.setState({
					enterpriseApp: updatedAdApp
				});
			},
			inProgressMessage: 'Canceling...',
			successMessage: 'App Transfer Ownership cancelled successfully.',
		});
	};

	handleGrantCredentialAdministratorRole = async (appToGrant: ApplicationLookupObject) => {
		this.closeDecisionModal();
		this.setSubmissionAlertInProgress(`Granting ${this.appCredentialAdminRoleName} Role...`);
		try {
			await this.props.applicationsService.grantCredentialAdminRole(appToGrant.applicationId, this.state.enterpriseApp.applicationId);
			const updatedAdApp = new EnterpriseApp(this.state.enterpriseApp);
			this.setState({
				enterpriseApp: updatedAdApp
			});
			this.setEmbedAlertSuccess(`${this.appCredentialAdminRoleName} role to ${appToGrant.displayName} was granted successfully.`);
		} catch (err) {
			this.handleSubmissionAlert('error', err?.body?.message || 'The application could not be updated. Please, try again later.');
		}
		this.setState({
			loading: false
		}, this.resetStateForm);
		setTimeout(() => {
			this.setState({
				submissionAlert: {
					visible: false
				}
			});
		}, 10000);
	}

	handleOperationConfirmed = async ({
		operation,
		inProgressMessage,
		successMessage
	}) => {
		this.setSubmissionAlertInProgress(inProgressMessage);
		this.closeDecisionModal();
		try {
			await operation();
			this.setEmbedAlertSuccess(successMessage);
		} catch (err) {
			await this.loadRequest(this.state.enterpriseApp?.applicationId);
			this.handleSubmissionAlert('error', err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
		} finally {
			this.setState({
				operationInProgress: false,
			});
		}
	};

	setSubmissionAlertInProgress = (message) => {
		window.scroll(0, 0);
		this.setState({
			operationInProgress: true,
			submissionAlert: {
				visible: true,
				text: message
			}
		});
	};

	setEmbedAlertSuccess = (message) => {
		this.setState({
			submissionAlert: {
				variant: 'success',
				text: message,
				visible: true,
			}
		}, () => {
			setTimeout(this.handleSubmissionAlertClose, 5000);
		});
	};

	handleSubmissionAlertClose = () => {
		this.setState({
			submissionAlert: {
				visible: false
			}
		});
	};

	handleSubmissionAlert = (variant, text) => {
		this.setState({
			submissionAlert: {
				visible: true,
				text,
				variant
			}
		});
	};

	enableEdit = () => {
		this.setState({
			editMode: true,
		});
	};

	cancelEdit = () => {
		this.setState({ editMode: false });
		this.resetStateForm();
	};

	handleCancel = () => {
		this.setState({ editMode: false });
		this.resetStateForm();
	};

	closeDecisionModal = () => {
		this.setState({
			decisionModalVisible: {},
		});
	};

	closeCredentialAdministratorModal = () => {
		this.setState({
			decisionModalVisible: {},
		});
		this.resetStateForm();
	};

	closeTransferModal = () => {
		this.setState({
			transferModalVisible: {},
		});
		this.stateFormHandler().ownerInTransfer.onChange(null);
	};

	// eslint-disable-next-line class-methods-use-this
	getDefaultFormState() {
		return this.state != null ? this.stateFormFromRequest(this.state.enterpriseApp) : {};
	}

	getLinkTree = (): LinkTreeItem[] => {

		return [
			{ text: `${this.props.linkTreeText}`, to: `${this.props.redirectTo}` },
		];
	};

	render() {
		return (
			<>
				<WorkflowDetailWrapper
					title={this.state.enterpriseApp?.displayName}
					loading={this.state.loading}
					workflowInstance={this.state.enterpriseApp?.workflowInstance}
					linkTree={this.getLinkTree()}
					actions={!this.state.editMode ? this.actions() : this.editActions('small')}		
				>
					{this.state.submissionAlert.visible &&
					<Alert
						variant={this.state.submissionAlert.variant}
						onClose={this.handleSubmissionAlertClose}
					>
						{this.state.submissionAlert.text}
					</Alert>}
					<Grid variant="2-up">
						<Grid.Item>
							<ReadonlyField
								label="Owner"
								text={this.state.enterpriseApp?.owner.displayName}
							/>
						</Grid.Item>
						<Grid.Item>
							<EnvironmentInput
								{...this.stateFormHandler().environment}
								disabled={this.state.saving || !this.state.editMode || this.stateFormHandler().applicationLifeTime.value === ExpirationAction.Temporary}
							/>
						</Grid.Item>
					</Grid>
					<Grid variant="2-up">
						<Grid.Item>
							<ExpirationDateInput
								expirationDate={this.stateFormHandler().expirationDate}
								applicationLifeTime={this.stateFormHandler().applicationLifeTime}
								disableState={this.state.saving || !this.state.editMode}
							/>
							{this.props.editingApplication?.expirationDate != null &&
								<Alert
									variant="warning"
									className="no-alert-actions"
								>
									<>
										Your Application Expiration Date is: {this.props.editingApplication.expirationDate}  <br />
										Once the Expiration Date has been reached, the application will be deactivated.
									</>
								</Alert>}
							<CartaInput
								{...this.stateFormHandler().cartaIds}
								disabled={this.state.saving || !this.state.editMode}
								hideNotes={this.state.saving || !this.state.editMode}
							/>
							{this.state.enterpriseApp?.newOwner?.displayName != null &&
								<UserLookup
									id="owner-lookup"
									label="Ownership in Transfer to"
									value={this.state.enterpriseApp?.newOwner?.displayName ?? this.state.stateForm.ownerInTransfer?.displayName}
									disabled={true}
								/>}
							{(this.state.editMode && !this.state.saving) && this.editActions('medium')}
						</Grid.Item>
						<Grid.Item>
							<ReadonlyField
								label="Azure Application ID"
								text={this.state.enterpriseApp?.applicationId.toString()}
								copy
							/>
						</Grid.Item>
					</Grid>
				</WorkflowDetailWrapper>
				<ConfirmModal
					visible={this.state.decisionModalVisible.deleteApp}
					title={`Delete App ${this.state.enterpriseApp?.displayName}`}
					question="Are you sure you want to delete this application? This action cannot be undone."
					confirmButton={{
						label: 'Delete App',
						props: {
							variant: 'primary',
							color: 'negative'
						}
					}}
					onConfirm={() => this.handleAppDeletion()}
					onCancel={this.closeDecisionModal}
				/>
				<ConfirmModal
					visible={this.state.decisionModalVisible.cancelTransfer}
					title={`Cancel App Transfer Ownership ${this.state.enterpriseApp?.displayName}`}
					question="Are you sure you want to cancel the transfer?"
					confirmButton={{
						label: 'Cancel Transfer',
						props: {
							variant: 'primary',
							color: 'negative'
						}
					}}
					onConfirm={() => this.handleAppCancelTransfer()}
					onCancel={this.closeDecisionModal}
				/>
				<GrantCredentialAdministratorModal
					visible={this.state.decisionModalVisible.grantCredentialAdministrator}
					title={`Grant "${this.appCredentialAdminRoleName}" Role`}
					confirmButton={{
						label: 'Grant role',
						props: {
							variant: 'primary'
						}
					}}
					onConfirm={(appToGrant) => this.handleGrantCredentialAdministratorRole(appToGrant)}
					onCancel={this.closeCredentialAdministratorModal}
				/>
				<TransferAdAppModal
					ownerInTransfer={this.stateFormHandler().ownerInTransfer}
					visible={this.state.transferModalVisible.transferApp}
					formIsValid={!this.formModel.isValid() || this.state.operationInProgress}
					onConfirm={this.handleSave}
					onCancel={this.closeTransferModal}
				/>
			</>
		);
	}
}

const AADAppDetail = (props: ADAppDetailProps) => {
	return (
		<AADAppDetailWithParams 
			id={props.id}
			redirectTo={props.redirectTo}
			linkTreeText={props.linkTreeText}
			applicationsService={props.applicationsService}
			navigate={useNavigate()}
			visible={props.visible}
			onCreate={props.onCreate}
			onClose={props.onClose}
		/>
	)
}

export default AADAppDetail;