import React from 'react';
import { Badge } from 'react-unity';

interface POROperationBadgeProps {
	children: string;
}

const POROperationBadge = ({ children }: POROperationBadgeProps) => {
	const getStyle = () => {
		switch (children) {
			case 'Add':
				return {backgroundColor: '#0192d0', color: 'white'};
			case 'Modify':
				return {backgroundColor: '#ffd700', color: 'black'};
			case 'Remove':
				return {backgroundColor: '#b12a0b', color: 'white'};
			case 'Keep':
				return {backgroundColor: '#2eb804', color: 'white'};
			default:
				return {backgroundColor: '#dddddd', color: 'black'};
		}
	};

	const getText = () => {
		switch (children) {
			case 'Add':
				return 'new';
			case 'Modify':
				return 'modify';
			case 'Remove':
				return 'delete';
			default:
				return children;
		}
	};

	return (
		<Badge style={{...getStyle(), minWidth: 80}} className="em-u-max-width-10">
			{getText()}
		</Badge>
	);
};

export default POROperationBadge;