import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Table,
	ProgressBar,
	TextPassage,
	TextLink,
	AlertVariant,
	Alert,
} from 'react-unity';
import EnterpriseApp from '../../models/entities/EnterpriseApp';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import AlertModal from '../common/modals/AlertModal';
import Paginator from '../common/tables/Paginator';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import '../common/wrappers/SectionHomeWrapper.css';
import './AADAppsHome.css';
import AADAppsHomeTableRow from './AADAppsHomeTableRow';
import ApplicationsHomeFilters from './components/ApplicationsHomeFilters';
import AppPermissionsModal from './components/AppPermissionsModal';
import EnterpriseAppModal from './components/EnterpriseAppModal';
import { RequestEnterpriseAppModel } from '../../models/viewModels/EnterpriseApps/RequestEnterpriseAppModel';
import AADEditModal from './AADEditModal';


interface AdAppsHomeState {
	typeOfApps: 'Applications' | '3rd Party Applications';
	applicationsService: any;
}

interface WarningsApp {
	id: number;
	visible: Boolean;
}

const memoized={
	myApps: void 0,
	allApps: void 0,
	my3rdApps: void 0,
	all3rdApps: void 0,
}

const AADAppsHome = ({
	typeOfApps,
	applicationsService
}: AdAppsHomeState) => {

	async function getApps(method:string, key:string) {
		if (typeof memoized[key] !== 'undefined') return memoized[key];
		let data = [];
		data = await applicationsService[method]();
		memoized[key] = data;
		return data;
	}

	function refreshCache() {
		if (typeOfApps == 'Applications') {
			memoized.myApps = void 0;
			memoized.allApps = void 0;
		}
		else {
			memoized.my3rdApps = void 0;
			memoized.all3rdApps = void 0;
		}
	}

	const [allApplications, setAllApplications] = useState<EnterpriseApp[]>([]);
	const [myApplications, setMyApplications] = useState<EnterpriseApp[]>([]);
	const [filteredApplications, setFilteredApplications] = useState<EnterpriseApp[]>([]);
	const [applicationsOnPage, setApplicationsOnPage] = useState<EnterpriseApp[]>([]);
	const [permissionReportModalVisible, setPermissionReportModalVisible] = useState<boolean>(false);
	const [enterpriseAppModalVisible, setEnterpriseAppModalVisible] = useState<boolean>(false);
	const [selectedApplication, setSelectedApplication] = useState<EnterpriseApp>(new EnterpriseApp(''));
	const [areMyAppsLoading, setAreMyAppsLoading] = useState<boolean>(true);
	const [areAllAppsLoading, setAreAllAppsLoading] = useState<boolean>(true);
	const [progressLabel, setProgressLabel] = useState<string>('Loading your Applications...');
	const [endModal, setEndModal] = useState<AlertBanner>({ visible: false });
	const [editMultiple, setEditMultiple] = useState<boolean>(false);
	const [modalVisibleEditMode, setModalVisibleEditMode] = useState<boolean>(false);
	const [submissionAlert, setSubmissionAlert] = useState<AlertBanner>({
		visible: false,
		text: ''
	});
	const [warningAppsNotification, setWarningAppsNotification] = useState<WarningsApp[]>([]);
	const [editingApplication, setEditingApplication] = useState<EnterpriseApp>(null);
	const [selectedPermissionsTab, setSelectedPermissionTab] = useState<'App Permissions' | 'Permissions to App'>('App Permissions');
	const navigate = useNavigate();
	const applicationRow: React.RefObject<HTMLBaseElement> = React.createRef();

	const retreiveApps = async (isRefresh: boolean) => {
		setAreMyAppsLoading(true);
		setAreAllAppsLoading(true);
		setProgressLabel(`${isRefresh ? 'Reloading' : 'Loading'} your ${typeOfApps}...`);

		try {				
			if (isRefresh) refreshCache();

			let myApplications: any;
			if (typeOfApps == 'Applications') myApplications = await getApps('getMyApplications', 'myApps');
			else myApplications = await getApps('getMyApplications', 'my3rdApps');

			setAllApplications(mapApplications(myApplications));
			setMyApplications(mapApplications(myApplications));
			const warningVisibility = [];
			myApplications.forEach( (app: { warnings: { id: any; }[]; }) => {
				app.warnings?.map((warning: { id: any; }) => {
					const Wvisibility = {
						id: warning.id,
						visible: true,
					 }
					 warningVisibility.push(Wvisibility);
				})
			});

			setWarningAppsNotification(warningVisibility)
			setAreMyAppsLoading(false);
			setProgressLabel(`${isRefresh ? 'Reloading' : 'Loading'} all Applications...`);

			let allApps: any;
			if (typeOfApps == 'Applications') allApps = await getApps('getAllApplications', 'allApps');
			else allApps = await getApps('getAllApplications', 'all3rdApps');

			allApps = allApps.map((app: EnterpriseApp) => (
				{...app, ...myApplications.find((myApp: EnterpriseApp) => app.applicationId === myApp.applicationId)}
			));
			setAllApplications(mapApplications(allApps));
			setAreAllAppsLoading(false);
		} catch (err) {			
			handleEndModal('The application could not be retrieved.', 'error', 10000);
		}
	};

	const mapApplications = (applications: EnterpriseApp[]) => {
		return(applications.map(a => new EnterpriseApp(a))
		.sort((a, b) => a.displayName.localeCompare(b.displayName)));
	};

	const handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		setEndModal({
			visible: true,
			text,
			variant,
		});
		setTimeout(() => endAndRedirect(), timeout);
	};

	const endAndRedirect = () => {
		setEndModal({ visible: false });
		navigate('/');
	};

	const seePermissionsReport = (application: EnterpriseApp, selectedPermissionsTab: 'App Permissions'|'Permissions to App') => {
		setSelectedPermissionTab(selectedPermissionsTab);
		setSelectedApplication(application);
		setPermissionReportModalVisible(true);
	};

	const closeModalAndNotifySubmission = () => {
		setEnterpriseAppModalVisible(false);
		setSubmissionAlert({
			visible: true,
			text: 'Your request has been successfully submitted. You will be notified once your application has been created and is ready for use.',
			variant: 'success',
		});
	};
	
	const setEnterpriseAppAndModal = () => {
		setEnterpriseAppModalVisible(false);
		setEditingApplication(null);
	};

	const handleEnerpriseAppCreate = async (model: RequestEnterpriseAppModel) => {
		closeModalAndNotifySubmission();
		try {
			await applicationsService.createApplication(model);
			retreiveApps(true);
			setSubmissionAlert({
				visible: true,
				text: `Your new application '${model.displayName}' has been successfully created and can be found in the table below.`,
				variant: 'success',
			});
		} catch (err) {
			setSubmissionAlert({
				visible: true,
				text: err?.body?.message ?? 'An error occured when processing your request. Please, try again later.',
				variant: 'error',
			});
		};
	};

	const handleAlertClose = () => {
		setSubmissionAlert({
			visible: false,
			text: ''
		});
	};
	const handleRefresh = () => {
		retreiveApps(true);
	};
	const handleModalEditModeConfirmed = async () => {
		setModalVisibleEditMode(false);
		handleRefresh();
	};
	const handleModalEditModeClosed = () => {
		setModalVisibleEditMode(false);
		setEditMultiple(false);
	};
	const handleModalEditModeCancel = async () => {
		setTimeout(() => {
			setModalVisibleEditMode(false);
		}, 5000);
		handleRefresh();
	};

	const getFilterProps = () => {
		return {
			setFilteredApplications,
			filteredApplications,
			areMyAppsLoading,
			areAllAppsLoading,
			retreiveApps,
			setEditMultiple,
			editMultiple,
			setModalVisibleEditMode,
			checkBoxState,
			allApplications,
			myApplications,
			typeOfApps,
			setEnterpriseAppModalVisible,
		};
	};

	const closeWarningNotification = (warningid: number) => {
		let warningVisibility = [...warningAppsNotification]
		warningVisibility.find(element => element.id === warningid).visible = false;
		setWarningAppsNotification(warningVisibility);
	}

	const [checkBoxState, setCheckBoxState] = useState(0);

	useEffect(() => {
		retreiveApps(false);
		setTimeout(() => {
			handleAlertClose();
		  }, 5000);
	}, []);

	return (
		<div>
			<SectionHomeWrapper title={`Entra ID ${typeOfApps}`}>
				{(areMyAppsLoading || areAllAppsLoading) && (
					<ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label={progressLabel} />
				)}				
			</SectionHomeWrapper>
			<SectionHomeWrapper title="">
				<TextPassage>
					<p>
						<TextLink style={{ textDecoration: 'none' }} target="_blank" external href="https://appwiki.xom.cloud/docs/Id-Auth/HowTos.html">
							Create a New Application
						</TextLink>
						<TextLink style={{ textDecoration: 'none' }} target="_blank" external href="https://appwiki.xom.cloud/docs/Id-Auth/admin-consent.html">
							Application Permissions
						</TextLink>
						<TextLink style={{ textDecoration: 'none' }} target="_blank" external href="https://appwiki.xom.cloud/docs/Id-Auth/admin-consent.html#allowed-ms-graph-api-permissions-that-can-be-consented-via-the-cloud-self-service-portal">
							Administrative Consent
						</TextLink>
						<TextLink style={{ textDecoration: 'none' }} target="_blank" external href="https://appwiki.xom.cloud/docs/Id-Auth/SeamlessSSO.html">
							Seamless Single Sign-On
						</TextLink>
					</p>
				</TextPassage>
				{submissionAlert.visible && (
					<Alert
						variant={submissionAlert.variant}
						onClick={handleAlertClose}
						onClose={handleAlertClose}
					>
						{submissionAlert.text}
					</Alert>
				)}
				{!areMyAppsLoading && (
					<>
						<ApplicationsHomeFilters {...getFilterProps()} />
						<Table 
							footer={
								<Paginator
									data={filteredApplications}
									totalItems={allApplications.length}
									onPageChange={(apps) => {
										setApplicationsOnPage(apps);
									}}
								/>
							}
						>
							<Table.Head>
								<Table.Head.Row>
									<Table.Head.Cell className="em-u-display-flex">App Name</Table.Head.Cell>
									<Table.Head.Cell> App Permissions </Table.Head.Cell>
									<Table.Head.Cell> Admin Consent </Table.Head.Cell>
									<Table.Head.Cell> Seamless SSO </Table.Head.Cell>
									<Table.Head.Cell> Ownership </Table.Head.Cell>
									<Table.Head.Cell> Actions </Table.Head.Cell>
								</Table.Head.Row>
							</Table.Head>
								<Table.Body>
								{applicationsOnPage.map((app) => (
									<React.Fragment key={app.applicationId}>
										<AADAppsHomeTableRow
											key={app.id}
											application={app}
											seePermissionsReport={seePermissionsReport}
											setCheckBoxState={setCheckBoxState}
											checkBoxState={checkBoxState}
											applicationRowRef={applicationRow}
											allApplications={allApplications}
											editMultiple={editMultiple}
											takeOwnershipOfApplication={(applicationId: string) => applicationsService.takeOwnershipOfApplication(applicationId)}
											assignHomeRealmDiscoveryPolicy={(applicationId: string) => applicationsService.assignHomeRealmDiscoveryPolicy(applicationId)}
											removeHomeRealmDiscoveryPolicy={(applicationId: string) => applicationsService.removeHomeRealmDiscoveryPolicy(applicationId)}
											grantAdminConsent={(applicationId: string) => applicationsService.grantAdminConsent(applicationId)}
											retreiveApps={(e: boolean) => retreiveApps(e)}
											setSubmissionAlert={setSubmissionAlert}
											typeOfApps={typeOfApps}
											setAreMyAppsLoading={setAreMyAppsLoading}
										/>
										{app.warnings?.map(warning => {
											{
												return (warningAppsNotification.find(element => {
													return element.id === warning.id;
												}).visible == true) ?
													(
														<tr key={warning.id} >
															<th colSpan={100}>
																<Alert key={warning.id} variant="error"
																	onClose={() => closeWarningNotification(warning.id)}
																>
																	This application last used {warning.usedLibrary} during {warning.lastUsed}.
																	{warning.usedLibrary.includes('ADAL') ? ' ADAL library' : ' AD Graph API'} will be deprecated by December 31st.
																	Please migrate to {warning.usedLibrary.includes('ADAL') ? 'MSAL Library' : 'Graph Microsoft API'}.
																	For more details click <a target="_blank" href="https://spsharing1.exxonmobil.com/sites/ModernApps/Identity/_layouts/15/WopiFrame2.aspx?sourcedoc={96C996C2-920C-4A57-B72E-02968DE34419}&file=AAD%20apps%20Impacted%20By%20ADAL%20AD%20Graph%20Deprecation%2015Oct22.xlsx&action=default">here. </a>
																	<br />
																	If you have already fixed this, please disregard this message.
																</Alert>
															</th>
														</tr>
													)
													:
													<React.Fragment key={warning.id}  ></React.Fragment>
											}

										})}
									</React.Fragment>
										))}
								</Table.Body>
						</Table>
					</>
				)}
				<EnterpriseAppModal
					visible={enterpriseAppModalVisible}
					onCreate={handleEnerpriseAppCreate}
					onClose={setEnterpriseAppAndModal}
					editingApplication={editingApplication}
				/>
				<AADEditModal
					visible={modalVisibleEditMode}
					myApplications={applicationsOnPage}
					onConfirm={handleModalEditModeConfirmed}
					onClose={handleModalEditModeClosed}
					setCheckBoxState={setCheckBoxState}
					onCancel={handleModalEditModeCancel}
					setAlert={setSubmissionAlert}
				/>
				<AppPermissionsModal
					applicationsService={applicationsService}
					application={selectedApplication}
					selectedPermissionsTab={selectedPermissionsTab}
					visible={permissionReportModalVisible}
					onClose={() => setPermissionReportModalVisible(false)}
					togglePermissionsTab={(e) => setSelectedPermissionTab(e.target.textContent)}
				/>
				</SectionHomeWrapper>
				<AlertModal {...endModal} willTimeout={false} onClose={endAndRedirect} />
			</div>
	);
}

export default AADAppsHome;
