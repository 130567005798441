import * as React from 'react';
import { Link, Params, useParams } from 'react-router-dom';
import { Grid, Field, TreeNavigation, Badge, Button, SaveDiskIcon, XFilledIcon, AlertVariant, Alert, ButtonSize, ButtonBar, TextLink } from 'react-unity';
import moment from 'moment';
import { AbstractSubscriptionsForm, ISubscriptionStateForm } from './AbstractSubscriptionsForm';
import WorkflowDetailWrapper from '../common/wrappers/WorkflowDetailWrapper';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import ReadonlyField from '../common/form-controls/ReadonlyField';
import { OwnerInput, CustodianInput, CustodianTwoInput, EnvironmentInput, OrganizationInput, SubscriptionTypeInput, ProjectInput } from './components';
import CartaInput from '../common/form-controls/CartaInput';
import UserLookupObject from '../../models/UserLookupObject';
import TextAreaField from '../common/form-controls/TextareaField';
import Group from '../../models/Group';
import GroupsService from '../../services/GroupsService';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../../models/enums/UserRole';
import { UpdateSubscriptionModel } from '../../models/viewModels/Subscriptions';
import PermissionReportModal from './components/PermissionReportModal';
import { ObjectType } from '../../models/enums/ObjectType';
import ChangeHistoryItem from '../common/ChangeHistoryItem';
import Organization from '../../models/entities/Organization';
import { getOrganizations } from '../common/OrganizationStorage';
import UsersService from '../../services/UsersService';
import ServicePrincipalService from '../../services/ServicePrincipalService';
import ReactButton from '../common/ReactButton';
import SubDomainOperationModal from './DomainOperation/SubDomainOperationModal';
import TextInputField from '../common/form-controls/TextInputField';

interface SubscriptionsDetailParamsProps {
	params: Params<string>
}

interface DetailStateForm extends ISubscriptionStateForm {
}

interface SubscriptionsDetailParamsState {
	loading: boolean;
	editMode: boolean;
	saving: boolean;
	subscription?: SubscriptionRequest;
	initialGroup?: Group;
	resourceGroupOwner?: any;
	stateForm: DetailStateForm;
	porItemsExpanded: boolean;
	perItemsExpanded: boolean;
	alert: {
		visible: boolean;
		text?: string;
		variant?: AlertVariant;
	}
	ownersAlert: {
		visible: boolean,
		text?: string;
		variant?: AlertVariant;
		link?: string;
	}
	permissionReportModalVisible: boolean;
	organizationsList: Organization[];
	modalVisibleDomainOperation: boolean;
}

class SubscriptionsDetailParams
	extends AbstractSubscriptionsForm<SubscriptionsDetailParamsProps, SubscriptionsDetailParamsState> {
	groupsService: GroupsService;
	servicePrincipalsService: ServicePrincipalService;
	usersService: UsersService;
	constructor(props) {
		super(props);
		this.groupsService = new GroupsService();
		this.servicePrincipalsService = new ServicePrincipalService();
		this.usersService = new UsersService();
		this.state = {
			loading: true,
			editMode: false,
			saving: false,
			stateForm: {
				environment: null,
				selectedL3: '',
				selectedL4: '',
				selectedOrganization: '',
				isConnected: false,
				isProject: false,
				cartaIds: [],
				owner: null,
				custodian: null,
				custodianTwo: null,
				subscriptionUsedFor: '',
				isImportedOrUpdated: true,
				subsBudget: null,
			},
			porItemsExpanded: false,
			perItemsExpanded: false,
			alert: {
				visible: false,
			},
			ownersAlert: {
				visible: false,
			},
			permissionReportModalVisible: false,
			organizationsList: [],
			modalVisibleDomainOperation: false,
		};

		this.initFormModel();
	}

	initFormModel() {
		super.initFormModel();
		this.formModel.fields.cartaIds.validation.required = () => (!authenticatedUser.isInRole(UserRole.SubscriptionAdmin));
		this.formModel.fields.closeOutDate.validation.rules = [
			{
				assert: () => {
					let startDate = moment(this.state.subscription?.workflowInstance.createdDate);
					startDate = moment(`${startDate.year()}-${startDate.month() + 1}-01`, 'YYYY-MM-DD');
					let endDate = moment(this.state.subscription?.workflowInstance.createdDate).add(15, 'years');
					endDate = moment(`${endDate.year()}-12-31`, 'YYYY-MM-DD');
					return moment(this.state.stateForm.closeOutDate) >= startDate && moment(this.state.stateForm.closeOutDate) <= endDate;
				},
				message: 'The close out date is out of range.'
			}
		];
		this.formModel.fields.contributorAccessGroupId.validation.required = () =>
			(this.state.stateForm.isConnected && this.state.subscription?.tenant.name !== 'ExxonMobilTest');
		this.handleStateFormChange('isImportedOrUpdated', true);
	}

	async componentDidMount() {
		const id = parseInt(this.props.params.id);
		const subscription = new SubscriptionRequest(await this.subscriptionsService.getById(id));
		const organizations = (await getOrganizations()).map(o => new Organization(o));
		subscription.organization.setLevels(organizations);
		this.setState({
			subscription,
			organizationsList: organizations
		},
		async () => {
			this.resetStateForm();
			if (this.state.subscription.isConnected && this.state.subscription.adGroupObjectId != null) {
				await this.setInitialGroup();
				if (this.state.subscription.resourceGroupOwnerType == 'Groups') {
					return this.setResourceOwnerAsGroup();
				} else if (this.state.subscription.resourceGroupOwnerType == 'Users') {
					return this.setResourceOwnerAsOwner();
				} else {
					return this.setResourceOwnerAsServicePrincipal();
				}
			} else {
				this.setState({
					loading: false,
				});
			}
		}
		);
	}

	resetStateForm = () => {
		this.setState((prevState) => (
			{
				stateForm: {
					environment: prevState.subscription?.environment,

					selectedL3: prevState.subscription?.organization.L3.id.toString(),
					selectedL4: prevState.subscription?.organization.L4.id.toString(),
					selectedOrganization: prevState.subscription?.organization.id.toString(),

					isConnected: prevState.subscription?.isConnected,
					amountOfDevices: (prevState.subscription?.addressSpaceLength === 0) ? undefined : prevState.subscription?.addressSpaceLength,
					region: prevState.subscription?.region,
					initialResourceGroup: prevState.subscription?.resourceGroupName,
					contributorAccessGroup: prevState.initialGroup,
					resourceGroupOwnerType: prevState.subscription?.resourceGroupOwnerType,
					resourceGroupOwner: prevState.resourceGroupOwner,

					isProject: prevState.subscription?.isProject,
					wpmCode: prevState.subscription?.wpmCode || '',

					closeOutDate: prevState.subscription?.closeOutDate ? moment(prevState.subscription?.closeOutDate, 'MM-YYYY').toString() : null,

					cartaIds: prevState.subscription?.cartaIds,

					owner: UserLookupObject.fromUser(prevState.subscription?.owner),
					custodian: UserLookupObject.fromUser(prevState.subscription?.custodian),
					custodianTwo: UserLookupObject.fromUser(prevState.subscription?.custodianTwo),
					subscriptionUsedFor: prevState.subscription?.subscriptionUsedFor,
					isImportedOrUpdated: true,
					subsBudget: prevState.subscription?.subsBudget
				}
			}
		));
	};

	setInitialGroup = async () => {
		this.setState({ loading: true });
		const group = await this.groupsService.getById(this.state.subscription?.adGroupObjectId);
		this.setState({
			initialGroup: group,
			loading: false
		});
		this.handleStateFormChange('contributorAccessGroup', this.state.initialGroup);

	};

	setResourceOwnerAsGroup = async () => {
		this.setState({ loading: true });
		const group = await this.groupsService.getById(this.state.subscription?.resourceGroupOwnerId);
		this.setState({
			resourceGroupOwner: group,
			loading: false
		});
		this.handleStateFormChange('resourceGroupOwner', this.state.resourceGroupOwner);
	};

	setResourceOwnerAsOwner = async () => {
		this.setState({ loading: true });
		const user = await this.usersService.getById(this.state.subscription?.resourceGroupOwnerId);
		this.setState({
			resourceGroupOwner: user,
			loading: false
		});
		this.handleStateFormChange('resourceGroupOwner', this.state.resourceGroupOwner);
	};

	setResourceOwnerAsServicePrincipal = async () => {
		this.setState({ loading: true });
		const servicePrincipal = await this.servicePrincipalsService.getById(this.state.subscription?.resourceGroupOwnerId);
		this.setState({
			resourceGroupOwner: servicePrincipal,
			loading: false
		});
		this.handleStateFormChange('resourceGroupOwner', this.state.resourceGroupOwner);
	};

	actions = () => (
		<TreeNavigation className="em-u-clickable">
			{this.state.subscription?.createdSubscriptionId != null &&
				['Enabled', 'Disabled'].includes(this.state.subscription?.azureState) &&
				<TreeNavigation.Item
					label="Open in Azure"
					onClick={() => {
						window.open(`https://portal.azure.com/#@${this.state.subscription?.tenant.name}.onmicrosoft.com/resource/subscriptions/${this.state.subscription?.createdSubscriptionId}/overview`, '_blank');
					}}
				/>}
			{(this.state.subscription?.isEditable()) && 
				<TreeNavigation.Item
					label="Edit Subscription"
					onClick={this.enableEdit}
				/>}
			{this.state.subscription?.cansSeePermissionsReport() &&
				<TreeNavigation.Item
					label="Permissions Report"
					onClick={() =>
						this.setState({
							permissionReportModalVisible: true
						})}
				/>}
			{this.state.subscription?.canSeePortOpeningRequests() &&
				<TreeNavigation.Dropdown
					label="Port Opening Requests"
					expanded={this.state.porItemsExpanded}
					onClick={() => {
						this.setState({ porItemsExpanded: !this.state.porItemsExpanded });
					}}
					className="submenu"
				>
					{this.state.subscription?.canSubmitPortOpeningRequests() &&
						<Link to={`/subscriptions/${this.state.subscription?.id}/portOpeningRequest/new`}>
							<TreeNavigation.Item label="Create a Request" />
						</Link>}
					<Link to={`/subscriptions/${this.state.subscription?.id}/PortOpeningRequestsHistory`}>
						<TreeNavigation.Item label="Request History" />
					</Link>
				</TreeNavigation.Dropdown>}
			{this.state.subscription?.canSeePortOpeningRequests() &&
				<TreeNavigation.Dropdown
					label="DPI (Deep Packet Inspection) Exception Requests"
					expanded={this.state.porItemsExpanded}
					onClick={() => {
						this.setState({ porItemsExpanded: !this.state.porItemsExpanded });
					}}
					className="submenu"
				>
					{this.state.subscription?.canSubmitPortOpeningRequests() &&
						<Link to={`/subscriptions/${this.state.subscription?.id}/doNotDecryptRequests/new`}>
							<TreeNavigation.Item label="Create a Request" />
						</Link>}
					<Link to={`/subscriptions/${this.state.subscription?.id}/doNotDecryptRequestsHistory`}>
						<TreeNavigation.Item label="Request History" />
					</Link>
				</TreeNavigation.Dropdown>}
			{this.state.subscription?.canSeePolicyExemptionRequests() &&
				<TreeNavigation.Dropdown
					label="Policy Exception Requests"
					expanded={this.state.perItemsExpanded}
					onClick={() => {
						this.setState({ perItemsExpanded: !this.state.perItemsExpanded });
					}}
					className="submenu"
				>
					<Link to={`/subscriptions/${this.state.subscription?.id}/policyExemptionRequests/new`}>
						<TreeNavigation.Item label="Create a Request" />
					</Link>
					<Link to={`/subscriptions/${this.state.subscription?.id}/policyExemptionRequests`}>
						<TreeNavigation.Item label="Request History" />
					</Link>
				</TreeNavigation.Dropdown>}
			{this.state.subscription?.canSeeSubscriptionAdminConsent() &&
				<Link to={`/subscriptions/${this.state.subscription?.id}/adminConsent`}>
					<TreeNavigation.Item
						label="Graph API Admin Consent"
					/>
				</Link>}
			{this.state.subscription?.canSeeSubscriptionDomainOperation() && (
				<TreeNavigation.Item
					label="Request Domain Operation"
					onClick={() => this.setState({ modalVisibleDomainOperation: true })}
				/>)}
			<ChangeHistoryItem
				id={this.state.subscription?.id}
				type={ObjectType.SubscriptionRequest}
				service={this.subscriptionsService}
				handleAlert={this.handleAlert}
				handleAlertClose={this.handleAlertClose}
			/>
		</TreeNavigation>
	);

	editActions = (size: ButtonSize) => (
		<ButtonBar className='edit-button-bar'>
			{!this.state.saving &&
				<>
					<ButtonBar.Item>
						<Button
							size={size}
							variant="secondary"
							onClick={this.handleCancel}
						>
							<XFilledIcon
								size={size}
							/>
							<span>Cancel</span>
						</Button>
					</ButtonBar.Item>
					<ButtonBar.Item separator />
				</>}
			<ButtonBar.Item>
				<ReactButton
					size={size}
					variant="primary"
					disabled={!this.formModel.isValid() || this.state.saving}
					isLoading={this.state.saving}
					loadingVariant="secondary"
					handleUpdateSubmit={this.handleSave}
					name="Save"
				>
					<SaveDiskIcon className='em-u-margin-right-half'
						size={size}
					/>
				</ReactButton>
			</ButtonBar.Item>
		</ButtonBar>
	);

	enableEdit = () => {
		this.setState({ editMode: true });
		this.handleOwnersAlert('Please make sure to also update this in Azure as these changes will not be reflected automatically. For more information, please go to this ', 'https://appwiki.xom.cloud/docs/admin/gettingstarted/LearningPathSub.html');
	};

	canEditAsOwner = () => (
		this.state.editMode && (
			authenticatedUser.isInRole(UserRole.SubscriptionAdmin) ||
			this.state.subscription?.owner.id === authenticatedUser.userId
		)
	);

	canEditAsCustodian = () => (
		this.state.editMode && (
			authenticatedUser.isInRole(UserRole.SubscriptionAdmin) ||
			this.state.subscription?.custodian.id === authenticatedUser.userId
		) && !this.state.subscription?.owner.accountEnabled
	);

	canEditAsOrgAdmin = () => (
		this.state.editMode && (
			authenticatedUser.isInRole(UserRole.SubscriptionAdmin) ||
			(authenticatedUser.isInRole(UserRole.OrgAdmins) && 
				authenticatedUser.myL3Orgs.includes(this.state.subscription?.organization?.L3?.displayName))
	));

	handleSave = async () => {
		window.scroll(0, 0);
		this.setState({
			saving: true,
			alert: {
				visible: true,
				text: 'Saving changes...'
			}
		});
		const model = this.formModel.create(UpdateSubscriptionModel);
		this.subscriptionsService.update(this.state.subscription.id, model)
			.then(response => {
				const subscription = new SubscriptionRequest(response);
				this.setState({
					subscription,
					editMode: false,
					alert: {
						visible: true,
						variant: 'success',
						text: 'Subscription updated successfully.'
					}
				},
				() => {
					setTimeout(this.handleAlertClose, 5000);
				});
			})
			.catch((err) => {
				this.handleAlert('error', err.response?.data.message || 'The subscription could not be updated. Please, try again later.');
			})
			.finally(() => {
				this.setState({
					saving: false
				});
			});
	};

	handleAlert = (variant, text) => {
		this.setState({
			alert: {
				visible: true,
				variant,
				text
			}
		});
	};

	handleOwnersAlert = (text, link) => {
		this.setState({
			ownersAlert: {
				visible: true,
				text,
				link
			}
		});
	};

	handleCancel = () => {
		this.setState({ editMode: false });
		this.resetStateForm();
	};

	handleAlertClose = () => {
		this.setState({
			alert: {
				visible: false
			}
		});
	};

	handleOwnersAlertClose = () => {
		this.setState({
			ownersAlert: {
				visible: false
			}
		});
	};

	render() {
		return (
			<>
				<WorkflowDetailWrapper
					title={this.state.subscription?.createdSubscriptionName}
					loading={this.state.loading}
					workflowInstance={this.state.subscription?.workflowInstance}
					linkTree={
						[
							{ to: '/subscriptions', text: 'Subscriptions' }
						]
					}
					actions={!this.state.editMode ? this.actions() : this.editActions('small')}
				>
					{this.state.alert.visible &&
						<Alert
							variant={this.state.alert.variant}
							onClose={this.handleAlertClose}
						>
							{this.state.alert.text}
						</Alert>}

					<Grid variant="3-up">
						<Grid.Item>
							<Field>
								<Field.Label>
									Subscription Status in Azure
								</Field.Label>
								<Field.Body>
									{this.state.subscription?.azureState ?
										<Badge>
											{this.state.subscription?.azureState}
										</Badge>
										:
										'Not Available'}
								</Field.Body>
							</Field>
						</Grid.Item>
						<Grid.Item>
							<ReadonlyField
								label="Platform"
								text={this.state.subscription?.platform.name}
							/>
						</Grid.Item>
						<Grid.Item>
							<ReadonlyField
								label="Tenant"
								text={this.state.subscription?.tenant.name}
							/>
						</Grid.Item>
					</Grid>
					<Grid>
						<Grid.Item>
							<ReadonlyField
								label="Subscription ID"
								text={this.state.subscription?.createdSubscriptionId}
								copy
							/>
						</Grid.Item>
						<Grid.Item>
							<EnvironmentInput
								{...this.stateFormHandler().environment}
								disabled={this.state.saving || !this.state.editMode || (!this.canEditAsOwner() && !this.canEditAsCustodian()) }
								hideNotes={!this.state.editMode}
							/>
						</Grid.Item>
					</Grid>
					<OrganizationInput
						disabled={this.state.saving || !this.state.editMode}
						organizationL3={this.stateFormHandler().selectedL3}
						organizationL4={this.stateFormHandler().selectedL4}
						globalOrganization={this.stateFormHandler().selectedOrganization}
					/>
					<SubscriptionTypeInput
						subscriptionType={this.stateFormHandler().isConnected}
						amountOfDevices={this.stateFormHandler().amountOfDevices}
						region={this.stateFormHandler().region}
						initialResourceGroup={this.stateFormHandler().initialResourceGroup}
						contributorAccessGroup={this.stateFormHandler().contributorAccessGroup}
						disabled={this.state.saving || !this.state.editMode}
						hideNotes={this.state.saving || !this.state.editMode}
						resourceGroupOwnerType={this.stateFormHandler().resourceGroupOwnerType}
						resourceGroupOwner={this.stateFormHandler().resourceGroupOwner}
						virtualNetwork={this.stateFormHandler().virtualNetwork}
						requesting={true}
					/>
					<ProjectInput
						isProject={this.stateFormHandler().isProject}
						wpmCode={this.stateFormHandler().wpmCode}
						closeOutDate={this.stateFormHandler().closeOutDate}
						disabled={this.state.saving || !this.state.editMode || (!this.canEditAsOwner() && !this.canEditAsCustodian())}
						hideNotes={this.state.saving || !this.state.editMode}
					/>
					<CartaInput
						{...this.stateFormHandler().cartaIds}
						disabled={this.state.saving || !this.state.editMode || (!this.canEditAsOwner() && !this.canEditAsCustodian())}
						hideNotes={this.state.saving || !this.state.editMode}
					/>
					{(this.state.editMode && this.state.ownersAlert.visible) && <Alert
						onClose={this.handleOwnersAlertClose}
					>
						{this.state.ownersAlert.text}
						<TextLink
							href={`${this.state.ownersAlert.link}`}
							external
							target="_blank"
						>
							link.
						</TextLink>
					</Alert>}
					<Grid variant="3-up">
						<Grid.Item>
							<TextInputField
								label="Budget"
								{...this.stateFormHandler().subsBudget}
								disabled={this.state.saving || !this.state.editMode || (!this.canEditAsOwner() && !this.canEditAsOrgAdmin())}
							/>
						</Grid.Item>
					</Grid>
					<Grid>
						<Grid.Item>
							<OwnerInput
								{...this.stateFormHandler().owner}
								disabled={this.state.saving || (!this.canEditAsOwner() && !this.canEditAsCustodian() && !this.canEditAsOrgAdmin()) }
								hideNotes={this.state.saving || !this.canEditAsOwner()}
							/>
						</Grid.Item>
						<Grid.Item>
							<CustodianInput
								{...this.stateFormHandler().custodian}
								disabled={this.state.saving || (!this.canEditAsOwner() && !this.canEditAsOrgAdmin()) }
								hideNotes={this.state.saving || !this.canEditAsOwner()}
							/>
						</Grid.Item>
						<Grid.Item>
							<CustodianTwoInput
								{...this.stateFormHandler().custodianTwo}
								disabled={this.state.saving || (!this.canEditAsOwner() && !this.canEditAsOrgAdmin()) }
								hideNotes={this.state.saving || !this.canEditAsOwner()}
							/>
						</Grid.Item>
					</Grid>
					<TextAreaField
						label="Intended Usage"
						disabled={this.state.saving || !this.canEditAsOwner()}
						value={this.state.subscription?.subscriptionUsedFor}
						{...this.stateFormHandler().subscriptionUsedFor}
					/>
					{(this.state.editMode && !this.state.saving) && this.editActions('medium')}
				</WorkflowDetailWrapper>
				<PermissionReportModal
					visible={this.state.permissionReportModalVisible}
					onClose={() => this.setState({ permissionReportModalVisible: false })}
					subscription={this.state.subscription}
				/>
				<SubDomainOperationModal
					subId={this.state.subscription?.id}
					isConnected={this.state.subscription?.isConnected}
					visible={this.state.modalVisibleDomainOperation}
					onConfirm={() => this.setState({ modalVisibleDomainOperation: false })}
					onClose={() => this.setState({ modalVisibleDomainOperation: false })}
				/>
			</>
		);
	}
}

function SubscriptionsDetail() {
	return <SubscriptionsDetailParams params={useParams()} />;
}

export default SubscriptionsDetail;