import * as React from 'react';
import { Button, Grid, Alert } from 'react-unity';
import RequestFormWrapper from '../common/wrappers/RequestFormWrapper';
import {
	OrganizationInput,
	EnvironmentInput,
	PlatformInput,
	CustodianInput,
	CustodianTwoInput,
	ProjectInput,
	OwnerInput,
	SubscriptionTypeInput,
} from './components';
import TextInputField from '../common/form-controls/TextInputField';
import CartaInput from '../common/form-controls/CartaInput';
import { RequestSubscriptionModel } from '../../models/viewModels/Subscriptions';
import TextAreaField from '../common/form-controls/TextareaField';
import { AbstractSubscriptionsFormNew, ISubscriptionNewStateForm, SubscriptionsFormNewProps } from './AbstractSubscriptionsFormNew';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import TenantService from '../../services/TenantService';
import Tenant from '../../models/entities/Tenant';
import Organization from '../../models/entities/Organization';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../../models/enums/UserRole';
import { getOrganizations } from '../common/OrganizationStorage';
import { useNavigate } from 'react-router-dom';

interface SubscriptionsRequestProps extends SubscriptionsFormNewProps { }

interface RequestStateForm extends ISubscriptionNewStateForm {
	tenant: string;
	guid: string;
}

interface SubscriptionsRequestState {
	stateForm: RequestStateForm,
	submitting: boolean;
	showSubNameAlert: boolean;
	submissionAlert: AlertBanner;
	organizations: Organization[];
	allTenants: Tenant[];
}

class SubscriptionsRequest
	extends AbstractSubscriptionsFormNew<SubscriptionsRequestProps, SubscriptionsRequestState> {
	tenantService: TenantService;

	constructor(props: SubscriptionsRequestState) {
		super(props);
		this.state = {
			stateForm: {
				selectedL3: '',
				selectedL4: '',
				selectedOrganization: '',
				tenant: '',
				guid: '',
				subscriptionName: '',
				environment: null,
				cartaIds: [],
				platform: 0,
				owner: null,
				custodian: null,
				custodianTwo: null,
				isProject: null,
				isConnected: false,
				subscriptionUsedFor: '',
				isImportedOrUpdated: false,
				subsBudget: null,
			},
			submitting: false,
			submissionAlert: {
				visible: false,
			},
			showSubNameAlert: true,
			organizations: [],
			allTenants: [],
		};
		this.tenantService = new TenantService();
		this.initFormModel();
	}


	async componentDidMount() {
		if (!(authenticatedUser.isInRole(UserRole.SubscriptionRequestor) ||
			authenticatedUser.isInRole(UserRole.DisconnectedSubscriptionRequestor) ||
			authenticatedUser.isInRole(UserRole.ConnSubUser))) {
				this.userNotAllowed();
		}

		const organizations = (await getOrganizations()).map(o => new Organization(o));
		this.setState({
			organizations: organizations,
			allTenants: await this.tenantService.getAll(),
		});
	}

	initFormModel() {
		super.initFormModel();
		
		this.formModel.fields.contributorAccessGroupId.validation.required = () => 
			(this.state.stateForm.isConnected && this.state.stateForm.tenant !== this.state.allTenants.filter(tenant => tenant.name === 'ExxonMobilTest')[0]?.id);
	}

	handleSave = async () => {
		window.scroll(0, 0);
		this.setState({
			submitting: true,
			submissionAlert: {
				visible: true,
				text: 'Submitting request...',
				variant: 'default'
			}
		});
		try {
			const model = this.formModel.create(RequestSubscriptionModel);
			this.stateFormHandler().isConnected.value ? await this.subscriptionsService.createConnected(model) : await this.subscriptionsService.create(model);
			this.props.navigate('/subscriptions');
		} catch (err) {
			this.setState({
				submissionAlert: {
					visible: true,
					variant: 'error',
					text: err.response?.data.message || 'An error occured with your request. Please, try again later.'
				}
			});
		} finally {
			this.setState({
				submitting: false
			});
		}
	};

	handleAlertClose = () => {
		this.setState({
			submissionAlert: {
				visible: false
			}
		});
	};

	getSubscriptionFinalName(): string {
		const form = this.state.stateForm;
		const selectedL3Org = this.state.organizations.find(org => org.id.toString() === form.selectedL3);
		const fieldsValues = [selectedL3Org?.shortName || form.selectedL3, form.subscriptionName, form.environment?.name];
		const filledFields = fieldsValues.filter(field => !!field);
		return filledFields.join('_');
	}
	
	showSubscriptionSuggestedName(): boolean {
		const form = this.state.stateForm;
		const requiredFields = [form.selectedL3, form.subscriptionName, form.environment];
		return requiredFields.every(field => !!field) && this.state.showSubNameAlert;
	}

	render() {
		return (
			<RequestFormWrapper
				title="Request Subscription"
				linkTree={
					[
						{ to: '/subscriptions', text: 'Subscriptions' }
					]
				}
			>
				{this.state.submissionAlert.visible &&
					<Alert
						variant={this.state.submissionAlert.variant}
						onClose={this.state.submitting ? undefined : this.handleAlertClose}
					>
						{this.state.submissionAlert.text}
					</Alert>}
				<OrganizationInput
					organizationL3={this.stateFormHandler().selectedL3}
					organizationL4={this.stateFormHandler().selectedL4}
					globalOrganization={this.stateFormHandler().selectedOrganization}
					disabled={this.state.submitting}
				/>
				<Grid>
					<Grid.Item>
						<PlatformInput value={this.state.stateForm.platform} />
					</Grid.Item>
					<Grid.Item>
						<EnvironmentInput
							{...this.stateFormHandler().environment}
							disabled={this.state.submitting}
						/>
					</Grid.Item>
				</Grid>
				<TextInputField
					label="Subscription Name"
					{...this.stateFormHandler().subscriptionName}
					disabled={this.state.submitting}
				/>
				{this.showSubscriptionSuggestedName() &&
					<Alert
						onClose={() => this.setState({ showSubNameAlert: false })}
					>
						The subscription name will look like: {this.getSubscriptionFinalName()}
					</Alert>}
				<SubscriptionTypeInput
					subscriptionType={this.stateFormHandler().isConnected}
					amountOfDevices={this.stateFormHandler().amountOfDevices}
					region={this.stateFormHandler().region}
					initialResourceGroup={this.stateFormHandler().initialResourceGroup}
					resourceGroupOwnerType={this.stateFormHandler().resourceGroupOwnerType}
					resourceGroupOwner={this.stateFormHandler().resourceGroupOwner}
					contributorAccessGroup={this.stateFormHandler().contributorAccessGroup}
					virtualNetwork={this.stateFormHandler().virtualNetwork}
					disabled={this.state.submitting}
					requesting={false}
				/>
				<ProjectInput
					isProject={this.stateFormHandler().isProject}
					wpmCode={this.stateFormHandler().wpmCode}
					closeOutDate={this.stateFormHandler().closeOutDate}
					disabled={this.state.submitting}
				/>
				<CartaInput
					{...this.stateFormHandler().cartaIds}
					disabled={this.state.submitting}
				/>
				<Grid variant="3-up">
					<Grid.Item>
				<TextInputField
					label="Budget"
					{...this.stateFormHandler().subsBudget}
					disabled={this.state.submitting}
				/>
				</Grid.Item>
				</Grid>
				<Grid variant="halves">
					<Grid.Item>
						<OwnerInput
							{...this.stateFormHandler().owner}
							disabled={this.state.submitting}
						/>
					</Grid.Item>
					<Grid.Item>
						<CustodianInput
							{...this.stateFormHandler().custodian}
							disabled={this.state.submitting}
						/>
					</Grid.Item>
					<Grid.Item>
						<CustodianTwoInput
							{...this.stateFormHandler().custodianTwo}
							disabled={this.state.submitting}
						/>
					</Grid.Item>
				</Grid>
				<TextAreaField
					label="Subscription Usage"
					{...this.stateFormHandler().subscriptionUsedFor}
					disabled={this.state.submitting}
				/>
				<Grid variant="3-up">
					<Grid.Item>
						<Button
							variant="secondary"
							disabled={this.state.submitting}
							className="z-index-0"
							onClick={() => this.props.navigate('/subscriptions')}
						>
							Cancel
						</Button>
					</Grid.Item>
					<Grid.Item />
					<Grid.Item>
						<Button
							variant="primary"
							disabled={!this.formModel.isValid()}
							loading={this.state.submitting}
							onClick={this.handleSave}
							className="z-index-0"
						>
							Request Subscription
						</Button>
					</Grid.Item>
				</Grid>
			</RequestFormWrapper>
		);
	}
}

function addHookTo(AbstractSubscriptionsFormNew) {
	function CompWithHook(props) {
		const navigate = useNavigate();

		return <AbstractSubscriptionsFormNew {...props} navigate={navigate} />;
	}

	return CompWithHook;
}

export default addHookTo(SubscriptionsRequest);