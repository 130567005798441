import * as React from 'react';
import { Grid } from 'react-unity';
import moment from 'moment';
import TextInputField from '../../common/form-controls/TextInputField';
import ToggleField from '../../common/form-controls/ToggleField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import DatePickerField from '../../common/form-controls/DatePickerField';

interface ProjectInputProps {
	isProject: FieldHandler<boolean | null>;
	wpmCode: FieldHandler<string | null>;
	closeOutDate: FieldHandler<string | null>;
	disabled?: boolean;
	hideNotes?: boolean;
}

interface ProjectInputState {
	closeOutDateInput: string;
}

export class ProjectInput extends React.Component<ProjectInputProps, ProjectInputState> {
	
	render() {
		return (
			<Grid variant="3-up">
				<Grid.Item>
					<ToggleField
						label="Usage"
						{...this.props.isProject}
						value={this.props.isProject.value?.toString()}
						options={!this.props.disabled ?
						    [
								{value: 'false', text: 'Base'},
								{value: 'true', text: 'Project / SWI PPL'}
							]:
							this.props.isProject.value? 
								[ {value: 'true', text: 'Project / SWI PPL'} ]
								:
								[ {value: 'false', text: 'Base'} ]
						}
						onChange={this.props.isProject.onChange}
						disabled={this.props.disabled}
					/>
				</Grid.Item>
				{this.props.isProject.value &&
					<>
						<Grid.Item>
							<TextInputField
								label="Project/SWI Work ID"
								note={!this.props.hideNotes && 'Project/SWI Work ID for cost recovery. Only numbers allowed.'}
								{...this.props.wpmCode}
								disabled={this.props.disabled}
							/>
						</Grid.Item>
						<Grid.Item>
							<DatePickerField
								label="Closed Out Month & Year"
								value={this.props.closeOutDate.value ? moment(this.props.closeOutDate.value).format('MMMM yyyy') : this.props.closeOutDate.value}
								format="MMMM yyyy"
								validation={this.props.closeOutDate.validation}
								onChange={(event) => {
									this.props.closeOutDate.onChange(event);
								}}
								note={
									!this.props.hideNotes &&
									"This is the month the project will close out and costs associated with this subscription will be billed back to the respective L3's Base Budget"
								}
								disabled={this.props.disabled}
							/>
						</Grid.Item>
					</>}

			</Grid>
		);
	}
}