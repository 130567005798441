import * as React from 'react';
import moment from 'moment';
import { Modal, Button, TextPassage, LinkExternalIcon, Table, Toolbar, TextInput, Field, ProgressBar, Tabs, XFilledIcon, AlertVariant, Alert } from 'react-unity';
import './AppPermissionsModal.css';
import EnterpriseApp from '../../../models/entities/EnterpriseApp';
import ApplicationPermission from '../../../models/entities/ApplicationPermission';
import PermissionToApplication from '../../../models/entities/PermissionToApplication';
import CSVGeneratorButton from '../../common/CSVGeneratorButton';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';

interface AppPermissionsModalProps {
	applicationsService: any;
	application: EnterpriseApp;
	selectedPermissionsTab: 'App Permissions' | 'Permissions to App';
	visible: boolean;
	onClose: (event: React.ChangeEvent<any>) => void;
	togglePermissionsTab: (event: React.ChangeEvent<any>) => void;
}
interface AppPermissionsModalState {
	applicationPermissions: ApplicationPermission[];
	visibleApplicationPermissions: ApplicationPermission[];
	appPermissionsSearchText: string;
	permissionsToApplication: PermissionToApplication[];
	visiblePermissionsToApplication: PermissionToApplication[];
	permissionsToAppSearchText: string;
	submissionAlert: AlertBanner;
	isLoading: boolean;
}

export default class AppPermissionsModal extends React.Component<AppPermissionsModalProps, AppPermissionsModalState> {
	applicationsService: any;

	constructor(props: AppPermissionsModalProps) {
		super(props);
		this.state = {
			appPermissionsSearchText: '',
			permissionsToAppSearchText: '',
			applicationPermissions: [],
			visibleApplicationPermissions: [],
			permissionsToApplication: [],
			visiblePermissionsToApplication: [],
			submissionAlert: {
				visible: false,
			},
			isLoading: false
		};

		this.applicationsService = this.props.applicationsService;
	}

	componentDidUpdate = async (prevProps: AppPermissionsModalProps) => {
		const { applicationId } = this.props.application;
		if (applicationId !== prevProps.application.applicationId) {
			this.setState(
				{
					applicationPermissions: [],
					visibleApplicationPermissions: [],
					permissionsToApplication: [],
					visiblePermissionsToApplication: [],
					isLoading: true
				},
				async () => {
					let applicationPermissions = [];
					let permissionsToApplication = [];
					try {
						applicationPermissions = await this.applicationsService.getApplicationPermissions(applicationId);
						permissionsToApplication = await this.applicationsService.getPermissionsToApplication(applicationId);
					} catch (err) {
						this.setSubmissionAlert('An error occured when processing your request. Please, try again later.', 'error');
					}

					this.setState({ 
						applicationPermissions,
						visibleApplicationPermissions: applicationPermissions,
						permissionsToApplication,
						visiblePermissionsToApplication: permissionsToApplication,
						isLoading: false 
					});
				}
			);
		}
	};

	setSubmissionAlert = (message: string, variant: AlertVariant) => {
		this.setState({
			submissionAlert: {
				visible: true,
				text: message,
				variant
			}
		});
	};

	handleAlertClose = () => {
		this.setState({
			submissionAlert: {
				visible: false,
				text: ''
			}
		});
	};

	handleModalClosed = (e) => {
		this.setState({
			appPermissionsSearchText: '',
			permissionsToAppSearchText: '',
			visibleApplicationPermissions: this.state.applicationPermissions,
			visiblePermissionsToApplication: this.state.permissionsToApplication,
		});

		this.handleAlertClose();

		this.props.onClose(e);
	};
	
	groupBy = (key: string, array: any[]) =>
  			array.reduce((objectsByKeyValue, obj) => {
    			const value = obj[key];
    			objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
     			return objectsByKeyValue;
		  }, {});

	filterArray = (arr: any[], searchKey: string) => {
		return arr.filter((obj) => {
			return Object.keys(obj).some((key) => {
				return obj[key].toString().toLowerCase().includes(searchKey.toLowerCase());
			});
		});
	};

	handleAppPermissionsSearchTextChange = (e) => {
		const searchText = e.target.value;
		const visibleApplicationPermissions = this.filterArray(this.state.applicationPermissions, searchText);

		this.setState({
			visibleApplicationPermissions,
			appPermissionsSearchText: searchText
		});
	};

	handlePermissionsToAppSearchTextChange = (e) => {
		const searchText = e.target.value;
		const visiblePermissionsToApplication = this.filterArray(this.state.permissionsToApplication, searchText);

		this.setState({
			visiblePermissionsToApplication,
			permissionsToAppSearchText: searchText
		});
	};

	render() {
		const appPermissionsTableRows = [];		
		const permissionsToAppTableRows = [];

		// App permissions
		let groupedPermissions = this.groupBy('resourceName', this.state.visibleApplicationPermissions);
		let keys = Object.keys(groupedPermissions);

		if (keys.length === 0) {
			appPermissionsTableRows.push(
				<Table.Body.Row key={`App_Permission_${0}`}>
					<Table.Body.Cell colSpan={5}><b>No permissions found.</b></Table.Body.Cell>
				</Table.Body.Row>
			);
		} else {
			keys.forEach((key) => {
				appPermissionsTableRows.push(
					<Table.Body.Row key={`App_Permission_${key}`} style={{ background: '#f5f5f5' }}>
						<Table.Body.Cell colSpan={5}><b>{`${key} (${groupedPermissions[key].length})`}</b></Table.Body.Cell>
					</Table.Body.Row>
				);
	
				groupedPermissions[key].forEach((permission: ApplicationPermission) => {
					appPermissionsTableRows.push(
						<Table.Body.Row key={`App_Permission_${`${key}_${permission.id}`}`}>
							<Table.Body.Cell>{permission.name}</Table.Body.Cell>
							<Table.Body.Cell>
								{permission.type === 'Scope' ? 'Delegated' : 'Application'}
							</Table.Body.Cell>
							<Table.Body.Cell>{permission.requiresAdmin ? 'Yes' : 'No'}</Table.Body.Cell>
							<Table.Body.Cell>{permission.isWhitelisted ? 'Yes' : 'No'}</Table.Body.Cell>
							<Table.Body.Cell>{permission.isGranted ? 'Yes' : 'No'}</Table.Body.Cell>
						</Table.Body.Row>
					);
				});
			});
		}

		// Permissions to app
		groupedPermissions = this.groupBy('childDisplayName', this.state.visiblePermissionsToApplication);
		keys = Object.keys(groupedPermissions);

		if (keys.length === 0) {
			permissionsToAppTableRows.push(
				<Table.Body.Row key={`Permission_to_App_${0}`}>
					<Table.Body.Cell colSpan={5}>
						<b>No permissions found.</b>
					</Table.Body.Cell>
				</Table.Body.Row>
			);
		} else {
			keys.forEach((key) => {
				permissionsToAppTableRows.push(
					<Table.Body.Row key={`Permission_to_App_${key}`} style={{ background: '#f5f5f5' }}>
						<Table.Body.Cell colSpan={2}>
							<b>{`${key} (${groupedPermissions[key].length})`}</b>
						</Table.Body.Cell>
					</Table.Body.Row>
				);

				groupedPermissions[key].forEach((permissionToApp: PermissionToApplication, index) => {
					permissionsToAppTableRows.push(
						<Table.Body.Row key={`Permission_to_App_${`${key}_${permissionToApp.permission}`}`}>
							<Table.Body.Cell>{permissionToApp.permission}</Table.Body.Cell>
							<Table.Body.Cell>{permissionToApp.permissionType}</Table.Body.Cell>
						</Table.Body.Row>
					);
				});
			});
		}

		const goToAppDS = (
			<Button
				variant="secondary"
				size="small"
				onClick={() =>
					window.open('https://hoesql791.na.xom.com/Reports/powerbi/Cloud%20Identity/AppDS/Owners?rs:embed=true%20or%C2%A0%3Frs:Command%3DRender&rc:Toolbar=false')}
			>
				<LinkExternalIcon size="small" />&nbsp;
				<span>Open AppDS</span>&nbsp;
			</Button>
		);


		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Modal.Window id="AppPermissionModal" className="em-u-margin-double">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							Applications that {this.props.application.displayName} can access (
							{this.props.selectedPermissionsTab === 'App Permissions' ? this.state.applicationPermissions.length : this.state.permissionsToApplication.length})
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.handleModalClosed}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<TextPassage>
							{this.props.selectedPermissionsTab === 'App Permissions' ? (
								<div>
									Below is a list of applications to which <b>{this.props.application.displayName}</b>{' '}
									has permissions. The permission, type, and administrator consent information are
									grouped by application.
								</div>
							) : (
								<div>
									Below is a list of applications that have permission(s) to{' '}
									<b>{this.props.application.displayName}</b>. The permission and permission type are
									grouped by application.
								</div>
							)}
							<br />
						</TextPassage>
						{this.state.submissionAlert.visible && (
							<Alert
								variant={this.state.submissionAlert.variant}
								onClick={this.handleAlertClose}
								onClose={this.handleAlertClose}
							>
								{this.state.submissionAlert.text}
							</Alert>
						)}
						{this.state.isLoading ? (
							<ProgressBar indeterminate hideValueLabel />
						) : (
							<Tabs
								selected={this.props.selectedPermissionsTab}
								onChange={this.props.togglePermissionsTab}
							>
								<Tabs.Item value="App Permissions" title="App Permissions">
									<Table
										className="em-u-margin-none"
										header={
											<Toolbar>
												<Toolbar.Item>
													<TextInput
														id="0"
														inline
														size="small"
														placeholder="Search permissions"
														value={this.state.appPermissionsSearchText}
														onChange={this.handleAppPermissionsSearchTextChange}
													/>
												</Toolbar.Item>
												<Toolbar.Item right>
													<Field inline size="small">
														<Button.Group className="em-u-padding-none">
															<CSVGeneratorButton
																data={this.state.visibleApplicationPermissions}
																fileName={`AppPermissions_${moment().format('YYYY-MM-DD hhmmss')}.csv`}
																columns={
																	[
																		{ header: 'ID', cell: (app) => app.id },
																		{ header: 'Type', cell: (app) => app.type },
																		{ header: 'Permission', cell: (app) => app.name },
																		{ header: 'Resource ID', cell: (app) => app.resourceId },
																		{ header: 'Resource Name', cell: (app) => app.resourceName },
																		{ header: 'Requires Admin?', cell: (app) => app.requiresAdmin.toString() },
																		{ header: 'Is Whitelisted?', cell: (app) => app.isWhitelisted.toString() },
																		{ header: 'Is Granted?', cell: (app) => app.isGranted.toString() },
																	]
																}
															/>
															{goToAppDS}
														</Button.Group>
													</Field>
												</Toolbar.Item>
											</Toolbar>
										}
									>
										<Table.Head>
											<Table.Head.Row>
												<Table.Head.Cell>App/Permission</Table.Head.Cell>
												<Table.Head.Cell>Type</Table.Head.Cell>
												<Table.Head.Cell>Requires Admin</Table.Head.Cell>
												<Table.Head.Cell>Consent Allowed</Table.Head.Cell>
												<Table.Head.Cell>Is Granted</Table.Head.Cell>
											</Table.Head.Row>
										</Table.Head>
										<Table.Body>{appPermissionsTableRows}</Table.Body>
									</Table>
								</Tabs.Item>
								<Tabs.Item value="Permissions to App" title="Permissions to App">
									<Table
										className="em-u-margin-bottom-none"
										header={
											<Toolbar>
												<Toolbar.Item>
													<TextInput
														id="1"
														inline
														size="small"
														placeholder="Search permissions"
														value={this.state.permissionsToAppSearchText}
														onChange={this.handlePermissionsToAppSearchTextChange}
													/>
												</Toolbar.Item>
												<Toolbar.Item right>
													<Field inline size="small">
														<Button.Group className="em-u-padding-none">
															<CSVGeneratorButton
																data={this.state.visiblePermissionsToApplication}
																fileName={`PermissionsToApp_${moment().format('YYYY-MM-DD hhmmss')}.csv`}
																columns={
																	[
																		{ header: 'Parent Application ID', cell: (permission) => permission.parentAppId },
																		{ header: 'Parent Application Display Name', cell: (permission) => permission.parentDisplayName },
																		{ header: 'Parent Object ID', cell: (permission) => permission.parentObjectId },
																		{ header: 'Permission Type', cell: (permission) => permission.permissionType },
																		{ header: 'Permission', cell: (permission) => permission.permission },
																		{ header: 'Child Application ID', cell: (permission) => permission.childAppId },
																		{ header: 'Child Application Display Name', cell: (permission) => permission.childDisplayName },
																		{ header: 'Child Object ID', cell: (permission) => permission.childObjectId },
																	]
																}
															/>
															{goToAppDS}
														</Button.Group>
													</Field>
												</Toolbar.Item>
											</Toolbar>
										}
									>
										<Table.Head>
											<Table.Head.Row>
												<Table.Head.Cell>App/Permission</Table.Head.Cell>
												<Table.Head.Cell>Type</Table.Head.Cell>
											</Table.Head.Row>
										</Table.Head>
										<Table.Body>{permissionsToAppTableRows}</Table.Body>
									</Table>
								</Tabs.Item>
							</Tabs>
						)}
					</Modal.Window.Body>
				</Modal.Window>
			</Modal>
		);
	}
}