import * as React from 'react';
import { Modal, XFilledIcon, Table, Toolbar, Button } from 'react-unity';
import ChangeHistory from '../../../models/entities/ChangeHistory';
import CSVGeneratorButton from '../CSVGeneratorButton';
import Paginator from '../tables/Paginator';
import './ChangeHistoryModal.css';
import { ChangeHistoryColumnsLayout } from './ChangeHistoryColumnsLayout';

interface ChangeHistoryModalProps {
	visible: boolean;
	onClose: (event: React.ChangeEvent<any>) => void;
	changeHistory: ChangeHistory[];
}

interface ChangeHistoryModalState {
    changesOnPage: ChangeHistory[];
}

export default class ChangeHistoryModal extends React.Component<ChangeHistoryModalProps, ChangeHistoryModalState> {

	columns = ChangeHistoryColumnsLayout;

	constructor(props: ChangeHistoryModalProps) {
		super(props);
		this.state = {
			changesOnPage: [],
		};
	}	

	render() {
		const tableRows = [];
		this.state.changesOnPage.forEach((change) => {
			tableRows.push(
				<Table.Body.Row key={`${change.field  }-${  change.timestamp}`}>
					{this.columns.map(e => (<Table.Body.Cell key={e.header}>{e.cell(change)}</Table.Body.Cell>))}
				</Table.Body.Row>
			);
		});

		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Modal.Window id="ChangeHistoryModal" className="em-u-margin-double">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>Changes History</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<Toolbar id="csv-toolbar">
							<Toolbar.Item right>
								<CSVGeneratorButton
									data={this.props.changeHistory}
									columns={this.columns}
								/>
							</Toolbar.Item>
						</Toolbar>
						<Table
							footer={
								<Paginator
									data={this.props.changeHistory}
									totalItems={this.props.changeHistory.length}
									onPageChange={(changes) => this.setState({ changesOnPage: changes })}
								/>
							}
						>
							<Table.Head>
								<Table.Head.Row>
									{this.columns.map(e => 
										(<Table.Head.Cell key={e.header}>{e.header}</Table.Head.Cell>))}
								</Table.Head.Row>
							</Table.Head>
							<Table.Body>{tableRows}</Table.Body>
						</Table>
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Button variant="secondary" onClick={this.props.onClose}>
							Close
						</Button>
					</Modal.Window.Footer>					
				</Modal.Window>
			</Modal>
		);
	}
}
