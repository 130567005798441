import React from 'react';
import { Grid, Tag } from 'react-unity';
import ReactButton from '../../common/ReactButton';
import TAP from '../../../models/entities/TAP';
import moment from 'moment';
import { authenticatedUser } from '../../../authentication/authModule';
import { UserRole } from '../../../models/enums/UserRole';

interface TAPApprovalsProps {
    tap: TAP;
    handleCompleteTAP: any;
    loading: boolean;
}

const TAPApprovals = ({ tap, handleCompleteTAP, loading }: TAPApprovalsProps) => {

    return (
        <Grid style={{ justifyContent: 'space-around' }} className='wrapper'>
            {
                {
                    'Requested':
                    <>
                            <ReactButton
                                size='small'
                                color='positive'
                                name="Approve"
                                isLoading={loading}
                                variant='primary'
                                disabled={(authenticatedUser.isInRole(UserRole.SuperAdmin) && tap.approver.id !== authenticatedUser.userId)}
                                loadingVariant='secondary'
                                handleUpdateSubmit={() => handleCompleteTAP(tap.id, true)}
                            />
                            <ReactButton
                                size='small'
                                name="Reject"
                                color='negative'
                                variant='primary'
                                loadingVariant='secondary'
                                isLoading={loading}
                                disabled={(authenticatedUser.isInRole(UserRole.SuperAdmin) && tap.approver.id !== authenticatedUser.userId)}
                                handleUpdateSubmit={() => handleCompleteTAP(tap.id, false)}
                            />
                    </>,
                    'Approved':
                        <Tag
                            className='small-margin'
                            color='positive'
                        >
                            Completed
                        </Tag>,
                    'Cancelled':
                        <Tag
                            className='small-margin'
                            color='negative'
                        >
                            Cancelled
                        </Tag>,
                }[tap.approvalStatus]
            }
        </Grid>
    );
}

export default TAPApprovals;