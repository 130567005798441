import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';

export async function getVnets(id: string, tenant: string) {
    
    let vNets = JSON.parse(sessionStorage.getItem(id));
    if (vNets == null) {        
        vNets = await new SubscriptionRequestsService().getAddrSpaces(id, tenant);
        sessionStorage.setItem(id, JSON.stringify(vNets));
    }
    return vNets;
}
