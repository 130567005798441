import React, { useState, useEffect, useContext } from 'react';
import { ThemeProvider, ThemeContext, unityTheme, unityDarkTheme, Page } from 'react-unity';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home/Home';
import SubscriptionsHome from './components/Subscriptions/SubscriptionsHome/SubscriptionsHome';
import AdAppsHome from './components/AdApps/Apps/AdAppsHome';
import AdThirdPartyAppsHome from './components/AdApps/ThirdPartyApps/AdThirdPartyAppsHome';
import AdThirdPartyAppsImport from './components/AdApps/ThirdPartyApps/AdThirdPartyAppsImport';
import AdAppsDetail from './components/AdApps/Apps/AdAppsDetail';
import AdAppsImport from './components/AdApps/Apps/AdAppsImport';
import DecideTransferAppOwnership from './components/AdApps/components/DecideTransferAppOwnership';
import CloudAccountsHome from './components/CloudAccounts/CloudAccountsHome';
import ConfirmPasswordReset from './components/CloudAccounts/ConfirmPasswordReset';
import CloudGroupsHome from './components/CloudGroups/CloudGroupsHome';
import PortOpeningRequestsHome from './components/PortOpeningRequests/PortOpeningRequestsHome';
import DoNotDecryptRequestsHome from './components/DoNotDecryptRequests/DoNotDecryptRequestsHome';
import PortOpeningRequestsCreate from './components/PortOpeningRequests/PortOpeningRequestsCreate';
import DoNotDecryptRequestsCreate from './components/DoNotDecryptRequests/DoNotDecryptRequestsCreate';
import PortOpeningRequestsHistory from './components/PortOpeningRequests/PortOpeningRequestsHistory';
import DoNotDecryptRequestsHistory from './components/DoNotDecryptRequests/DoNotDecryptRequestsHistory';
import { PortOpeningRequestsDirection } from './models/enums/POR/PortOpeningRequestsDirection';
import DoNotDecryptRequestsDetail from './components/DoNotDecryptRequests/DoNotDecryptRequestsDetail';
import { DoNotDecryptRequestsDirection } from './models/enums/DNDR/DoNotDecryptRequestsDirection';
import PortOpeningRequestsDetail from './components/PortOpeningRequests/PortOpeningRequestsDetail';
import FeedbackHome from './components/Feedback/FeedbackHome';
import SubscriptionsDetail from './components/Subscriptions/SubscriptionsDetail';
import SubscriptionsRequest from './components/Subscriptions/SubscriptionsRequest';
import SubscriptionRulesTable from './components/Subscriptions/SubscriptionRulesTable';
import SelfAttestation from './components/Subscriptions/SelfAttestation/SelfAttestationHome';
import SwaggerHome from './components/Swagger/SwaggerHome';
import PolicyExemptionRequestsHome from './components/PolicyExemption/PolicyExemptionRequestsHome';
import PolicyExemptionRequestsCreate from './components/PolicyExemption/PolicyExemptionRequestsCreate';
import PolicyExemptionRequestsDetail from './components/PolicyExemption/PolicyExemptionRequestDetail';
import SubAdminConsent from './components/Subscriptions/AdminConsent/SubAdminConsent';
import AdThirdPartyAppsDetail from './components/AdApps/ThirdPartyApps/AdThirdPartyAppsDetail';
import OrganizationsHome from './components/Organizations/OrganizationsHome/OrganizationsHome';

import TagManager from 'react-gtm-module';
import SubscriptionsImport from './components/Subscriptions/SubscriptionsImport';
import NetworkSecurityGroup from './components/NetworkSecurityGroup/NetworkSecurityGroupHome';
import TemporaryAccessPassword from './components/TemporaryAccessPass/TemporaryAccessPassword';
import ApprovalsHome from './components/Approvals/ApprovalsHome';

const tagManagerArgs = {
	gtmId: 'G-1GMQYF6KHL'
}

const App = () => {
	const { setTheme } = useContext(ThemeContext);
	if (process.env.REACT_APP_NODE_GA_ENV === 'production') {
		TagManager.initialize(tagManagerArgs);
	}
	const [themeName, setThemeName] = useState(() => {
		const themeName = localStorage.getItem('theme');
		if (!themeName) return 'default';
		return themeName;
	});

	useEffect(() => {
		setTheme(themeName === 'default' ? unityTheme : unityDarkTheme);
	}, [setTheme, themeName]);

	const handleThemeChange = () => {
		setThemeName((previousThemeName) => {
			const newThemeName = previousThemeName === 'default' ? 'dark' : 'default';
			localStorage.setItem('theme', newThemeName);
			return newThemeName;
		});
	};

	const RedirectRoute = (from: string, to: string) => (
		<Route path={from}  element={<Navigate to={to} />} />
	);

	return (
		<ThemeProvider theme={themeName === 'default' ? unityTheme : unityDarkTheme}>
			<BrowserRouter>
				<Page>
					<Page.Header>
						<Header currentTheme={themeName} onThemeChange={handleThemeChange} />
					</Page.Header>
					<Page.Body>
						<main role="main" className="main-content-container min-height">
							<Routes>
								<Route path="/" element={<Home />}  />

								<Route
									path="/swagger"
									
									element={<SwaggerHome />}
								/>
								{RedirectRoute('/swagger/index.html', '/swagger')}

								<Route path="/adApps" element={<AdAppsHome />}  />
								{RedirectRoute('/appRegistrationHome', '/adApps')}

								<Route path="/adApps/:id" element={<AdAppsDetail />}  />
								
								<Route path="/cloudAccounts" element={<CloudAccountsHome />}  />
								{RedirectRoute('/cloudaccount', '/cloudAccounts')}
								
								<Route path="/account/reset/:token" element={<ConfirmPasswordReset />}  />
								<Route path="/cloudGroups" element={<CloudGroupsHome />}  />

								<Route path="/adApps/decide/:id" element={<DecideTransferAppOwnership />}  />

								<Route path="/adAppsImport" element={<AdAppsImport />}  />

								<Route path="/ad3rdPartyApps" element={<AdThirdPartyAppsHome />}  />

								<Route path="/ad3rdPartyApps/:id" element={<AdThirdPartyAppsDetail />}  />

								<Route path="/ad3rdPartyAppsImport" element={<AdThirdPartyAppsImport />}  />

								<Route path="/tap" element={<TemporaryAccessPassword />}  />

								<Route
									path="/portOpeningRequests/connectedAzure"
									
									element={
										<PortOpeningRequestsHome
											subtitle="Connected Azure"
											scopes={[
												PortOpeningRequestsDirection.SubscriptionToOnPrem,
												PortOpeningRequestsDirection.SubscriptionToInternet,
											]}
										/>}
								/>

								<Route
									path="/doNotDecryptRequests"
									
									element={
										<DoNotDecryptRequestsHome
											subtitle=""
											scopes={[
												DoNotDecryptRequestsDirection.SubscriptionToOnPrem,
												DoNotDecryptRequestsDirection.SubscriptionToInternet,
											]}
										/>}
								/>
								<Route
									path="/subscriptions/:id/doNotDecryptRequests/new"
									element={<DoNotDecryptRequestsCreate />}
								/>
								<Route
									path="/subscriptions/:id/doNotDecryptRequests/connectedAzure"
									
									element={
										<DoNotDecryptRequestsHome
											scopes={[
												DoNotDecryptRequestsDirection.SubscriptionToOnPrem,
												DoNotDecryptRequestsDirection.SubscriptionToInternet,
											]}
										/>
									}
								/>
								<Route
									path="/subscriptions/:id/doNotDecryptRequestsHistory"
									element={<DoNotDecryptRequestsHistory />}
									
								/>


								<Route path="/doNotDecryptRequests/:id" element={<DoNotDecryptRequestsDetail />}  />
								<Route path="/subscriptions/:subsId/portOpeningRequests/:porId" element={<PortOpeningRequestsDetail />}  />

								<Route path="/subscriptions" element={<SubscriptionsHome />}  />
								<Route path="/subscriptions/selfattestation" element={<SelfAttestation />} />	
								<Route path="/subscriptions/new" element={<SubscriptionsRequest />} />
								<Route path="/subscriptions/import" element={<SubscriptionsImport />} />
								<Route path="/subscriptions/:id" element={<SubscriptionsDetail />}  />
								<Route path="/subscriptions/:id/adminConsent" element={<SubAdminConsent />}  />
								<Route
									path="/subscriptions/:subsId/portOpeningRequests"
									
									element={<PortOpeningRequestsHome
											scopes={[
												PortOpeningRequestsDirection.SubscriptionToOnPrem,
												PortOpeningRequestsDirection.SubscriptionToInternet,
											]}
										/>}
								/>
								<Route
									path="/subscriptions/:subsId/portOpeningRequest/new"
									element={<PortOpeningRequestsCreate />}
								/>
								<Route path="/subscriptions/:id/rules" element={<SubscriptionRulesTable />} />
								<Route
									path="/subscriptions/:id/policyExemptionRequests"
									element={<PolicyExemptionRequestsHome />}
									
								/>
								<Route
									path="/subscriptions/:id/portOpeningRequestsHistory"
									element={<PortOpeningRequestsHistory />}
									
								/>
								<Route
									path="/subscriptions/:id/policyExemptionRequests/new"
									element={<PolicyExemptionRequestsCreate />}
									
								/>
								<Route
									path="/subscriptions/:id/policyExemptionRequests/:perid"
									element={<PolicyExemptionRequestsDetail />}
								/>

								<Route path="/feedback" element={<FeedbackHome />}  />

								<Route path="/nsg/:id?" element={<NetworkSecurityGroup />} />

								<Route path="/organizations" element={<OrganizationsHome />}  />
								<Route path="/approvals" element={<ApprovalsHome />}  />
							</Routes>
						</main>
					</Page.Body>
					<Page.Footer>
						<Footer />
					</Page.Footer>
				</Page>
			</BrowserRouter>
		</ThemeProvider>
	);
};

export default App;
