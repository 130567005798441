import DoNotDecryptRule from './DoNotDecryptRule';
import WorkflowInstance from '../WorkflowInstance';
import User from '../User';
import { WorkflowState } from '../enums/WorkflowState';
import { Environment } from '../enums/Environment';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../enums/UserRole';
import { DoNotDecryptRequestsDirection } from '../enums/DNDR/DoNotDecryptRequestsDirection';
import SubscriptionRequest from './SubscriptionRequest';

export default class DoNotDecryptRequest {

	id!: number;

	doNotDecryptRules!: DoNotDecryptRule[];

	workflowInstance!: WorkflowInstance;

	direction!: DoNotDecryptRequestsDirection;

	environment!: Environment;

	contactUser!: User;

	isEmergency!: boolean;

	incidentNumber!: string;

	submitDate!: Date;

	businessJustification!: string;

	comments!: string;

	subscriptionRequestId!: number;

	subscriptionRequestName: string;

	customerCoordination!: boolean;

	custodianApproval!: boolean;

	sevenDayExemption!: boolean;

	constructor(obj: any) {
		Object.assign(this, obj);
		this.workflowInstance = new WorkflowInstance(obj.workflowInstance);
		this.direction = new DoNotDecryptRequestsDirection().fromName('SubscriptionToInternet');	//always sets dndr direction to SubToInternet
	}

	implementationTicket(): string {
		const implementationState = this.workflowInstance.workflowInstanceStates
			.reverse()
			.find(state => state.ticketId !== null);
		return implementationState?.ticketId || '';
	}

	isCancellable(): boolean {
		if(!this.sevenDayExemption){
			return (
				authenticatedUser.userId === this.workflowInstance.createdBy.id &&[
				WorkflowState.Draft.value,
				WorkflowState.PendingSubscriptionOwnerApproval.value,
				WorkflowState.PendingForCyberApproval.value,
				WorkflowState.PendingForGatewayPolicyTeamApproval.value,
				WorkflowState.Review.value,
			].includes(this.workflowInstance.currentWorkflowInstanceState.workflowState.value));
		}
		else{
			return (
				authenticatedUser.userId === this.workflowInstance.createdBy.id &&
			[
				WorkflowState.Draft.value,
				WorkflowState.PendingSubscriptionOwnerApproval.value,
				WorkflowState.PendingCloudArchitectApproval.value,
				WorkflowState.Review.value,
			].includes(this.workflowInstance.currentWorkflowInstanceState.workflowState.value));
		}
	}


	canHaveSevenDayDecision(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			(
				(currentState === WorkflowState.PendingCloudArchitectApproval.value)
				&& authenticatedUser.isInRole(UserRole.SubToInternetTechnicalReviewer)
			)
		);
	}

	canHaveCyberDecision(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			(
				(currentState === WorkflowState.PendingForCyberApproval.value)
				&& authenticatedUser.isInRole(UserRole.DNDRSubToInternetCyberReviewer)
			)
		);
	}

	canHaveGatewayPolicyDecision(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			(
				(currentState === WorkflowState.PendingForGatewayPolicyTeamApproval.value)
				&& authenticatedUser.isInRole(UserRole.DNDRSubToInternetGatewayPolicyReviewer)
			)
		);
	}

	canHaveSubscriptionOwnerDecision(subscription: SubscriptionRequest, hasCustodianApproval: boolean): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState;
		if (hasCustodianApproval){
			return currentState === WorkflowState.PendingSubscriptionOwnerApproval && (
				subscription.owner?.id === authenticatedUser.userId ||
				subscription.custodian?.id === authenticatedUser.userId ||
				subscription.custodianTwo?.id === authenticatedUser.userId
			);
		}
		return subscription.owner?.id === authenticatedUser.userId
			&& currentState === WorkflowState.PendingSubscriptionOwnerApproval;
				
	}

	isEditable(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			authenticatedUser.userId === this.workflowInstance.createdBy.id &&
			[
				WorkflowState.Draft.value,
				WorkflowState.Review.value,
			].includes(currentState)
		);
	}

}