import { AbstractSubscriptionsForm, ISubscriptionStateForm } from './AbstractSubscriptionsForm';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import { Params } from 'react-router-dom';

export interface SubscriptionsFormNewProps {
	navigate?: any;
	params?: Params<string>;
 }

export interface ISubscriptionNewStateForm extends ISubscriptionStateForm {
	subscriptionName: string;
	platform: number;
}

interface SubscriptionsFormNewState {
	stateForm: ISubscriptionNewStateForm;
	submissionAlert: AlertBanner
}

/** @abstract */
export abstract class AbstractSubscriptionsFormNew<
	Props extends SubscriptionsFormNewProps,
	State extends SubscriptionsFormNewState
	> 
	extends AbstractSubscriptionsForm<Props, State> {
	userNotAllowed = () => {
		this.setState({
			submissionAlert: {
				visible: true,
				variant: 'error',
				text: 'This user is not allowed to enter this page.'
			}
		},
		() => {
			setTimeout(() => {
				this.redirectToSubscriptions();
			}, 5000);
		});
	};

	redirectToSubscriptions = () => {
		this.setState({
			submissionAlert: { visible: false }
		},
		() => {
			this.props.navigate('/subscriptions');
		});
	};

	initFormModel() {
		super.initFormModel();
		
		this.formModel.addField('subscriptionName', {
			getValue: () => this.state.stateForm.subscriptionName,
			validation: {
				required: true,
				rules: [
					{
						assert: () => this.state.stateForm.subscriptionName.length >= 3 && this.state.stateForm.subscriptionName.length < 46,
						message: 'The name must be 3-46 characters long.',
					},
					{
						assert: () => /^(?![-&_])[a-zA-Z][a-zA-Z0-9-&_]*(?<![-&_])$/.test(this.state.stateForm.subscriptionName),
						message: 'Alphanumeric and hyphens are only allowed.',
					},
				],
			},
		});

		this.formModel.addField('isImported', {
			getValue: () => false,
		});
		
		this.formModel.addField('platform', {
			getValue: () => this.state.stateForm.platform,
			validation: {
				required: true
			}
		});
	}

	stateFormHandler = () => this.getStateFormHandlers();
	
	getStateFormHandlers() {
		return {
			...super.getStateFormHandlers(),
			subscriptionName: {
				value: this.state.stateForm.subscriptionName,
				validation: this.formModel.fields.subscriptionName.validation,
				onChange: (event) => {
					this.handleStateFormChange('subscriptionName', event.target.value);
				}
			},
		};
	}
}