import React from 'react';
import { Button, Table, TextLink } from 'react-unity';
import serviceNow from '../../services/serviceNow.config';
import PortOpeningRequest from '../../models/entities/PortOpeningRequest';
import { WorkflowState } from '../../models/enums/WorkflowState';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ProgressBarSmall from './components/ProgressBarSmall';

interface PortOpeningRequestsByPORRowProps {
	request: PortOpeningRequest;
	subOwnerDisplayName?: string;
}

const StyledButton = styled(Button)`
	width: 120px;
	height: 34px;
`;

const PortOpeningRequestsByPORRow = ({ request, subOwnerDisplayName }: PortOpeningRequestsByPORRowProps) => {
	const navigate = useNavigate();
	const getTicketURL = (ticket: string) => {
		return ticket.toUpperCase().includes('CRQ')
			? `https://itconsole.na.xom.com/#/${ticket}`
			: `https://${serviceNow.serviceNowEnv}.service-now.com/nav_to.do?uri=change_request.do?sysparm_query=number=${ticket}&sysparm_limit=1`;
	};

	const linkToTicket = () => {
		const implementationTicket = request.implementationTicket();
		return implementationTicket !== '' ? (
			<TextLink external href={getTicketURL(implementationTicket)} target="_blank">
				{implementationTicket}&nbsp;
			</TextLink>
		) : (
			'-'
		);
	};

	const handleViewDetails = () => {
		navigate(`/subscriptions/${request.subscriptionRequestId}/portOpeningRequests/${request.id}`);
	};

	return (
		<Table.Body.Row key={request.id}>
			<Table.Body.Cell>{request.id}</Table.Body.Cell>
			<Table.Body.Cell>{request.direction.displayName}</Table.Body.Cell>
			<Table.Body.Cell style={{position: 'relative'}}>
				<ProgressBarSmall workflowState={request.workflowInstance.currentWorkflowInstanceState.workflowState} porScope={request.direction.name}/>
			</Table.Body.Cell>
			<Table.Body.Cell>{linkToTicket()}</Table.Body.Cell>
			<Table.Body.Cell>{request.isAttestation ? 'Yes' : 'No'}</Table.Body.Cell>
			<Table.Body.Cell>{request.workflowInstance.createdBy.displayName}</Table.Body.Cell>
			<Table.Body.Cell>{request.contactUser?.displayName}</Table.Body.Cell>
			<Table.Body.Cell>
				{request.workflowInstance.currentWorkflowInstanceState.workflowState ===
				WorkflowState.PendingSubscriptionOwnerApproval
					? subOwnerDisplayName
					: request.approvingUser?.displayName}
			</Table.Body.Cell>
			<Table.Body.Cell>
				<StyledButton onClick={handleViewDetails} variant="primary">
					See Details
				</StyledButton>
			</Table.Body.Cell>
		</Table.Body.Row>
	);
};

export default PortOpeningRequestsByPORRow;
