import React, { useState, useEffect } from 'react';
import {
	Grid,
	Button, 
	XFilledIcon,
	Modal,
    PlusFilledIcon,
    TrashcanIcon,
    TextInput,
    Alert,
} from 'react-unity';
import SelectField from '../common/form-controls/SelectField';
import { VNetConfiguration } from '../../models/viewModels/Subscriptions/VNetConfiguration';
import { SubnetConfiguration } from '../../models/viewModels/Subscriptions/SubnetConfiguration';
import { AlertBanner } from '../../models/interfaces/AlertBanner';

export interface IVirtualNetworkModalProps {
	visible: boolean;
	onCancel: (e) => any;
	onClose: (e) => any;
	onConfirm: (e) => any;
    regionSelected: string;
    vNetConfig?: VNetConfiguration;
    setVNetConfig: any;
    editing: boolean;
    setEditing: any;
}

const VirtualNetworkModal = ({
    visible,
    onCancel,
    onClose,
    onConfirm,
    regionSelected,
    vNetConfig,
    setVNetConfig,
    editing,
    setEditing
}: IVirtualNetworkModalProps) => {

    const [ size, setSize ] = useState<string>('');
    const [ subnets, setSubnets ] = useState<SubnetConfiguration[]>([]);
    const [ alert, setAlert ] = useState<AlertBanner>({ visible: false });
    const [ canSubmit, setCanSubmit ] = useState<boolean>(false);

    const handleOnCancel = (e) => {
        resetForm();
        onCancel(e);
    };

    const validSubnetsSize = () => {
        const maxNetAvailable = 32;
        const vNetSize = Math.pow(2, maxNetAvailable - parseInt(size.split('/')[1]));
        let subnetSize = 0;
        subnets.forEach(function(subnet){
            subnetSize += Math.pow(2, maxNetAvailable - subnet.size);
        });
        if (vNetSize < subnetSize){
            setAlert({
                visible: true,
                variant: 'error',
                text: 'The sum of the size of the subnets cannot be greater than the size of the virtual network'
            });
            setCanSubmit(false);
            return false;
        }
        setCanSubmit(true);
        return true;
    }

    const handleOnConfirm = (e) => {
        if (validSubnetsSize()){
            const temp = {
                region: regionSelected,
                size: parseInt(size.split('/')[1]),
                subnets: subnets
            };
            setVNetConfig(temp);
            resetForm();
            onConfirm(e);   
        }
    };

    const handleOnClose = (e) => {
        resetForm();
        onClose(e);
    };

    const handleAddSubnet = () => {
        const newSubnet = {
            name: '',
            size: 0
        }
        setSubnets([...subnets, newSubnet])
    }

    const resetForm = () => {
        setSize('');
        setSubnets([]);
        setEditing(false);
    };

    const handleRemoveSubnet = (subnet: any) => {
        if (subnets.length == 1 ) {
            setSubnets([]);
        }
        else {
            const filtered = subnets.filter(e => e != subnet);
            setSubnets(filtered);
        }
    }

    const isValid = () => {
        return (subnets.length != 0 
            && size != null
            && !(subnets.map((subnet) => isValidSubnetName(subnet)).some(e => e == false))
            && !(subnets.map((subnet) => isValidSubnetSize(subnet)).some(e => e == false)))
    }

    const handleSubnetNameChange = (value: string, subnet: SubnetConfiguration) => {
        setSubnets(prevState => {
            const newState = prevState.map(obj => {
              if (obj === subnet) {
                return {...obj, name: value};
              }
              return obj;
            });
      
            return newState;
          });
    }

    const handleSubnetSizeChange = (value: string, subnet: SubnetConfiguration) => {
        const val = value.split('/')[1]
        setSubnets(prevState => {
            const newState = prevState.map(obj => {
              if (obj === subnet) {
                return {...obj, size: parseInt(val) ?? null};
              }
              return obj;
            });
      
            return newState;
          });
    }

    const isValidSubnetName = (subnet: SubnetConfiguration) => {
        return (/^(?![-_])[a-zA-Z][a-zA-Z-_]*(?<![-_])$/.test(subnet.name)
            && subnet.name.length > 1
            && subnet.name.length <= 50
            && subnet.name != '')
    }

    const isValidSubnetSize = (subnet: SubnetConfiguration) => {
        return (/^[0-9]*$/.test(subnet.size?.toString())
            && subnet.size > 19
            && subnet.size < 30
            && subnet.size != null)
    }

    useEffect(() => {
        if (editing){
            setSize(`/${vNetConfig.size.toString()}`);
            setSubnets(vNetConfig.subnets);
        }
    }, [editing])

return (
    <Modal show={visible}
        onHide={() => { }}>
        <Modal.Window className="autoOverflowModal" id="virtual-network-modal">
            <Modal.Window.Header>
                <Modal.Window.Header.Title>New Virtual Configuration</Modal.Window.Header.Title>
                <Modal.Window.Header.CloseButton onClick={handleOnClose}>
                    <XFilledIcon size='small' />
                </Modal.Window.Header.CloseButton>
            </Modal.Window.Header>
            <Modal.Window.Body>
                {alert.visible &&
                    <Alert
                        variant={alert.variant}
                        onClose={() => setAlert({ visible: false })}
                    >
                        {alert.text}
                    </Alert>}
                <Grid variant="halves">
                    <Grid.Item>
                        <SelectField
                            label="Region"
                            className="full-width"
                            options={[regionSelected]}
                            disabled={true}
                            value={regionSelected}
                        />
                    </Grid.Item>
                    <Grid.Item>
                        <SelectField
                            label="Size"
                            className="full-width"
                            options={['/27','/26','/25','/24']}
                            value={size}
                            onChange={(e) => setSize(e.target.value)}
                        />
                    </Grid.Item>
                </Grid>
                <Button
                    variant="primary"
                    size="small"
                    onClick={() => handleAddSubnet()}
                >
                    <>
                        <PlusFilledIcon size="small" />
                        <span>Add Virtual Subnet</span>
                    </>
                </Button>
                {subnets.length > 0 && subnets?.map((subnet: SubnetConfiguration, rowIndex: number) => (
                        <Grid variant="halves" key={rowIndex} style={{ alignItems: 'center' }}>
                            <Grid.Item>
                                <TextInput
                                    id={`subnetName`}
                                    label="Subnet Name"
                                    valid={isValidSubnetName(subnet)}
						            error={!isValidSubnetName(subnet)}
                                    value={subnet.name}
                                    note={"The subnet name must be less than 50 characters"}
                                    onChange={(e) => handleSubnetNameChange(e.target.value, subnet)}
                                />
                            </Grid.Item>
                            <Grid.Item>
                                <TextInput
                                    id={`subnetSize`}
                                    label="Subnet Size"
                                    valid={isValidSubnetSize(subnet)}
						            error={!isValidSubnetSize(subnet)}
                                    value={isNaN(subnet.size) ? '/' : `/${subnet.size?.toString()}`}
                                    note={"The addresses size must be between 29-20"}
                                    onChange={(e) => handleSubnetSizeChange(e.target.value, subnet)}
                                />
                            </Grid.Item>
                            <Button
                                style={{ zIndex: 0 }}
                                variant="primary"
                                size="small"
                                onClick={() => handleRemoveSubnet(subnet)}
                            >
                                <TrashcanIcon size="small" />
                            </Button>
                        </Grid>
                    ))}
            </Modal.Window.Body>
            <Modal.Window.Footer>
                <Grid variant="2-up">
                    <Grid.Item>
                        <Button
                            variant="secondary"
                            disabled={false}
                            onClick={(e) => handleOnCancel(e)}
                        >
                            Cancel
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <Button 
                            variant="primary"
                            disabled={!isValid()}
                            onClick={(e) => handleOnConfirm(e)}
                        >
                            Save
                        </Button>
                    </Grid.Item>
                </Grid>
            </Modal.Window.Footer>
        </Modal.Window>
    </Modal>
);
};

export default VirtualNetworkModal;
