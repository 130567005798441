import * as React from 'react';
import { Grid, Field } from 'react-unity';
import SelectField from '../../../common/form-controls/SelectField';
import FieldHandler from '../../../../models/interfaces/FieldHandler';
import TextInputField from '../../../common/form-controls/TextInputField';

interface ProtocolInputProps extends FieldHandler<string>{
}

interface ProtocolInputState {
	protocolSelected: string;
}

export class ProtocolInput extends React.Component<ProtocolInputProps, ProtocolInputState> {

	constructor(props: ProtocolInputProps) {
		super(props);
		this.state = {
			protocolSelected: '',
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value){
			const { value } = this.props;
			if (['TCP', 'UDP', null].includes(value)) {
				this.setState({ protocolSelected: value === null ? '' : value });
			} else {
				this.setState({ protocolSelected: 'Other' });
			}
		}
	}

	handleOnChangeProtocol = (event) => {
		const { value } = event.target;
		this.setState(
			{ protocolSelected: value },
			() => this.props.onChange(value === 'Other' ? '' : value)
		);
	};

	isOtherSelected = () => {
		return this.state.protocolSelected === 'Other';
	};

	render() {
		return (
			<Field>
				<Field.Label>Protocol</Field.Label>
				<Field.Body>
					<Grid variant="2-up">
						<Grid.Item className="em-u-padding-none">
							<SelectField
								value={this.state.protocolSelected}
								options={['TCP', 'UDP', 'Other']}
								className="full-width"
								onChange={this.handleOnChangeProtocol}
								validation={this.isOtherSelected() ?
									undefined :
									this.props.validation}
							/>
						</Grid.Item>
						{ this.isOtherSelected() &&
						<Grid.Item className="em-u-padding-none">
							<TextInputField
								value={this.props.value ? this.props.value : ''}
								onChange={(event) => this.props.onChange(event.target.value)}
								validation={this.props.validation}
							/>
						</Grid.Item>}
					</Grid>
				</Field.Body>
			</Field>
		
		);
	}
}