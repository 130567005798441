import * as React from 'react';
import { Button, Grid, TextPassage, Fieldset, BulletedList, Alert, AlertVariant } from 'react-unity';
import FormModel from '../../utils/FormModel';
import TextInputField from '../common/form-controls/TextInputField';
import CloudAccountsService from '../../services/CloudAccountsService';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import { isNull } from 'util';

interface ConfirmPasswordResetState {
    passwordField1: string;
	passwordField2: string;
	alertVariant: AlertVariant;
	alertText: string
}

export default class ConfirmPasswordReset extends React.Component<{}, ConfirmPasswordResetState> {
	cloudAccountsService: CloudAccountsService;

	form: FormModel;

	constructor(props) {
		super(props);
		this.state = {
			passwordField1: '',
			passwordField2: '',
			alertVariant: 'default',
			alertText: ''
		};

		this.cloudAccountsService = new CloudAccountsService();
		
		this.form = new FormModel({
			passwordField1: {
				getValue: () => this.state.passwordField1,
				validation: {
					required: true,
					rules: [
						{
							assert: () =>  (this.state.passwordField1 !== undefined && this.state.passwordField1 !== null && this.state.passwordField1 !== "") ,
							message: "",
						},
						{
							assert: () => /^(?=.*[a-z])/.test(this.state.passwordField1),
							message: "The password is not valid (missing lowercase character)" ,
						},
						{
							assert: () => /^(?=.*[A-Z])/.test(this.state.passwordField1),
							message: "The password is not valid (missing uppercase character)",
						},
						{
							assert: () => /^(?=.*[0-9])/.test(this.state.passwordField1),
							message: "The password is not valid (missing number)",
						},
						{
							assert: () => /^(?=.*[!"#$%&()*+,-./:;=?@[\]^_`{|}~])/.test(this.state.passwordField1),
							message: "The password is not valid (missing or invalid special character)",
						},
						{
							assert: () => /^(?=.{8,})/.test(this.state.passwordField1),
							message: "The password is not valid (invalid lenght)",
						},
					],
				},
			},
			passwordField2: {
				getValue: () => this.state.passwordField2,
				validation: {
					required: true,
					rules: [
						{
							assert: () => this.state.passwordField2 === this.state.passwordField1,
							message: 'The provided passwords do not match.',
						},
					],
				},
			},
		});
	}

	handleSubmit = async () => {
		try {
			this.setState({ alertText: 'Processing your request...' });

			const token = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
			await this.cloudAccountsService.confirmPasswordReset({ token, password: this.state.passwordField2 });

			this.setState({ alertVariant: 'success', alertText: 'You have successfully reset your password.' });
		} catch (error) {
			this.setState({ alertVariant: 'error', alertText: error.body.message });
		}
	};

	render() {
		return (
			<SectionHomeWrapper title="Reset your password">
				{this.state.alertText !== '' ? (
					<div>
						<Alert variant={this.state.alertVariant}>{this.state.alertText}</Alert>
						{this.state.alertVariant === 'success' && (
							<p>You may now use your new password to log into your account.</p>
						)}
						<Button disabled={this.state.alertVariant === 'default'} variant="primary" onClick={() => window.location.href = '/cloudAccounts'}>Manage Accounts</Button>
					</div>
				) : (
					<div>
						<TextPassage>
							<p>
								Ensure your new password meets the following criteria:<br /><br />
								<BulletedList className="indented-list">
									<BulletedList.Item>
										Is a minimum of 8 characters and a maximum of 256 characters.
									</BulletedList.Item>
									<BulletedList.Item>
										Contains both uppercase and lowercase characters.
									</BulletedList.Item>
									<BulletedList.Item>Contains at least one numeric character.</BulletedList.Item>
									<BulletedList.Item>
										Contains at least one of the following special characters: @#$%^*-_!+=[]|:',.?/`~();
									</BulletedList.Item>
								</BulletedList>
							</p>
						</TextPassage>
						<Fieldset>
							<Grid variant="2-up">
								<Grid.Item>
									<TextInputField
										label="New Password"
										type="password"
										value={this.state.passwordField1}
										validation={this.form.fields.passwordField1.validation}
										onChange={(e) => this.setState({ passwordField1: e.target.value })}
									/>
								</Grid.Item>
								<Grid.Item />
								<Grid.Item>
									<TextInputField
										label="Confirm Password"
										type="password"
										value={this.state.passwordField2}
										validation={this.form.fields.passwordField2.validation}
										onChange={(e) => this.setState({ passwordField2: e.target.value })}
									/>
								</Grid.Item>
							</Grid>
							<Button variant="primary" onClick={this.handleSubmit} disabled={!this.form.isValid()}>
								Confirm
							</Button>
						</Fieldset>
					</div>
				)}
			</SectionHomeWrapper>
		);
	}
}
