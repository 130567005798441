import React from 'react';
import { Toolbar, Select, Pagination } from 'react-unity';
import './Paginator.css';

interface PaginatorProps<T> {
	data: T[];
	totalItems?: number;
	itemsPerPage?: number;
	onPageChange: (rows: T[]) => any;
}

interface PaginatorState<T> {
	itemsPerPage: number;
	pages: {
		[pageNumber: string]: T[];
	},
	activePage: string,
	pageGroup: number,
	amountOfPagesAvailable: number
}

export default class Paginator<T>
	extends React.Component<PaginatorProps<T>, PaginatorState<T>> {
	
	defaultItemsPerPage = 25;
	
	constructor(props: PaginatorProps<T>) {
		super(props);
		this.state = {
			itemsPerPage: props.itemsPerPage || this.defaultItemsPerPage,
			pages: {
				'1': []
			},
			activePage: '1',
			pageGroup: 1,
			amountOfPagesAvailable: 3
		};
	}

	componentDidMount() {
		this.handleItemsPerPageChange(this.props.itemsPerPage || this.defaultItemsPerPage);
	}

	componentDidUpdate(prevProps: PaginatorProps<T>) {
		if ((prevProps.data?.length !== this.props.data?.length)
			|| prevProps.data?.filter(x => !this.props.data?.includes(x)).length > 0) {
			this.handleItemsPerPageChange(this.state.itemsPerPage);
		}
	}

	handleItemsPerPageChange = (numberOfItems) => {
		const pages = this.getPages(numberOfItems);
		this.setState({
			itemsPerPage: numberOfItems,
			pages,
			activePage: '1',
			pageGroup: 1
		}, () => {
			this.props.onPageChange(this.state.pages['1'] ?? []);
		});
	};

	getPages = (itemsPerPage: number) => {
		const pages = {};
		for (let index = 0; index < this.props.data?.length / itemsPerPage; index++) {

			const startIndex = index === 0 ? 0 : index * itemsPerPage;

			pages[`${index + 1}`] = [...this.props.data.slice(startIndex, +startIndex + +itemsPerPage)];
		}
		return pages;
	};

	pageKeys = () => {
		return Object.keys(this.state.pages);
	};

	goToPage(pageIndex: string){
		this.setState({
			activePage: pageIndex
		});
		this.props.onPageChange(this.state.pages[pageIndex]);
	}

	goToFirstPage(){
		this.setState({
			activePage: '1',
			pageGroup: 1
		}, () => {
			this.props.onPageChange(this.state.pages[1]);
		});
	}

	goToLastPage(){
		this.setState({
			pageGroup: Math.ceil(this.pageKeys().length / this.state.amountOfPagesAvailable),
			activePage: this.pageKeys().length.toString()
		}, () => {
			this.props.onPageChange(this.state.pages[this.pageKeys().length.toString()]);
		});
	}

	showThreeNextPages(){
		const toFirstOfPageGroup = this.state.amountOfPagesAvailable - 1;
		this.setState(previousState => ({
			pageGroup: previousState.pageGroup + 1,
			activePage: ((previousState.pageGroup * this.state.amountOfPagesAvailable) + 1).toString()
		}), () => {
			this.props.onPageChange(this.state.pages[(this.state.pageGroup * this.state.amountOfPagesAvailable) - toFirstOfPageGroup]);
		});
	}

	showThreePreviousPages(){
		this.setState(previousState => ({
			pageGroup: previousState.pageGroup - 1,
			activePage: ((previousState.pageGroup - 1) * this.state.amountOfPagesAvailable).toString()
		}), () => {
			this.props.onPageChange(this.state.pages[this.state.pageGroup * this.state.amountOfPagesAvailable]);
		});
	}

	render() {
		return (
			<Toolbar>
				<Toolbar.Item>
					{this.props.data?.length} item
					{this.props.data?.length !== 1 && 's'}
				</Toolbar.Item>
				<Toolbar.Item>
					<Select
						value={this.state.itemsPerPage}
						className="em-u-margin-bottom-none"
						size="small"
						onChange={(e) => {
							this.handleItemsPerPageChange(e.target.value);
						}}
					>
						{
							[10, 25, 50, 100].map(x =>
								<option className='dropdown-paginator-filter-color' key={x} value={x}>{x} items per page</option>
							)
						}
					</Select>
				</Toolbar.Item>
				<Toolbar.Item right>
					{
						this.pageKeys().length > 0 &&
						<Pagination maxPages={this.state.amountOfPagesAvailable} initialPage={1}>
							<Pagination.First onChange={() => this.goToFirstPage()} disabled={this.state.activePage === '1'} />
							{
								this.state.pageGroup > 1 &&
								<Pagination.Item onChange={() => this.showThreePreviousPages()}>...</Pagination.Item>
							}
							{
								this.pageKeys()
									.filter(key => 
										parseInt(key) >= (this.state.pageGroup * this.state.amountOfPagesAvailable) - (this.state.amountOfPagesAvailable -1) 
										&& parseInt(key) <= (this.state.pageGroup * this.state.amountOfPagesAvailable))
									.map(key => 
										<Pagination.Item 
											onChange={() => this.goToPage(key)} 
											key={key}
											active={this.state.activePage.toString() === key}
										>
											{key}
										</Pagination.Item>
									)
							}
							{
								this.pageKeys().length > this.state.pageGroup * this.state.amountOfPagesAvailable &&
								<Pagination.Item onChange={() => this.showThreeNextPages()}>...</Pagination.Item>
							}
							<Pagination.Last 
								onChange={() => this.goToLastPage()} 
								disabled={this.state.activePage === this.pageKeys().length.toString()} 
							/>
						</Pagination>	
					}
				</Toolbar.Item>
			</Toolbar>
		);
	}
}