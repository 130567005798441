import User from './User';

export default class UserLookupObject {

	id!: string;

	surname!: string;

	givenName!: string;

	// eslint-disable-next-line react/static-property-placement
	displayName!: string;

	mailNickname!: string;

	userPrincipalName!: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}

	// TODO: merge this object with user, shouldn't exist
	static fromUser(user: User): UserLookupObject {
		if (!user) return null;
		return new UserLookupObject({
			id: user.id,
			surname: user.surname,
			givenName: user.givenName,
			displayName: user.displayName,
			mailNickname: user.mailNickname,
			userPrincipalName: user.userPrincipalName.address
		});
	}
}