import * as React from 'react';
import { Table, Toolbar, TextLink, ProgressBar, AlertVariant, Button, PlusFilledIcon, TextInput} from 'react-unity';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import PolicyExemptionRequest from '../../models/entities/PolicyExemptionRequest';
import PolicyExemptionRequestService from '../../services/PolicyExemptionRequestService';
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import { LinkTreeItem } from '../common/LinkTree';
import WorkflowStateBadge from '../common/WorkflowStateBadge';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import AlertModal from '../common/modals/AlertModal';
import Paginator from '../common/tables/Paginator';
import './PolicyExemptionRequestsHome.css';
import '../common/wrappers/SectionHomeWrapper.css';
import CheckboxFilter, { CheckboxOption } from '../common/tables/CheckboxFilter';
import Filter from '../common/tables/Filter';
import { PolicyGeneralInformation } from './components/PolicyGeneralInformation';
import moment from 'moment';
import { Params, useNavigate, useParams } from 'react-router-dom';

export type PolicyExemptionFilters = {
	scope: CheckboxOption[];
	status: CheckboxOption[];
	searchUser: string;
};

interface PolicyExemptionRequestsHomeProps{
	subtitle?: string;
}

interface PolicyExemptionRequestsHomeWithParamsProps {
	subtitle?: string;
	params: Params<string>;
	navigate: any;
}

interface PolicyExemptionRequestsHomeWithParamsState {
	loading: boolean;
	subscription?: SubscriptionRequest;
	requests: PolicyExemptionRequest[];
	filteredRequests: PolicyExemptionRequest[];
	endModal: AlertBanner;
	
	synching: boolean;
	visibleRequests: PolicyExemptionRequest[];
}

class PolicyExemptionRequestsHomeWithParams extends
	React.Component<PolicyExemptionRequestsHomeWithParamsProps, PolicyExemptionRequestsHomeWithParamsState> {
	
	policyExemptionRequestService: PolicyExemptionRequestService;

	subscriptionRequestsService: SubscriptionRequestsService;

	filter: Filter<PolicyExemptionFilters>;

	constructor(props) {
		super(props);
		this.policyExemptionRequestService = new PolicyExemptionRequestService();
		this.subscriptionRequestsService = new SubscriptionRequestsService();
		this.state = {
			loading: true,
			requests: [],
			filteredRequests: [],
			visibleRequests: [],
			endModal: {
				visible: false,
			},
			synching: false,
		};

		this.filter = new Filter(this.filterPolicyExemptions, this.getDefaultFilters());
	}

	async componentDidMount() {
		this.fetchData();
	}

	async componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.filter.changeDefaultFilters(this.getDefaultFilters());
			await this.fetchData();
		}
	}

	fetchData = async () => {
		if (this.props.params.id) {
			const id = parseInt(this.props.params.id);
			const subscription = new SubscriptionRequest(await this.subscriptionRequestsService.getById(id));
			if (!(subscription.canSeePolicyExemptionRequests())
			) {
				this.handleEndModal('You are not allowed to see this request.', 'error', 5000);
			}

			this.setState({
				subscription
			});
		} else {
			this.setState({
				subscription: null
			});
		}
		await this.loadRequests();
	};

	getLinkTree = (): LinkTreeItem[] => {
		return (this.state.subscription?.id && [
			{ text: 'Subscriptions', to: '/subscriptions' },
			{ text: this.state.subscription?.createdSubscriptionName, to: `/subscriptions/${this.state.subscription?.id}` },
		]) || [];
	};

	loadRequests = async () => {
		try {
			this.setState({ loading: true });
			const id = parseInt(this.props.params.id);
			let requests = await this.subscriptionRequestsService
				.getPolicyExemptionRequestsBySubsId(id);

			requests = requests.map(r => new PolicyExemptionRequest(r))
				.reverse();
		
			this.setState({
				requests,
				loading: false,
			}, this.filter.filter);

		} catch {
			this.setState({
				loading: false,
			});
			this.handleEndModal('Something went wrong, try again later.', 'error', 5000);
		}
	};
	
	handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		this.setState({
			endModal: {
				visible: true,
				text,
				variant,
			}
		},
		() => {
			setTimeout(() => {
				this.redirectToSubscriptions();
			}, timeout);
		});
	};

	redirectToSubscriptions = () => {
		this.setState({
			endModal: { visible: false }
		},
		() => {
			this.props.navigate('/subscriptions');
		});
	};

	getDefaultFilters = () => {
		const defaultFilters = {
			searchUser: '',
			status: [
				{ label: 'Rejected', value: 'Rejected', checked: true },
				{ label: 'Awaiting Implementation', value: 'Awaiting Implementation', checked: true },
				{ label: 'Completed', value: 'Completed', checked: true },
			]					
		} as PolicyExemptionFilters;

		return defaultFilters;
	};

	filterPolicyExemptions = () => {
		let filteredRequests = [];

		const filterCallback = (per: PolicyExemptionRequest) => {
			return this.filter.filterCheckBox('status', per.workflowInstance.currentWorkflowInstanceState.workflowState.name)
			&& (this.filter.filterSearchText('searchUser', per.workflowInstance.createdBy.displayName));
		};

		filteredRequests = this.state.requests.filter(filterCallback);

		this.setState({
			filteredRequests
		});
		
	};

	goToNewRequest = () => {
		this.props.navigate(
			'/new'
		);
	};

	render() {
		const rows = this.state.visibleRequests?.map(request => (
			<Table.Body.Row key={request.id}>
				<Table.Body.Cell>
					<TextLink href={`policyExemptionRequests/${request.id}`}># {request.id}</TextLink>
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.implementationTicket() !== '' ?
						<TextLink
							external
							href={request.getTicketURL()}
							target="_blank"
						>
							{request.implementationTicket()}&nbsp;
						</TextLink>
						:
						'-'}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.displayName}
				</Table.Body.Cell>
				<Table.Body.Cell>
					<WorkflowStateBadge
						fullWidth
						workflowState={request.workflowInstance.currentWorkflowInstanceState.workflowState}
					/>
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.approvalType?.name}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{moment(request.workflowInstance.createdDate).format('MMM DD, YYYY')}
				</Table.Body.Cell>
			</Table.Body.Row>
		));

		return (
			<>
				<SectionHomeWrapper
					title={`Policy Exception Requests${this.props.subtitle ? `: ${this.props.subtitle}`: ''}`}
					linkTree={this.getLinkTree()}
				>
					<PolicyGeneralInformation />
					{(this.state.synching || this.state.loading) ?
						<ProgressBar
							label={this.state.synching ? 'Synchronizing requests data...' : 'Loading requests...'}
							className="em-u-margin-top-half"
							indeterminate
							hideValueLabel
						/> :
						<>
						
							<Table
								header={
									<Toolbar>
										<Toolbar.Item>
											<CheckboxFilter
												label="Status"
												onChange={(event) => this.filter.handleCheckboxChange(event, 'status')}
												options={this.filter.filters.status}
											/>
										</Toolbar.Item>
										<Toolbar.Item>
											<TextInput
												inline
												className="em-u-margin-bottom-none"
												placeholder=" Search User"
												type="text"
												size="small"
												value={this.filter.filters.searchUser}
												onChange={e => this.filter.handleSearchTextChange(e, 'searchUser')}
											/>
										</Toolbar.Item>
										<Toolbar.Item right>
											<Button
												variant="primary"
												size="small"
												onClick={() => {this.goToNewRequest();}}
											>
												<PlusFilledIcon size="small" />
												<span>New Request</span>
											</Button>
										</Toolbar.Item>
									</Toolbar>
								}
								footer={
									<Paginator
										data={this.state.filteredRequests}
										onPageChange={(page) => {
											this.setState({
												visibleRequests: page
											});
										}}
									/>
								}
							>
								<Table.Head>
									<Table.Head.Row>
										<Table.Head.Cell> REQUEST # </Table.Head.Cell>
										<Table.Head.Cell> TICKET # </Table.Head.Cell>
										<Table.Head.Cell> DESCRIPTION </Table.Head.Cell>
										<Table.Head.Cell> STATUS </Table.Head.Cell>
										<Table.Head.Cell> APPROVER </Table.Head.Cell>
										<Table.Head.Cell> SUBMIT DATE </Table.Head.Cell>
									</Table.Head.Row>
								</Table.Head>
								<Table.Body>
									{rows}
								</Table.Body>
							</Table>
						</>}
				</SectionHomeWrapper>
				<AlertModal
					{...this.state.endModal}
					willTimeout={false}
					onClose={this.redirectToSubscriptions}
				/>
			</>
		);
	}
}

const PolicyExemptionRequestsHome = (props: PolicyExemptionRequestsHomeProps) => {

    return <PolicyExemptionRequestsHomeWithParams subtitle={props.subtitle} params={useParams()} navigate={useNavigate()}/>;
}

export default PolicyExemptionRequestsHome;