import * as React from 'react';
import { Modal, Button, Alert, XFilledIcon, Grid } from 'react-unity';
import TextInputField from '../../common/form-controls/TextInputField';
import TextAreaField from '../../common/form-controls/TextareaField';
import SelectField from '../../common/form-controls/SelectField';
import './../FeedbackHome.css';
import ReactButton from '../../common/ReactButton';
import Feedback from '../../../models/entities/Feedback';
import UpdateFeedback from '../../../models/viewModels/Feedback/UpdateFeedback';
import FeedbackService from '../../../services/FeedbackService';
import FormModel from '../../../utils/FormModel';

interface UpdateFeedbackModalProps {
	feedback: Feedback;
	visible: boolean;
	isSSPDev: boolean;
	onConfirm: (event: React.ChangeEvent<any>) => void;
	onCancel?: (event: React.ChangeEvent<any>) => void;
	onClose: (event: React.ChangeEvent<any>) => void;
}

interface UpdateFeedbackModalState {
	workItem: number;
	workItemState: string;
	comments: string;
	errorAlert: boolean;
	loading: boolean;
}

export default class UpdateFeedbackModal extends React.Component<UpdateFeedbackModalProps, UpdateFeedbackModalState> {
	feedbackService: FeedbackService;

	form: FormModel;

	constructor(props: UpdateFeedbackModalProps) {
		super(props);
		this.state = {
			workItem: null,
			workItemState: '',
			comments: '',
			errorAlert: false,
			loading: false
		};
		this.feedbackService = new FeedbackService();
		this.form = new FormModel({
			workItemState: {
				getValue: () => this.state.workItemState,
				validation: {
					required: true,
				},
			}
		});
	}

	handleUpdateSubmit = async (e) => {

		const body = this.form.create(UpdateFeedback);
		
		if (body.workItemNumber == 0) body.workItemNumber = null;
		if (this.state.comments != null) body.comments = this.state.comments;
		
		this.setState({
			loading: true
		});
		
		this.feedbackService.UpdateFeedback(this.props.feedback.id, body).then(() => {
			this.setState({
				loading: false
			});
			this.props.onConfirm(e);
		}
		).catch(() => {
			{
				this.setState({
					errorAlert: true,
					loading: false
				});
			}
		});
	};

	componentDidUpdate = async (prevProps: UpdateFeedbackModalProps) => {
		let workItemState;
		const comments = '';
		const workItem = this.props.feedback.workItemNumber;
		if (prevProps.visible == false && this.props.visible) {
			workItemState = this.props.feedback.workItemState === null ? 'New' : this.props.feedback.workItemState.name;
			this.setState({ workItem, workItemState, comments, loading: false });
		}
	};

	render() {
		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Modal.Window>
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							Feedback Message #{this.props.feedback.id}
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<TextInputField
							label="Title"
							value={this.props.feedback.title}
							disabled
						/>
						<TextAreaField
							className="scoll"
							label="Description"
							value={this.props.feedback.description}
							disabled
						/>
						<TextInputField
							label="Work Item #"
							type="number"
							value={this.state.workItem != null ? this.state.workItem : ''}
							disabled={!this.props.isSSPDev}
							onChange={(event) => {
								this.setState({
									workItem: event.target.value,
								});
							}}
						/>
						<SelectField
							label="Work Item State"
							value={this.state.workItemState}
							disabled={!this.props.isSSPDev}
							options={['New', 'Approved', 'Committed', 'To Do', 'In Progress', 'Done', 'Removed', 'Rejected']}
							className="full-width"
							onChange={(e) => this.setState({ workItemState: e.target.value })}
							validation={this.form.fields.workItemState.validation}
						/>
						<TextInputField
							label="Additional Comments for email"
							value={this.state.comments != null ? this.state.comments : ''}
							onChange={(e) => this.setState({ comments: e.target.value })}
							disabled={!this.props.isSSPDev}
						/>
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Grid variant="2-up">
							<Grid.Item>
								<Button
									variant="secondary"
									disabled={this.state.loading}
									onClick={this.props.onCancel || this.props.onClose}
								>
									Cancel
								</Button>
							</Grid.Item>
							{this.props.isSSPDev && <Grid.Item>
								<ReactButton
									variant='secondary'
									loadingVariant = 'secondary'
									isLoading={this.state.loading}
									handleUpdateSubmit={this.handleUpdateSubmit}
									disabled={!this.form.isValid()}
									name = 'Update'
								>
								</ReactButton>
							</Grid.Item>}
						</Grid>
						{this.state.errorAlert &&
							<Alert variant="error" onClose={() => this.setState({ errorAlert: false })}>
								Something went wrong when trying to update the entry 
							</Alert>}
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}
