import React from 'react';
import { Badge } from 'react-unity';

interface PORTypeBadgeProps {
	children: string;
}

const PORTypeBadge = ({ children }: PORTypeBadgeProps) => {
	const getStyle = () => {
		switch (children) {
			case 'Permanent':
				return {backgroundColor: '#0192d0', color: 'white'};
			case 'Temporary':
				return {backgroundColor: '#ffd700', color: 'black'};
			default:
				return {backgroundColor: '#dddddd', color: 'black'};
		}
	};

	const getText = () => {
		switch (children) {
			case 'Temporary':
				return 'Temp';
			case 'Permanent':
				return 'Perm';
			default:
				return children;
		}
	};

	return (
		<Badge style={{...getStyle(), minWidth: 80}} className="em-u-max-width-10">
			{getText()}
		</Badge>
	);
};

export default PORTypeBadge;