import SubmitDoNotDecryptRequestRule from './SubmitDoNotDecryptRequestRule';

export default class SubmitDoNotDecryptRequest {

	subscriptionRequestId!: number;

	doNotDecryptRules!: SubmitDoNotDecryptRequestRule[];

	direction!: string;

	environment: string;

	contactId!: string;

	emergency!: boolean;

	incidentNumber!: string;

	businessJustification!: string;

	comments!: string;

	customerCoordination!: boolean;

	doNotDecryptRequestId!: number; 

	custodianApproval!: boolean;

	sevenDayExemption!: boolean;

	constructor(obj: any) {
		this.direction = "SubscriptionToInternet"
		Object.assign(this, obj);
	}
}