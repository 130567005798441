import React, { useState, useEffect } from 'react';
import { Alert, Button, CheckInDoc2Icon, PlusIcon, RefreshIcon, Searchbar, Select, Toolbar, EditIcon } from 'react-unity';
import { authenticatedUser } from '../../../authentication/authModule';
import SubscriptionRequest from '../../../models/entities/SubscriptionRequest';
import { UserRole } from '../../../models/enums/UserRole';
import CSVGeneratorButton from '../../common/CSVGeneratorButton';
import CheckboxFilter, { CheckboxOption } from '../../common/tables/CheckboxFilter';
import Filter from '../../common/tables/Filter';
import ExportColumns from './SubscriptionsHomeExport';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import './SubscriptionsHome.css';
import ResourceTabs from '../../common/ResourceTabs';
import Organization from '../../../models/entities/Organization';

type SubscriptionsFilters = {
	searchGeneral: string;
	status: CheckboxOption[];
	l3Organization: CheckboxOption[];
	type: CheckboxOption[];
	azureState: CheckboxOption[];
	dateRangeFrom: Date;
	dateRangeTo: Date;
	searchTenantName: CheckboxOption[];
	showResources: string;
	orderBy: string;
};

interface SubscriptionsHomeFiltersProps {
	setFilteredSubscriptions: any;
	areMySubsLoading: boolean;
	areAllSubsLoading: boolean;
	filteredSubscriptions: SubscriptionRequest[];
	retreiveSubscriptions: any;
	allSubscriptions: SubscriptionRequest[];
	setEditMultiple: any;
	editMultiple: boolean;
	setModalVisibleEditMode: any;
	checkBoxState: number;
	parentOrganizationsList: Organization[];
}

const SubscriptionsHomeFilters = ({
	setFilteredSubscriptions,
	areMySubsLoading,
	areAllSubsLoading,
	filteredSubscriptions,
	retreiveSubscriptions,
	allSubscriptions,
	setEditMultiple,
	editMultiple,
	setModalVisibleEditMode,
	checkBoxState,
	parentOrganizationsList,
}: SubscriptionsHomeFiltersProps) => {
	const navigate = useNavigate();
	const resourceTabs = [];
	const parentOrgs = [];

	parentOrganizationsList.forEach(org => {
		parentOrgs.push({
			label: org.shortName, 
			value: org.shortName, 
			checked: true 
		});
	});
	//adding Others option for Subscriptions that are still assigned to old Orgs
	parentOrgs.push({
		label: "Others", 
		value: "Others", 
		checked: true 
	});
	resourceTabs.push({
		item1 : `My Subscriptions`,
		item2: null,
	});
	authenticatedUser.isInRole(UserRole.OrgAdmins) && resourceTabs.push({
		item1: `Admin Subscriptions`,
		item2: null
	});
	resourceTabs.push({
		item1: `All Subscriptions`,
		item2: null
	});
	const defaultFilters = {
		searchGeneral: '',
		searchTenantName: [
			{ label: 'XOMALT', value: 'XOMALT', checked: true },
			{ label: 'UTCSProdMgmt', value: 'UTCSProdMgmt', checked: true },
			{ label: 'UTCSDevTst', value: 'UTCSDevTst', checked: true },
			{ label: 'XOMProdMgmt', value: 'XOMProdMgmt', checked: true },
			{ label: 'EMCloudAD', value: 'EMCloudAD', checked: true },
			{ label: 'EMITDevTst', value: 'EMITDevTst', checked: true },
			{ label: 'ExxonMobilTest', value: 'ExxonMobilTest', checked: true },
			{ label: 'Other', value: 'Other', checked: true },
		],
		status: [
			{ label: 'Submitted', value: 'Submitted', checked: true },
			{ label: 'Approved', value: 'Approved', checked: true },
			{ label: 'Rejected', value: 'Rejected', checked: true },
			{ label: 'Deleted', value: 'Deleted', checked: false },
			{ label: 'Error', value: 'Error', checked: false },
		],
		l3Organization: parentOrgs,
		type: [
			{ label: 'Connected', value: 'Connected', checked: true },
			{ label: 'Disconnected', value: 'Disconnected', checked: true },
		],
		azureState: [
			{ label: 'Enabled', value: 'Enabled', checked: true },
			{ label: 'Disabled', value: 'Disabled', checked: false },
			{ label: 'Deleted', value: 'Deleted', checked: false },
			{ label: 'Not Available', value: 'Not Available', checked: false },
		],
		dateRangeFrom: new Date('January 01, 2000 23:15:30'),
		dateRangeTo: new Date(),
		showResources: 'My Subscriptions',
		orderBy: 'newest',
	} as SubscriptionsFilters;

	const [isOrgAdmin] = useState<boolean>(authenticatedUser.isInRole(UserRole.OrgAdmins));

	const filterSubscriptions = () => {
		let filtered = [];

		const filterCallback = (sub: SubscriptionRequest) => {
			return (
				filter.filterCheckBox('status', sub.workflowInstance.currentWorkflowInstanceState.workflowState.name) &&
				((filterOthersOption('l3Organization',sub.organization.L3?.shortName ?? '(No organization)') ||
					filter.filterCheckBox('l3Organization', sub.organization.L3?.shortName ?? '(No organization)')) ) &&
				filter.filterCheckBox('type', sub.isConnected ? 'Connected' : 'Disconnected') &&
				(filter.filterSearchText('searchGeneral', sub.createdSubscriptionName) ||
					filter.filterSearchText('searchGeneral', sub.createdSubscriptionId) ||
					filter.filterSearchText('searchGeneral', sub.owner.displayName) ||
					filter.filterSearchText('searchGeneral', sub.custodian.displayName) ||
					filter.filterSearchText('searchGeneral', sub.subscriptionUsedFor) ||
					filter.filterSearchText('searchGeneral', sub.custodianTwo?.displayName ?? '')||
					(sub.cartaIds.length > 0 && filter.filterSearchTextInArray('searchGeneral', sub.cartaIds, 'cartaAppId'))
				) &&
				filter.filterCheckBox('searchTenantName', sub.tenant.name) &&
				filter.filterCheckBox('azureState', sub.azureState) &&
				filter.filterDateRange('dateRangeFrom', 'dateRangeTo', sub.workflowInstance.createdDate)  
				);
			
		}; 
		//Filter added for the subscriptions that are still assigned to old organizations
		const filterOthersOption = ( filterOption: string ,value : String ) => {
			let test = filter.defaultFilters[filterOption].find(filter => filter.value === 'Others' && filter.checked === true) ? true : false;
			return test ? !parentOrgs.some( (str) => (str.value === value) ) : false ;
		};

		filtered = allSubscriptions.filter(filterCallback);

		if (filter.filters.showResources == 'My Subscriptions') {
			filtered = filtered.filter(
				(sub: SubscriptionRequest) =>
					(sub.owner.id === authenticatedUser.userId ||
					sub.custodian.id === authenticatedUser.userId ||
					sub.custodianTwo?.id === authenticatedUser.userId)
			);
		}

		if (filter.filters.showResources == 'Admin Subscriptions') {
			filtered = filtered.filter(
				(sub: SubscriptionRequest) =>
					isOrgAdmin && authenticatedUser.myL3Orgs.includes(sub.organization?.L3?.displayName)
			);
		}

		//Disables Multiple edit functionality when user enable the functionality beeing in My / Admin subscriptions and then switch to All subscritions tab
		if (filter.filters.showResources == 'All Subscriptions') {
			setEditMultiple(false);
			setModalVisibleEditMode(false);
		}

		const nullState = 'Not Available';
		filtered.sort((a, b) => {
			const aa = a.azureState ? a.azureState : nullState;
			const bb = b.azureState ? b.azureState : nullState;
			if (aa === bb) {
				return filter.filters.orderBy === 'newest'
					? moment(b.workflowInstance.createdDate).valueOf() -
							moment(a.workflowInstance.createdDate).valueOf()
					: moment(a.workflowInstance.createdDate).valueOf() -
							moment(b.workflowInstance.createdDate).valueOf();
			}
			if (aa === 'Enabled') return -1;
			if (bb === 'Enabled') return 1;
			if (aa > bb) return 1;
			if (aa < bb) return -1;
			return 0;
		});

		setFilteredSubscriptions(filtered);
	};

	useEffect(() => {
		filter.updateFilterFunction(filterSubscriptions);
	}, [allSubscriptions]);

	useEffect(() => {
		filter.filter();
	}, []);

	const [filter] = useState<Filter<SubscriptionsFilters>>(new Filter(filterSubscriptions, defaultFilters));

	const handleRefresh = () => {
		setEditMultiple(false);
		filter.updateFilter('My Subscriptions', 'showResources');
		retreiveSubscriptions(true);
	};

	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchGeneral');
	};

	return (
		<>
			{filter && (
				<>
					<ResourceTabs
						allResources={resourceTabs}
						filter={filter}
						disabled={areMySubsLoading || areAllSubsLoading}
					/>
					<Toolbar id="toggle-toolbar">
						<Toolbar.Item className="searchbar-width">
							<Searchbar
								condensed
								buttonProps={{ onClick: clearSearchbar}}
								inputProps={{
									value: filter.filters.searchGeneral,
									onChange: (e) => filter.handleSearchTextChange(e, 'searchGeneral'),
									placeholder: 'Search (i.e. Subscription Name, Intended Usage, Type, etc.)',
								}}
							/>
						</Toolbar.Item>
						<Toolbar.Item right>
							<Button.Group>
								{(authenticatedUser.isInRole(UserRole.SubscriptionRequestor) ||
									authenticatedUser.isInRole(UserRole.DisconnectedSubscriptionRequestor)) && (
									<Button
										variant="primary"
										size="small"
										onClick={() => {
											navigate('/subscriptions/new');
										}}
									>
										<PlusIcon size="small" />
										<span>Request Subscription</span>
									</Button>
								)}
								{authenticatedUser.isInRole(UserRole.SubscriptionAdmin) && (
									<Button
										variant="primary"
										size="small"
										onClick={() => {
											navigate('/subscriptions/import');
										}}
									>
										<CheckInDoc2Icon size="small" />
										<span>Import Subscription</span>
									</Button>
								)}
								<CSVGeneratorButton data={filteredSubscriptions} columns={ExportColumns} />
								<Button variant="secondary" size="small" onClick={handleRefresh}>
									<RefreshIcon size="small" />
									<span> Refresh</span>
								</Button>
								<Button
										disabled={!authenticatedUser.isInRole(UserRole.SubscriptionAdmin) && !(filter.filters.showResources == 'My Subscriptions' || filter.filters.showResources == 'Admin Subscriptions') && !areMySubsLoading}
										variant="secondary"
										size="small"
										onClick={() => {
											setEditMultiple(!editMultiple);											
										}}
									>
										<EditIcon className="edit-icon" size="small" />
										{!editMultiple ? <span>Edit Multiple</span> : <span>Cancel Edition</span>}
								</Button>
								{editMultiple && 
									<Button
											disabled={checkBoxState == 0}
											variant="secondary"
											size="small"
											onClick={() => {
												setModalVisibleEditMode(true);								
											}}
										>
											<EditIcon className="edit-icon" size="small" />
											<span>Edit</span>
									</Button>
								}
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>

					<Toolbar>
						<Toolbar.Item>
							<CheckboxFilter
								label="Type"
								onChange={(event) => filter.handleCheckboxChange(event, 'type')}
								options={filter.filters.type}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<CheckboxFilter
								label="Status"
								buttonProps={{ size: 'small' }}
								onChange={(event) => filter.handleCheckboxChange(event, 'status')}
								options={filter.filters.status}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<CheckboxFilter
								label="Organization"
								onChange={(event) => filter.handleCheckboxChange(event, 'l3Organization')}
								options={filter.filters.l3Organization}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<CheckboxFilter
								label="Tenant Name"
								onChange={(event) => filter.handleCheckboxChange(event, 'searchTenantName')}
								options={filter.filters.searchTenantName}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<CheckboxFilter
								label="Azure State"
								onChange={(event) => filter.handleCheckboxChange(event, 'azureState')}
								options={filter.filters.azureState}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<span
								className="clear-filters"
								onClick={() =>
									filter.updateFilters({ ...defaultFilters, showResources: filter.filters.showResources })
								}
							>
								Clear filters
							</span>
						</Toolbar.Item>
						<Toolbar.Item right>
							<Button.Group>
								<p className="sort-by-p">Sort by</p>
								<Select
									style={{ marginBottom: -10, cursor: 'pointer' }}
									value={filter.filters.orderBy}
									onChange={(e) => filter.handleSearchTextChange(e, 'orderBy')}
								>
									<option value="newest">Newest</option>
									<option value="oldest">Oldest</option>
								</Select>
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>
				</>
			)}
		</>
	);
};

export default SubscriptionsHomeFilters;
