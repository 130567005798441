import React from 'react';
import { Link } from 'react-router-dom';
import { TreeNavigation } from 'react-unity';
import SubscriptionRequest from '../../../models/entities/SubscriptionRequest';

interface SubscriptionsHomeActionsRowProps {
	subscription: SubscriptionRequest;
	seePermissionsReport: (sub: SubscriptionRequest) => void;
	setModalVisibleDomainOperation: (visible: boolean) => any;
	setSubId: (subId: number) => void;
	setSubType: (subType: boolean) => void;
}

const SubscriptionsHomeActiosnRow = ({ subscription, seePermissionsReport, setModalVisibleDomainOperation, setSubId, setSubType }: SubscriptionsHomeActionsRowProps) => {

	const handleDomainOperationModal = () => {
		setSubId(subscription?.id)
		setModalVisibleDomainOperation(true);
		setSubType(subscription?.isConnected)
	}

	return (
		<TreeNavigation>
			{subscription.cansSeePermissionsReport() && (
				<TreeNavigation.Item label="Permissions Report" onClick={() => seePermissionsReport(subscription)} />
			)}
			{subscription?.canSeePortOpeningRequests() && (
				<TreeNavigation.Dropdown label="Port Opening Requests" className="submenu">
					{subscription.canSubmitPortOpeningRequests() && (
						<Link to={`/subscriptions/${subscription?.id}/portOpeningRequest/new`}>
							<TreeNavigation.Item label="Create a Request" />
						</Link>
					)}
					<Link to={`/subscriptions/${subscription?.id}/PortOpeningRequestsHistory`}>
						<TreeNavigation.Item label="Request History" />
					</Link>
					
				</TreeNavigation.Dropdown>
			)}
			{subscription?.canSeeDNDRequests() && ( //TODO: change to canSeeDoNotDecryptRequest
				<TreeNavigation.Dropdown label="DPI (Deep Packet Inspection) Exception Requests" className="submenu">
					{subscription.canSubmitPortOpeningRequests() && (
						<Link to={`/subscriptions/${subscription?.id}/doNotDecryptRequests/new`}>
							<TreeNavigation.Item label="Create a Request" />
						</Link>
					)}
					<Link to={`/subscriptions/${subscription?.id}/doNotDecryptRequestsHistory`}>
						<TreeNavigation.Item label="Request History" />
					</Link>
				</TreeNavigation.Dropdown>
			)}
			<Link to={`/nsg/${subscription?.createdSubscriptionId}`}>
				<TreeNavigation.Item label="Network Security Group" />
			</Link>
			{subscription?.canSeePolicyExemptionRequests() && (
				<TreeNavigation.Dropdown label="Policy Exception Requests" className="submenu">
					<Link to={`/subscriptions/${subscription?.id}/PolicyExemptionRequests/new`}>
						<TreeNavigation.Item label="Create a Request" />
					</Link>
					<Link to={`/subscriptions/${subscription?.id}/PolicyExemptionRequests`}>
						<TreeNavigation.Item label="Request History" />
					</Link>
				</TreeNavigation.Dropdown>
			)}
			{subscription?.canSeeSubscriptionAdminConsent() && (
				<Link to={`/subscriptions/${subscription?.id}/adminConsent`}>
					<TreeNavigation.Item label="Graph API Admin Consent" />
				</Link>
			)}
			{subscription.canSeeSubscriptionDomainOperation() && (
				<TreeNavigation.Item
					label="Request Domain Operation"
					onClick={() => handleDomainOperationModal()}
				/>
			)}
		</TreeNavigation>
	);
};

export default SubscriptionsHomeActiosnRow;
