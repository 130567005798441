import * as React from 'react';
import { Modal, Alert } from 'react-unity';
import './AlertModal.css';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';

interface AlertModalProps extends AlertBanner {
	willTimeout: boolean;
	onClose: (event: React.ChangeEvent<any>) => void;
}
interface AlertModalState {
}

export default class AlertModal extends React.Component<AlertModalProps, AlertModalState> {
	handleOnCloseAlert = () => {
		setTimeout(this.props.onClose, 1);
	};

	componentDidUpdate = () => {
		if (this.props.visible && this.props.willTimeout) {
			setTimeout(this.props.onClose, 4000);
		}
	};

	render() {
		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Alert className='alert' onClose={this.handleOnCloseAlert} variant={this.props.variant}> {this.props.text} </Alert>
			</Modal>
		);
	}
}
