import * as React from 'react';
import { TreeNavigation, AlertVariant } from 'react-unity';
import ChangeHistoryModal from './modals/ChangeHistoryModal';
import ChangeHistory from '../../models/entities/ChangeHistory';
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import ApplicationService from '../../services/ApplicationsService';
import { ObjectType } from '../../models/enums/ObjectType';
import EntityChangeHistoryService from '../../models/interfaces/EntityChangeHistoryService';


interface ChangeHistoryItemProps {
	id: any;
	type: ObjectType;
    service: EntityChangeHistoryService;
	handleAlert(variant: AlertVariant, text: string): void | undefined;
    handleAlertClose(): void | undefined;
}

interface ChangeHistoryItemState {
	changeHistoryModalVisible: boolean;
	changeHistoryData: ChangeHistory[];
	changeHistoryLoaded: boolean;
}

export default class ChangeHistoryItem extends React.Component<ChangeHistoryItemProps, ChangeHistoryItemState> {

    subscriptionService: SubscriptionRequestsService;

	applicationService: ApplicationService;

	constructor(props) {
    	super(props);
    	this.subscriptionService = new SubscriptionRequestsService();
		this.applicationService = new ApplicationService();
    	this.state = {
    		changeHistoryModalVisible: false,
    		changeHistoryData: [],
    		changeHistoryLoaded: false
    	};
	}

    handleChangeHistory = async () => {
    	if (!this.state.changeHistoryLoaded) {
    		try {
    			this.props.handleAlert('default', `Retrieving ${this.props.type.name}´s change history.`);
				
    			const allChanges = await this.props.service.getChangeHistory(this.props.id);

    			this.setState({
    				changeHistoryData: this.setChanges(allChanges),
    				changeHistoryLoaded: true,
    			}, this.handleChangeHistory);

    		} catch (err) {
				this.props.handleAlert('error', err.response?.data.message || `The ${this.props.type.name}´s change history could not be retrieved. Please, try again later.`);
    		}
    	} else if (!this.state.changeHistoryData || !this.state.changeHistoryData.length) {
    		this.props.handleAlert('default', `The ${this.props.type.name} has an empty change history.`);
    	} else {
    		this.props.handleAlertClose();
    		this.setState({ changeHistoryModalVisible: true });
    	}
    };

	setChanges = (changes: ChangeHistory[]) => {
		const finalChanges = changes.flatMap(change => {
			const c = new ChangeHistory(change);
			if (c.field !== 'multiple fields') return c;
			const oldValues = JSON.parse(c.oldValue);
			const newValues = JSON.parse(c.newValue);
			return Object.keys(oldValues).map(key => new ChangeHistory(c, key, 
				JSON.stringify(oldValues[key]), JSON.stringify(newValues[key])));
		});
		return finalChanges;
	};

	render() {
		return (
			<>
				<TreeNavigation.Item
				    label="Change History"
				    onClick={this.handleChangeHistory}
				/> 
				<ChangeHistoryModal
					visible={this.state.changeHistoryModalVisible}
					onClose={() => this.setState({ changeHistoryModalVisible: false })}
					changeHistory={this.state.changeHistoryData}
				/>
			</>
		);
	}
}