import React, { useState, useEffect } from 'react';
import {
	Table,
	TextPassage,
	TextLink,
	ProgressBar,
	AlertVariant,
	Alert,
} from 'react-unity';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import SectionHomeWrapper from '../../common/wrappers/SectionHomeWrapper';
import SubscriptionRequestsService from '../../../services/SubscriptionRequestsService';
import PermissionReportModal from '../components/PermissionReportModal';
import SubscriptionRequest from '../../../models/entities/SubscriptionRequest';
import './SubscriptionsHome.css';
import Organization from '../../../models/entities/Organization';
import Paginator from '../../common/tables/Paginator';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import AlertModal from '../../common/modals/AlertModal';
import SubscriptionHomeTableRow from './SubscriptionHomeTableRow';
import SubscriptionsHomeFilters from './SubscriptionsHomeFilters';
import SubscriptionsEditModal from './SubscriptionsEditModal';
import { getOrganizations } from '../../common/OrganizationStorage';
import SubDomainOperationModal from '../DomainOperation/SubDomainOperationModal';

const memoized = {
	mySubs: void 0,
	myAdminSubs: void 0,
	allSubs: void 0,
}

const SubscriptionsHome = () => {
	const [allSubscriptions, setAllSubscriptions] = useState<SubscriptionRequest[]>([]);
	const [filteredSubscriptions, setFilteredSubscriptions] = useState<SubscriptionRequest[]>([]);
	const [mySubscriptions, setMySubscriptions] = useState<SubscriptionRequest[]>([]);
	const [subscriptionsOnPage, setSubscriptionsOnPage] = useState<SubscriptionRequest[]>([]);
	const [permissionReportModalVisible, setPermissionReportModalVisible] = useState<boolean>(false);
	const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionRequest>(null);
	const [areMySubsLoading, setAreMySubsLoading] = useState<boolean>(true);
	const [areAllSubsLoading, setAreAllSubsLoading] = useState<boolean>(true);
	const [progressLabel, setProgressLabel] = useState<string>('Loading your subscriptions...');
	const [endModal, setEndModal] = useState<AlertBanner>({ visible: false });
	const [modalVisibleEditMode, setModalVisibleEditMode] = useState<boolean>(false);
	const [alertEditMode, setAlertEditMode] = useState<boolean>(false);
	const [editMultiple, setEditMultiple] = useState<boolean>(false);
	const [alert, setAlert] = useState<AlertBanner>({
		visible: false,
		variant: 'error',
		text: ''
	});
	const [modalVisibleDomainOperation, setModalVisibleDomainOperation] = useState<boolean>(false);
	const [subId, setSubId] = useState<number>(null);
	const [subType, setSubType] = useState<boolean>(null);
	const [parentOrganizationsList, setParentOrganizationsList] = useState<Organization[]>([]);

	const subscriptionRow: React.RefObject<HTMLBaseElement> = React.createRef();
	const subscriptionRequestsService = new SubscriptionRequestsService();
	const navigate = useNavigate();
	const handleModalEditModeClosed = () => {
		setModalVisibleEditMode(false);
		setEditMultiple(false);
	};

	async function getSubs(method: string, key: string) {
		if (typeof memoized[key] !== 'undefined') return memoized[key];
		let data = [];
		data = await subscriptionRequestsService[method]();
		memoized[key] = data;
		return data;
	}

	function refreshCache() {
		memoized.mySubs = void 0;
		memoized.myAdminSubs = void 0;
		memoized.allSubs = void 0;

	}

	const [checkBoxState, setCheckBoxState] = useState(0);

	useEffect(() => {
		retreiveSubscriptions(false);
	}, []);

	const seePermissionsReport = (subscription: SubscriptionRequest) => {
		setSelectedSubscription(subscription);
		setPermissionReportModalVisible(true);
	};

	const retreiveSubscriptions = async (isRefresh: boolean) => {
		setAreMySubsLoading(true);
		setAreAllSubsLoading(true);
		setProgressLabel(`${isRefresh ? 'Reloading' : 'Loading'} your subscriptions...`);

		try {
			if (isRefresh) refreshCache();

			const mySubs = await getSubs('getMySubscriptions', 'mySubs');
			const organizations = (await getOrganizations()).map((org) => new Organization(org));
			setParentOrganizationsList(organizations.filter((organization) => organization.parentOrganizationId == null && organization.status == 'Active'));
			setMySubscriptions(mySubs);
			setAllSubscriptions(mapSubscriptions(mySubs, organizations));
			setAreMySubsLoading(false);
			setProgressLabel(`${isRefresh ? 'Reloading' : 'Loading'} all subscriptions...`);

			const allSubscriptions = await getSubs('getAll', 'allSubs');
			setAllSubscriptions(mapSubscriptions(allSubscriptions, organizations));
			setAreAllSubsLoading(false);
		} catch (err) {
			handleEndModal('The subscriptions could not be retrieved.', 'error', 10000);
		}
	};

	const mapSubscriptions = (subscriptions: SubscriptionRequest[], organizations: Organization[]) => {
		const nullState = 'Not Available';
		subscriptions.sort((a, b) => {
			const aa = a.azureState ? a.azureState : nullState;
			const bb = b.azureState ? b.azureState : nullState;
			if (aa === bb) {
				return (
					moment(b.workflowInstance.createdDate).valueOf() - moment(a.workflowInstance.createdDate).valueOf()
				);
			}
			if (aa === 'Enabled') return -1;
			if (bb === 'Enabled') return 1;
			if (aa > bb) return 1;
			if (aa < bb) return -1;
			return 0;
		});
		return subscriptions.map((result) => {
			const subscription = new SubscriptionRequest(result);
			subscription.organization?.setLevels(organizations);
			if (subscription.azureState == null) {
				subscription.azureState = nullState;
			}
			return subscription;
		});
	};

	const handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		setEndModal({
			visible: true,
			text,
			variant,
		});
		setTimeout(() => endAndRedirect(), timeout);
	};

	const endAndRedirect = () => {
		setEndModal({ visible: false });
		navigate('/');
	};

	const getFilterProps = () => {
		return {
			setFilteredSubscriptions,
			areMySubsLoading,
			areAllSubsLoading,
			filteredSubscriptions,
			retreiveSubscriptions,
			allSubscriptions,
			setEditMultiple,
			editMultiple,
			subscriptionsOnPage,
			setModalVisibleEditMode,
			checkBoxState,
			parentOrganizationsList,
		};
	};

	const handleRefresh = () => {
		retreiveSubscriptions(true);
	};

	const handleModalEditModeConfirmed = async () => {
		setModalVisibleEditMode(false);
		setAlertEditMode(true);
		setTimeout(() => {
			setAlertEditMode(false);
		}, 5000);
		handleRefresh();
	};

	const handleModalEditModeCancel = async () => {
		setTimeout(() => {
			setModalVisibleEditMode(false);
		}, 5000);
		handleRefresh();
	};

	return (
		<>
			<SectionHomeWrapper title="Subscriptions">
				<TextPassage>
					<p>
						For more information, please follow {' '}
						<TextLink
							target="_blank" external href="https://gotocloud.xom.cloud/subscription-basics/"
						>
							this link
						</TextLink>
					</p>
				</TextPassage>
				{alert.visible &&
					<Alert
						variant={alert.variant}
						onClose={() => {
							setAlert({
								visible: false,
							});
						}}
					>
						{alert.text}
					</Alert>}
				{(areMySubsLoading || areAllSubsLoading) && (
					<ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label={progressLabel} />
				)}
				{!areMySubsLoading && (
					<>
						<SubscriptionsHomeFilters {...getFilterProps()} />
						<Table
							footer={
								<Paginator
									data={filteredSubscriptions}
									totalItems={allSubscriptions.length}
									onPageChange={(subs) => {
										setSubscriptionsOnPage(subs);
									}}
								/>
							}
						>
							<Table.Head>
								<Table.Head.Row >
									{editMultiple && <Table.Head.Cell> </Table.Head.Cell>}
									<Table.Head.Cell className='table__header-cell'> Subscription Name </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Type </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Status </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Organization (L3/L4) </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Owner </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Custodian </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Custodian Two </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Azure State </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Requested Date </Table.Head.Cell>
									<Table.Head.Cell > </Table.Head.Cell>
								</Table.Head.Row>
							</Table.Head>
							<Table.Body>
								{subscriptionsOnPage.map((sub) => (
									<SubscriptionHomeTableRow
										key={sub.id}
										subscription={sub}
										seePermissionsReport={seePermissionsReport}
										SubscriptionRowRef={subscriptionRow}
										editMultiple={editMultiple}
										setCheckBoxState={setCheckBoxState}
										checkBoxState={checkBoxState}
										setModalVisibleDomainOperation={setModalVisibleDomainOperation}
										setSubId={setSubId}
										setSubType={setSubType}
									/>
								))}
							</Table.Body>
						</Table>
					</>
				)}
			</SectionHomeWrapper>
			<AlertModal {...endModal} willTimeout={false} onClose={endAndRedirect} />
			<PermissionReportModal
				visible={permissionReportModalVisible}
				onClose={() => setPermissionReportModalVisible(false)}
				subscription={selectedSubscription}
			/>
			{modalVisibleEditMode && <SubscriptionsEditModal
				visible={modalVisibleEditMode}
				mySubscriptions={subscriptionsOnPage}
				onConfirm={handleModalEditModeConfirmed}
				onClose={handleModalEditModeClosed}
				setCheckBoxState={setCheckBoxState}
				onCancel={handleModalEditModeCancel}
				setAlert={setAlert}
			/>}
			<SubDomainOperationModal
				subId={subId}
				visible={modalVisibleDomainOperation}
				onConfirm={() => setModalVisibleDomainOperation(false)}
				onClose={() => setModalVisibleDomainOperation(false)}
				isConnected={subType}
			/>
		</>
	);
};

export default SubscriptionsHome;
