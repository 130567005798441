import httpclient from 'typescript-http-client';
import NetworkSecurityGroup from '../models/entities/NetworkSecurityGroup';
import ApiClient from './ApiClient';
import { getToken } from '../authentication/authModule';

export default class NetworkSecurityGroupService extends ApiClient{

    async searchNSGs(subId: string, rgname: string, nsgname: string) {
		return this.get<NetworkSecurityGroup[]>(`networkSecurityGroup/nsgs/?subId=${subId}&resoruceGroupName=${rgname}&networkSecurityGroupName=${nsgname}`);
	}

	async downloadNSGBlob(downloadUrl: string) {
		const client = httpclient.newHttpClient();
		const request = async<T>(method: string, resourceUrl: string, body?: any) => {
			const token = await getToken();
			const request = new httpclient.Request(resourceUrl, {
				method,
				contentType: 'application/json',
				body,
				timeout:200000,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				responseType: 'arraybuffer',
			});
			return client.execute<T>(request);
		};
		return request<any>('GET', `${downloadUrl}`);
	}
}