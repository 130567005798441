import React from 'react';
import { Button, Loader, ButtonVariant, ButtonSize, ButtonColor } from 'react-unity';

interface ButtonProps {
    children?: any;
    isLoading: boolean;
    variant: ButtonVariant;
    handleUpdateSubmit: any;
    disabled: boolean;
    name: string;
    loadingVariant: ButtonVariant;
    size?: ButtonSize;
    color?: ButtonColor;
}

const ReactButton = ( props: ButtonProps ) => {
    const size = props.size ? props.size : 'medium';
    const buttonStyle = {borderRadius: '4px'}
	return (
        <>
            {!props.isLoading ?
                <Button
                    color={props.color}
                    style={buttonStyle}
                    variant={props.variant}
                    onClick={props.handleUpdateSubmit}
                    disabled={props.disabled}
                    size={size}
                >
                    <>
                        {props.children}
                    </>
                    {props.name}
                </Button>

                :
                <Button
                    color={props.color}
                    variant={props.loadingVariant}
                    style={buttonStyle}
                    size={props.size}
                    disabled={props.disabled}
                >
                    <Loader className="em-u-margin em-c-icon--medium" />
                </Button>
            }
		</>
	);
};

export default ReactButton;