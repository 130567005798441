import React from 'react';
import { Link } from 'react-router-dom';

export type LinkTreeItem = { to: string, text: string };

interface LinkTreeProps {
	links: LinkTreeItem[]
}

export class LinkTree extends React.Component<LinkTreeProps> { 
	render() {
		return (
			<ol className="em-c-breadcrumbs" role="navigation">
				{this.props.links.map((link: LinkTreeItem, index: number) => (
					<li className="em-c-breadcrumbs__item" key={index}>
						<Link className="em-c-breadcrumbs__link " to={link.to}>{link.text}</Link>
					</li>
				))}
				<li className="em-c-breadcrumbs__item" key={this.props.links.length} />
			</ol>
		);
	}
}