import * as React from 'react';
import UserLookup from '../../common/form-controls/UserLookup';
import FieldHandler from '../../../models/interfaces/FieldHandler';

interface ApproverInputProps extends FieldHandler<string> {
	higherApproval: boolean;
	scope?: string;
}

interface ApproverInputState {}

export class ApproverInput extends React.Component<ApproverInputProps, ApproverInputState> {

	// TODO: When Moratorium Period ends, switch this to false
	moratoriumPeriodEnabled = false;

	doagLevel = () => {
		var level = (this.props.higherApproval ? 6 : 7);
		this.moratoriumPeriodEnabled && level--;

		return level;
	};

	render() {
		return (
			<UserLookup
				label={`Approver (DOAG ${this.doagLevel().toString()})`}
				isEmployee
				note='Select your functional management’s authority'
				{...this.props}
			/>
		);
	}
}
