import moment from "moment";
import SubscriptionRequest from "../../../models/entities/SubscriptionRequest";

const exportColumns = [
    {
        header: 'Subscription Name',
        cell: (subscription: SubscriptionRequest) => subscription.createdSubscriptionName,
    },
    {
        header: 'Request Status',
        cell: (subscription: SubscriptionRequest) =>
            subscription.workflowInstance.currentWorkflowInstanceState.workflowState.name,
    },
    {
        header: 'Requested By',
        cell: (subscription: SubscriptionRequest) => subscription.workflowInstance.createdBy.email?.address,
    },
    {
        header: 'Requested Date',
        cell: (subscription: SubscriptionRequest) =>
            moment(subscription.workflowInstance.createdDate).format('MM/DD/YYYY'),
    },
    { header: 'Platform', cell: (subscription: SubscriptionRequest) => subscription.platform.name },
    { header: 'Tenant', cell: (subscription: SubscriptionRequest) => subscription.tenant.name },
    {
        header: 'Status in Azure',
        cell: (subscription: SubscriptionRequest) => subscription.azureState || 'Not Available',
    },
    {
        header: 'Subscription GUID',
        cell: (subscription: SubscriptionRequest) => subscription.createdSubscriptionId,
    },
    {
        header: 'Subscription Type',
        cell: (subscription: SubscriptionRequest) => (subscription.isConnected ? 'Connected' : 'Disconnected'),
    },
    { header: 'Environment', cell: (subscription: SubscriptionRequest) => subscription.environment.name },
    {
        header: 'OrgL3',
        cell: (subscription: SubscriptionRequest) => {
            if (!subscription.organization?.L3) return 'No organization';
            return `${subscription.organization?.L3.displayName}`;
        },
    },
    {
        header: 'OrgL4',
        cell: (subscription: SubscriptionRequest) => {
            if (!subscription.organization.L4) return 'No organization';
            return `${subscription.organization?.L4.displayName}`;
        },
    },
    {
        header: 'OrgL5',
        cell: (subscription: SubscriptionRequest) => {
            if (!subscription.organization.L5) return 'No organization';
            return `${subscription.organization?.L5.displayName}`;
        },
    },
    {
        header: 'CostCenter',
        cell: (subscription: SubscriptionRequest) => {
            var L4CC = subscription.organization.L4?.costCenter;
            if (!subscription.organization.L5) {
                if (!L4CC || L4CC == null)
                    return 'No CC'
                else
                    return `${L4CC}`
            }
            else {
                var L5CC = subscription.organization.L5?.costCenter;
                if ((!L5CC || L5CC == null) && (!L4CC || L4CC == null))
                    return 'No CC'
                else if ((!L5CC || L5CC == null))
                    return `${L4CC}`
                else
                    return `${L5CC}`
            }
        },
    },
    {
        header: 'Budget',
        cell: (subscription: SubscriptionRequest) => {
            if (subscription.subsBudget == null) return '';
            else return `${subscription.subsBudget}`;
        },
    },
    { header: 'Owner', cell: (subscription: SubscriptionRequest) => subscription.owner?.email?.address },
    { header: 'Custodian', cell: (subscription: SubscriptionRequest) => subscription.custodian?.email?.address },
    {
        header: 'CustodianTwo',
        cell: (subscription: SubscriptionRequest) => subscription.custodianTwo?.email?.address,
    },
    {
        header: 'Project Usage',
        cell: (subscription: SubscriptionRequest) => (subscription.isProject ? 'Project / SWI' : 'Base'),
    },
    { header: 'WPM Code', cell: (subscription: SubscriptionRequest) => subscription.wpmCode },
    { header: 'Close Out Date', cell: (subscription: SubscriptionRequest) => subscription.closeOutDate },
    {
        header: 'Carta IDs',
        cell: (subscription: SubscriptionRequest) => {
            const { cartaIds } = subscription;

            if (cartaIds.length === 1 && !cartaIds[0].cartaAppId && !!cartaIds[0].comment) return 'No DBM/ID';
            return cartaIds.map((cartaId) => cartaId.cartaAppId).join(', ');
        },
    },
    { header: 'Intended Usage', cell: (subscription: SubscriptionRequest) => subscription.subscriptionUsedFor },
];

export default exportColumns;