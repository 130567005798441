import ApiClient from './ApiClient';
import SubmitFeedback from '../models/viewModels/Feedback/SubmitFeedback';
import Feedback from '../models/entities/Feedback';
import UpdateFeedback from '../models/viewModels/Feedback/UpdateFeedback';

export default class PortOpeningRequestService extends ApiClient {

	async GetAllFeedback() {
		return this.get<Feedback[]>(`Feedback`);
	}

	async GetMyFeedback() {
		return this.get<Feedback[]>(`Feedback/MyFeedback`);
	}

	async GetFeedbackById(Id: number) {
		return this.get<Feedback>(`Feedback/${Id}`);
	}

	async GetFeedbackSubmitedBy(userId: string) {
		return this.get<Feedback[]>(`Feedback/submitter/${userId}`);
	}

	async SubmitFeedback(feedbackModel: SubmitFeedback) {
		return this.post<Feedback>(`Feedback`, feedbackModel);
	}

	async UpdateFeedback(Id: number, updateFeedbackModel: UpdateFeedback) {
		return this.patch<Feedback>(`Feedback/${Id}`, updateFeedbackModel);
	}
}