import * as React from 'react';
import './RequestFormWrapper.css';
import { Container, ProgressBar, Section } from 'react-unity';
import { LinkTreeItem, LinkTree } from '../LinkTree';

interface RequestFormWrapperProps {
	title: string;
	passage?: any;
	linkTree?: LinkTreeItem[];
	loading?: boolean;
	children?: any;
}

export default class RequestFormWrapper extends React.Component<RequestFormWrapperProps> {
	render() {
		return (
			<Container className="em-u-margin-top-double em-u-margin-bottom-double">
				{this.props.linkTree?.length > 0 && !this.props.loading &&
					<LinkTree links={this.props.linkTree} />}
				<div id={localStorage.getItem('theme') === 'default' ? 'content' : null}>
					<Section title={this.props.title} underline="main" className="em-u-padding">
						{!!this.props.passage &&
							<header className="em-c-section__header">
								{this.props.passage}
							</header>}
						{this.props.loading ?
							<ProgressBar
								label='Loading form...'
								className="em-u-margin-top-half"
								indeterminate
								hideValueLabel
							/> :
							<Container className="content-body">
								{this.props.children}
							</Container>}
					</Section>
				</div>
			</Container>
		);
	}
}
