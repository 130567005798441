import moment from "moment";
import EnterpriseApp from '../../../models/entities/EnterpriseApp';
import { ExpirationAction } from "../../../models/enums/AdApps/ExpirationAction";
import ExpirationDateInput from "./ExpirationDateInput";


const exportColumns = [
    {
        header: 'Application Name',
        cell: (application: EnterpriseApp) => application.displayName,
    },

    { header: 'Owner', cell: (application: EnterpriseApp) => application.owner?.email?.address },

    {
        header: 'Status',
        cell: (application: EnterpriseApp) =>
            application.workflowInstance.currentWorkflowInstanceState.workflowState.name,
    },
    {
        header: 'Application Type',
        cell: (application: EnterpriseApp) => (application.expirationDate ? 'Temporary' : 'Permanent'),
    },

    {
        header: 'Expiration Day',
         cell: (application: EnterpriseApp) => application.expirationDate?.toString() ?? 'No date',
    },

    { header: 'Environment', cell: (application: EnterpriseApp) => application.environment.name },

    {
        header: 'Carta IDs',
        cell: (application: EnterpriseApp) => {
            const cartaIds  = application.cartaIds;

            if (cartaIds.length === 1 && !cartaIds[0].cartaAppId && !!cartaIds[0].comment) return 'No DBM/ID';
            return cartaIds.map((cartaId) => cartaId.cartaAppId).join(', ');
        },
    },

    {
        header: 'Requested By',
        cell: (application: EnterpriseApp) => application.workflowInstance.createdBy.email?.address,
    },
    {
        header: 'Requested Date',
        cell: (application: EnterpriseApp) =>
            moment(application.workflowInstance.createdDate).format('MM/DD/YYYY'),
    },

    {
        header: 'Current Status',
        cell: (application: EnterpriseApp) => application.azureState || 'Not Available',
    },
    {
        header: 'Application Azure Id',
        cell: (application: EnterpriseApp) => application.applicationId},
];

export default exportColumns;