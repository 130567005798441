import React, { useState } from 'react';
import {
    Table,
    Button,
} from 'react-unity';
import EnterpriseApp from '../../models/entities/EnterpriseApp';
import ApplicationsService from '../../services/ApplicationsService';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../../models/enums/UserRole';
import './ApprovalsEntraIDAppsTableRow.css'


interface ApprovalsEntraIDAppsTableRowProps {
    enterpriseApp: EnterpriseApp;
    setAlert: any;
    setRefresh: any;
    refresh: boolean;
}

const ApprovalsEntraIDAppsTableRow = ({ enterpriseApp, setAlert, setRefresh, refresh}: ApprovalsEntraIDAppsTableRowProps) => {

    const applicationsService = new ApplicationsService();

    const handleReject = async () => {
        try {
            setAlert({ text: 'Processing your request...', visible: true });

            await applicationsService.rejectOwnershipOfApplication(enterpriseApp.applicationId);
            setRefresh(!refresh)

            setAlert({ variant: 'warning', text: 'You have rejected the ownership proposal for the application.', visible: true });
        } catch (error) {
            setAlert({ variant: 'error', text: error.response?.data.message ?? 'An error occured when processing your request. Please, try again later.', visible: true });
        }
    }

    const handleAccept = async () => {
        try {
            setAlert({ text: 'Processing your request...', visible: true });

            await applicationsService.acceptOwnershipOfApplication(enterpriseApp.applicationId);
            setRefresh(!refresh)

            setAlert({ variant: 'success', text: 'You have successfully took the application ownership.', visible: true });
        } catch (error) {
            setAlert({ variant: 'error', text: error.response?.data.message ?? 'An error occured when processing your request. Please, try again later.', visible: true });
        }
    }
    return (
        <tr>
            <Table.Body.Cell>
                {enterpriseApp.displayName}
            </Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center'>
                {enterpriseApp.owner.displayName}
            </Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center'>
                {enterpriseApp.newOwner?.displayName}
            </Table.Body.Cell>
            <Table.Body.Cell className='center-align'>
                <Button.Group>
                    <Button
                        size='small'
                        color='positive'
                        variant="primary"
                        disabled={(authenticatedUser.isInRole(UserRole.ApprovalsViewer) && enterpriseApp.newOwner.id !== authenticatedUser.userId)}
                        onClick={handleAccept}
                    >Accept
                    </Button>
                    <Button
                        size='small'
                        color='negative'
                        variant="primary"
                        disabled={(authenticatedUser.isInRole(UserRole.ApprovalsViewer) && enterpriseApp.newOwner.id !== authenticatedUser.userId)}
                        onClick={handleReject}
                    >Reject
                    </Button>
                </Button.Group>
            </Table.Body.Cell>
        </tr>
    );
}

export default ApprovalsEntraIDAppsTableRow;