import React, { useState, useEffect } from 'react';
import { Modal, XFilledIcon, Alert, Grid, Button } from "react-unity";

export interface TrashcanModalProps {
	onDelete: () => any;
	onRemove: () => any;
    onClose: () => any;
}

const TrashcanModal = (trashcanModalProps: TrashcanModalProps) => {
    return (
            <Modal show={true}>
				<Modal.Window>
					<Modal.Window.Header>
						<Modal.Window.Header.Title>Delete/Remove Rule</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={trashcanModalProps.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
                        You can: 
						<ul style={{listStyleType: 'disc', marginLeft: 30}}>
							<li><b>DELETE</b> the rule from the subscription</li>
							<li><b>REMOVE</b> the rule from the grid</li>
						</ul> 
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Grid variant="2-up">
                            <Grid.Item>
								<Button variant="primary" onClick={trashcanModalProps.onDelete}>
									DELETE FROM SUBSCRIPTION
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Button variant="secondary" color="accent" onClick={trashcanModalProps.onRemove}>
									REMOVE FROM REQUEST
								</Button>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
    );
}

export default TrashcanModal;