import * as React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Grid, XFilledIcon, ImageWithCaption, Alert } from 'react-unity';
import NewDNDRInfoImage from '../../../images/newDNDRInfoImage.png';
import './NewDNDRInfoModal.css';

interface NewDNDRInfoModalProps {
	visible: boolean;
	onClose: (event: React.ChangeEvent<any>) => void;
}

interface NewDNDRInfoModalState {
}

export default class NewDNDRInfoModal extends React.Component<
	NewDNDRInfoModalProps,
	NewDNDRInfoModalState
	> {

	render() {
		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Modal.Window id="PORInfoModal" className="em-u-margin-double">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>New DPI (Deep Packet Inspection) Exception Request</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<Alert
							variant="warning"
							className="no-alert-actions"
						>
							For DPI (Deep Packet Inspection) Exception Requests please go to
							the subscriptions page and follow the instructions
							in the image below.
						</Alert>
						<ImageWithCaption
							src={NewDNDRInfoImage}
							caption=""
						/>
					</Modal.Window.Body>					
					<Modal.Window.Footer>
						<Grid variant="2-up">
							<Grid.Item>
								<Button variant="secondary" onClick={this.props.onClose}>
									Cancel
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Link to="/subscriptions" className="redirect-button">
									<Button
										variant="primary"
										className="redirect-button"
									>
									Go to Subscriptions
									</Button>
								</Link>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}
