import React, { useEffect, useState } from 'react';
import {
	Modal,
	Button,
	Grid,
	XFilledIcon,
	Alert,
	TextArea,
	Checkbox,
	TextInput,
	Radio,
	ProgressBar,
	TextPassage,
	InfoIcon,
} from 'react-unity';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import './CloudGroupsModal.css';
import GroupAccessReview from '../../../models/GroupAccessReview';
import { DatePicker } from 'react-unity';
import Group from '../../../models/Group';
import GroupsService from '../../../services/GroupsService';
import SelectField from '../../common/form-controls/SelectField';
import moment from 'moment';

interface CloudGroupsModalProps {
	visible: boolean;
	onConfirm: (event: React.ChangeEvent<any>) => void;
	onCancel?: (event: React.ChangeEvent<any>) => void;
	onClose: (event: React.ChangeEvent<any>) => void;
	groupSelected: Group;
}

const options = [
	{value: 0, text: 'One Time'},
	{value: 1, text: 'Quarterly'},
	{value: 2, text: 'Semi-Annually'},
	{value: 3, text: 'Annually'},
];

const CloudGroupsAccessReviewModal = ({ visible, onConfirm, onClose, groupSelected } : CloudGroupsModalProps) => {
	const [ groupsService ] = useState<GroupsService>(new GroupsService());
	const [ form, setForm ] = useState({
		name: {
			value: ' ',
			assert: (e) => {return(e.length >= 1)},
			message: 'The name must not be empty',
		},
		duration: {
			value: 15,
			assert: (e) => {return(e >= 1 && e <= 80)},
			message: 'The duration must be between 1 and 80',
		},
		recurrence: options[0].value,
		occurrences: {
			value: 1,
			assert: (e) => {return(e >= 1 && e <= 99999)},
			message: 'The occurrences must be 1 or less than 5 digits',
		},
		description: {
			value: '',
			assert: (e) => {return(e.length >= 1 && e.length <= 220)},
			message: 'The description must be more than 1 and less than 220 caracters',
		},
	});
	const [ startDate, setStartDate ] = useState({
		value: new Date(),
		assert: (e) => {return(moment(e).diff(new Date(), 'days') >= 0)},
		message: 'Starting Date should not be prior than today.',
	});
	const [ endDate, setEndDate ] = useState({
		value: new Date(),
		assert: (end, start) => {return(end.getTime() > start.getTime())},
		message: 'End Date should not be prior than Starting Date.',
	});
	const [ submitting, setSubmitting ] = useState(false);
	const [ alert, setAlert ] = useState<AlertBanner>({
										visible: false,
										variant: 'error',
										text: ''
									});
	const [ isEndDate, setIsEndDate ] = useState(false);
	const [ never, setNever ] = useState(true);
	const [ checkboxOptions, setCheckBoxChecked ] = useState([
							{ id: 1, label: "Never", value: "never", method: 0, checked: true },
							{ id: 2, label: "End on Specific Date", value: "specific-date", method: 1, checked: false },
							{ id: 3, label: "End after number of occurrences", value: "occurrences", method: 2, checked: false },
						]);
	const [ optionSelected, setOptionSelected ] = useState(checkboxOptions[0].method);

	const isValid = () => {
		if(form.duration.assert(form.duration.value) && startDate.assert(startDate.value) && form.description.assert(form.description.value)){
			if(!never && form.recurrence !== 0){
				if(isEndDate) return endDate.assert(endDate.value,startDate.value);
				return form.occurrences.assert(form.occurrences.value);
			}
			return true;
		}
		return false;
	}

	const handleCheck = (option) => {
		option.value !== "never" ? setNever(false) : setNever(true);
		option.value === "specific-date" ? setIsEndDate(true) : setIsEndDate(false);
		setCheckBoxChecked(
			checkboxOptions.map((o) =>
			  o.id == option.id ? { ...o, checked: !o.checked } : { ...o, checked: false }
			)
		  );
		checkboxOptions.map((o) =>{
			o.id == option.id && setOptionSelected(o.method)
		})
	}


	const resetState = () => {
		setForm({...form, 
			name: {...form.name, value: `${groupSelected.displayName}_${options[0].text}`},
			duration: {...form.duration, value: 80},
			recurrence: options[0].value,
			occurrences: {...form.occurrences, value: null},
			description: {...form.description, value: ''},
		});
		setStartDate({...startDate, value: new Date()});
		setEndDate({...endDate, value: new Date()});
		setSubmitting(false);
		setAlert({visible: false});
	}

	const handleRequest = async (e: any) => {
		try {
			setSubmitting(true);
			const accessReview = new GroupAccessReview({
				name: form.name.value,
				description: form.description.value,
				duration: form.duration.value,
				startingDate: startDate.value,
				recurrence: form.recurrence,
				endMethod: optionSelected,
				endOccurrency: form.occurrences.value,
				endDate: endDate.value,
			});
			await groupsService.createGroupAccessReview(`${groupSelected.id}`, accessReview);
			onConfirm(e);
			resetState();

		} catch (err) {
			setSubmitting(false);
			setAlert({
				visible: true,
				variant: 'error',
				text: `${err.body.code}:${err.body.message}`
			});
		}

	};

	const handleCancel = (e: any) => {
		resetState();
		onClose(e);
	};

	const handleSelect = (e) => {
		if(form.name.value.substring(0, form.name.value.indexOf("_")) != ''){
			options.map((o) =>{
				o.value == e.target.value && setForm({ ...form, recurrence: parseInt(e.target.value, 10), name: {...form.name, value: `${form.name.value.substring(0, form.name.value.indexOf("_"))}_${o.text}`}});
			})
		}else{
			options.map((o) =>{
				o.value == e.target.value && setForm({ ...form, recurrence: parseInt(e.target.value, 10), name: {...form.name, value: `${form.name.value}_${o.text}`}});
			})
		}
	}

	const setAccessReviewName = () => {
		setForm({ ...form, name: {...form.name, value: `${groupSelected?.displayName}_${options[0].text}`}});
		return form.name.value;
	};

	useEffect(() => {
		setAccessReviewName()
	}, [groupSelected])

	if(groupSelected === null){
		return(<></>);
	};


	return (
		<Modal show={visible}>
			<Modal.Window id="cloud-groups-modal">
				<Modal.Window.Header>
					<Modal.Window.Header.Title>Create Access Review</Modal.Window.Header.Title>
					<Modal.Window.Header.CloseButton onClick={onClose}>
						<XFilledIcon size='small' />
					</Modal.Window.Header.CloseButton>
				</Modal.Window.Header>
				<Modal.Window.Body>
				{submitting ? <ProgressBar indeterminate hideValueLabel label="Creating your Access Review..." /> :
				<>
					<Grid variant='3-up'>
						<Grid.Item>
							<DatePicker
								id='startDateInput'
								label="Review Start Date"
								value={moment(startDate.value).format("MMMM DD yyyy")}
								valid={startDate.assert(startDate.value)}
								error={!startDate.assert(startDate.value)}
								onChange={(e) => setStartDate({...startDate, value: e.target.value})}
								note={!startDate.assert(startDate.value) ? startDate.message : ''}
							/>
						</Grid.Item>
						<Grid.Item>
							<TextInput
							id='durationInput'
							label="Duration (in days)"
							value={form.duration.value.toString()}
							valid={form.duration.assert(form.duration.value)}
							error={!form.duration.assert(form.duration.value)}
							onChange={(e) => setForm({ ...form, duration: {...form.duration, value: e.target.value}})}
							note={!form.duration.assert(form.duration.value) ? form.duration.message : ''}
							/>
						</Grid.Item>
						<Grid.Item>
							<SelectField
							label="Set Review Frequency"
							value={form.recurrence}
							onChange={(e) => handleSelect(e)}
							options={options}
							/>
						</Grid.Item>
					</Grid>
					<TextPassage>
						<>
							<div id="text-passage">
								<InfoIcon className="info-icon"/>
								Please take into account:
							</div>
							Duration is how many days a review is going to be open for input
						</>
					</TextPassage>
					{form.recurrence !== 0 &&
					<Grid>
						<Grid.Item>
							<Checkbox.Group
								label="End Recurrence"
								>
								{checkboxOptions.map(option => (
									<Radio
									style={{ borderBottom: '0px solid blue', borderTop: '0px solid blue'}}
										key={option.id}
										value={option.id}
										label={option.label}
										checked={option.checked}
										onChange={() => handleCheck(option)}
									/>
								))}
							</Checkbox.Group>
						</Grid.Item>
						<Grid.Item>
							{!never && <> {isEndDate ?
								<DatePicker
									id='endDateInput'
									label="End Date"
									value={moment(endDate.value).format('MMMM DD yyyy')}
									valid={endDate.assert(endDate.value, startDate.value)}
									error={!endDate.assert(endDate.value, startDate.value)}
									onChange={(e) => setEndDate({...endDate, value: e.target.value})}
									note={!endDate.assert(endDate.value, startDate.value) ? endDate.message : ''}
								/> : 
								<TextInput
								id='occurrences'
								label="Occurrences"
								value={form.occurrences.value === null ? '' : form.occurrences.value.toString()}
								valid={form.occurrences.assert(form.occurrences.value)}
								error={!form.occurrences.assert(form.occurrences.value)}
								onChange={(e) => setForm({ ...form, occurrences: {...form.occurrences, value: e.target.value}})}
								note={!form.occurrences.assert(form.occurrences.value) ? form.occurrences.message : ''}
								/>
								}
							</>}
						</Grid.Item>
					</Grid>
					}
					<TextArea
						id='nameInput'
						label="Display Name"
						value={form.name.value === ' ' ? setAccessReviewName() : form.name.value}
						error={!form.name.assert(form.name.value)}
						onChange={(e) => setForm({ ...form, name: {...form.name, value: e.target.value}})}
						note={!form.name.assert(form.name.value) ? form.name.message : ''}
					/>
					<TextArea
						id='descriptionInput'
						label="Description"
						value={form.description.value}
						valid={form.description.assert(form.description.value)}
						error={!form.description.assert(form.description.value)}
						onChange={(e) => setForm({ ...form, description: {...form.description, value: e.target.value}})}
						note={!form.description.assert(form.description.value) ? form.description.message : ''}
					/>
					{alert.visible &&
							<Alert
								variant={alert.variant}
								onClose={() => {
									setAlert({
										visible: false,
									});
								}}
							>
								{alert.text}
							</Alert>}
				</> }
				</Modal.Window.Body>
				<Modal.Window.Footer>
					<Grid variant="2-up">
						<Grid.Item>
							<Button
								type='submit'
								variant="secondary"
								disabled={submitting}
								onClick={handleCancel}
							>
								Cancel
							</Button>
						</Grid.Item>
						<Grid.Item>
							<Button variant="primary" disabled={!isValid() || submitting} onClick={handleRequest} type='submit'>
								Create
							</Button>
						</Grid.Item>
					</Grid>
				</Modal.Window.Footer>
			</Modal.Window>
		</Modal>
	);
};

export default CloudGroupsAccessReviewModal;
