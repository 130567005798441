import { Enumeration } from '../../utils/AppEnum';

export class WorkflowType extends Enumeration<WorkflowType> {
	public static CloudAccount = new WorkflowType('CloudAccount', 0);

    public static SubscriptionRequest = new WorkflowType('SubscriptionRequest', 1);

    public static ApimHub = new WorkflowType('ApimHub', 2);

    public static ApimApi = new WorkflowType('ApimApi', 3);

    public static PortOpeningRequest = new WorkflowType('PortOpeningRequest', 4);

    public static DoNotDecryptRequest = new WorkflowType('DoNotDecryptRequest', 5);
}