import * as React from 'react';
import { Modal, Button, Alert, Grid, XFilledIcon } from 'react-unity';
import FormModel from '../../../utils/FormModel';
import TextInputField from '../../common/form-controls/TextInputField';
import CloudAccountsService from '../../../services/CloudAccountsService';
import CloudAccount from '../../../models/CloudAccount';
import { WorkflowState } from '../../../models/enums/WorkflowState';

interface CloudAccountsModalProps {
	visible: boolean;
	onSubmit: (alertText: string) => void;
	onChange: (oldAccount: CloudAccount, newAccount: CloudAccount) => void;
	onSuccess: (alertText: string) => void;
	onError: (error: any) => void;
	onClose: (event: React.ChangeEvent<any>) => void;
	cloudAccount: CloudAccount;
}
interface CloudAccountDeletionModalState {
	confirmationText: string;
}

export default class CloudAccountDeletionModal
	extends React.Component<CloudAccountsModalProps, CloudAccountDeletionModalState> {
	
	cloudAccountsService: CloudAccountsService;

	form: FormModel;

	constructor(props: CloudAccountsModalProps) {
		super(props);
		this.state = {
			confirmationText: '',
		};

		this.cloudAccountsService = new CloudAccountsService();
		
		this.form = new FormModel({
			confirmationText: {
				getValue: () => this.state.confirmationText,
				validation: {
					required: true,
					rules: [
						{
							assert: () => this.state.confirmationText === this.props.cloudAccount?.createdUserPrincipalName,
							message: 'The account name is case-sensitive and must match exactly.',
						},
					],
				},
			},
		});
	}

	componentDidMount = () => {
		document.getElementById('delete-alert').lastChild.remove();
	};

	resetState = () => this.setState({ confirmationText: '' });

	handleCancel = (e) => {
		this.resetState();
		this.props.onClose(e);
	};

	handleSubmit = async () => {		
		try {
			this.props.onSubmit('Submitting request...');

			await this.cloudAccountsService.deleteCloudAccount(this.props.cloudAccount.id);

			const updatedAccount = this.props.cloudAccount;
			updatedAccount.workflowInstance.currentWorkflowInstanceState.workflowState = WorkflowState.Deleted;

			this.props.onChange(this.props.cloudAccount, updatedAccount);

			this.props.onSuccess(
				`Your request to delete the account ${this.props.cloudAccount.createdUserPrincipalName} has been submitted. 
			 	 You will receive a notification upon the successful completion of your request.`
			);

		} catch (error) {
			this.props.onError(error);
		} finally {
			this.resetState();
		}
	};

	render() {
		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Modal.Window>
					<Modal.Window.Header>
						<Modal.Window.Header.Title>{`Delete the account '${this.props.cloudAccount?.createdUserPrincipalName}'?`}</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.handleCancel}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<Alert
							id='delete-alert'
							variant="warning"
						>
							{`Are you sure? This action cannot be undone. A request will be submitted to permanently delete the cloud-only account '${this.props.cloudAccount?.createdUserPrincipalName}'.`}
						</Alert>
						<TextInputField
							label='Please type the name of your account below to confirm.'
							value={this.state.confirmationText}
							validation={this.form.fields.confirmationText.validation}
							onChange={(e) => this.setState({ confirmationText: e.target.value })}
						/>
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Grid variant="2-up">
							<Grid.Item>
								<Button variant="secondary" onClick={this.handleCancel}>
									Cancel
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Button variant="primary" onClick={this.handleSubmit} disabled={!this.form.isValid()}>
									Confirm
								</Button>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}
