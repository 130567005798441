import React, { useState, useEffect } from 'react';
import {
	Modal,
	Button,
	Table,
	XFilledIcon,
} from 'react-unity';
import SubscriptionRequestsService from '../../../services/SubscriptionRequestsService';
import { GrantConsentIdentity } from '../../../models/viewModels/Subscriptions';

interface GrantAdminConsentModalProps {
	visible: boolean;
	subscriptionId: number;
	onClose: () => void;
	showBanner: (x: any) => any;
	resource: any;
	setAlertBanner: any;
}

const GrantAdminConsentModal = ({ subscriptionId, visible, onClose, showBanner, resource, setAlertBanner }: GrantAdminConsentModalProps) => {
	const subscriptionRequestsService = new SubscriptionRequestsService();
	const [selectedPermissions, setSelectedPermissions] = useState([]);
	const [grantingAdminConsent, setGrantingAdminConsent] = useState<boolean>(false);
	const permissionsAvailable = [
		'Application.Read.All',
		'Directory.Read.All',
		'Group.Read.All',
		'User.Read.All',
	];

	const handlePermission = (permission) => {
		if (selectedPermissions.some((perm) => perm === permission)) {
			setSelectedPermissions(selectedPermissions.filter((perm) => perm !== permission));
			return;
		}
		setSelectedPermissions([...selectedPermissions, permission]);
	};

	const handleGrantConsent = async () => {
		setGrantingAdminConsent(true);
		const model: GrantConsentIdentity = {
			permissionsToAdd: selectedPermissions,
		};
		try{
			const res = await subscriptionRequestsService.grantAdminConsent(subscriptionId, resource.identity.principalId, model);
			setGrantingAdminConsent(false);
			onClose();
			setSelectedPermissions([]);
			showBanner(res.permissionsList);
		}
		catch(err){
			onClose();
			setGrantingAdminConsent(false);
			setSelectedPermissions([]);
			setAlertBanner({ 
                visible: true,
                text: 'An error occurred. Please try again later',
                variant: 'error'
            });
		}
	};

	useEffect(() => {
		setSelectedPermissions([]);
	}, [resource]);

	return (
		<Modal show={visible}>
			<Modal.Window id="AppPermissionModal" className="em-u-margin-double">
				<Modal.Window.Header>
					<Modal.Window.Header.Title>
						{`Identity selected: '${resource?.name}'. Specify permissions to grant admin consent.`}
					</Modal.Window.Header.Title>
					<Modal.Window.Header.CloseButton disabled={grantingAdminConsent} onClick={onClose}>
						<XFilledIcon size="small" />
					</Modal.Window.Header.CloseButton>
				</Modal.Window.Header>
				<Modal.Window.Body>
					<Table>
						<Table.Head>
							<Table.Head.Row>
								<Table.Head.Cell>App/Permission</Table.Head.Cell>
								<Table.Head.Cell>Checked</Table.Head.Cell>
							</Table.Head.Row>
						</Table.Head>
						<Table.Body>
							{permissionsAvailable.map((permission) => {
								return (
									<Table.Body.Row
										style={{ cursor: 'pointer' }}
										onClick={() => handlePermission(permission)}
										key={permission}
									>
										<Table.Body.Cell>
											{permission}
										</Table.Body.Cell>
										<Table.Body.Cell>
											<input
												type="checkbox"
												value={permission}
												id={permission}
												checked={selectedPermissions.some((perm) => perm === permission)}
											/>
										</Table.Body.Cell>
									</Table.Body.Row>
								);
							})}
						</Table.Body>
					</Table>
				</Modal.Window.Body>
				<Modal.Window.Footer>
					<Button
						style={{ float: 'right' }}
						disabled={selectedPermissions.length === 0 || grantingAdminConsent}
						variant="primary"
						onClick={handleGrantConsent}
					>
						{grantingAdminConsent ? 'Granting Admin Consent...' : 'Grant Admin Consent'}
					</Button>
				</Modal.Window.Footer>
			</Modal.Window>
		</Modal>
	);
};

export default GrantAdminConsentModal;
