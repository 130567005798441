import React, { useState } from 'react';
import { Button, Searchbar, Select, Toolbar } from 'react-unity';
import PortOpeningRule from '../../models/entities/PortOpeningRule';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import CSVGeneratorButton from '../common/CSVGeneratorButton';
import ToggleField from '../common/form-controls/ToggleField';
import CheckboxFilter, { CheckboxOption } from '../common/tables/CheckboxFilter';
import { PORColumn, RuleColumnsLayout } from './components/RuleColumnsLayout';
import Filter from '../common/tables/Filter';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

interface PORByRuleFiltersProps {
	columns: PORColumn[];
	onFilterChange: any;
	rules: PortOpeningRule[];
	changeColumns: any;
	subscriptionId: number;
	rulesFilteredForCSVCreation: PortOpeningRule[];
	resetSelectedRules: any;
	selectedRules: PortOpeningRule[];
}

export type PORRuleFilters = {
	action: CheckboxOption[];
	region: CheckboxOption[];
	type: CheckboxOption[];
	isBeingAttested: CheckboxOption[];
	orderBy: string;
	searchBox: string;
	selectedScope: string;
};

const MyP = styled.p`
	margin-right: 8px;
	margin-bottom: auto !important;
	margin-top: auto !important;
`;

const StyledItem = styled(Toolbar.Item)`
	width: 50%;
`;

const MyLink = styled.span`
	color: #0c69b0;
	text-decoration: underline;
	cursor: pointer;
`;

const PORByRuleFilters = ({
	rules,
	columns,
	changeColumns,
	onFilterChange,
	subscriptionId,
	rulesFilteredForCSVCreation,
	resetSelectedRules,
	selectedRules,
}: PORByRuleFiltersProps) => {
	const navigate = useNavigate();
	const getDefaultFilters = () => {
		const defaultFilters = {
			action: [
				{ label: 'Allow', value: 'Allow', checked: true },
				{ label: 'Block', value: 'Block', checked: true },
			],
			region: [
				{
					label: 'USSC',
					value: 'USSC',
					checked: true,
				},
				{
					label: 'USE1',
					value: 'USE1',
					checked: true,
				},
				{
					label: 'ASST',
					value: 'ASST',
					checked: true,
				},
			],
			type: [
				{
					label: 'Temporary',
					value: 'true',
					checked: true,
				},
				{
					label: 'Permanent',
					value: 'false',
					checked: true,
				}
			],
			isBeingAttested: [
				{
					label: 'Yes',
					value: 'true',
					checked: true,
				},
				{
					label: 'No',
					value: 'false',
					checked: true,
				}
			],
			orderBy: 'newest',
			selectedScope: PortOpeningRequestsDirection.SubscriptionToInternet.name,
			searchBox: '',
		} as PORRuleFilters;

		return defaultFilters;
	};

	const filterPORs = () => {
		let filteredRequests = [];
		
		const filterCallback = (rule: PortOpeningRule) => {
			return (
				filter.filterSearchText('selectedScope', rule.direction.name) &&
				filter.filterCheckBox('action', rule.action.name) &&
				filter.filterCheckBox('region', rule.region.name) &&
				filter.filterCheckBox('type', rule.isTemporary.toString()) &&
				filter.filterCheckBox('isBeingAttested', rule.isBeingAttested?.toString()) &&
				(filter.filterSearchText('searchBox', rule.name) ||
					filter.filterSearchText('searchBox', rule.description) ||
					filter.filterSearchText('searchBox', rule.azureName)||
					filter.filterSearchText('searchBox', rule.sourceIPs) ||
					filter.filterSearchText('searchBox', rule.destinationIPs) ||
					filter.filterSearchText('searchBox', rule.destinationURLs) ||
					filter.filterSearchText('searchBox', rule.destinationPorts))
			);
		};

		filteredRequests = rules.filter(filterCallback).sort((a, b) => {
			if (filter.filters.orderBy === 'newest') {
				return a.id < b.id ? 1 : -1;
			}
			return a.id > b.id ? 1 : -1;
		});
		onFilterChange(filteredRequests);
	};

	const [filter] = useState<Filter<PORRuleFilters>>(new Filter(filterPORs, getDefaultFilters()));

	const diffActionScoped = {
		deny: { label: 'Deny', value: 'Deny', checked: true },
		block: { label: 'Block', value: 'Block', checked: true },
	};

	const diffRegionScoped = {
		label: 'ASEA',
		value: 'ASEA',
		checked: true,
	};

	const handleChangeScope = (e) => {
		changeFilterOptions(e.target.value);
		changeColumns(RuleColumnsLayout.get(new PortOpeningRequestsDirection().fromName(e.target.value)));
		resetSelectedRules();
		filter.handleSearchTextChange(e, 'selectedScope');
	};

	const changeFilterOptions = (newSelectedScope) => {
		if (newSelectedScope === PortOpeningRequestsDirection.SubscriptionToInternet.name) {
			if (filter.filters.action.some((act) => act.value === diffActionScoped.block.value)) return; //change of state triggering this function twice (fix that to remove this line!)
			filter.updateFilter(
				[
					...filter.filters.action.filter((act) => act.value !== diffActionScoped.deny.value),
					diffActionScoped.block,
				],
				'action'
			);
			filter.updateFilter([...filter.filters.region, diffRegionScoped], 'region');
		} else {
			if (filter.filters.action.some((act) => act.value === diffActionScoped.deny.value)) return;
			filter.updateFilter(
				[
					...filter.filters.action.filter((act) => act.value !== diffActionScoped.block.value),
					diffActionScoped.deny,
				],
				'action'
			);
			filter.updateFilter(
				filter.filters.region.filter((reg) => reg.value !== 'ASEA'),
				'region'
			);
		}
	};

	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchBox');
	};

	return (
		<>
			{filter && (
				<>
					<Toolbar>
						<StyledItem>
							<Searchbar
								condensed
								buttonProps={{ onClick: clearSearchbar}}
								inputProps={{
									value: filter.filters.searchBox,
									onChange: (e) => filter.handleSearchTextChange(e, 'searchBox'),
									placeholder: 'Search all fields shown below (i.e. specific IP address, Description, etc.)',
								}}
							/>
						</StyledItem>
						<Toolbar.Item right>
							<Button.Group>
								<CSVGeneratorButton data={rulesFilteredForCSVCreation} columns={columns} />
								<Button
									variant="primary"
									size="small"
									onClick={() => {
										navigate(`/subscriptions/${subscriptionId}/portOpeningRequest/new`);
									}}
								>
									<span>Open new request</span>
								</Button>

								<Link
									to={`/subscriptions/${subscriptionId}/portOpeningRequest/new`}
									state={{ selectedRules: selectedRules }}								
								>
									<Button
										variant="secondary"
										size="small"
										disabled={selectedRules.length === 0 || selectedRules.some(r => r.isBeingAttested == true)}
									>
										<span>Modify selected</span>
									</Button>
								</Link>
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>
					<Toolbar>
						<Toolbar.Item>
							<ToggleField
								label=""
								className="em-u-margin-none"
								size="small"
								value={filter.filters.selectedScope}
								options={[
									{
										value: PortOpeningRequestsDirection.SubscriptionToInternet.name,
										text: 'Sub -> Internet',
									},
									{
										value: PortOpeningRequestsDirection.SubscriptionToOnPrem.name,
										text: 'Sub <-> XOMNet',
									},
								]}
								onChange={(e) => handleChangeScope(e)}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<CheckboxFilter
								label="Action"
								buttonProps={{ size: 'small' }}
								onChange={(event) => filter.handleCheckboxChange(event, 'action')}
								options={filter.filters.action}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<CheckboxFilter
								label="Region"
								onChange={(event) => filter.handleCheckboxChange(event, 'region')}
								options={filter.filters.region}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<CheckboxFilter
								label="Type"
								onChange={(event) => filter.handleCheckboxChange(event, 'type')}
								options={filter.filters.type}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<CheckboxFilter
								label="BeingAttested"
								onChange={(event) => filter.handleCheckboxChange(event, 'isBeingAttested')}
								options={filter.filters.isBeingAttested}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<MyLink onClick={() => filter.updateFilters(getDefaultFilters())}>Clear filters</MyLink>
						</Toolbar.Item>
						<Toolbar.Item right>
							<Button.Group>
								<MyP>Sort by</MyP>
								<Select
									style={{ marginBottom: -10 }}
									value={filter.filters.orderBy}
									onChange={(e) => filter.handleSearchTextChange(e, 'orderBy')}
								>
									<option className='em-c-field-option' value="newest">Newest</option>
									<option className='em-c-field-option' value="oldest">Oldest</option>
								</Select>
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>
				</>
			)}
		</>
	);
};

export default PORByRuleFilters;
