import * as React from 'react';
import ApplicationsService from '../../../services/ApplicationsService';
import AADAppsHome from '../AADAppsHome';

const AdAppsHome = () => {

	return (
		<AADAppsHome
			applicationsService={new ApplicationsService()}
			typeOfApps={"Applications"}
		/>
	);
}

export default AdAppsHome;