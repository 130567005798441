import React, { useState } from 'react';
import { Button, CaretDownIcon, CaretRightIcon, PencilIcon, Table, TrashcanIcon } from 'react-unity';
import './OrganizationHomeTableRow.css';
import { useNavigate } from 'react-router-dom';
import { L4Tuple } from '../../../models/entities/OrganizationTuple';
import Organization from '../../../models/entities/Organization';

interface Dictionary {
	[Key: string]: boolean;
}

interface OrganizationHomeTableRowProps {
	l4: L4Tuple;
	open: Dictionary;
	setOpen: (open: any) => any;
	selectedOrganization: Organization;
	setSelectedOrganization: any;
	setDecisionModalVisible: any;
	setIsEditting: any;
	setEditingL5: any;
}

const OrganizationsHomeTableRow = ({
	l4,
	open,
	setOpen,
	selectedOrganization,
	setSelectedOrganization,
	setDecisionModalVisible,
	setIsEditting,
	setEditingL5,

}: OrganizationHomeTableRowProps) => {
	const navigate = useNavigate();

	const handleViewDetails = () => {
		navigate(`/organizations/${l4.item1.id}`);
	};

	const handleDisplayModal = (organization: Organization, modal: string, isL5: boolean) => {
		setSelectedOrganization(organization);
		setDecisionModalVisible({ [modal]: true });
		setIsEditting(true);
		setEditingL5(isL5);
	}

	return (
		<React.Fragment key={l4.item1.id}>
		<Table.Body>
			<Table.Body.Cell
				dropdown={l4.item2.length > 0}
				icon={(open[l4.item1.displayName] ? <CaretDownIcon size="small" /> : <CaretRightIcon size="small" />)}
				onClick={() => setOpen({ ...open, [l4.item1.displayName]: !open[l4.item1.displayName] })}
			>
				{l4.item1.shortName ?? l4.item1.displayName}
			</Table.Body.Cell>
			{l4.item2.length > 0 ?
				<>
					<Table.Body.Cell className='em-u-text-align-center'>
					</Table.Body.Cell>
					<Table.Body.Cell className='em-u-text-align-center'>
						<Button.Group>
							<Button
								style={{ zIndex: 0 }}
								variant="primary"
								size="small"
								onClick={() => handleDisplayModal(l4.item1, 'createOrEditOrg', false)}
							>
								<PencilIcon size="small" />
							</Button>
							<Button
								style={{ zIndex: 0 }}
								variant="primary"
								size="small"
								onClick={() => handleDisplayModal(l4.item1, 'deleteOrg', false)}
							>
								<TrashcanIcon size="small" />
							</Button>
						</Button.Group>
					</Table.Body.Cell>
				</>
				:
				<>
					<Table.Body.Cell className='em-u-text-align-center'>
						No Organization L5
					</Table.Body.Cell>
					<Table.Body.Cell className='em-u-text-align-center'>
						<Button.Group>
							<Button
								style={{ zIndex: 0 }}
								variant="primary"
								size="small"
								onClick={() => handleDisplayModal(l4.item1, 'createOrEditOrg', false)}
							>
								<PencilIcon size="small" />
							</Button>
							<Button
								style={{ zIndex: 0 }}
								variant="primary"
								size="small"
								onClick={() => handleDisplayModal(l4.item1, 'deleteOrg', false)}
							>
								<TrashcanIcon size="small" />
							</Button>
						</Button.Group>
					</Table.Body.Cell>
				</>
			}
		</Table.Body>
		{open[l4.item1.displayName] && l4.item2?.map((l5) =>
			<Table.Body key={l5.id}>
				<Table.Body.Cell className='em-u-text-align-center'>
				</Table.Body.Cell>
				<Table.Body.Cell className='em-u-text-align-center'>
					{l5.shortName ?? l5.displayName}
				</Table.Body.Cell>
				<Table.Body.Cell className='em-u-text-align-center'>
					<Button.Group>
						<Button
							style={{ zIndex: 0 }}
							variant="primary"
							size="small"
							onClick={(e) => handleDisplayModal(l5, 'createOrEditOrg', true)}
						>
							<PencilIcon size="small" />
						</Button>
						<Button
							style={{ zIndex: 0 }}
							variant="primary"
							size="small"
							onClick={() => handleDisplayModal(l5, 'deleteOrg', true)}
						>
							<TrashcanIcon size="small" />
						</Button>
					</Button.Group>
				</Table.Body.Cell>
			</Table.Body>
		)}
		</React.Fragment>
	);
};

export default OrganizationsHomeTableRow;
