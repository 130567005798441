import PortOpeningRule from '../../../models/entities/PortOpeningRule';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';

export type PORColumn = {
	header: string;
	cell: (rule: PortOpeningRule) => string;
	limit?: number;
};

export const RuleColumnsLayout = new Map<PortOpeningRequestsDirection, PORColumn[]>();

RuleColumnsLayout.set(PortOpeningRequestsDirection.SubscriptionToOnPrem, [
	{
		header: 'Operation',
		cell: (rule: PortOpeningRule) => rule.operation?.name,
	},
	{
		header: 'Name',
		cell: (rule: PortOpeningRule) => rule.azureName ? rule.azureName : rule.name,
		limit: 40,
	},
	{
		header: 'Type',
		cell: (rule: PortOpeningRule) => rule.isTemporary.toString() == 'true' ? 'Temporary' : 'Permanent',
	},
	{
		header: 'Being Attested',
		cell: (rule: PortOpeningRule) => rule.isBeingAttested?.toString() == 'true' ? 'Yes' : 'No',
	},
	{
		header: 'Action',
		cell: (rule: PortOpeningRule) => rule.action?.name,
	},
	{
		header: 'Region',
		cell: (rule: PortOpeningRule) => rule.region?.name,
	},
	{
		header: 'Dir.',
		cell: (rule: PortOpeningRule) => rule.trafficDirection?.name,
		limit: 15,
	},
	{
		header: 'Source IPs',
		cell: (rule: PortOpeningRule) => rule.sourceIPs,
		limit: 15,
	},
	{
		header: 'Dest. IPs',
		cell: (rule: PortOpeningRule) => rule.destinationIPs,
		limit: 15,
	},
	{
		header: 'Ports',
		cell: (rule: PortOpeningRule) => rule.destinationPorts,
	},
	{
		header: 'Protocol',
		cell: (rule: PortOpeningRule) => rule.protocol,
	},
	{
		header: 'Description',
		cell: (rule: PortOpeningRule) => rule.description,
		limit: 20,
	},
]);

RuleColumnsLayout.set(PortOpeningRequestsDirection.SubscriptionToInternet, [
	{
		header: 'Operation',
		cell: (rule: PortOpeningRule) => rule.operation?.name,
	},
	{
		header: 'Name',
		cell: (rule: PortOpeningRule) => rule.azureName ? rule.azureName : rule.name,
		limit: 20,
	},
	{
		header: 'Type',
		cell: (rule: PortOpeningRule) => rule.isTemporary.toString() == 'true' ? 'Temporary' : 'Permanent',
	},
	{
		header: 'Being Attested',
		cell: (rule: PortOpeningRule) => rule.isBeingAttested?.toString() == 'true' ? 'Yes' : 'No',
	},
	{
		header: 'Action',
		cell: (rule: PortOpeningRule) => rule.action?.name,
	},
	{
		header: 'Region',
		cell: (rule: PortOpeningRule) => rule.region?.name,
	},
	{
		header: 'Source IPs',
		cell: (rule: PortOpeningRule) => rule.sourceIPs,
		limit: 15,
	},
	{
		header: 'Dest. URLs',
		cell: (rule: PortOpeningRule) => rule.destinationURLs,
		limit: 15,
	},
	{
		header: 'Dest. IPs',
		cell: (rule: PortOpeningRule) => rule.destinationIPs,
		limit: 15,
	},
	{
		header: 'Ports',
		cell: (rule: PortOpeningRule) => rule.destinationPorts,
	},
	{
		header: 'Description',
		cell: (rule: PortOpeningRule) => rule.description,
		limit: 20,
	},
]);