import Application from '../models/Application';
import EnterpriseApp from '../models/entities/EnterpriseApp';
import ApplicationPermission from '../models/entities/ApplicationPermission';
import PermissionToApplication from '../models/entities/PermissionToApplication';
import { RequestEnterpriseAppModel } from '../models/viewModels/EnterpriseApps/RequestEnterpriseAppModel';
import { UpdateEnterpriseAppModel } from '../models/viewModels/EnterpriseApps/UpdateEnterpriseAppModel';
import ApiClient from './ApiClient';
import ChangeHistory from '../models/entities/ChangeHistory';
import EntityChangeHistoryService from '../models/interfaces/EntityChangeHistoryService';
import { getToken } from '../authentication/authModule';
import ApplicationLookupObject from '../models/ApplicationLookupObject';

export default class ApplicationsService extends ApiClient implements EntityChangeHistoryService {
	async getAll() {
		return this.get<Application[]>('applications');
	}

	async getAllApplications() {
		return this.get<EnterpriseApp[]>('applications/allApps');
	}

	async getEnterpriseAppByAppId(id: string) {
		return this.get<EnterpriseApp>(`applications/enterpriseAppByAppId/${id}`);
	}

	async getEnterpriseAppById(id: number) {
		return this.get<EnterpriseApp>(`applications/${id}/enterpriseapp`);
	}

	async getMyAppsApprovals() {
		return this.get<EnterpriseApp[]>(`applications/OwnerApprovals`);
	}

	async applicationLookup(name: string) {
		return this.get<ApplicationLookupObject[]>(`applications/APPLookup?name=${name}&maxRows=10`);
	}

	async deleteById(id: string) {
		return this.delete<Application>(`applications/${id}`, null);
	}

	async cancelTransferOwnership(id: string){
		return this.patch(`applications/${id}/cancelTransferOwnership`, null);
	}

	async getMyApplications() {
		return this.get<EnterpriseApp[]>('applications/me');
	}

	async createApplication(app: RequestEnterpriseAppModel) {
		return this.post('applications', app);
	}

	async updateApplication(appId: string, app: UpdateEnterpriseAppModel) {
		return this.patch(`applications/${appId}`, app);
	}
	
	async updateMultiple(apps : UpdateEnterpriseAppModel[]) {
		return this.patch('applications', apps);
	}

	async createSamlApplication(app: RequestEnterpriseAppModel) {
		return this.post('applications/saml', app);
	}

	async grantAdminConsent(id: string) {
		return this.patch(`applications/${id}/grantAdminConsent`, null);
	}

	async assignHomeRealmDiscoveryPolicy(id: string) {
		return this.post(`applications/${id}/homeRealmDiscoveryPolicy`, null);
	}

	async removeHomeRealmDiscoveryPolicy(id: string) {
		return this.delete(`applications/${id}/homeRealmDiscoveryPolicy`, null);
	}

	async getApplicationPermissions(id: string) {
		return this.get<ApplicationPermission[]>(`applications/${id}/configuredPermissions`);
	}

	async getPermissionsToApplication(id: string) {
		return this.get<PermissionToApplication[]>(`applications/${id}/permissionsToApp`);
	}

	async takeOwnershipOfApplication(id: string) {
		return this.patch(`applications/${id}/takeOwnership`, null);
	}

	async acceptOwnershipOfApplication(id: string) {
		return this.patch(`applications/${id}/acceptOwnership`, null);
	}

	async rejectOwnershipOfApplication(id: string) {
		return this.patch(`applications/${id}/rejectOwnership`, null);
	}

	async grantCredentialAdminRole(id: string, scopeId: string) {
		var reqBody = {
			isAppCredentialAdminRoleGranted: true
		};
		return this.patch(`applications/${id}/applicationAdministratorRole/${scopeId}`, reqBody);
	}

	async getChangeHistory(id: string) {
		return this.get<ChangeHistory[]>(`applications/${id}/history`);
	}

	async import(apps: []) {
		return this.post(`applications/import`, apps);
	}

	// eslint-disable-next-line class-methods-use-this
	async parse(file: File) {
		const formData = new FormData();
		formData.append(file.name, file);
		const token = await getToken();		

		return new Promise(async (resolve, reject) => 
			fetch(`/api/v1.0/applications/parse`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json, */*',
				},
				body: formData,
			})
				.then((response) => {
					if (!response.ok) throw response;
					return response;
				})
				.then(response => resolve(response.json()))
				.catch(response => response.json())
				.then(response => reject(response))
		);
	}
	
}