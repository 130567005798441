import * as React from 'react';
import SelectField from '../../common/form-controls/SelectField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import Tenant from '../../../models/entities/Tenant';

interface TenantInputProps extends FieldHandler<string> { 
	tenants: Tenant[]
}

export class TenantInput extends React.Component<TenantInputProps> {
	tenantOptions = () => {
		return this.props.tenants
			.map((tenant) => ({ value: tenant.id, text: tenant.name }));
	};

	render() {
		return (
			<SelectField
				label="Tenant"
				className="full-width"
				options={this.tenantOptions()}
				{...this.props}
			/>
		);
	}
}