import moment from 'moment';
import FormModel from '../../utils/FormModel';
import Group from '../../models/Group';
import UserLookupObject from '../../models/UserLookupObject';
import { Environment } from '../../models/enums/Environment';
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import { AbstractFormState, AbstractForm } from '../common/AbstractForm';
import CartaId from '../../models/entities/CartaId';
import { VNetConfiguration } from '../../models/viewModels/Subscriptions/VNetConfiguration';

export interface ISubscriptionStateForm {
	selectedL3: string;
	selectedL4: string;
	selectedOrganization: string;
	environment: Environment;
	cartaIds: CartaId[];
	isConnected: boolean | null;
	amountOfDevices?: number;
	region?: string;
	initialResourceGroup?: string;
	resourceGroupOwnerType?: string;
	resourceGroupOwner?: any;
	contributorAccessGroup?: Group;
	virtualNetwork?: VNetConfiguration[];
	isProject: boolean;
	wpmCode?: string;
	closeOutDate?: string;
	owner: UserLookupObject;
	custodian: UserLookupObject;
	custodianTwo: UserLookupObject;
	subscriptionUsedFor: string;
	isImportedOrUpdated: boolean;
	subsBudget: number;
}

/** @abstract */
export abstract class AbstractSubscriptionsForm<
	Props,
	State extends AbstractFormState<ISubscriptionStateForm>
	> extends AbstractForm<Props, State> {
	subscriptionsService: SubscriptionRequestsService;

	constructor(props) {
		super(props);
		this.subscriptionsService = new SubscriptionRequestsService();
	}

	initFormModel() {
		this.formModel = new FormModel(
			{
				selectedL3GlobalId: {
					getValue: () => this.state.stateForm.selectedL3,
					validation: {
						required: true,
					},
				},
				selectedL4GlobalId: {
					getValue: () => this.state.stateForm.selectedL4,
					validation: {
						required: true,
					},
				},
				organizationGlobalId: {
					getValue: () => this.state.stateForm.selectedOrganization,
					validation: {
						required: this.state.stateForm.selectedOrganization == null,
					},
				},
				environment: {
					getValue: () => this.state.stateForm.environment?.value,
					validation: {
						required: () => true
					}
				},
				isConnected: {
					getValue: () => this.state.stateForm.isConnected,
					validation: {
						required: true,
					},
				},
				amountOfDevices: {
					getValue: () => this.state.stateForm.amountOfDevices,
					validation: {
						required: () => this.state.stateForm.isConnected 
					},
				},
				region: {
					getValue: () => this.state.stateForm.region,
					validation: {
						required: () => this.state.stateForm.isConnected
					},
				},
				initialResourceGroup: {
					getValue: () => this.state.stateForm.initialResourceGroup,
					validation: {
						required: () => this.state.stateForm.isConnected
					},
				},
				resourceGroupOwnerId: {
					getValue: () => this.state.stateForm.resourceGroupOwner?.id,
					validation: {
						required: () => this.state.stateForm.isConnected && !this.state.stateForm.isImportedOrUpdated
					},
				},
				resourceGroupOwnerType: {
					getValue: () => this.state.stateForm.resourceGroupOwnerType,
					validation: {
						required: () => this.state.stateForm.isConnected && !this.state.stateForm.isImportedOrUpdated
					},
				},
				contributorAccessGroupId: {
					getValue: () => this.state.stateForm.contributorAccessGroup?.id,
					validation: {
						required: () => this.state.stateForm.isConnected
					},
				},
				virtualNetwork: {
					getValue: () => this.state.stateForm.virtualNetwork,
					validation: {
						required: () => this.state.stateForm.isConnected && !this.state.stateForm.isImportedOrUpdated
					},
				},
				isProject: {
					getValue: () => this.state.stateForm.isProject,
					validation: {
						required: true,
					},
				},
				wpmCode: {
					getValue: () => this.state.stateForm.wpmCode || '0',
					validation: {
						required: () => this.state.stateForm.isProject,
						rules: [
							{
								assert: () => /^[0-9]*$/.test(this.state.stateForm.wpmCode),
								message: 'Only numbers are allowed.',
							},
							{
								assert: () => this.state.stateForm.wpmCode?.toString().trim().length < 20,
								message: 'The WPM code can\'t be longer than 20 characters.'
							},
						]
					}
				},
				closeOutDate: {
					getValue: () => {
						const value = this.state.stateForm.closeOutDate;
						return value ? moment(value).format('MM-YYYY') : value;
					},
					validation: {
						required: () => this.state.stateForm.isProject,
						rules: [
							{
								assert: () => moment(this.state.stateForm.closeOutDate) >= moment(),
								message: 'The close out date can\'t occur before today.'
							},
							{
								assert: () => {
									let endDate = moment().add(15, 'years');
									endDate = moment(`${endDate.year()}-12-31`, 'YYYY-MM-DD');
									return moment(this.state.stateForm.closeOutDate) <= endDate;
								},
								message: 'The close out date is out of range. Please choose an earlier date.'
							}
						]
					}
				},
				cartaIds: {
					getValue: () => this.state.stateForm.cartaIds,
					validation: {
						required: false,
						rules: [{
							assert: () => {
								if (this.state.stateForm.cartaIds.every(x => x.cartaAppId.length > 0 
									&& x.cartaAppName.length > 0))
									return true;
								if (this.state.stateForm.cartaIds.length === 1)
									return (this.state.stateForm.cartaIds[0].comment.length > 0 
										&& this.state.stateForm.cartaIds[0].cartaAppId.length === 0);
								return false;
							},
							message: '',
						}]
					},
				},
				ownerUserId: {
					getValue: () => this.state.stateForm.owner?.id,
					validation: {
						required: true,
					},
				},
				custodianUserId: {
					getValue: () => this.state.stateForm.custodian?.id,
					validation: {
						required: true,
					},
				},
				custodianTwoUserId: {
					getValue: () => this.state.stateForm.custodianTwo?.id,
					validation: {
						required: false,
						rules: [
							{
								assert: () => this.state.stateForm.custodian?.displayName!=this.state.stateForm.custodianTwo?.displayName && this.state.stateForm.owner?.displayName!=this.state.stateForm.custodianTwo?.displayName,
								message: 'Custodian two and Owner cannot be the same. Custodian two and Custodian cannot be the same.',
							}
						]
					},
				},
				subscriptionUsedFor: {
					getValue: () => this.state.stateForm.subscriptionUsedFor.trim(),
					validation: {
						required: false,
						rules: [
							{
								assert: () => this.state.stateForm.subscriptionUsedFor.trim().length <= 500,
								message: 'Please ensure your response does not exceed 500 characters.',
							}
						]
					},
				},
				subsBudget: {
					getValue: () => this.state.stateForm.subsBudget,
					validation: {
						required: () => this.state.stateForm.selectedL3 == "EGUL IT",
						rules: [
							{
								assert: () => /^[0-9]*$/.test(this.state.stateForm.subsBudget?.toString()),
								message: 'Only numbers are allowed.',
							},
						]	
					},
				},
			}
		);
	}

	stateFormHandler = () => this.getStateFormHandlers();

	getStateFormHandlers() {
		return {
			selectedL3: {
				value: this.state.stateForm.selectedL3,
				validation: this.formModel.fields.selectedL3GlobalId.validation,
				onChange: (orgId: string) => {
					this.handleStateFormChange('selectedL3', orgId);
				}
			},
			selectedL4: {
				value: this.state.stateForm.selectedL4,
				validation: this.formModel.fields.selectedL4GlobalId.validation,
				onChange: (orgId: string) => {
					this.handleStateFormChange('selectedL4', orgId);
				}
			},
			selectedOrganization: {
				value: this.state.stateForm.selectedOrganization,
				validation: this.formModel.fields.organizationGlobalId.validation,
				onChange: (orgId: string) => {
					this.handleStateFormChange('selectedOrganization', orgId);
				}
			},
			environment: {
				value: this.state.stateForm.environment?.value,
				validation: this.formModel.fields.environment.validation,
				onChange: (event) => {
					const env = new Environment().fromValue(Number(event.target.value));
					this.handleStateFormChange('environment', env);
				}
			},
			isConnected: {
				value: this.state.stateForm.isConnected,
				validation: this.formModel.fields.isConnected.validation,
				onChange: (event) => {
					const dependentFields = ['amountOfDevices', 'region', 'initialResourceGroup', 'resourceGroupOwner', 'resourceGroupOwnerType', 'contributorAccessGroup', 'virtualNetwork'];
					const connectedFields = ['isConnected', ...dependentFields];
					const values = [event.target.value === 'true', ...dependentFields.map(() => null)];
					this.handleStateFormChange(connectedFields, values);
				}
			},
			amountOfDevices: {
				value: this.state.stateForm.amountOfDevices,
				validation: this.formModel.fields.amountOfDevices.validation,
				onChange: (event) => {
					this.handleStateFormChange('amountOfDevices', event.target.value);
				}
			},
			region: {
				value: this.state.stateForm.region,
				validation: this.formModel.fields.region.validation,
				onChange: (event) => {
					this.handleStateFormChange('region', event.target.value);
				}
			},
			initialResourceGroup: {
				value: this.state.stateForm.initialResourceGroup,
				validation: this.formModel.fields.initialResourceGroup.validation,
				onChange: (event) => {
					this.handleStateFormChange('initialResourceGroup', event.target.value);
				}
			},
			resourceGroupOwner: {
				value: this.state.stateForm.resourceGroupOwner?.displayName,
				onChange: (value) => {
					this.handleStateFormChange('resourceGroupOwner', value);
				}
			},
			resourceGroupOwnerType: {
				value: this.state.stateForm.resourceGroupOwnerType,
				validation: this.formModel.fields.resourceGroupOwnerType.validation,
				onChange: (event) => {
					this.handleStateFormChange('resourceGroupOwnerType', event.target.value);
				}
			},
			contributorAccessGroup: {
				value: this.state.stateForm.contributorAccessGroup?.displayName,
				validation: this.formModel.fields.contributorAccessGroupId.validation,
				onChange: (value) => {
					this.handleStateFormChange('contributorAccessGroup', value);
				}
			},
			virtualNetwork: {
				value: this.state.stateForm.virtualNetwork,
				validation: this.formModel.fields.virtualNetwork.validation,
				onChange: (value) => {
					this.handleStateFormChange('virtualNetwork', value);
				}
			},
			isProject: {
				value: this.state.stateForm.isProject,
				validation: this.formModel.fields.isProject.validation,
				onChange: (event) => {
					const dependentFields = ['wpmCode', 'closeOutDate'];
					const projectFields = ['isProject', ...dependentFields];
					const values = [event.target.value === 'true', '', null];
					this.handleStateFormChange(projectFields, values);
				}
			},
			wpmCode: {
				value: this.state.stateForm.wpmCode,
				validation: this.formModel.fields.wpmCode.validation,
				onChange: (event) => {
					this.handleStateFormChange('wpmCode', event.target.value);
				}
			},
			closeOutDate: {
				value: this.state.stateForm.closeOutDate,
				validation: this.formModel.fields.closeOutDate.validation,
				onChange: (event) => {
					this.handleStateFormChange('closeOutDate', event.target.value);
				}
			},
			cartaIds: {
				value: this.state.stateForm.cartaIds,
				validation: this.formModel.fields.cartaIds.validation,
				onChange: (cartaIds) => {
					if (cartaIds.length === 1 && cartaIds[0]?.cartaAppId === this.state.stateForm.cartaIds[0]?.cartaAppId) {
						const newComment = cartaIds[0].comment;
						cartaIds[0] = this.state.stateForm.cartaIds[0];
						cartaIds[0].comment = newComment;
					}
					this.handleStateFormChange('cartaIds', cartaIds);
				}
			},
			owner: {
				value: this.state.stateForm.owner?.userPrincipalName,
				validation: this.formModel.fields.ownerUserId.validation,
				onChange: (value) => {
					this.handleStateFormChange('owner', value);
				}
			},
			custodian: {
				value: this.state.stateForm.custodian?.userPrincipalName,
				validation: this.formModel.fields.custodianUserId.validation,
				onChange: (value) => {
					this.handleStateFormChange('custodian', value);
				}
			},
			custodianTwo: {
				value: this.state.stateForm.custodianTwo?.userPrincipalName,
				validation: this.formModel.fields.custodianTwoUserId.validation,
				onChange: (value) => {
					this.handleStateFormChange('custodianTwo', value);
				}
			},
			subscriptionUsedFor: {
				value: this.state.stateForm.subscriptionUsedFor,
				validation: this.formModel.fields.subscriptionUsedFor.validation,
				onChange: (e) => {
					this.handleStateFormChange('subscriptionUsedFor', e.target.value);
				}
			},
			subsBudget: {
				value: this.state.stateForm.subsBudget,
				validation: this.formModel.fields.subsBudget.validation,
				onChange: (e) => {
					this.handleStateFormChange('subsBudget', e.target.value);
				}
			},
		};
	}
}