import * as React from 'react';
import { Grid } from 'react-unity';
import ToggleField from '../../common/form-controls/ToggleField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import DatePickerField from '../../common/form-controls/DatePickerField';
import { ExpirationAction } from '../../../models/enums/AdApps/ExpirationAction';

interface ExpirationDateInputProps{
	expirationDate: FieldHandler<string|null>
	applicationLifeTime: FieldHandler<ExpirationAction>;
	disableState: boolean;
}

export default class ExpirationDateInput extends React.Component<ExpirationDateInputProps> {

	render() {
		return (
			<>

				<ToggleField
					id="application-type"
					label='How long is this application needed?'
					value={this.props.applicationLifeTime.value?.value}
					options={
						!this.props.disableState ?
						[
							ExpirationAction.Temporary,
							ExpirationAction.Permanent,
						]
						:
						this.props.applicationLifeTime.value?.value  ?
							[ExpirationAction.Permanent]
							:
							[ExpirationAction.Temporary]
					}
					onChange={this.props.applicationLifeTime.onChange}
					disabled={this.props.disableState}
				/>


				<Grid variant="2-up">
					{this.props.applicationLifeTime.value === ExpirationAction.Temporary &&
						<Grid.Item>
							<DatePickerField
								{...this.props.expirationDate}
								disabled={this.props.disableState}
							/>
						</Grid.Item>}
						<Grid.Item className='em-u-padding-top-none'>
					</Grid.Item>
				</Grid>
			</>
		);
	}
}