import * as React from 'react';
import { Table, ProgressBar, Alert, RefreshIcon } from 'react-unity';
import { authenticatedUser } from './../../authentication/authModule';
import { UserRole } from './../../models/enums/UserRole';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import CreateFeedbackModal from './components/CreateFeedbackModal';
import UpdateFeedbackModal from './components/UpdateFeedbackModal';
import Feedback from '../../models/entities/Feedback';
import FeedbackService from '../../services/FeedbackService';
import './FeedbackHome.css';
import Paginator from '../common/tables/Paginator';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import FeedbackHomeFilters from './components/FeedbackHomeFilters';
import FeedbackHomeTableRow from './FeedbackHomeTableRow';
import { useState, useEffect } from 'react';
import Filter from '../common/tables/Filter';

const FeedbackHome = () => {

	const feedbackService = new FeedbackService();
	const [createFeedbackModalVisible, setCreateFeedbackModalVisible] = useState<boolean>(false);
	const [updateFeedbackModalVisible, setUpdateFeedbackModalVisible] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [allFeedback, setAllFeedback] = useState<Feedback[]>([]);
	const [feedbackFiltered, setFeedbackFiltered] = useState<Feedback[]>([]);
	const [feedbackPage, setFeedbackPage] = useState<Feedback[]>([]);
	const [selectedFeedback, setSelectedFeedback] = useState<Feedback>(new Feedback());
	const [alert, setAlert] = useState<AlertBanner>({ visible: false });
	const [isSSPDev] = useState<boolean>(authenticatedUser.isInRole(UserRole.SSPDeveloper));
	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		fillTable();
	}, []);

	const fillTable = () => {
		setIsLoading(true);
		var feedbackRequest = isSSPDev ? feedbackService.GetAllFeedback() : feedbackService.GetMyFeedback();

		feedbackRequest.then(result => {
			const feedbackResults = result.reverse();
			setAllFeedback(feedbackResults);
		})
			.catch(() => {
				setAlert({
					visible: true,
					text: 'Something went wrong',
					variant: 'error'
				})
				alertCallback()
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleRecordUpdated = async () => {
		setAlert({
			visible: true,
			text: `Record #${selectedFeedback.id.toString()} updated successfully.`,
			variant: 'success'
		});
		alertCallback();
		fillTable();
		setRefresh(true);
	};

	const handleSubmitNewFeedback = async () => {
		setAlert({
			visible: true,
			text: `Your new feedback has been successfully created.`,
			variant: 'success'
		});
		alertCallback();
		fillTable();
	};

	const alertCallback = () => {
		setTimeout(() => 
			setAlert({
				visible: false
			}), 6000);
	};

	const getFilterProps = () => {
		return {
			setFilteredFeedback:setFeedbackFiltered,
			createFeedbackModal:setCreateFeedbackModalVisible,
			fillTable,
			allFeedback,
			showFeedback:false,
			setRefresh,
			refresh
		};
	};

	return (
		<SectionHomeWrapper title="Feedback messages">
			{alert.visible &&
				<Alert
					variant={alert.variant}
					onClose={() => setAlert({
						visible: false,
					})}
				> {alert.text}
				</Alert>}
			{isLoading ? (
				<ProgressBar indeterminate hideValueLabel label="Loading feedback..." />
			) : (
				<>
					<FeedbackHomeFilters {...getFilterProps()}
					/>
					<Table
						footer={
							<Paginator
								data={feedbackFiltered}
								itemsPerPage={10}
								totalItems={allFeedback.length}
								onPageChange={(feed) => {
									setFeedbackPage(feed);
								}}
							/>
						}
					>
						<Table.Head>
							<Table.Head.Row>
								<Table.Head.Cell> ID </Table.Head.Cell>
								<Table.Head.Cell> Work Item State </Table.Head.Cell>
								<Table.Head.Cell> Title </Table.Head.Cell>
								<Table.Head.Cell> Category </Table.Head.Cell>
								<Table.Head.Cell> Description </Table.Head.Cell>
								<Table.Head.Cell> Submitted By </Table.Head.Cell>
								<Table.Head.Cell> Date </Table.Head.Cell>
								<Table.Head.Cell> Actions </Table.Head.Cell>
							</Table.Head.Row>
						</Table.Head>
						<Table.Body>{
							feedbackPage.map((feedback) => (
								<FeedbackHomeTableRow
									key={feedback.id}
									feedback={feedback}
									setUpdateFeedbackModalVisible={setUpdateFeedbackModalVisible}
									setSelectedFeedback={setSelectedFeedback}
									isSSPDev={isSSPDev}
								/>
							))
						}</Table.Body>
					</Table>
				</>)}
			<UpdateFeedbackModal
				visible={updateFeedbackModalVisible}
				feedback={selectedFeedback}
				isSSPDev={isSSPDev}
				onConfirm={() => {
					setUpdateFeedbackModalVisible(false);
					handleRecordUpdated();
				}}
				onClose={() => {
					setUpdateFeedbackModalVisible(false);
				}}
			/>
			<CreateFeedbackModal
				visible={createFeedbackModalVisible}
				onConfirm={() => {
					setCreateFeedbackModalVisible(false)
					handleSubmitNewFeedback();
				}}
				onClose={() => {setCreateFeedbackModalVisible(false)}}
			/>
		</SectionHomeWrapper>
	);
}

export default FeedbackHome;