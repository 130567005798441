import * as React from 'react';
import {
	TextPassage,
	BulletedList,
	TextLink,
	Table,
	Toolbar,
	Button,
	Select,
	TextInput,
	PlusFilledIcon,
	ProgressBar,
	Alert,
	AlertVariant,
	TreeNavigation,
	Modal,
	XFilledIcon,
	Grid,
	TextArea,
} from 'react-unity';
import moment from 'moment';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper'; 
import '../common/wrappers/SectionHomeWrapper.css';
import CloudAccountsService from '../../services/CloudAccountsService';
import CloudAccount from '../../models/CloudAccount';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../../models/enums/UserRole';
import './CloudAccountsHome.css';
import CloudAccountRequestModal from './components/CloudAccountRequestModal';
import CloudAccountDeletionModal from './components/CloudAccountDeletionModal';
import ConfirmModal from '../common/modals/ConfirmModal';
import WorkflowStateBadge from '../common/WorkflowStateBadge';
import ActionRow from '../common/tables/ActionRow';
import { WorkflowState } from '../../models/enums/WorkflowState';
import Tooltip from '../common/Tooltip';
import Paginator from '../common/tables/Paginator';

interface CloudAccountsHomeState {
	actionsEnabled: boolean;
	cloudAccounts: CloudAccount[];
	filteredAccounts: CloudAccount[];
	accountsOnPage: CloudAccount[];
	selectedCloudAccount: CloudAccount;
	searchText: string;
	searchDropdownStatus: string;
	requestAccountModalVisible: boolean;
	resetPasswordModalVisible: boolean;
	resetMfaModalVisible: boolean;
	deleteAccountModalVisible: boolean;
	isLoading: boolean;
	alertVisible: boolean;
	alertText: string;
	alertType: AlertVariant;
	approveModalVisible: boolean;
	rejectModalVisible: boolean;
	reasonModalVisible: boolean;
	rejectionReason: string;
	availableAccountTypes: string[];
}

export default class CloudAccountsHome extends React.Component<{}, CloudAccountsHomeState> {
	cloudAccountsService: CloudAccountsService;

	timeoutId: NodeJS.Timeout;

	actionRef: React.RefObject<HTMLBaseElement>;

	constructor(props: any) {
		super(props);

		this.cloudAccountsService = new CloudAccountsService();
		this.timeoutId = undefined;
		this.actionRef = React.createRef();

		this.state = {
			cloudAccounts: [],
			filteredAccounts: [],
			accountsOnPage: [],
			selectedCloudAccount: undefined,
			searchText: '',
			searchDropdownStatus: 'all',
			requestAccountModalVisible: false,
			deleteAccountModalVisible: false,
			resetPasswordModalVisible: false,
			resetMfaModalVisible: false,
			isLoading: true,
			alertVisible: false,
			alertText: '',
			alertType: 'default',
			approveModalVisible: false,
			rejectModalVisible: false,
			reasonModalVisible: false,
			rejectionReason: '',
			availableAccountTypes: [],
			actionsEnabled: true
		};
	}

	async componentDidMount() {
		this.setState({
			searchDropdownStatus: authenticatedUser.isInRole(UserRole.CloudAccountAdmin)
				? 'Submitted'
				: 'All'
		});

		try {
			await this.updateState();
		}
		catch(error) {
			const HTTP_INTERNAL_SERVER_ERROR = 500;
			if (error.status === HTTP_INTERNAL_SERVER_ERROR) {
				this.handleError(error, "Error gathering cloud accounts data");
			}
		}
		this.setState({ isLoading: false });
	}

	updateState = async () => {
		let myCloudAccounts = await this.cloudAccountsService.getMyAccounts();
		
		 await Promise.all(
		 	myCloudAccounts.map(async (cloudAccount) => {
		 		cloudAccount.canResetMFA = true;
		 	})
		 );

		const myCloudAccountsForApprove = await this.cloudAccountsService.getAccountsForApprove();		
		let cloudAccounts = [...myCloudAccounts, ...myCloudAccountsForApprove];

		if (authenticatedUser.isInRole(UserRole.CloudAccountAdmin)) {
			cloudAccounts = await this.cloudAccountsService.getAll();

			await Promise.all(
				cloudAccounts.map(async (cloudAccount) => {
					myCloudAccounts.forEach(Account => {
						if(Account.id == cloudAccount.id)
							cloudAccount.canResetMFA = true;
						else;
					});
				})
			);
		}

		cloudAccounts.sort((a, b) => 
			new Date(b.workflowInstance.createdDate).getTime() - new Date(a.workflowInstance.createdDate).getTime());

		const supportedDomains = (await this.cloudAccountsService.getSupportedDomains()).map((type) => type.domainName);
		const myExistingDomains = myCloudAccounts.filter((account) => 
			account.workflowInstance.currentWorkflowInstanceState.workflowState.name === WorkflowState.Approved.name || 
			account.workflowInstance.currentWorkflowInstanceState.workflowState.name === WorkflowState.Submitted.name)
			.map((account) => account.createdUserPrincipalName.split('@')[1]);
		const availableAccountTypes = supportedDomains.filter((d) => !myExistingDomains.includes(d))
		this.setState({
			cloudAccounts,
			filteredAccounts: cloudAccounts,
			availableAccountTypes,
		}, () => {
			if (this.state.searchText !== '') {
				this.handleSearchTextChange(this.state.searchText);
			}
			if (this.state.searchDropdownStatus !== 'All') {
				this.handleStatusDropdownChange(this.state.searchDropdownStatus);
			}
		});
	};

	handleStatusDropdownChange = (e) => {
		const dropdownValue = e.target?.value ?? e;
		const searchText = this.state.searchText.toLowerCase();

		this.applyFilters(searchText, dropdownValue);
	};

	handleSearchTextChange = (e) => {
		const searchText = e.target?.value.trim() ?? e;
		const dropdownValue = this.state.searchDropdownStatus;

		this.applyFilters(searchText, dropdownValue);
	};

	applyFilters = (searchText, dropdownValue) => {

		let filteredAccounts = this.state.cloudAccounts.filter(account =>
			account.createdUserPrincipalName.toLowerCase().includes(searchText.toLowerCase()));

		if (dropdownValue !== 'All') {
			filteredAccounts = filteredAccounts.filter(account => 
				account.workflowInstance.currentWorkflowInstanceState.workflowState.name.toLowerCase() === 
				dropdownValue.toLowerCase());
		}

		this.setState(prev => ({
			filteredAccounts,
			searchText,
			searchDropdownStatus: dropdownValue,
		}));
	};

	handleSubmitAlert = (alertText: string) => {
		this.setState({
			requestAccountModalVisible: false,
			deleteAccountModalVisible: false,
			alertVisible: true,
			alertType: 'default',
			alertText,
			actionsEnabled: true
		});
	};

	handleSuccessAlert = (alertText: string) => {
		this.setState(_ => ({
			requestAccountModalVisible: false,
			deleteAccountModalVisible: false,
			alertVisible: true,
			alertType: 'success',
			alertText,
			actionsEnabled: true,
		}), () => {
			this.handleSetTimeout();
		});
	};

	handleAccountChange = (oldAccount: CloudAccount, newAccount: CloudAccount) => {
		const index = this.state.cloudAccounts.indexOf(oldAccount);
		const accounts = this.state.cloudAccounts;
		accounts[index] = newAccount;
		
		// update state and filter again to see changes reflected
		this.setState(_ => ({
			cloudAccounts: accounts,
		}), () => {
			this.applyFilters(this.state.searchText, this.state.searchDropdownStatus);
		});
	};

	handlePasswordResetRequest = async () => {
		this.setState({ resetPasswordModalVisible: false });

		try {
			const cloudAccount = this.state.selectedCloudAccount;

			this.handleSubmitAlert('Submitting request...');

			await this.cloudAccountsService.requestPasswordReset(cloudAccount.createdUserPrincipalName);

			this.handleSuccessAlert(`An email has been sent containing a link to update the password for your account. 
								Please check your junk folder if you don't receive anything within a few minutes.`);
		} catch (error) {
			this.handleError(error);
		}
	};

	handleMfaResetRequest = async () => {
		this.setState({ resetMfaModalVisible: false });

		try {
			const cloudAccount = this.state.selectedCloudAccount;

			this.handleSubmitAlert('Submitting request...');

			await this.cloudAccountsService.resetMfaConfiguration(cloudAccount.createdUserPrincipalName, authenticatedUser.userEmail);

			this.handleSuccessAlert(`The MFA configuration has been reset.`);
		} catch (error) {
			this.handleError(error);
		}
	};

	handleRequestApproved = async () => {
		this.setState({ approveModalVisible: false });

		try {
			const cloudAccount = this.state.selectedCloudAccount;

			this.handleSubmitAlert('Submitting request...');

			const updatedAccount = cloudAccount.approver ? await this.cloudAccountsService.approveUserCloudAccount(cloudAccount.id) :
			await this.cloudAccountsService.approveCloudAccount(cloudAccount.id);

			this.handleAccountChange(cloudAccount, updatedAccount);

			this.handleSuccessAlert(`The cloud-only account request '${cloudAccount.createdUserPrincipalName}' has been approved.`);

		} catch (error) {
			this.handleError(error);
		}
	};

	handleRequestRejected = async () => {
		this.setState({ rejectModalVisible: false });

		try {
			const cloudAccount = this.state.selectedCloudAccount;

			this.handleSubmitAlert('Submitting request...');

			const updatedAccount = cloudAccount.approver ? await this.cloudAccountsService.rejectUserCloudAccount(
				cloudAccount.id, this.state.rejectionReason) : await this.cloudAccountsService.rejectCloudAccount(
				cloudAccount.id, this.state.rejectionReason);

			this.handleAccountChange(cloudAccount, updatedAccount);

			this.handleSuccessAlert(`The cloud-only account request '${cloudAccount.createdUserPrincipalName}' has been rejected.`);

		} catch (error) {
			this.handleError(error);
		} finally {
			this.setState({ rejectionReason: '' });
		}
	};

	handleSubmit = async (alertText: string) => {
		this.setState({
			requestAccountModalVisible: false,
			deleteAccountModalVisible: false,
			alertVisible: true,
			alertType: 'default',
			alertText,
			actionsEnabled: false
		});

		this.handleSetTimeout();
	};

	handleSuccess = async (alertText: string) => {

		this.setState({
			resetMfaModalVisible: false,
			requestAccountModalVisible: false,
			deleteAccountModalVisible: false,
			alertVisible: true,
			alertType: 'success',
			alertText,
			actionsEnabled: true,
			isLoading: true
		});

		this.handleSetTimeout();

		await this.updateState();
		this.setState({ isLoading: false });
	};

	handleError = async (error, message = 'Your request could not be completed at this time. Please try again later.') => {
		this.setState({
			resetMfaModalVisible: false,
			requestAccountModalVisible: false,
			deleteAccountModalVisible: false,
			alertVisible: true,
			alertType: 'error',
			alertText: error.body?.message ?? message,
			isLoading: true,
			actionsEnabled: true,
		});

		this.handleSetTimeout();
		await this.updateState();
		this.setState({ isLoading: false });

		throw error;
	};

	handleAlertClose = () => {
		this.setState({
			alertVisible: false,
			alertText: '',
			alertType: 'default'
		});
	};

	handleSetTimeout = () => {
		if (this.timeoutId !== undefined) {
			window.clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(this.handleAlertClose, 10000);
	};

	render() {
		const tableRows = [];
		const statusRows = [];
		this.state.accountsOnPage.forEach((cloudAccount) => {
			const date = new Date(cloudAccount.workflowInstance.createdDate);
			const status = cloudAccount.workflowInstance.currentWorkflowInstanceState.workflowState.name;
			const approver = cloudAccount.approver;
			const rowData = () => (
				<>
					<Table.Body.Cell className='em-u-text-align-center'>
						<WorkflowStateBadge
							workflowState={cloudAccount.workflowInstance.currentWorkflowInstanceState.workflowState}
							fullWidth
						/>
					</Table.Body.Cell>
					<Table.Body.Cell className='em-u-text-align-center'>
						{approver ? 
							approver
							: "Entra ID Team"}
					</Table.Body.Cell>
					<Table.Body.Cell className='em-u-text-align-center'>
						{authenticatedUser.isInRole(UserRole.CloudAccountAdmin)
							? cloudAccount.workflowInstance.createdBy.displayName
							: cloudAccount.workflowInstance.currentWorkflowInstanceState.performedByUser.displayName}
					</Table.Body.Cell>
					<Table.Body.Cell className='em-u-text-align-center'>{moment(date).format('MM/DD/YYYY')}</Table.Body.Cell>
				</>
			);
			tableRows.push(
				<ActionRow
					key={cloudAccount.id}
					actionRef={this.actionRef}
					actionCell={
						cloudAccount.createdUserPrincipalName
					}
					actions={
						this.state.actionsEnabled &&
						<TreeNavigation>
							{status === WorkflowState.Approved.name && 
								<>
									<TreeNavigation.Item
										label='Open in Azure'
										onClick={() => {
											window.open(
												`https://portal.azure.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Profile/userId/${cloudAccount.createdUserPrincipalName}`,
												'_blank'
											);
											this.actionRef.current.click();
										}}
									/>
									<TreeNavigation.Item
										label='Reset Password'
										onClick={() => this.setState({
											resetPasswordModalVisible: true,
											selectedCloudAccount: cloudAccount,
										})}
									/>
									
									{!cloudAccount.createdUserPrincipalName.endsWith("@xomservices.com") && cloudAccount.canResetMFA &&
									<TreeNavigation.Item
										label = 'Reset MFA Configuration'
										onClick = {() => this.setState({
											resetMfaModalVisible: true,
											selectedCloudAccount: cloudAccount,
										})}
									/>} 
									
									<TreeNavigation.Item
										label='Delete Account'
										onClick={() => this.setState({
											deleteAccountModalVisible: true,
											selectedCloudAccount: cloudAccount,
										})}
									/>
								</>}
							{status === WorkflowState.Submitted.name && (approver == authenticatedUser.userEmail ||
							(authenticatedUser.isInRole(UserRole.CloudAccountAdmin) && !approver)) &&
								<>
									<TreeNavigation.Item
										label='Approve'
										onClick={() => this.setState({
											approveModalVisible: true,
											selectedCloudAccount: cloudAccount,
										})}
									/>
									<TreeNavigation.Item
										label='Reject'
										onClick={() => this.setState({
											rejectModalVisible: true,
											selectedCloudAccount: cloudAccount,
										})}
									/>
								</>}
							<TreeNavigation.Item
								label='View Request Information'
								onClick={() => this.setState({
									reasonModalVisible: true,
									selectedCloudAccount: cloudAccount
								})}
							/>
						</TreeNavigation>
					}
				>
					{rowData()}
				</ActionRow>
			);
		});

		this.state.selectedCloudAccount?.workflowInstance.workflowInstanceStates.forEach((state) => {
			statusRows.push(
				<TextArea
					label={`${state.workflowState.name} on ${moment(state.performedOnDate).format('MM/DD/YYYY')} by ${state.performedByUser.displayName}`}
					disabled
					value={`Reason: ${state.performedReason}`}
				/>);
		});

		return (
			<SectionHomeWrapper title="Cloud-only Accounts Management">
				<TextPassage>
					<p>
						For more information, please follow {' '}
						<TextLink
							target="_blank" external href="https://gotocloud.xom.cloud/cloudwiki/docs/admin/CloudSelfServicePortal.html#cloud-only-accounts"
						>							
							this link
						</TextLink>
					</p>
				</TextPassage>
				<br />
				{this.state.alertVisible && (
					<Alert
						variant={this.state.alertType}
						onClick={this.handleAlertClose}
						onClose={this.handleAlertClose}
					>
						{this.state.alertText}
					</Alert>
				)}
				<ConfirmModal
					visible={this.state.approveModalVisible}
					title={`Approve the cloud-only account request '${this.state.selectedCloudAccount?.createdUserPrincipalName}'?`}
					question={`Are you sure you want to approve this request? 
							   A notification will be sent to ${this.state.selectedCloudAccount?.workflowInstance.createdBy.displayName} (${this.state.selectedCloudAccount?.workflowInstance.createdBy.userPrincipalName.address}) 
							   upon confirmation informing them of this change.`}
					confirmButton={{ label: 'Confirm Approval' }}
					onConfirm={this.handleRequestApproved}
					onCancel={() => this.setState({ approveModalVisible: false })}
				/>
				<ConfirmModal
					visible={this.state.rejectModalVisible}
					title={`Reject the cloud-only account request '${this.state.selectedCloudAccount?.createdUserPrincipalName}'?`}
					question={`Are you sure you want to reject this request?
							   A notification will be sent to ${this.state.selectedCloudAccount?.workflowInstance.createdBy.displayName} (${this.state.selectedCloudAccount?.workflowInstance.createdBy.userPrincipalName.address}) 
							   upon confirmation informing them of this change.`}
					confirmButton={{ label: 'Confirm Rejection' }}
					confirmationField={{
						label: 'Please provide a reason for rejecting this request.',
						value: this.state.rejectionReason,
						validation: { required: true },
						onChange: (e) => this.setState({ rejectionReason: e.target.value }),
					}}
					onConfirm={this.handleRequestRejected}
					onCancel={() => {
						this.setState({ rejectModalVisible: false, rejectionReason: '' });

					}}
				/>
				<ConfirmModal
					visible={this.state.resetPasswordModalVisible}
					title={`Reset the password for '${this.state.selectedCloudAccount?.createdUserPrincipalName}'?`}
					question={`An email will be sent to ${this.state.selectedCloudAccount?.workflowInstance.owner.displayName}'s primary ExxonMobil email address with a link to complete the process.`}
					onConfirm={this.handlePasswordResetRequest}
					onCancel={() => this.setState({ resetPasswordModalVisible: false })}
				/>
				<ConfirmModal
					visible={this.state.resetMfaModalVisible}
					title={`Reset MFA configuration for '${this.state.selectedCloudAccount?.createdUserPrincipalName}'?`}
					question={`The MFA configuration for the selected account will be reset.`}
					onConfirm={this.handleMfaResetRequest}
					onCancel={() => this.setState({ resetMfaModalVisible: false })}
				/>
				{/* TODO: Move to separate component */}
				<Modal show={this.state.reasonModalVisible}>
					<Modal.Window>
						<Modal.Window.Header>
							<Modal.Window.Header.Title>
								{this.state.selectedCloudAccount?.createdUserPrincipalName}
							</Modal.Window.Header.Title>
							<Modal.Window.Header.CloseButton
								onClick={() => { this.setState({ reasonModalVisible: false }); }}
							>
								<XFilledIcon />
							</Modal.Window.Header.CloseButton>
						</Modal.Window.Header>
						<Modal.Window.Body id="reason-modal-body">
							{statusRows}
						</Modal.Window.Body>
						<Modal.Window.Footer>
							<Grid variant="2-up">
								<Grid.Item>
									<Button
										variant="secondary"
										onClick={() => { this.setState({ reasonModalVisible: false }); }}
									>
										Close
									</Button>
								</Grid.Item>
							</Grid>
						</Modal.Window.Footer>
					</Modal.Window>
				</Modal>
				{this.state.isLoading ? (
					<ProgressBar indeterminate hideValueLabel label="Loading your accounts..." />
				) : (
					<Table
						header={
							<Toolbar>
								<Toolbar.Item>
									<TextInput
										onChange={this.handleSearchTextChange}
										value={this.state.searchText}
										className="em-u-margin-bottom-none"
										placeholder="Search accounts"
										size="small"
									/>
								</Toolbar.Item>
								<Toolbar.Item>
									<Select
										className="em-u-margin-bottom-none dropdown-filter-color"
										size="small"
										onChange={this.handleStatusDropdownChange}
										value={this.state.searchDropdownStatus}
									>
										<option className='dropdown-filter-color' value="All">All</option>
										<option className='dropdown-filter-color' value="Submitted">Submitted</option>
										<option className='dropdown-filter-color' value="Approved">Approved</option>
										<option className='dropdown-filter-color' value="Rejected">Rejected</option>
										<option className='dropdown-filter-color' value="Deleted">Deleted</option>
										<option className='dropdown-filter-color' value="Error">Error</option>
									</Select>
								</Toolbar.Item>
								<Toolbar.Item right>
									{this.state.availableAccountTypes.length === 0 ? (
										<Tooltip
											content={
												<Button
													disabled
													variant="primary"
													size="small"
												>
													<PlusFilledIcon size="small" />
													&nbsp; Request Cloud-only Account
												</Button>
											}
										>
											Our records show you already have an active account for each of the supported account types listed above.
										</Tooltip>
									) : (
										<Button
											variant="primary"
											size="small"
											onClick={() => this.setState({ requestAccountModalVisible: true })}
										>
											<PlusFilledIcon size="small" />
											&nbsp; Request Cloud-only Account
										</Button>
									)}
								</Toolbar.Item>
							</Toolbar>
						}
						footer={
							<Paginator
								data={this.state.filteredAccounts}
								onPageChange={(accounts: CloudAccount[]) => {
									this.setState({
										accountsOnPage: accounts
									});
								}}
							/>
						}
					>
						<Table.Head>
							<Table.Head.Row>
								<Table.Head.Cell>Account Name</Table.Head.Cell>
								<Table.Head.Cell className='em-u-text-align-center'>Status</Table.Head.Cell>
								<Table.Head.Cell className='em-u-text-align-center'>Approver</Table.Head.Cell>
								<Table.Head.Cell className='em-u-text-align-center'>
									{authenticatedUser.isInRole(UserRole.CloudAccountAdmin)
										? 'Requested By'
										: 'Last Modified By'}
								</Table.Head.Cell>
								<Table.Head.Cell className='em-u-text-align-center'>Date Requested</Table.Head.Cell>
							</Table.Head.Row>
						</Table.Head>
						<Table.Body>{tableRows}</Table.Body>
					</Table>
				)}
				<CloudAccountRequestModal
					visible={this.state.requestAccountModalVisible}
					availableAccountTypes={this.state.availableAccountTypes}
					onSubmit={this.handleSubmit}
					onSuccess={this.handleSuccess}
					onError={this.handleError}
					onClose={() => this.setState({
						requestAccountModalVisible: false
					})}
				/>
				<CloudAccountDeletionModal
					visible={this.state.deleteAccountModalVisible}
					cloudAccount={this.state.selectedCloudAccount}
					onSubmit={this.handleSubmitAlert}
					onChange={this.handleAccountChange}
					onSuccess={this.handleSuccessAlert}
					onError={this.handleError}
					onClose={() => this.setState({
						deleteAccountModalVisible: false
					})}
				/>
			</SectionHomeWrapper>
		);
	}
}
