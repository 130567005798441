import { Enumeration } from '../../../utils/AppEnum';

export class RuleOperation extends Enumeration<RuleOperation> {
	public static Remove = new RuleOperation('Remove', 0);

	public static Add = new RuleOperation('Add', 1);

	public static Modify = new RuleOperation('Modify', 2);

	public static Keep = new RuleOperation('Keep', 3);
}
