import moment from 'moment';
import ChangeHistory from '../../../models/entities/ChangeHistory';

export type ChangeHistoryColumn = {
    header: string;
	cell: (rule: ChangeHistory) => string;
	limit?: number;
};

export const ChangeHistoryColumnsLayout = new Array<ChangeHistoryColumn>();

ChangeHistoryColumnsLayout.push(
	{
		header: 'Change Date Time',
		cell: (change: ChangeHistory) => moment(change.timestamp).format('MM/DD/YYYY HH:mm:ss')
	},
	{ 
		header: 'Responsible User',
		cell: (change: ChangeHistory) => change.responsibleUser?.displayName ?? '-' 
	},
	{ 
		header: 'Field', cell: (change: ChangeHistory) => change.field 
	},
	{ 
		header: 'Old Value',
		cell: (change: ChangeHistory) => change.oldValue ?? 'null'
	},
	{
		header: 'New Value',
		cell: (change: ChangeHistory) => change.newValue ?? 'null'
	}
);
