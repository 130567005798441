import * as React from 'react';
import { Grid, Button, Alert } from 'react-unity';
import RequestFormWrapper from '../common/wrappers/RequestFormWrapper';
import UserLookup from '../common/form-controls/UserLookup';
import TextAreaField from '../common/form-controls/TextareaField';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import { EnvironmentInput, DNDRTypeInput, RulesTable } from './components';
import { AbstractDNDRForm, AbstractDNDRFormProps, IDNDRStateForm, AbstractDNDRFormState } from './AbstractDNDRForm';
import { TenantLabel } from '../../models/enums/TenantLabel';
import { DoNotDecryptRequestsDirection } from '../../models/enums/DNDR/DoNotDecryptRequestsDirection'; 
import DoNotDecryptRequest from '../../models/entities/DoNotDecryptRequest'; 
import AlertModal from '../common/modals/AlertModal';
import SubmitDoNotDecryptRequest from '../../models/viewModels/DoNotDecryptRequests/SubmitDoNotDecryptRequest'; 
import SubmitDoNotDecryptRequestRule from '../../models/viewModels/DoNotDecryptRequests/SubmitDoNotDecryptRequestRule'; 
import ConfirmModal from '../common/modals/ConfirmModal';
import DoNotDecryptRule from '../../models/entities/DoNotDecryptRule';			
import { VirtualNetwork } from '../../models/entities/AzureResources/VirtualNetwork';
import { RuleOperation } from '../../models/enums/DNDR/RuleOperation';
import { useLocation, useNavigate, useParams } from 'react-router-dom';


interface DoNotDecryptRequestsCreateProps extends AbstractDNDRFormProps {
	draftRequest?: DoNotDecryptRequest;
}

interface DoNotDecryptRequestsCreateWithParamsProps extends AbstractDNDRFormProps {
	draftRequest?: DoNotDecryptRequest;
}

interface StateForm extends IDNDRStateForm {
	selectedScope: string;
}

interface ILocationState {
	selectedRules?: DoNotDecryptRule[]			
}

interface DoNotDecryptRequestsCreateWithParamsState extends AbstractDNDRFormState<StateForm> {
	subscription: SubscriptionRequest | null;	
	cancelModalVisible: boolean;
	changeScopeModalVisible: boolean;
	implementedRules: DoNotDecryptRule[];
	newScope: string; 
	subVirtualNetworks: VirtualNetwork[];
	loading: boolean;
	subToInternetWarningVisible: boolean;
	loadingImplementedRules: boolean;
	selectedFromExisting: string[];
}

class DoNotDecryptRequestsCreateWithParams extends AbstractDNDRForm
	<DoNotDecryptRequestsCreateWithParamsProps, DoNotDecryptRequestsCreateWithParamsState>{

	constructor(props) {
		super(props);
		this.state = {
			subscription: null,
			stateForm: {
				selectedScope: "SubscriptionToInternet",
				contact: null,
				isEmergency: false,
				customerCoordination: null,
				businessJustification: '',
				ruleList: [],
				// custodianApproval: false,
				//sevenDayExemption: false,
			},
			endModal: {
				visible: false,
			},
			submissionAlert: {
				visible: false,
			},
			submissionWarned: false,
			operationInProgress: false,
			cancelModalVisible: false,
			changeScopeModalVisible: false,
			implementedRules: [],
			newScope: null,
			subVirtualNetworks: [],
			loading: true,
			subToInternetWarningVisible: true,
			loadingImplementedRules: true,
			selectedFromExisting: []
		};
		this.initFormModel();
	}
	
	initFormModel() {
		super.initFormModel();
		
		 this.formModel.addField('direction', {
		 	getValue: () => this.state.stateForm.selectedScope = "SubscriptionToInternet", 
		 	validation: {
		 		required: true,
		 	},
		 });
		this.formModel.fields.doNotDecryptRules.getValue =
			() => this.state.stateForm.ruleList.map(rule => new SubmitDoNotDecryptRequestRule(rule)); 

		this.formModel.addField('subscriptionRequestId', {
			getValue: () => this.props.draftRequest?.subscriptionRequestId || this.state.subscription?.id,
		});

		this.formModel.addField('doNotDecryptRequestId', { 
			getValue: () => this.props.draftRequest?.id,
		});
		

	}
	
	stateFormFromRequest(dndr: DoNotDecryptRequest): StateForm {
		let obj = {
			...super.stateFormFromRequest(dndr),
			selectedScope: "SubscriptionToInternet",
		};
		return {...obj, ruleList: obj.ruleList.map(rule => {return {...rule, azureName: undefined}})}
	}

	async setImplementedRules(subId: number) {
		const implementedRules = await this.doNotDecryptRequestService.getRulesBySubscriptionId(subId); 
		const subVirtualNetworks = await this.subscriptionRequestService.getAddressSpaces(subId);
		this.setState({
			implementedRules,
			subVirtualNetworks,
			loadingImplementedRules: false
		})
	}
	
	async componentDidMount() {
		window.scroll(0, 0);
		if (!this.props.draftRequest) {
			const id = parseInt(this.props.params.id);
			const subscription = await this.getSubscription(id);
			this.setState({
				subscription,
			});
			this.setImplementedRules(id);
		} else {
			const subscription = await this.getSubscription(this.props.draftRequest?.subscriptionRequestId);
			const implementedRules = await this.doNotDecryptRequestService.getRulesBySubscriptionId( 
				this.props.draftRequest?.subscriptionRequestId
			);
			this.setState({
				stateForm: this.stateFormFromRequest(this.props.draftRequest),
				subscription,
				implementedRules
			});
		}
		if (this.props.location.state){
			const { selectedRules }: ILocationState = this.props.location.state;
			this.handleStateFormChange('ruleList', selectedRules.map(rule => {return {...rule, operation: RuleOperation.Modify}}));
			this.setState({
				selectedFromExisting: selectedRules.map(rule => rule.name)
			})
		}
		this.setState({
			loading: false
		});
	}

	async getSubscription(id) {
		const subscription = await super.getSubscription(id);
		
		if (!subscription.canSubmitDoNotDecryptRequests()) {
			this.handleEndModal('You are not allowed to submit a request.', 'error', 5000, '/subscriptions');
		}

		return subscription;
	}

	returningRoute = () => {
		return `/subscriptions/${this.state.subscription?.id}/DoNotDecryptRequestsHistory`;
	};

	handleCancel = () => {
		this.setSubmissionAlertInProgress('Cancelling...');
		this.setState({
			cancelModalVisible: false,
		}, async () => {
			try {
				await this.doNotDecryptRequestService.cancel(this.props.draftRequest?.id, { 
					cancellationReason: 'Draft dismissed',
				});
				this.handleEndModal(
					'Request cancelled sucessfully.',
					'success',
					5000,
					this.returningRoute()
				);
			} catch (err) {
				this.setSubmissionAlertError(err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
			} finally {
				this.setState({
					operationInProgress: false,
				});
			}
		});
	};

	handleSubmit = async () => {
		this.setSubmissionAlertInProgress('Submitting your request...');
		try {
			const model = this.formModel.create(SubmitDoNotDecryptRequest); 
			const request = await this.doNotDecryptRequestService.create(model); 
			this.handleEndModal(
				`Request #${request.id} was submitted successfully.`,
				'success',
				5000,
				this.returningRoute()
			);
		} catch (err) {
			this.setSubmissionAlertError(err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
		} finally {
			this.setState({
				operationInProgress: false,
			});
		}
	};

	handleUpdate = async () => {
		this.setSubmissionAlertInProgress('Saving changes...');
		try {
			const model = this.formModel.create(SubmitDoNotDecryptRequest);
			const request = await this.doNotDecryptRequestService.saveDraft(model);
			this.handleEndModal(
				`Request #${request.id} was saved successfully.`,
				'success',
				5000,
				this.returningRoute()
			);
		} catch (err) {
			this.setSubmissionAlertError(err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
		} finally {
			this.setState({
				operationInProgress: false,
			});
		}
	};

	handleScopeChange = (event: any) => {
		if (this.state.stateForm.ruleList.length > 0) {
			this.setState({ changeScopeModalVisible: true, newScope: event.target.value });
		} else {
			this.handleStateFormChange('selectedScope', event.target.value);
			this.handleStateFormChange('approver', null);
			this.handleStateFormChange('delegatedApprover', null);
		}
			
	};

	confirmScopeChange = () => {
		this.handleStateFormChange('selectedScope', this.state.newScope);
		this.handleStateFormChange('ruleList', []);
		this.handleStateFormChange('approver', null);
		this.setState({
			changeScopeModalVisible: false,
			newScope: null
		});
	};

	ruleIsFromSelectedDirection(rule: DoNotDecryptRule): boolean {
		return rule.direction.name === "SubscriptionToInternet";
	}

	ruleIsAddedAlready(rule: DoNotDecryptRule): boolean {				
		return this.state.stateForm.ruleList
			.map(editedRule => editedRule.azureName).includes(rule.azureName);
	}

	getImplementedRulesToShow(): DoNotDecryptRule[] {				
		return this.state.implementedRules.filter(rule =>
			this.ruleIsFromSelectedDirection(rule) && !this.ruleIsAddedAlready(rule) && !this.state.selectedFromExisting.includes(rule.name));
	}

	setSelectedFromExisting = (rulesName: string[]) => {
		this.setState({
			selectedFromExisting: rulesName
		})
	}

	render() {
		return (
			<>
				<RequestFormWrapper
					title="New DPI (Deep Packet Inspection) Exception Request"
					linkTree={this.getLinkTree()}
					loading={this.state.loading}
				>
					{this.state.submissionAlert.visible &&
						<Alert
							variant={this.state.submissionAlert.variant}
							onClose={this.handleSubmissionAlertClose}
						>
							{this.state.submissionAlert.text}
						</Alert>}
					<Grid variant="halves">
						<Grid.Item>
							<DNDRTypeInput
								scope={{
									value: this.state.stateForm.selectedScope,
									validation: this.formModel.fields.direction.validation,
									onChange: this.handleScopeChange,
									disabled: this.state.operationInProgress,
								}}
							/>  
						</Grid.Item>						
						{this.state.subscription?.tenant.id === TenantLabel.ExxonMobilTest &&
							<Grid.Item>
								<EnvironmentInput
									{...this.stateFormHandler().environment}
									disabled={this.state.operationInProgress}
								/>
							</Grid.Item>}
					</Grid>
					<Grid variant="halves">
						<Grid.Item>
							<UserLookup
								label="Technical contact"
								{...this.stateFormHandler().contact}
								disabled={this.state.operationInProgress}
							/>
						</Grid.Item>
					</Grid>					
					{/* <ToggleField
						{...this.stateFormHandler().custodianApproval}
						options={[
							{ value: 'true', text: 'Yes' },
							{ value: 'false', text: 'No' },
						]}
						disabled={this.state.operationInProgress}
					/> */}
					{/* <ToggleField
						{...this.stateFormHandler().sevenDayExemption}
						options={[
							{ value: 'true', text: 'Yes' },
							{ value: 'false', text: 'No' },
						]}
						disabled={this.state.operationInProgress}
					/> */}
					
					<TextAreaField
						label="Business Justification"
						{...this.stateFormHandler().businessJustification}
						disabled={this.state.operationInProgress}
					/>
					<RulesTable
						{...this.stateFormHandler().ruleset}
						dndrDirection={new DoNotDecryptRequestsDirection().fromName(this.state.stateForm.selectedScope)}
						readonly={this.state.operationInProgress}
						subscriptionRequestName={this.state.subscription?.createdSubscriptionName}
						implementedRules={this.getImplementedRulesToShow()}
						validVirtualNetworks={this.state.subVirtualNetworks}
						loadingImplementedRules={this.state.loadingImplementedRules}
						selectedFromExisting={this.state.selectedFromExisting}
						setSelectedFromExisting={this.setSelectedFromExisting}
					/>
					{this.state.subscription == null && (
						<TextAreaField
							label="Additional Comments"
							{...this.stateFormHandler().comments}
							disabled={this.state.operationInProgress}
						/>
					)}
					<Grid variant="4-up">
						<Grid.Item>
							{!!this.props.draftRequest && this.props.draftRequest?.isCancellable() &&
								<Button
									variant="secondary"
									onClick={() => {
										this.setState({
											cancelModalVisible: true,
											operationInProgress: true,
										});
									}}
									disabled={this.state.operationInProgress}
								>
									Cancel Request
								</Button>}
						</Grid.Item>
						<Grid.Item />
						<Grid.Item>
							<Button
								variant="primary"
								disabled={!this.formModel.isValid()}
								loading={this.state.operationInProgress}
								onClick={this.handleUpdate}
								className="z-index-0"
							>
								Save & Close
							</Button>
						</Grid.Item>
						<Grid.Item>
							<Button
								variant="primary"
								disabled={!this.formModel.isValid()}
								loading={this.state.operationInProgress}
								onClick={this.handleSubmit}
								className="z-index-0"
							>
								Submit Request
							</Button>
						</Grid.Item>
					</Grid>
				</RequestFormWrapper>
				<ConfirmModal
					visible={this.state.cancelModalVisible}
					title={`Cancel DNDR #${this.props.draftRequest?.id}`}
					question="Are you sure you want to cancel this request? This action cannot be undone."
					confirmButton={{
						label: 'Cancel Request',
						props: {
							variant: 'primary',
							color: 'negative'
						}
					}}
					onConfirm={this.handleCancel}
					onCancel={() => {
						this.setState({
							cancelModalVisible: false,
							operationInProgress: false
						});
					}}
				/>
				<AlertModal
					{...this.state.endModal}
					willTimeout={false}
					onClose={this.endAndRedirect}
				/>
			</>
		);
	}
}

const DoNotDecryptRequestsCreate = (props: DoNotDecryptRequestsCreateProps) => {

    return <DoNotDecryptRequestsCreateWithParams draftRequest={props.draftRequest} location={useLocation()} navigate={useNavigate()} params={useParams()}/>;
}

export default DoNotDecryptRequestsCreate