import { TOption, Option } from "../../../utils/Option";
import { FieldProps, Field } from "./Field";
import { FieldProps as UnityFieldProps } from 'react-unity';

export interface AbstractSelectableProps extends FieldProps {
	options: TOption[];
}

export interface AbstractSelectableState {
	options: Option[];
}

export abstract class AbstractSelectable
	<UnityProps extends UnityFieldProps,
	Props extends AbstractSelectableProps,
	State extends AbstractSelectableState>
	extends Field<UnityProps, Props, State> {
	

	setOptions() {
		this.setState({
			options: this.props.options.map((op: TOption) => new Option(op))
		})
	}


	componentDidMount() {
		this.setOptions();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.options !== this.props.options || prevProps.options.length !== this.props.options.length) {
			this.setOptions();
		}
	}
}