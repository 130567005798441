import * as React from 'react';
import { Modal, Field, Grid, XFilledIcon, Button, Alert, ProgressBar} from 'react-unity';
import ReadonlyField from '../../common/form-controls/ReadonlyField';
import UserLookup from '../../common/form-controls/UserLookup';
import { useEffect, useState } from 'react';
import UserLookupObject from '../../../models/UserLookupObject';
import ReactButton from '../../common/ReactButton';
import TemporaryAccessPasswordService from '../../../services/TemporaryAccessPasswordService';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import AlertModal from '../../common/modals/AlertModal';

interface TAPModalOnBehalfOfProps {
  visible: boolean,
  setVisible:any
  tapService: TemporaryAccessPasswordService;
  refreshTap:any;
}

const TAPModalOnBehalfOf = ({visible, setVisible, tapService, refreshTap}:TAPModalOnBehalfOfProps) => {
    
  const [employee, setEmployee] = useState<UserLookupObject>();
  const [enableRequestButton, setEnableRequestButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVerifyingSup, setIsVerifyingSup] = useState<boolean>(false);
  const [tap, settap] = useState<string>('');
  const [ alertBanner, setAlertBanner ] = useState<AlertBanner>({
    visible: false,
    variant: 'default'
	});

  useEffect(() => {
    settap('');
    enableRequest();
}, [employee]);

  const endAndRedirect = () => {
		setAlertBanner({ visible: false });
	};

  const handleRequest = async () => {
    try {
      setIsLoading(true);

      const response = await tapService.createOnBehalfOfTap(employee.id);
      if (response.taPass != null) {
        settap(response.taPass);
        setEnableRequestButton(false);
      } else {
        setAlertBanner({
          visible: true,
          text: `TAP could not be generated, please try again or submit a feedback for getting assistance`,
          variant: 'error'
        });
      }
      setIsLoading(false);
    }
    catch (err) {
      setIsLoading(false);
      settap('');
      setAlertBanner({
        visible: true,
        text: err.body.message,
        variant: 'error'
      });
      console.log(err.body.message);
    }
  }

  const handleClose = async () => {
    if(tap != ''){
      refreshTap(true);}
    setVisible(false);
  }

  //Validates if requestor is the supervisor of the selected employee
  const enableRequest = async () => {
    try {
      if (employee) {
        setIsVerifyingSup(true);
        const response = await tapService.getSupVerifycation(employee.userPrincipalName);
        setIsVerifyingSup(false);
        setEnableRequestButton(true);
      }
      else {
        setEnableRequestButton(false);
        setIsVerifyingSup(false);
      }
    }
    catch (err) {
      setEnableRequestButton(false);
      setIsVerifyingSup(false);
      setAlertBanner({visible: true, text: err.body.message, variant: 'error'})
      console.log(err);
    }
  }


    return (
      <Modal show={visible}
        onHide={() => { }}>
        <Modal.Window id="TAPModalOnBehalfOf" className="em-u-margin-double">
          <Modal.Window.Header>
            <Modal.Window.Header.Title>TAP Request</Modal.Window.Header.Title>
            <Modal.Window.Header.CloseButton onClick={() => setVisible(false)}>
              <XFilledIcon size='small' />
            </Modal.Window.Header.CloseButton>
          </Modal.Window.Header>
          <Modal.Window.Body>
            {(isLoading || isVerifyingSup ) ?
              <ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label={isLoading ? 'Getting TAP' : 'Verifying Supervisor'} />
              :
              <>
                <UserLookup
                  id="employee-lookup"
                  label="On Behalf Of:"
                  note={'You must be the Supervisor of the selected employee'}
                  value={employee?.displayName}
                  onChange={(value) => setEmployee(value)} />
                <AlertModal {...alertBanner} willTimeout={false} onClose={endAndRedirect} />
                <Field>
                  <Field.Body>
                    <ReadonlyField label="Temporary Access Password" text={tap} copy={true} />
                  </Field.Body>
                </Field>
              </>
            }
            <Alert
              variant='warning'
            >
              Please keep track of this passcode. The passcode is valid for 60 minutes and you cannot generate another passcode until the current passcode expires.
            </Alert>
          </Modal.Window.Body>
          <Modal.Window.Footer>
            <Grid variant="2-up">
              <Grid.Item style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                <Button
                  variant="secondary"
                  onClick={() => handleClose()}
                  disabled = {isLoading}
                >
                  Close
                </Button>
              </Grid.Item>
              <Grid.Item style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                <ReactButton
                  isLoading={isLoading || isVerifyingSup}
                  variant="primary"
                  handleUpdateSubmit={() => handleRequest()}
                  disabled={!enableRequestButton}
                  name='Request'
                  loadingVariant='secondary'
                >
                </ReactButton>
              </Grid.Item>
            </Grid>
          </Modal.Window.Footer>
        </Modal.Window>
      </Modal>
    );
}

export default TAPModalOnBehalfOf;
