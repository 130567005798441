export default class GroupAccessReview {
    //id!: string;
  
      // eslint-disable-next-line react/static-property-placement
      name!: string;

      description!: string;
  
      duration!: number;

      startingDate!: string;
  
      recurrence!: number;
  
      endMethod!: number;

      endOccurrency!: string;
  
      endDate!: string;

      constructor(obj: any) {
          Object.assign(this, obj);
      }
  }
  