import * as React from 'react';
import { Modal, Button, Grid, XFilledIcon, LinkExternalIcon, Alert, ImageWithCaption, TextLink } from 'react-unity';
import permissionReportImage from '../../../images/PermissionReport.png';
import SubscriptionRequest from '../../../models/entities/SubscriptionRequest';
import './PermissionReportModal.css';

interface PermissionReportModalProps {
	visible: boolean;
	onClose: (event: React.ChangeEvent<any>) => void;
	subscription?: SubscriptionRequest;
}

interface PermissionReportModalState {
}

export default class PermissionReportModal extends React.Component<
	PermissionReportModalProps,
	PermissionReportModalState
	> {

	render() {
		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Modal.Window id="PermissionReportModal" className="em-u-margin-double">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>Permission Report</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<Alert
							variant="warning"
							className="no-alert-actions"
						>
							Download role assignments is currently available
							in the Azure portal. The ‘Download role
							assignments’ button will be visible as long as you
							have any assigned role on the subscription". <br/><br/>

							For more information, <TextLink target="_blank" external href="https://appwiki.xom.cloud/docs/admin/cloudssp/how-to-perform-access-review.html"> read our documentation.</TextLink>
						</Alert>
						<ImageWithCaption
							src={permissionReportImage}
							caption=""
						/>
					</Modal.Window.Body>					
					<Modal.Window.Footer>
						<Grid variant="2-up">
							<Grid.Item>
								<Button variant="secondary" onClick={this.props.onClose}>
									Cancel
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Button
									variant="primary"
									onClick={() =>
										window.open(`https://portal.azure.com/#@${this.props.subscription?.tenant.name}.onmicrosoft.com/resource/subscriptions/${this.props.subscription?.createdSubscriptionId}/users`)}
								>
									<LinkExternalIcon size="small" />&nbsp;
									<span>Go to Azure Portal</span>&nbsp;
								</Button>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}
