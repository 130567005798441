import React from 'react';
import { Container, TwoColumnLayout, TextPassage } from 'react-unity';
import { LinkTree, LinkTreeItem } from '../LinkTree';
import styled from 'styled-components';

interface SectionHomeWrapperProps {
	title: string;
	subtitle?: string;
	linkTree?: LinkTreeItem[];
	reference?: string;
	soft?: boolean;
	children?: any;
}

interface SubtitleProps {
	soft?: boolean;
}

const Subtitle = styled.span<SubtitleProps>`
	margin: 0px !important;
	font-size: 18px;
	${p => p.soft ? 'color: gray' : ''}
`

const SectionHomeWrapper = ({ linkTree, subtitle, title, reference, soft, children }: SectionHomeWrapperProps) => {
	return (
		<Container className="em-u-margin-top-double em-u-margin-bottom-double">
			{linkTree?.length > 0 && <LinkTree links={linkTree} />}
			<TwoColumnLayout>
				<TwoColumnLayout.Main>
					<TextPassage>
						<h1 style={soft ? {fontSize:32} : {}}>{reference ? reference + ' > ' + title : title}</h1>
						{subtitle && <Subtitle soft={soft}>{subtitle}</Subtitle>}
					</TextPassage>
				</TwoColumnLayout.Main>
			</TwoColumnLayout>
			{children}
		</Container>
	);
};

export default SectionHomeWrapper;