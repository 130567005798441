import { PolicyDefinitionResponse } from "../../../models/viewModels/PolicyExemptionRequests/PolicyDefinitionResponse";

// TODO: Add ColumnLayout<T> class in common
export type PolicyDefinitionColumn = {
	header: string;
	cell: (policyDefinition: PolicyDefinitionResponse) => string;
	limit?: number;
};

export const PolicyDefinitionsColumnsLayout: PolicyDefinitionColumn[] = [
    {
		header: 'Name',
		cell: (policyDefinition: PolicyDefinitionResponse) => policyDefinition.properties.displayName,
	},
	{
		header: 'Type',
		cell: (policyDefinition: PolicyDefinitionResponse) => policyDefinition.properties.policyType,
	},
	{
		header: 'Approver',
		cell: (policyDefinition: PolicyDefinitionResponse) => policyDefinition.properties.metadata.xomExemptionApprover,
	},
	{
		header: 'Risk Category',
		cell: (policyDefinition: PolicyDefinitionResponse) => policyDefinition.properties.metadata.xomRiskRating,
	}
];