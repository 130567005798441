import React, { useState, useEffect } from 'react';
import { Button, Searchbar, Select, Toolbar } from 'react-unity';
import NetworkSecurityGroup from '../../models/entities/NetworkSecurityGroup';
import Filter from '../common/tables/Filter';
import RequestFormWrapper from '../common/wrappers/RequestFormWrapper';
//import './SubscriptionsHome.css';ahi ando

type NetworkSecurityGroupsHomeFilters = {
	searchGeneral: string;
	dateRangeFrom: Date;
	dateRangeTo: Date;
	orderBy: string;
};

interface NetworkSecurityGroupsHomeFiltersProps {
	setFilteredNSGs: any;
	allNSGs: NetworkSecurityGroup[];
    nsgOnPage: NetworkSecurityGroup[];
}

const NetworkSecurityGroupsHomeFilters = ({
	setFilteredNSGs,
	allNSGs,
    nsgOnPage
}: NetworkSecurityGroupsHomeFiltersProps) => {
	const defaultFilters = {
		searchGeneral: '',
		dateRangeFrom: new Date('January 01, 2000 23:15:30'),
		dateRangeTo: new Date(),
		orderBy: 'newest',
	} as NetworkSecurityGroupsHomeFilters;


	const filterNSGs = () => {
		let filtered = [];

		const filterCallback = (nsg: NetworkSecurityGroup) => {
			return (
				filter.filterSearchText('searchGeneral', nsg.blobHour.toString()) ||
				filter.filterSearchText('searchGeneral', nsg.blobDate)  
				);
			
		}; 

		filtered = allNSGs.filter(filterCallback);

		setFilteredNSGs(filtered);
	};

	useEffect(() => {
		filter.updateFilterFunction(filterNSGs);
	}, [allNSGs]);

	useEffect(() => {
		filter.filter();
	}, []);

	const [filter] = useState<Filter<NetworkSecurityGroupsHomeFilters>>(new Filter(filterNSGs, defaultFilters));

	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchGeneral');
	};

    return (
        <>
            {filter && (
                <>
                    <Toolbar>
                        <span>BLOB LISTS OF NETWORK SECURITY GROUP FLOW LOGS THAT ARE AVAILABLE IN STORAGE ACCOUNT (RETENTION PERIOD 10 DAYS).</span>
                    </Toolbar>
                    <Toolbar id="toggle-toolbar">
						<Toolbar.Item className="searchbar-width">
							<Searchbar
								condensed
								buttonProps={{ onClick: clearSearchbar}}
								inputProps={{
									value: filter.filters.searchGeneral,
									onChange: (e) => filter.handleSearchTextChange(e, 'searchGeneral'),
									placeholder: 'Search by Date or Time.',
								}}
							/>
						</Toolbar.Item>
						<Toolbar.Item right>
                            <span>Total Result: {nsgOnPage?.length} / {allNSGs?.length}</span>
						</Toolbar.Item>
					</Toolbar>
					<Toolbar>
						<Toolbar.Item>
							<span
								className="clear-filters"
								onClick={() =>
									filter.updateFilters({ ...defaultFilters })
								}
							>
								Clear filters
							</span>
						</Toolbar.Item>
						<Toolbar.Item right>
							<Button.Group>
								<p className="sort-by-p">Sort by</p>
								<Select
									style={{ marginBottom: -10, cursor: 'pointer' }}
									value={filter.filters.orderBy}
									onChange={(e) => filter.handleSearchTextChange(e, 'orderBy')}
								>
									<option value="newest">Newest</option>
									<option value="oldest">Oldest</option>
								</Select>
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>
				</>
			)}
		</>
	);
};

export default NetworkSecurityGroupsHomeFilters;
