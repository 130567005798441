import * as React from 'react';
import {
	TextPassage,
	BulletedList,
	RefreshIcon,
	TextLink,
	Table,
	Toolbar,
	Button,
	Select,
	TextInput,
	PlusFilledIcon,
	ProgressBar,
	Alert,
} from 'react-unity';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import '../common/wrappers/SectionHomeWrapper.css';
import CloudGroupsModal from './components/CloudGroupsModal';
import CloudGroupsAccessReviewModal from './components/CloudGroupsAccessReviewModal';
import GroupsService from '../../services/GroupsService';
import Group from '../../models/Group';
import Paginator from '../common/tables/Paginator';
import './CloudGroupsHome.css'
import { group } from 'console';
import { getgroups } from 'process';
import Filter from '../common/tables/Filter';

interface CloudGroupsHomeProps { }

interface CloudGroupsHomeState {
	groups: Group[];
	filteredGroups: Group[];
	groupsPage: Group[];
	searchText: '';
	filterValue: string;
	modalVisible: boolean;
	modalVisibleAccessReview: boolean;
	alertVisible: boolean;
	alertVisibleAccessReview: boolean;
	isLoading: boolean;
	groupSelected: Group;
}

export default class CloudGroupsHome extends React.Component<CloudGroupsHomeProps, CloudGroupsHomeState> {
	groupsService: GroupsService;

	constructor(props: CloudGroupsHomeProps) {
		super(props);
		this.state = {
			groups: [],
			filteredGroups: [],
			groupsPage: [],
			searchText: '',
			filterValue: 'All',
			modalVisible: false,
			modalVisibleAccessReview: false,
			alertVisible: false,
			alertVisibleAccessReview: false,
			isLoading: true,
			groupSelected: null,
		};

		this.groupsService = new GroupsService();
	}

	async componentDidMount() {
		this.handleRefresh();
	}

	updateState = async () => {
		const groups = await this.groupsService.getMyGroups();
		groups.sort((a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime());
		this.setState({
			groups,
			filteredGroups: groups,
		});
	};

	handleModalConfirmed = async () => {
		await this.updateState();
		this.setState({ alertVisible: true,	modalVisible: false });

		setTimeout(() => {
			this.setState({
				alertVisible: false
			});
		}, 5000);
		await this.updateState();
	};

	handleModalClosed = () => {
		this.setState({ modalVisible: false });
	};

	handleModalAccessReviewConfirmed = async () => {
		this.setState({ modalVisibleAccessReview: false, alertVisibleAccessReview: true });
		await this.updateState();
		setTimeout(() => {
			this.setState({
				alertVisibleAccessReview: false
			});
		}, 5000);
	};

	handleModalAccessReviewClosed = () => {
		this.setState({ modalVisibleAccessReview: false });
	};

	handleSearchTextChange = (e) => {
		const searchText = e.target.value;

		const visibleGroups = this.getFilteredGroups(searchText, this.state.filterValue);

		this.setState({
			filteredGroups: visibleGroups,
			searchText,
		});
	};

	handleFilterChange = (e) => {
		const filterValue = e.target.value;

		const visibleGroups = this.getFilteredGroups(this.state.searchText, filterValue);

		this.setState({
			filteredGroups: visibleGroups,
			filterValue,
		});
	};

	getFilteredGroups = (textToSearch: string, selectedType: string) => {
		const visibleGroups = this.state.groups.filter(group =>
			group.displayName.toLowerCase().includes(textToSearch.trim().toLowerCase()));

		return selectedType === 'All'
			? visibleGroups
			: visibleGroups.filter((group) => {
				const membershipType = group.membershipRule == null ? 'Assigned' : 'Dynamic';
				return membershipType === selectedType;
			});
	};


	handleAlertClose = () => {
		this.setState({ alertVisible: false });
	};

	async handleCreateAccessReview(group: Group){
		this.setState({
			groupSelected: new Group(group),
			modalVisibleAccessReview: true,
		});
		await this.updateState();
	}

	handleRefresh = async () => {
		this.setState({ isLoading: true });
		await this.updateState();
		this.setState({ isLoading: false });
	};

	render() {
		const tableRows = [];
		this.state.groupsPage.forEach((group) => {
			tableRows.push(
				<Table.Body.Row key={group.id}>
					<Table.Body.Cell>
						<TextLink
							onClick={() =>
								window.open(
									`https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Overview/groupId/${group.id}`,
									'_blank'
								)}
						>
							{group.displayName}
						</TextLink>
					</Table.Body.Cell>
					<Table.Body.Cell>{group.membershipRule == null ? 'Assigned' : 'Dynamic'}</Table.Body.Cell>
					<Table.Body.Cell style={{display: 'flex', justifyContent: 'space-around'}}>
								<Button
									onClick={() =>
										window.open(
											group.membershipRule == null
												? `https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Members/groupId/${group.id}`
												: `https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/DynamicGroupMembershipRule/groupId/${group.id}`,
											'_blank'
										)}
								>
									Manage Membership
								</Button>
								{group.membershipRule == null &&
								<>
									<Button
										onClick={() => this.handleCreateAccessReview(group)}
									>
										Create Access Review
									</Button>
									<Button
										onClick={() =>
											window.open(
												`https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/AccessReviews/groupId/${group.id}`,
												'_blank'
											)}
									>
										See Access Review
									</Button>
								</>}
					</Table.Body.Cell>
				</Table.Body.Row>
			);
		});

		return (
			<SectionHomeWrapper title="Cloud-only Groups Management">
				{this.state.alertVisible && (
					<Alert variant="success" onClick={this.handleAlertClose} onClose={this.handleAlertClose}>
						Your new group has been successfully created.
					</Alert>
				)}
				{this.state.alertVisibleAccessReview && (
					<Alert variant="success" onClick={this.handleAlertClose} onClose={this.handleAlertClose}>
						Your new access review has been successfully created.
					</Alert>
				)}
				{this.state.isLoading ? (
					<ProgressBar indeterminate hideValueLabel label="Loading your groups..." />
				) : (
					<Table
						header={
								<Toolbar>
									<Toolbar.Item>
										<TextInput
											inline
											placeholder="Search groups"
											size="small"
											onChange={this.handleSearchTextChange}
											value={this.state.searchText}
										/>
									</Toolbar.Item>
									<Toolbar.Item>
										<Select
											size="small"
											inline
											onChange={this.handleFilterChange}
										>
											<option className='dropdown-filter-color' value="All">All</option>
											<option className='dropdown-filter-color' value="Assigned">Assigned</option>
											<option className='dropdown-filter-color' value="Dynamic">Dynamic</option>
										</Select>
									</Toolbar.Item>
									<Toolbar.Item right>
										<Button
											variant="primary"
											size="small"
											onClick={() => this.setState({ modalVisible: true })}
										>
											<PlusFilledIcon size="small" />
											<span>New Entra ID Group</span>
										</Button>
									</Toolbar.Item>
									<Toolbar.Item>
										<Button variant="secondary" size="small" onClick={this.handleRefresh}>
											<RefreshIcon size="small" />
											<span> Refresh</span>
										</Button>
									</Toolbar.Item>
								</Toolbar>
						}
						footer={
							<Paginator
								data={this.state.filteredGroups}
								totalItems={this.state.groups.length}
								onPageChange={(groupsPage: Group[]) => {
									this.setState({
										groupsPage
									});
								}}
							/>
						}
					>
						<Table.Head>
							<Table.Head.Row>
								<Table.Head.Cell>Name</Table.Head.Cell>
								<Table.Head.Cell>Type</Table.Head.Cell>
								<Table.Head.Cell>Actions</Table.Head.Cell>
							</Table.Head.Row>
						</Table.Head>
						<Table.Body>{tableRows}</Table.Body>
					</Table>
				)}
				{this.state.modalVisible &&<CloudGroupsModal
					visible={this.state.modalVisible}
					onConfirm={this.handleModalConfirmed}
					onClose={this.handleModalClosed}
				/>}
				<CloudGroupsAccessReviewModal
					visible={this.state.modalVisibleAccessReview}
					onConfirm={this.handleModalAccessReviewConfirmed}
					onClose={this.handleModalAccessReviewClosed}
					groupSelected={this.state.groupSelected}
				/>
				<TextPassage>
					<p>
						<i>
							For general assistance with cloud-only groups, please visit the {' '}
							<TextLink
								target="_blank"
								href="https://appwiki.xom.cloud/docs/admin/cloudssp/CloudSelfServicePortal.html#cloud-only-group-management"
							>
								Cloud-only Groups Section
							</TextLink>{' '}
						</i>
					</p>
					<p>
						<i>
							If you need assistance, please reach out to the {' '}
							<TextLink
								target="_blank"
								href="https://gotocloud.xom.cloud/request-consultation/"
							>
								Cloud Consulting team.
							</TextLink>{' '}
						</i>
					</p>
				</TextPassage>
			</SectionHomeWrapper>
		);
	}
}
