import SubmitPortOpeningRequestRule from './SubmitPortOpeningRequestRule';

export default class SubmitPortOpeningRequest {

	subscriptionRequestId!: number;

	portOpeningRules!: SubmitPortOpeningRequestRule[];

	direction!: string;

	environment: string;

	contactId!: string;

	emergency!: boolean;

	incidentNumber!: string;

	businessJustification!: string;

	comments!: string;

	customerCoordination!: boolean;

	approverId!: string;

	delegatedApproverId!: string;

	portOpeningRequestId!: number;

	custodianApproval!: boolean;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}