import { Enumeration } from '../../../utils/AppEnum';
import { VirtualNetwork } from '../../entities/AzureResources/VirtualNetwork';
import { PortOpeningRequestsDirection } from './PortOpeningRequestsDirection';

export class RuleRegion extends Enumeration<RuleRegion> {

    constructor();
    constructor(name: string, value: number);
    constructor(name: string, value: number, porDirections: PortOpeningRequestsDirection[])

    constructor(name?: string, value?: number, porDirections?: PortOpeningRequestsDirection[]) {
        super(name, value);
        this.porDirections = porDirections ?? [];
    }

    public porDirections: PortOpeningRequestsDirection[];

    public static America = new RuleRegion('America', 0);

    public static EAME = new RuleRegion('EAME', 1);

    public static AP = new RuleRegion('AP', 2);

    public static USSC = new RuleRegion('USSC', 3, new PortOpeningRequestsDirection().list());

    public static USE1 = new RuleRegion('USE1', 4, new PortOpeningRequestsDirection().list());

    public static ASST = new RuleRegion('ASST', 5, new PortOpeningRequestsDirection().list());

    public static ASEA = new RuleRegion('ASEA', 6, [PortOpeningRequestsDirection.SubscriptionToOnPrem]);

    public static WW = new RuleRegion('WW', 7, [PortOpeningRequestsDirection.SubscriptionToInternet]);

    public listByDirection = (porDirection: PortOpeningRequestsDirection, validNetworks: VirtualNetwork[], subscriptionRequestId: number): RuleRegion[] => {
        const vNetDictionary = {
            'America': 'America',
            'EAME': 'EAME',
            'AP': 'AP',
            'USSC': 'southcentralus',
            'USE1': 'eastus',
            'ASST': 'southeastasia',
            'ASEA': 'eastasia',
            'WW': 'WW',
        }
        const temp = this.list().filter(region => {
            return validNetworks.find(vnet => vNetDictionary[region.name].toLowerCase() == vnet.location.toLowerCase())
        });
        // Subcription with exception Region Rules
        if (subscriptionRequestId == 1243)
            return (temp)
        else
            return (temp.filter(region => region.porDirections.includes(porDirection)))
    }
}