import React, { useState } from 'react';
import { Button, Table, Checkbox } from 'react-unity';
import DoNotDecryptRule from '../../models/entities/DoNotDecryptRule';
import { DNDRColumn } from './components/RuleColumnsLayout';
import styled from 'styled-components';
import { DoNotDecryptRequestsDirection } from '../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import RuleModalSubToInternet from './components/dndrRule/RuleModalSubToInternet';
import RuleModalSubToOnPrem from './components/dndrRule/RuleModalSubToOnPrem';
import { VirtualNetwork } from '../../models/entities/AzureResources/VirtualNetwork';

interface DoNotDecryptRequestsByRuleRowProps {
	rule: DoNotDecryptRule;
	indexOnList: number;
	columns: DNDRColumn[];
	ruleIndex: number;
	selectedRules: DoNotDecryptRule[];
	onChangeSelectedRules: any;
	subscriptionRequestName: string;
	validVnets: VirtualNetwork[];
}

const StyledButton = styled(Button)`
	width: 120px;
	height: 34px;
`;

const StyledCheckbox = styled.input`
	margin: 0 auto !important;
`;

const DoNotDecryptRequestsByRuleRow = ({
	rule,
	ruleIndex,
	columns,
	selectedRules,
	onChangeSelectedRules,
	subscriptionRequestName,
	validVnets
}: DoNotDecryptRequestsByRuleRowProps) => {
	const defaultLimit = 10;
	const [ruleModalVisible, setRuleModalVisible] = useState(false);

	const handleViewDetails = (e) => {
		e.stopPropagation();
		setRuleModalVisible(true)
	};

	const getRulesModalProps = () => {
		return {
			visible: ruleModalVisible,
			onConfirm: () => {},
			onClose: () => setRuleModalVisible(false),
			editingRule: rule,
			validVirtualNetworks: validVnets,
			isReadOnly: true,
			isFromHistory: true
		};
	}

	return (
		<>
			<Table.Body.Row style={{ cursor: 'pointer' }} key={rule.id} onClick={() => onChangeSelectedRules(rule)}>
				<Table.Body.Cell>
					<StyledCheckbox
						type="checkbox"
						value={rule.id}
						checked={selectedRules.some((selectedRule) => selectedRule.id === rule.id)}
					></StyledCheckbox>
				</Table.Body.Cell>
				{columns?.map((column, colIndex) => (
					<Table.Body.Cell
						key={`${ruleIndex}-${colIndex}`}
						className="em-u-margin-right-auto"
						title={column.cell(rule) || '-'}
					>
						{column.cell(rule)?.length > (column.limit || defaultLimit)
							? `${column
									.cell(rule)
									.trim()
									.substring(0, column.limit || defaultLimit)}...`
							: column.cell(rule) || '-'}
					</Table.Body.Cell>
				))}
				<Table.Body.Cell>
					<StyledButton onClick={handleViewDetails} variant="primary">
						See Details
					</StyledButton>
				</Table.Body.Cell>
			</Table.Body.Row>
			{rule.direction.value === DoNotDecryptRequestsDirection.SubscriptionToInternet.value && (
				<RuleModalSubToInternet
					{...getRulesModalProps()}
					subscriptionRequestName={subscriptionRequestName}
				/>
			)}
			{rule.direction.value === DoNotDecryptRequestsDirection.SubscriptionToOnPrem.value && (
				<RuleModalSubToOnPrem
					{...getRulesModalProps()}
					subscriptionRequestName={subscriptionRequestName}
				/>
			)}			
		</>
	);
};

export default DoNotDecryptRequestsByRuleRow;
