import React, { useState, useEffect } from 'react';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import DoNotDecryptRequestService from '../../services/DoNotDecryptRequestService';				
import DoNotDecryptRequest from '../../models/entities/DoNotDecryptRequest';				
import DoNotDecryptRequestsByDNDR from './DoNotDecryptRequestsByDNDR';
import DoNotDecryptRequestsByRule from './DoNotDecryptRequestsByRule';
import { AlertVariant, ProgressBar, Tabs } from 'react-unity';
import DoNotDecryptRule from '../../models/entities/DoNotDecryptRule';				
import styled from 'styled-components';
import AlertModal from '../common/modals/AlertModal';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import { useNavigate , useParams} from 'react-router-dom';
import { VirtualNetwork } from '../../models/entities/AzureResources/VirtualNetwork';

const StyledTabsItem = styled(Tabs.Item)`
	padding-top: 0px !important;
`;

interface DoNotDecryptRequestsHistoryProps {
	subscriptionName?: string;
	subscriptionId?: number;
}

type IParam = {
	id: string;
}

const DoNotDecryptRequestsHistory = ({}: DoNotDecryptRequestsHistoryProps) => {
	const subscriptionRequestsService = new SubscriptionRequestsService();
	const doNotDecryptRequestService = new DoNotDecryptRequestService();
	const { id } = useParams<IParam>();
	const subscriptionId = parseInt(id);
	const [subscription, setSubscription] = useState<SubscriptionRequest>(null);
	const [dndrs, setDndrs] = useState<DoNotDecryptRequest[]>(null);				
	const [rules, setRules] = useState<DoNotDecryptRule[]>(null);				
	const [endModal, setEndModal] = useState<AlertBanner>({ visible: false });
	const [validVirtualNetworks, setValidVirtualNetworks] = useState<VirtualNetwork[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		const getData = async () => {
			try {
				const [resDndrs, resRules, subscription] = await Promise.all([
					doNotDecryptRequestService.getBySubscriptionId(subscriptionId),
					doNotDecryptRequestService.getRulesBySubscriptionId(subscriptionId),
					subscriptionRequestsService.getById(subscriptionId),
					
				]);
				setDndrs(resDndrs.sort((a, b) => (a.id < b.id ? 1 : -1)));
				setRules(resRules.sort((a, b) => (a.id < b.id ? 1 : -1)));
				setSubscription(subscription);
				const vNets = await subscriptionRequestsService.getAddressSpaces(subscriptionId);
				setValidVirtualNetworks(vNets);
			} catch {
				handleEndModal('Something went wrong, try again later.', 'error', 5000);
			}
		};
		getData();
	}, []);

	const redirectToSubscriptions = () => {
		setEndModal({ ...endModal, visible: false });
		navigate('/subscriptions');
	};

	const handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		setEndModal({
			visible: true,
			text,
			variant,
		});
		setTimeout(() => redirectToSubscriptions(), timeout);
	};

	return (
		<>
			<SectionHomeWrapper
				reference={subscription?.createdSubscriptionName || ''}
				title="Request history"
				subtitle="You could see, modify or delete rules depending on your role."
				soft
			>
				{!subscription ? (
					<ProgressBar
						label="Loading subscription information..."
						className="em-u-margin-top-half"
						indeterminate
						hideValueLabel
					/>
				) : (
					<Tabs variant="underlined" style={{ fontSize: 20 }}>
						<StyledTabsItem title="See by DNDR">
							{dndrs ? (
								<DoNotDecryptRequestsByDNDR subscription={subscription} dndrs={dndrs} />
							) : (
								<ProgressBar
									label="Loading DPI (Deep Packet Inspection) Exception Requests..."
									className="em-u-margin-top-half"
									indeterminate
									hideValueLabel
								/>
							)}
						</StyledTabsItem>
						<StyledTabsItem title="See by Rule">
							{rules ? (
								<DoNotDecryptRequestsByRule
									subscription={subscription}
									rules={rules}
									validVnets={validVirtualNetworks}
								/>
							) : (
								<ProgressBar
									label="Loading Rules..."
									className="em-u-margin-top-half"
									indeterminate
									hideValueLabel
								/>
							)}
						</StyledTabsItem>
					</Tabs>
				)}
			</SectionHomeWrapper>
			<AlertModal {...endModal} willTimeout={false} onClose={redirectToSubscriptions} />
		</>
	);
};

export default DoNotDecryptRequestsHistory;
