import React, { useState, useEffect } from 'react';
import { Button, PlusFilledIcon, Select, Toolbar, Searchbar, RefreshIcon } from 'react-unity';
import CheckboxFilter, { CheckboxOption } from '../../common/tables/CheckboxFilter';
import Filter from '../../common/tables/Filter';
import CSVGeneratorButton from '../../common/CSVGeneratorButton';
import Feedback from '../../../models/entities/Feedback';
import moment from 'moment';

type FeedbackFilters = {
	filtered: Feedback[];
    showFeedback: Boolean;
	searchGeneral: string;
	state: CheckboxOption[];
    category: CheckboxOption[];
	startRange: string;
	endRange: string;
	date: string;
};

interface FeedbackHomeFiltersProps {
    setFilteredFeedback: any;
	createFeedbackModal: any;
	fillTable: any;
    allFeedback: Feedback[];
    showFeedback: boolean;
	setRefresh: any;
	refresh: boolean;
}

const FeedbackHomeFilters = ({
    setFilteredFeedback,
	createFeedbackModal,
	fillTable,
    allFeedback,
	setRefresh,
	refresh,
}: FeedbackHomeFiltersProps) => {
	const defaultFilters = {
        showFeedback: true,
		filtered: allFeedback,
		searchGeneral: '',
		state: [
			{ label: 'NEW', value: 'New', checked: true },
			{ label: 'APPROVED', value: 'Approved', checked: true },
			{ label: 'DONE', value: 'Done', checked: true },
			{ label: 'COMMITED', value: 'Commited', checked: true },
            { label: 'IN PROGRESS', value: 'In Progress', checked: true },
			{ label: 'REMOVED', value: 'Removed', checked: true },
			{ label: 'REJECTED', value: 'Rejected', checked: true },
            { label: 'TO DO', value: 'To Do', checked: true },
			{ label: 'UNKNOWN', value: 'UNKNOWN', checked: true },
		],
        category: [
			{ label: 'Bug', value: 'Bug', checked: true },
			{ label: 'Comment', value: 'Comment', checked: true },
            { label: 'Question', value: 'Question', checked: true },
			{ label: 'Suggestion', value: 'Suggestion', checked: true },
		],
		startRange: null,
		endRange: moment().utc().format(),
        date: 'All'
	} as FeedbackFilters;

    const filterFeedbacks = () => {
		let filtered = [];
        const filterCallback = (feed: Feedback) => {
			return (
				filter.filterCheckBox('state', feed.workItemState != undefined ? feed.workItemState.name : 'UNKNOWN') &&
				filter.filterCheckBox('category', feed.category.name) && filterDateRange(feed.submitDate) && (
					filter.filterSearchText('searchGeneral', feed.submittedBy.displayName) ||
					filter.filterSearchText('searchGeneral', feed.title) 
				)
			);
		}; 

		filtered = allFeedback.filter(filterCallback);
		filter.filters.filtered = filtered;
		setFilteredFeedback(filtered);
	};

	useEffect(() => {
		filter.updateFilterFunction(filterFeedbacks);
	}, [allFeedback]);

	useEffect(() => {
		filter.filter();
	}, []);

	useEffect(() => {
		if (refresh == true) {
			setTimeout(() => {
				handleRefresh();
				setRefresh(false);
			}, 2000);
		}
	}, [refresh]);

    const [filter] = useState<Filter<FeedbackFilters>>(new Filter(filterFeedbacks, defaultFilters));

	const filterDateRange = (date : Date) => {
		if (filter.filters.date == 'All') return true;
		return filter.isInDateRange("startRange", "endRange", date);
	}

	const handleSelectedDate = (e) => {
		filter.filters.date = e.target.value;
		let start = moment();
		if (filter.filters.date == 'All') {
			filter.filters.startRange = null;
		}
		if (filter.filters.date == 'Last 7 days') {
			filter.filters.startRange = start.subtract(7, 'days').utc().format();
		}
		if (filter.filters.date == 'Last 30 days') {
			filter.filters.startRange = start.subtract(30, 'days').utc().format();
		}
		if (filter.filters.date == 'Last 90 days') {
			filter.filters.startRange = start.subtract(90, 'days').utc().format();
		}
		if (filter.filters.date == 'Last year') {
			filter.filters.startRange = start.subtract(1, 'years').utc().format();
		}
		filterFeedbacks();
	}

	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchGeneral');
	};

	const handleRefresh = () => {
		filter.updateFilter('', 'searchGeneral');
		fillTable();
	};

	return (
		<>
			{filter && (
				<>
					<Toolbar>
						<Toolbar.Item className="searchbar-width">
							<Searchbar
								condensed
								buttonProps={{ onClick: clearSearchbar }}
								inputProps={{
									value: filter.filters.searchGeneral,
									onChange: (e) => filter.handleSearchTextChange(e, 'searchGeneral'),
									placeholder: 'Search on Title or Submitted by',
								}}
							/>
						</Toolbar.Item>
						<Toolbar.Item right>
							<Button
								variant="primary"
								size="small"
								onClick={() => createFeedbackModal(true)}
								style={{ marginRight: '10px' }}
								>
								<PlusFilledIcon size="small" />
								New Feedback
							</Button>
							<CSVGeneratorButton
								data={filter.filters.filtered}
								fileName={`export_feedback_${new Date().toLocaleDateString()}.csv`}
								columns={
									[
										{ header: 'Title', cell: (feedback: Feedback) => feedback.title },
										{ header: 'Category', cell: (feedback: Feedback) => feedback.category?.name },
										{ header: 'Description', cell: (feedback: Feedback) => feedback.description },
										{ header: 'Submitted By', cell: (feedback: Feedback) => feedback.submittedBy.displayName },
										{ header: 'Submitted By Email', cell: (feedback: Feedback) => feedback.submittedBy.userPrincipalName?.address },
										{ header: 'Submitted At', cell: (feedback: Feedback) => moment(feedback.submitDate).format('MM/DD/YYYY') },
										{ header: 'Work Item State', cell: (feedback: Feedback) => feedback.workItemState?.name },
										{ header: 'Work Item Number', cell: (feedback: Feedback) => feedback.workItemNumber?.toString() },
									]
								}
							/>
							<Button variant="secondary" size="small" onClick={handleRefresh}>
								<RefreshIcon size="small" />
								<span> Refresh</span>
							</Button>
						</Toolbar.Item>
					</Toolbar>
					<Toolbar>
						<Toolbar.Item>
							<CheckboxFilter
								label="State"
								onChange={(event) => filter.handleCheckboxChange(event, 'state')}
								options={filter.filters.state}
							/>
						</Toolbar.Item>
                        <Toolbar.Item>
							<CheckboxFilter
								label="Category"
								onChange={(event) => filter.handleCheckboxChange(event, 'category')}
								options={filter.filters.category}
							/>
						</Toolbar.Item>
						<Button.Group>
							<p className="filter-days-p">Filter by days</p>
							<Select
								style={{ cursor: 'pointer' }}
								inline
								onChange={(event) => handleSelectedDate(event)}
							>
								<option value="All">All</option>
								<option value="Last 7 days">Last 7 days</option>
								<option value="Last 30 days">Last 30 days</option>
								<option value="Last 90 days">Last 90 days</option>
								<option value="Last year">Last year</option>
							</Select>
						</Button.Group>
						<Toolbar.Item right>
							<span
								className="clear-filters"
								onClick={() =>
									filter.updateFilters({ ...defaultFilters, showFeedback: filter.filters.showFeedback })
								}
							>
								Clear filters
							</span>
						</Toolbar.Item>
					</Toolbar>
				</>
			)}
		</>
	);
};

export default FeedbackHomeFilters;
