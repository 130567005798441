import React from 'react';
import { Tabs, Card, Container, PromoBlock, Button, Field } from 'react-unity';
import './Home.css';
import HomeCard from './HomeCard';
import HomeCardData, { HomeCardTag } from '../../utils/HomeCardData';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../../models/enums/UserRole';

interface HomeProps {}

interface HomeState {
	textToSearch: string;
}

export default class Home extends React.Component<HomeProps,HomeState> {
	cards: HomeCardData[];

	constructor(props) {
		super(props);

		this.cards = [
			{
				title: 'Azure Subscriptions',
				description: 'Request a new subscription or view details associated with a subscription. Additionally, subscription owners can filter and export a list of subscription details.',
				startingLink: '/subscriptions',
				actions: [
					'Request a New Subscription',
					'Filter and export subscription details',
					'Manage roles',
					'View Cost Report (only UIT subscriptions)',
					'Request Policy Exception',
				],
				tags: [HomeCardTag.Subscriptions],
			},
			{
				title: 'Entra ID Applications',
				description: 'View your Entra ID applications and see which applications interact with your applications.',
				actions: [
					'Create a New Enterprise App',
					'View Application Permissions',
					'Grant Admin Consent',
					'Enable Seamless Single Sign-On',
				],
				startingLink: '/adApps',
				tags: [HomeCardTag.AzureAD],
			},
			// {
			// 	title: 'Entra ID 3rd Party Applications',
			// 	description: 'View your Entra ID 3rd Party applications and see which applications interact with your applications.',
			// 	actions: [
			// 		'Create a New 3rd Party App',
			// 		'View Application Permissions',
			// 		'Grant Admin Consent',
			// 		'Enable Seamless Single Sign-On',
			// 	],
			// 	startingLink: '/ad3rdPartyApps',
			// 	tags: [HomeCardTag.AzureAD],
			// },
			{
				title: 'Cloud Account Management',
				description: 'Request a new cloud-only account for a specialized use case or reset your password for an existing cloud-only account.',
				actions: [
					'Request Cloud-only Account',
					'Delete Cloud-only Account',
					'Reset Password',
				],
				startingLink: '/cloudAccounts',
				tags: [HomeCardTag.AzureAD],
			},
			{
				title: 'Cloud Groups Management',
				description: 'You can create cloud-only groups to assign permissions to users at once without having to individually grant access.',
				startingLink: '/cloudGroups',
				tags: [HomeCardTag.AzureAD],
				actions: [
					'Create new Entra ID group',
					'Manage group membership',
				],
			},
			{
				title: 'Container SSP',
				description: 'Container Self-Service Portal to manage your namespace(s).',
				actions: [
					'Request New Namespace',
					'Edit Your Namespace (Quota, RoleBindings, etc)',
					'Transfer Ownership',
					'Reattest to Owner R&R',
					'Delete Your Namespace',
					'Find Namespace Owner'
				],
				startingLink: 'https://goto/containerSSP',
				tags: [HomeCardTag.Containers],
			},
			{
				title: 'Port Opening Requests: On-Premises / NECT Update',
				kicker: 'External Services',
				description: 'Request a Port Opening from on-prem to internet or submit a NECT Update Request for Certified Internal or External Network Connections',
				actions: [
					'Request a Port Opening',
					'Request a NECT Update',
				],
				external: true,
				startingLink: 'https://goto/aff',
				tags: [HomeCardTag.Network],
			},
			{
				title: 'Port Opening Requests: Connected Azure',
				description: 'Request a Port Opening from your connected subscriptions to Internet or ExxonMobil Network.',
				actions: [
					'Request a Port Opening',
				],
				startingLink: '/portOpeningRequests/connectedAzure',
				tags: [HomeCardTag.Network]
			},
			{
				title: 'DPI (Deep Packet Inspection) Exception Requests: Connected Azure',
				description: 'Request a DPI (Deep Packet Inspection) Exception Rule from your connected subscriptions to Internet.',
				actions: [
					'Request a DPI (Deep Packet Inspection) Exception Rule',
				],
				startingLink: '/doNotDecryptRequests/connectedAzure',
				tags: [HomeCardTag.Network]
			},
			{
				title: 'DNS Management',
				kicker: 'External Services',
				description: 'Create subdomains associated with the top-level domain xom.cloud.',
				actions: [
					'Request subdomain',
					'Delegate zone manager',
					'Txt record manager',
				],
				external: true,
				startingLink: 'https://goto/ddiforms',
				tags: [HomeCardTag.Network],
			},
			{
				title: 'NSG Flow Logs',
				description: 'Display and download NSGs Blob files from Centralized Storage Account.',
				actions: [
					'Search NSG Blob Files',
					'Download NSG Blob Files',
				],
				startingLink: '/nsg',
				tags: [HomeCardTag.Network],
			},
			{
				title: 'Entra ID B2B',
				kicker: 'External Services',
				description: 'Securely share applications and services with guests from any other trusted business organization.',
				actions: [
					'Invite external users (B2B)',
				],
				external: true,
				startingLink: 'http://goto/b2bbeta',
				tags: [HomeCardTag.AzureAD],
			},
			{
				title: 'SAML SSO Consultation',
				kicker: 'External Services',
				description: 'Set up a new Entra ID Single Sign On application or request a SAML configuration update, maintenance or consulting.',
				additionalLinks: [
					{
						label: 'Set-up new SAML SSO consultation',
						href: 'https://goto/SSOForm'
					},
					{
						label: 'Request SAML App maintenance consultation',
						href: 'http://goto/SSO-Support',
					},
				],
				external: true,
				tags: [HomeCardTag.AzureAD],
			},
			{
				title: 'Azure Subscription Documentation',
				kicker: 'Documentation',
				description: 'Explore these cloudWiki references.',
				additionalLinks: [
					{
						label: 'How to request an Azure subscription',
						href: 'https://appwiki.xom.cloud/docs/admin/cloudssp/HowToRequestAnAzureSubscription.html',
					},
					{
						label: 'How to request a connected Azure subscription',
						href: 'https://appwiki.xom.cloud/docs/admin/cloudssp/HowToRequestAnAzureSubscription.html#how-to-request-a-connected-azure-subscription',
					},
					{
						label: 'Subscription Owner responsibilities',
						href: 'https://appwiki.xom.cloud/docs/admin/gettingstarted/OwnerResponsibilities.html',
					},
					{
						label: 'Subscription Owner Learning Path',
						href: 'https://appwiki.xom.cloud/docs/admin/gettingstarted/LearningPathSub.html',
					},
					{
						label: 'How to gather a permission report for your subscription',
						href: 'https://appwiki.xom.cloud/docs/admin/cloudssp/how-to-perform-access-review.html',
					},
					{
						label: 'Using Privileged Identity Management (PIM) for role management',
						href: 'https://appwiki.xom.cloud/docs/Id-Auth/pim.html',
					},
				],
				tags: [HomeCardTag.Subscriptions, HomeCardTag.Documentation],
			},
			{
				title: 'Entra ID Documentation',
				kicker: 'Documentation',
				description: 'Explore these cloudWiki references.',
				additionalLinks: [
					{
						label: 'How to reset your cloud account passwords',
						href: 'https://appwiki.xom.cloud/docs/admin/cloudssp/how-to-reset-password.html',
					},
					{
						label: 'Application Permissions overview',
						href: 'https://appwiki.xom.cloud/docs/Id-Auth/admin-consent.html',
					},
					{
						label: 'Entra ID Application Owner responsibilities',
						href: 'https://appwiki.xom.cloud/docs/Id-Auth/app-owner-responsibilities.html',
					},
					{
						label: 'Seamless Single Sign-On',
						href: 'https://appwiki.xom.cloud/docs/Id-Auth/SeamlessSSO.html',
					},
					{
						label: 'Client Secrets and Certificates',
						href: 'https://appwiki.xom.cloud/docs/Id-Auth/Client-Secrets-And-Certificates.html',
					},
					{
						label: 'How to reassign a Subscription Owner if assigned user left the company',
						href: 'https://appwiki.xom.cloud/docs/admin/cloudssp/LeaverProcessOfSubOwner.html',
					},

				],
				tags: [HomeCardTag.AzureAD, HomeCardTag.Documentation],
			},
			{
				title: 'Next steps with Terraform',
				description: 'To start creating your Azure resources, learn more about Terraform best practices and next steps.',
				kicker: 'Documentation',
				additionalLinks: [ 
					{
						label: 'Terraform onboarding',
						href: 'https://goto/terraform',
					},
					{
						label: 'Terraform modules',
						href: 'https://goto/terraformModules',
					},
					{
						label: 'Terraform Repository Templates',
						href: 'https://goto/terraformTemplates',
					},
					{
						label: 'Terraform Enterprise Self Service Portal',
						href: 'https://goto/tfessp',
					},
					{
						label: 'Why should I use Infrastructure as Code?',
						href: 'https://www.developerportal.xom.cloud/articles/we-can-strike-oil-with-iac.mdx',
					},
				],
				tags: [HomeCardTag.Subscriptions],
			}
		].map(card => new HomeCardData(card));

		this.state = {
			textToSearch: '',
		};
	}

	renderCards = (filter?) => {
		let cards = this.cards.filter(card => card.contains(this.state.textToSearch));
		if (filter) {
			cards = cards.filter(filter);
		}

		return (
			<Card.List>
				{cards.map((card, index) => <HomeCard info={card} key={index} />)}
			</Card.List>
		);
	};

	clearSearch = () => {
		this.setState({ textToSearch: '' });
	};
	
	render() {
		return (
			<>
				<PromoBlock id="search-block" className="em-u-padding-half">
					<Container>
						<Field className="em-c-search-form em-u-padding-top-double">
							<Field.Body className="em-c-search__body">
								<input
									placeholder="Search for services"
									className="em-c-input"
									value={this.state.textToSearch}
									onChange={
										(ev) => {
											this.setState({
												textToSearch: ev.target.value
											});
										}
									}
								/>
								<Button
									id="clear-search-button"
									variant="tertiary"
									onClick={this.clearSearch}
								>
										Clear
								</Button>
							</Field.Body>
						</Field>
					</Container>
				</PromoBlock>
				<Container className="em-u-margin-top-double">
					<Tabs variant="underlined">
						<Tabs.Item title="All">
							{this.renderCards()}
						</Tabs.Item>
						<Tabs.Item title="Subscriptions" onClick={this.clearSearch}>
							{this.renderCards(
								(card: HomeCardData) => card.tags.includes(HomeCardTag.Subscriptions)
							)}
						</Tabs.Item>
						<Tabs.Item title="Entra ID Services" onClick={this.clearSearch}>
							{this.renderCards(
								(card: HomeCardData) => card.tags.includes(HomeCardTag.AzureAD)
							)}
						</Tabs.Item>
						<Tabs.Item title="Network" onClick={this.clearSearch}>
							{this.renderCards(
								(card: HomeCardData) => card.tags.includes(HomeCardTag.Network)
							)}
						</Tabs.Item>
						<Tabs.Item title="Documentation" onClick={this.clearSearch}>
							{this.renderCards(
								(card: HomeCardData) => card.tags.includes(HomeCardTag.Documentation)
							)}
						</Tabs.Item>
						<Tabs.Item title="Containers" onClick={this.clearSearch}>
                            {this.renderCards(
                                (card: HomeCardData) => card.tags.includes(HomeCardTag.Containers)
                            )}
						</Tabs.Item>
					</Tabs>
				</Container>
				<PromoBlock id="still-need-help">
					<PromoBlock.Title>
						Still need help?
					</PromoBlock.Title>
					<PromoBlock.Description>
						Get a Cloud Consultation today.
					</PromoBlock.Description>
					<Button
						size="large"
						variant="primary"
						onClick={() => {
							window.open('https://goto/cloudconsult', '_blank');
						}}
					>
					Get Started
					</Button>
				</PromoBlock>
			</>
		);
	}
}