import React, { useState } from 'react';
import { Table } from 'react-unity';
import Paginator from '../common/tables/Paginator';
import ApprovalsDPIsTableRow from './ApprovalsDPIsTableRow';
import DoNotDecryptRequest from '../../models/entities/DoNotDecryptRequest';

interface ApprovalsDPIsTableProps {
    filteredDPIs: DoNotDecryptRequest[];
}

const ApprovalsAccountsTable = ({
    filteredDPIs,
}: ApprovalsDPIsTableProps) => {
    const [dpisOnPage, setDpisOnPage] = useState<DoNotDecryptRequest[]>([]);
    return (
        <Table
            footer={
                <Paginator
                    data={filteredDPIs}
                    onPageChange={(dpis: DoNotDecryptRequest[]) => {
                        setDpisOnPage(dpis)
                    }}
                />
            }
        >
            <Table.Head>
                <Table.Head.Row>
                    <Table.Head.Cell>DPI #</Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'>Subscription</Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'>Status</Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'>Action</Table.Head.Cell>
                </Table.Head.Row>
            </Table.Head>
            <Table.Body>
                {dpisOnPage.map((dpi: DoNotDecryptRequest) => {
                    return <ApprovalsDPIsTableRow
                        dpi={dpi}
                    />;
                }
                )}
            </Table.Body>
        </Table>
    );
}

export default ApprovalsAccountsTable;