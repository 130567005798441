import { RuleAction } from '../enums/POR/RuleAction';
import { RuleOperation } from '../enums/POR/RuleOperation';
import { TrafficDirection } from '../enums/POR/TrafficDirection';
import { RuleRegion } from '../enums/POR/RuleRegion';
import { PortOpeningRequestsDirection } from '../enums/POR/PortOpeningRequestsDirection';

export default class PortOpeningRule {
	id!: number;

	portOpeningRequestId!: number;

	action!: RuleAction;

	operation!: RuleOperation;

	description!: string;

	sourceIPs!: string;

	destinationIPs!: string;

	destinationURLs!: string;

	sourcePorts!: string;

	protocol!: string;

	service!: string;

	name!: string;

	azureName!: string;

	direction!: PortOpeningRequestsDirection;

	destinationPorts!: string;

	region!: RuleRegion;

	trafficDirection!: TrafficDirection;

	isTemporary!: boolean;

	isBeingAttested!: boolean;

	expirationDate!: Date;

	constructor(obj: any) {
		Object.assign(this, obj);
		this.direction = new PortOpeningRequestsDirection().fromValue(obj.direction?.value);
	}
}